import React from 'react';
import { Route, Link } from "react-router-dom";

const PagetypeSubmenu = () => {
    const pathname = window.location.pathname;
    return (
        <div>
            <div className="dk-adminSubmenuMain">
                <Route>
                    <ul>
                        <li><Link to="/page-type/company-info" className={`${pathname.match('/page-type/company-info') ? 'sub-active' : ''}`}>Company Info</Link></li>
                        <li><Link to="/page-type/about-us" className={`${pathname.match('/page-type/about-us') ? 'sub-active' : ''}`}>About us</Link></li>
                        <li><Link to="/page-type/services" className={`${pathname.match('/page-type/services') ? 'sub-active' : ''}`}>Services</Link></li>
                        <li><Link to="/page-type/video" className={`${pathname.match('/page-type/video') ? 'sub-active' : ''}`}>Video</Link></li>
                        <li><Link to="/page-type/album" className={`${pathname.match('/page-type/album') ? 'sub-active' : ''}`}>Album</Link></li>
                        <li><Link to="/page-type/social-media" className={`${pathname.match('/page-type/social-media') ? 'sub-active' : ''}`}>Social Media</Link></li>
                    </ul>
                </Route>
            </div>
        </div>
    )
}

export default PagetypeSubmenu;
