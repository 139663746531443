import React from "react";
import { Route, Link } from "react-router-dom";

const CandAccSubmenu = () => {
  const pathname = window.location.pathname;
  return (
    <div>
      <div className="dk-adminSubmenuMain">
        <Route>
          <ul>
            <li>
              <Link
                to="/candidate/dash-board"
                className={`${
                  pathname.match("/candidate/dash-board") ? "sub-active" : ""
                }`}
              >
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to="/candidate/mailbox"
                className={`${
                  pathname.match("/candidate/mailbox") ? "sub-active" : ""
                }`}
              >
                Mailbox
              </Link>
            </li>
            <li>
              <Link
                to="/candidate/jobs"
                className={`${
                  pathname.match("/candidate/jobs") ? "sub-active" : ""
                }`}
              >
                Jobs
              </Link>
            </li>
            <li>
              <Link
                to="/candidate/promotions"
                className={`${
                  pathname.match("/candidate/promotions") ? "sub-active" : ""
                }`}
              >
                Referrals
              </Link>
            </li>
            <li>
              <Link
                to="/candidate/dk-services"
                className={`${
                  pathname.match("/candidate/dk-services") ? "sub-active" : ""
                }`}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/candidate/my-profile"
                className={`${
                  pathname.match("/candidate/my-profile") ? "sub-active" : ""
                }`}
              >
                My Profile
              </Link>
            </li>
            <li>
              <Link
                to="/candidate/dk-resume"
                className={`${
                  pathname.match("/candidate/dk-resume") ? "sub-active" : ""
                }`}
              >
                DK Resume
              </Link>
            </li>
            <li>
              <Link
                to="/candidate/desired-job"
                className={`${
                  pathname.match("/candidate/desired-job") ? "sub-active" : ""
                }`}
              >
                Desired Job
              </Link>
            </li>
            {/* <li><Link to="#/candidate-acc/actions" className={`${pathname.match('/candidate-acc/mailshot') ? 'sub-active' : ''}`}>Inactive</Link></li>
                        <li><Link to="#/candidate-acc/notification" className={`${pathname.match('/dashboard/templates') ? 'sub-active' : ''}`}>Blocked</Link></li> */}
            <li>
              <Link
                to="/candidate/compliance"
                className={`${
                  pathname.match("/candidate/compliance") ? "sub-active" : ""
                }`}
              >
                Settings 
              </Link>
            </li>
            <li>
              <Link
                to="/candidate/faq"
                className={`${
                  pathname.match("/candidate/faq") ? "sub-active" : ""
                }`}
              >
                FAQ
              </Link>
            </li>
            <li>
              <Link
                to="/candidate/about-us"
                className={`${
                  pathname.match("/candidate/about-us") ? "sub-active" : ""
                }`}
              >
                About Us
              </Link>
            </li>
          </ul>
        </Route>
      </div>
    </div>
  );
};

export default CandAccSubmenu;
