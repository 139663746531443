import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav, Dropdown, FormControl } from "react-bootstrap";
import Step1 from "./Step1";
import Header from "../../../../../elements/header/Header";
// import Navbar from "../../../../../elements/navbar/dkgcrm/trainnig/CommanTraining";
import Navbar from "../../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../../elements/submenu/mainmenu/JobspecSubmenu";
import {
  getCategories,
  getSubCategories,
  clearState,
} from "../../../../../../slice/commonTraning/processSlice";
import { FaArrowsAlt } from "react-icons/fa";
import AddNewCategory from "./modal/maincategory/AddNewCategory";
import EditCategory from "./modal/maincategory/EditCategory";
import ReorderCategory from "./modal/maincategory/ReorderCategory";
import DeleteDummy from "./modal/maincategory/DeleteDummy";
import {
  showSuccess,
  // showError,
  // clearMessage,
} from "../../../../../../components/utils/messages/messageSlice";
// import { EditorState, ContentState, convertToRaw } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
// import htmlToDraft from "html-to-draftjs";
// import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import $ from "jquery";

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className=""
          placeholder="Search Text..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value ||
              child.props.children.toString().toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

const Overview = () => {
  const dispatch = useDispatch();
  const {
    isSuccess,
    isLoading,
    isCatUpdate,
    isError,
    isDelete,
    //isReoder,
    isSubCatUpdate,
    isSubReoder,
    isCatInsert,
    categoryProcessList,
  } = useSelector((state) => state.process);

  const [data, setData] = useState([]);
  const [catRowId, setCatRowId] = useState("");
  const [catRowName, setCatRowName] = useState("");
  const [activeEditTab, setActiveEditTab] = useState(false);
  //const [activeSubCat, setActiveSubCat] = useState("");
  const [activeSTab, setActiveSTab] = useState(false);

  useEffect(() => {
    if (!isLoading && categoryProcessList && categoryProcessList.length > 0) {
      setData(categoryProcessList);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isDelete) {
      setActiveEditTab(false);
    }
  }, [isSubCatUpdate, isCatUpdate, isDelete]);

  useEffect(() => {
    if (isSubReoder) {
      dispatch(clearState());
      dispatch(getSubCategories({ categoryId: catRowId, keys: "details" }));
    }
  }, [isSubReoder]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCategories({}));
      } catch (err) {
        // dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch, isCatInsert, isDelete]);

  useEffect(() => {
    if (isError) {
      // dispatch(showError({ msg: "Error while creating categories" }));
    }
    if (isCatInsert) {
      dispatch(showSuccess({ msg: "Category added successful created" }));
    }
  }, [isError, isCatInsert]);

  const [roleeName, setRoleTypeName] = useState("Select Company");
  const [activeTab, setActiveTab] = useState(null);

  const selectCat = async (catid, catName) => {
    setActiveSTab(true);
    setActiveEditTab(true);
    setCatRowId(catid);
    setCatRowName(catName);
    setActiveTab(catName);
    setRoleTypeName(catName);
    await dispatch(getSubCategories({ categoryId: catid, keys: "details" }));
    $(".activeSubTab").removeClass("activeSubTab");
    $(".activesSubTab").removeClass("activesSubTab");
  };

  const [isReorderModal, setIReorderModal] = useState(false);
  const showreorderModal = () => {
    setIReorderModal(true);
  };
  const hidereorderModal = () => {
    setIReorderModal(false);
  };

  return (
    <>
      {/* <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="Training" moduleLink="/dashboard/training" linkcolor="#3c4c62" mainheaderTitle=" - Comman" textcolor="#3c4c62" /> */}
      <Header
        dashboardheaderLink="DKG CRM "
        dashboardLink="/dashboard/summary"
      />
      <Navbar />
      <Submenu />

      <div
        className="dkg-clientlist-pageCon dkg-payment-qt-pageCon"
        style={{ marginTop: "140px" }}
      >
        <div className="dkg-maincategory-header">
          <ul className="headerlist">
            <li className="headeritem mr-2">
              <AddNewCategory />
            </li>
            <li className="headeritem mr-2">
              <EditCategory
                catIdds={catRowId}
                catName={catRowName}
                activeEditTab={activeEditTab}
              />
            </li>
            <li className="headeritem mr-2">
              <span className="dk-resCrcleIconCon" onClick={showreorderModal}>
                <FaArrowsAlt />
              </span>
              {isReorderModal ? (
                <ReorderCategory onHide={hidereorderModal} dataList={data} />
              ) : null}
            </li>
            <li className="headeritem">
              <DeleteDummy catIdds={catRowId} activeEditTab={activeEditTab} />
            </li>
          </ul>
          <div className="dkg-jobspec-header-centerCon">
            <div className="dkg-jobpsec-DropdownCon mr-2">
              <Dropdown className="dkg-jobpsec-Dropdown">
                <Dropdown.Toggle
                  variant=""
                  className="dkg-jobpsec-Dropdown-toggle"
                >
                  {roleeName}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  as={CustomMenu}
                  className="dkg-jobpsec-Dropdown-Menu"
                >
                  {data.map((item, i) => (
                    <Dropdown.Item
                      key={i + 1}
                      className="dkg-jobpsec-Dropdown-Item"
                      onClick={() => selectCat(item.id, item.categoryName)}
                    >
                      {item.categoryName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="dkg-clientlist-mainsec-Con dkg-adminclients-mainsec-Con">
          <div className="dkg-clientlist-main-ulCon dkg-adminclients-main-ulCon">
            <div className="dkg-clientlist-main-ul-tabsCon dkg-adminclients-main-ul-tabsCon">
              <Tab.Container defaultActiveKey="question-pt-new-overview">
                <div className="row m-0 dkg-payment-qt-tab-mainCon">
                  {/* <div className="col-md-2 pl-0 dkg-payment-qt-tabCon dkg-profile-task-sideMenu">
                    <Nav
                      variant="pills"
                      className="flex-column dkg-payment-qt-tab-navpills"
                    >
                      {data.map((item, i) => (
                        <Nav.Item
                          className="dkg-payment-qt-tab-nav-item"
                          onClick={() => selectCat(item.id, item.categoryName)}
                        >
                          {
                            <Nav.Link
                              eventKey={item.categoryName + i}
                              className="dkg-payment-qt-tab-navlink"
                            >
                              {item.categoryName}
                            </Nav.Link>
                          }
                        </Nav.Item>
                      ))}
                    </Nav>
                  </div> */}
                  <div className="col-md-12 dkg-payment-rt-tabCon-10 dkg-profile-task-rightCon">
                    <Tab.Content className="dkg-payment-qt-tabcontent">
                      {activeSTab == true
                        ? data.map((item, i) => (
                            <Tab.Pane
                              eventKey={item.categoryName + i}
                              className="dkg-payment-qt-tabpane"
                              active={activeTab === item.categoryName}
                            >
                              <Step1
                                pagetitle={item.categoryName}
                                catIdds={item.id}
                                tabName="tabChange"
                                catOverviewid={catRowId}
                                data={data}
                              />
                            </Tab.Pane>
                          ))
                        : null}
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
