import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav } from "react-bootstrap";
import { FaPlus, FaArrowsAlt, FaTrashAlt, FaEdit } from "react-icons/fa";
import AddNewCategoryModal from "./modal/AddNewCategory";
import EditSubCategory from "./modal/EditSubCategory";
import ReorderSubCategory from "./modal/ReorderSubCategory";
import DeleteModal from "./modal/DeleteDummy";

import {
  getSubCategories,
  getSubCatDetails,
  updateEditorValue,
  clearState,
} from "../../../../../../slice/commonTraning/processSlice";

import { Link } from "react-router-dom";
import $ from "jquery";

import SubmitButton from "../../../../../ui/submitButton";
import MessageBox from "../../../../../utils/joditEditor/MessageBox";
import { showSuccess } from "../../../../../../components/utils/messages/messageSlice";

const Step1 = (props) => {
  const dispatch = useDispatch();
  const {
    // isSuccess,
    isSubdesc,
    // isTabActive,
    // isError,
    isSubCatInsert,
    isSubCatUpdate,
    isDetails,
    isLoading,
    //issLoading,
    subCategoryDetails,
    isSubReoder,
    issDelete,
    subCategoryProcessList,
  } = useSelector((state) => state.process);

  const [categoryId, setCategoryId] = useState(props.catIdds);
  const [tabName, setTabName] = useState("details");
  const [subCategoryName, setSubCategoryName] = useState("");

  const [data, setData] = useState([]);
  const [catRowId, setCatRowId] = useState("");
  //const [activeSubCat, setActiveSubCat] = useState("");
  const [defaultId, setDefaultId] = useState("");
  //const [detailsSubtab, setDetailsSubtab] = useState("");
  const [showDetailsTab, setShowDetailsTab] = useState(false);
  const [editorDescription, setEditorDescription] = useState("");
  const [overviewData, setOverviewData] = useState("");
  const [activeEditTab, setActiveEditTab] = useState(true);
  const [editorData, setEditorData] = useState("");
  //const [subcatName, setSubcatName] = useState("");

  useEffect(() => {
    if (
      !isLoading &&
      subCategoryProcessList &&
      subCategoryProcessList.length > 0
    ) {
      setCategoryId(subCategoryProcessList[0].id);
      setSubCategoryName(subCategoryProcessList[0].subCategoryName);
      setData(subCategoryProcessList);
      setEditorDescription(subCategoryProcessList[0].description);
      setOverviewData(subCategoryProcessList[0].description);
      setDefaultId(subCategoryProcessList[0].id);
      setCatRowId(subCategoryProcessList[0].id);
    }
  }, [isLoading]);

  //   useEffect(() => {
  //   dispatch(getSubCategories({ categoryId: props.catIdds, keys: 'details' }));
  // }, [props.pagetitle]);
  useEffect(() => {
    $(".addnewClass").addClass("activesSubTab");
    setShowDetailsTab(true);
    setActiveEditTab(false);
  }, [props]);

  useEffect(() => {
    if (isDetails) {
      setOverviewData(subCategoryDetails.description);
      setEditorDescription(subCategoryDetails.description);
    }
    if (isSubReoder) {
      setShowDetailsTab(false);
    }
    if (isSubCatUpdate) {
      setShowDetailsTab(false);
    }
    if (issDelete) {
      // setShowDetailsTab(false);
      dispatch(
        getSubCategories({ categoryId: props.catIdds, keys: "details" })
      );
    }
    if (isSubdesc) {
      dispatch(showSuccess({ msg: "Record updated successfully." }));
    }
  }, [
    isSubdesc,
    isSubCatUpdate,
    isSubCatInsert,
    isDetails,
    isSubReoder,
    issDelete,
    props.pagetitle,
  ]);

  const subCategoryFunction = (id, catId, data) => {
    setDefaultId(id);
    if (props.pagetitle != "Overview") {
      $(".dk-tabs-right-navlink-13").removeClass("addActiveClass");
      $(".xyz" + id).addClass("addActiveClass");
    }

    setShowDetailsTab(true);
    setOverviewData("");
    setEditorDescription("");
    setSubCategoryName(data);
    setCategoryId(catId);
    setCatRowId(id);
    dispatch(getSubCatDetails({ id }));
    setActiveEditTab(true);
  };

  const [isReorderModal, setIReorderModal] = useState(false);
  const showreorderModal = () => {
    setIReorderModal(true);
  };
  const hidereorderModal = () => {
    setIReorderModal(false);
  };

  const SubmitBtn = () => {
    dispatch(
      updateEditorValue({
        id: defaultId,
        key: "description",
        value: editorData,
      })
    );
  };

  return (
    <>
      <div className="dkg-payment-qt-rightpanel">
        <div className="dkg-tabs-rightPanel-12">
          <div className="dkg-subcategory-header dkg-jobspec-subcat-cols">
            {props.pagetitle === "Overview" ? (
              <>
                <ul className="headerlist"></ul>
                <div className="dkg-script-overview-title">teeee</div>
              </>
            ) : (
              <>
                <ul className="headerlist">
                  <li className="headeritem mr-2">
                    <AddNewCategoryModal
                      categoryId={props.catIdds}
                      tabName={tabName}
                    />
                  </li>
                  <li className="headeritem mr-2">
                    <EditSubCategory
                      categoryId={categoryId}
                      tabName={tabName}
                      subCategory={subCategoryName}
                      catRowId={catRowId}
                      activeEditTab={activeEditTab}
                    />
                  </li>
                  <li className="headeritem mr-2">
                    <span
                      className="dk-resCrcleIconCon"
                      onClick={showreorderModal}
                    >
                      <FaArrowsAlt />
                    </span>
                    {isReorderModal ? (
                      <ReorderSubCategory
                        onHide={hidereorderModal}
                        dataList={data}
                        catIdds={props.catIdds}
                        activeEditTab={tabName}
                      />
                    ) : null}
                  </li>
                  <li className="headeritem">
                    <DeleteModal
                      catIdds={catRowId}
                      activeEditTab={activeEditTab}
                      atag={tabName}
                      catids={props.catIdds}
                    />
                  </li>
                </ul>
              </>
            )}

            {/* <div className="title dkg-page-title dkg-overview-title">
              {props.pagetitle}
            </div> */}
          </div>
          <div className="dk-tabs-rightCols-12">
            <div className="dk-tabs-right-detail-12">
              <Tab.Container id="left-tabs-example">
                {/* {props.pagetitle === "Overview" ? (
                  <></>
                ) : (
                  <>
                    <Nav variant="" className="dk-tabs-right-nav-12">
                      <Nav.Item className="dk-tabs-right-navitem-12">
                        <Link
                          to="#"
                          className={
                            tabName === "details"
                              ? `dk-tabs-right-navlink-12 tab_1 `
                              : `dk-tabs-right-navlink-12 tab_1 `
                          }
                          onClick={() => changeTabName("details")}
                        >
                          Details
                        </Link>
                      </Nav.Item>
                      <Nav.Item className="dk-tabs-right-navitem-12">
                        <Link
                          to="#"
                          className={
                            tabName === "audio"
                              ? `dk-tabs-right-navlink-12 tab_2 `
                              : `dk-tabs-right-navlink-12 tab_2 `
                          }
                          onClick={() => changeTabName("audio")}
                        >
                          Audio
                        </Link>
                      </Nav.Item>
                      <Nav.Item className="dk-tabs-right-navitem-12">
                        <Link
                          to="#"
                          className={
                            tabName === "video"
                              ? `dk-tabs-right-navlink-12 tab_3 `
                              : `dk-tabs-right-navlink-12 tab_3 `
                          }
                          onClick={() => changeTabName("video")}
                        >
                          Video
                        </Link>
                      </Nav.Item>
                    </Nav>
                  </>
                )} */}

                <div
                  className={`dk-tabs-right-tabpane-12 addnewClass dkg-hidewithoutselct`}
                >
                  <div className="dk-rightDetail-panel-13">
                    <div className="dk-getStartedRight-detail-13">
                      <Nav
                        variant=""
                        className="dk-tabs-right-nav-13 flex-column"
                      >
                        {data.map((item, key) => (
                          <Nav.Item className="dk-tabs-right-navitem-13">
                            {key == 0 ? (
                              <Link
                                to="#"
                                key={key + 1}
                                className={
                                  `dk-tabs-right-navlink-13 addActiveClass xyz` +
                                  item.id
                                }
                                onClick={() =>
                                  subCategoryFunction(
                                    item.id,
                                    item.categoryId,
                                    item.subCategoryName
                                  )
                                }
                              >
                                {item.subCategoryName}{" "}
                              </Link>
                            ) : (
                              <Link
                                to="#"
                                key={key + 1}
                                className={
                                  `dk-tabs-right-navlink-13 xyz` + item.id
                                }
                                onClick={() =>
                                  subCategoryFunction(
                                    item.id,
                                    item.categoryId,
                                    item.subCategoryName
                                  )
                                }
                              >
                                {item.subCategoryName}{" "}
                              </Link>
                            )}
                          </Nav.Item>
                        ))}
                      </Nav>

                      {showDetailsTab ? (
                        <Tab.Content className="dk-tabs-right-tabcontent-13">
                          <div className="dkg-jopspec-pageheader">
                            <div className="dkg-jopspec-page-title">Test-1</div>
                          </div>
                          <MessageBox
                            value={editorDescription}
                            onChange={(value) => setEditorData(value)}
                          />
                          <div className="dkg-submit-btnCon">
                            <SubmitButton
                              txt="SAVE"
                              className="dkg-cand-submit-btn"
                              onClick={SubmitBtn}
                            />
                          </div>
                        </Tab.Content>
                      ) : (
                        <>
                          {/* this is for default active tab data */}
                          {data.length > 0 ? (
                            <Tab.Content className="dk-tabs-right-tabcontent-13">
                              {/* <Editor
                                editorState={editorState}
                                onEditorStateChange={onEditorStateChange}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                toolbar={{
                                  options: [
                                    "inline",
                                    "fontSize",
                                    "fontFamily",
                                    "list",
                                    "textAlign",
                                    "colorPicker",
                                    "link",
                                    "image",
                                  ],
                                  inline: { inDropdown: false },
                                  list: { inDropdown: false },
                                  textAlign: { inDropdown: false },
                                  link: { inDropdown: false },
                                  history: { inDropdown: false },
                                }}
                              /> */}
                              <div className="dkg-jopspec-pageheader">
                                <div className="dkg-jopspec-page-title">
                                  Test-1
                                </div>
                              </div>
                              <MessageBox
                                value={overviewData}
                                onChange={(value) => setEditorData(value)}
                              />
                              <div className="dkg-submit-btnCon">
                                <SubmitButton
                                  txt="SAVE"
                                  className="dkg-cand-submit-btn"
                                  onClick={SubmitBtn}
                                />
                              </div>
                            </Tab.Content>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {props.pagetitle === "Overview" ? (
                  <>
                    <Tab.Content className="dk-tabs-right-tabcontent-13">
                      <MessageBox
                        value={overviewData}
                        onChange={(value) => setEditorData(value)}
                      />
                      <div className="dkg-submit-btnCon">
                        <SubmitButton
                          txt="SAVE"
                          className="dkg-cand-submit-btn"
                          onClick={SubmitBtn}
                        />
                      </div>
                    </Tab.Content>
                  </>
                ) : null}
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1;
