import React,{useState} from 'react'
import AdminHeader from '../../../../elements/header/Header'
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../../elements/submenu/mainmenu/PagetypeSubmenu';
import  './pg-typevideo.scss'
import PagetypeVideos from '../../.././../../assets/images/album.jpg'
import UpdateModal from './modal/UpdateModal'

const VideoAlbum = () => {
    const [isUpload , setIsUpload]= useState(false);
    
    const showuploadModal = () =>{
        setIsUpload(true);
    }
    const hideuploadModal = () =>{
        setIsUpload(false);
    }
  return (
    <>
        {
            (isUpload) ? <UpdateModal onHide={hideuploadModal} />: null
        }
        <AdminHeader />
        <Navbar />
        <Submenu />
        <div className="dkg-pagetype-video-mainCon">
            <div className="dkg-pagetype-videoCon">
                <div className="dkg-pagetype-mainRow row">
                    <div className="col-lg-4 col-md-4 col-sm-12 dkg-pagetype-video-cols">
                        <div className="dkg-pagetype-video-box ">
                            <figure className='dkg-pagetype-video-figure'>
                                <div className='dkg-pagetype-video-imgCon'>
                                    <img src={PagetypeVideos} className='dkg-pagetype-video-img' alt='Video' />
                                </div>
                            </figure>
                            <div className="dkg-pagetype-bottomCon">
                                <button className='btn dkg-upload-mediabtn' onClick={showuploadModal}>Upload</button>
                                <button className='btn dkg-edit-mediabtn'>Edit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 dkg-pagetype-video-cols">
                        <div className="dkg-pagetype-video-box ">
                            <figure className='dkg-pagetype-video-figure'>
                                <div className='dkg-pagetype-video-imgCon'>
                                    <img src={PagetypeVideos} className='dkg-pagetype-video-img' alt='Video' />
                                </div>
                            </figure>
                            <div className="dkg-pagetype-bottomCon">
                                <button className='btn dkg-upload-mediabtn' onClick={showuploadModal}>Upload</button>
                                <button className='btn dkg-edit-mediabtn'>Edit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 dkg-pagetype-video-cols">
                        <div className="dkg-pagetype-video-box ">
                            <figure className='dkg-pagetype-video-figure'>
                                <div className='dkg-pagetype-video-imgCon'>
                                    <img src={PagetypeVideos} className='dkg-pagetype-video-img' alt='Video' />
                                </div>
                            </figure>
                            <div className="dkg-pagetype-bottomCon">
                                <button className='btn dkg-upload-mediabtn' onClick={showuploadModal}>Upload</button>
                                <button className='btn dkg-edit-mediabtn'>Edit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 dkg-pagetype-video-cols">
                        <div className="dkg-pagetype-video-box ">
                            <figure className='dkg-pagetype-video-figure'>
                                <div className='dkg-pagetype-video-imgCon'>
                                    <img src={PagetypeVideos} className='dkg-pagetype-video-img' alt='Video' />
                                </div>
                            </figure>
                            <div className="dkg-pagetype-bottomCon">
                                <button className='btn dkg-upload-mediabtn' onClick={showuploadModal}>Upload</button>
                                <button className='btn dkg-edit-mediabtn'>Edit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 dkg-pagetype-video-cols">
                        <div className="dkg-pagetype-video-box ">
                            <figure className='dkg-pagetype-video-figure'>
                                <div className='dkg-pagetype-video-imgCon'>
                                    <img src={PagetypeVideos} className='dkg-pagetype-video-img' alt='Video' />
                                </div>
                            </figure>
                            <div className="dkg-pagetype-bottomCon">
                                <button className='btn dkg-upload-mediabtn' onClick={showuploadModal}>Upload</button>
                                <button className='btn dkg-edit-mediabtn'>Edit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 dkg-pagetype-video-cols">
                        <div className="dkg-pagetype-video-box ">
                            <figure className='dkg-pagetype-video-figure'>
                                <div className='dkg-pagetype-video-imgCon'>
                                    <img src={PagetypeVideos} className='dkg-pagetype-video-img' alt='Video' />
                                </div>
                            </figure>
                            <div className="dkg-pagetype-bottomCon">
                                <button className='btn dkg-upload-mediabtn' onClick={showuploadModal}>Upload</button>
                                <button className='btn dkg-edit-mediabtn'>Edit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
  )
}

export default VideoAlbum