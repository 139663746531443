import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav } from "react-bootstrap";
import {
  getTextHeading,
  updateCMSText,
  clearState,
} from "../../../../../slice/candidate/frontendCms/frontendCmsSlice";
import SubmitButton from "../../../../ui/submitButton";
import {
  showError,
  showSuccess,
} from "../../../../../components/utils/messages/messageSlice";

const LegalTab = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList, isSuccess, isUpdate } = useSelector(
    (state) => state.cmslist
  );

  const [pageHeading, setPageHeading] = useState([]);
  const [pageSubHeading, setPageSubHeading] = useState([]);
  const [pageDescritiong, setPageDescritiong] = useState([]);

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setPageHeading(getcmsList[21].page_description);
      setPageSubHeading(getcmsList[22].page_description);
      setPageDescritiong(getcmsList[32].page_description);
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState({}));
      dispatch(showSuccess({ msg: "Information updated successfully" }));
    }
  }, [isUpdate]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getTextHeading({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  const SubmitBtn = (id) => {
    if (id === 23) {
      dispatch(
        updateCMSText({
          id,
          key: "page_description",
          value: pageSubHeading,
        })
      );
    } else if (id === 33) {
      dispatch(
        updateCMSText({
          id,
          key: "page_description",
          value: pageDescritiong,
        })
      );
    } else if (id === 22) {
      dispatch(
        updateCMSText({
          id,
          key: "page_description",
          value: pageHeading,
        })
      );
    } 
  };
  return (
    <>
      <div className="dkg-cand-services-Side">
        <Tab.Container defaultActiveKey="first">
          <div className="row m-0 dkg-payment-qt-tab-mainCon">
            <div className="col-md-2 pl-0 dkg-payment-qt-tabCon dkg-profile-task-sideMenu">
              <Nav
                variant="pills"
                className="flex-column dkg-payment-qt-tab-navpills"
              >
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="first"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Title
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="second"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Subtitle
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="description"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Description
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-md-10  dkg-paidservices-rightCon">
              <Tab.Content className="dkg-paidservices-tabcontent">
                <Tab.Pane eventKey="first" className="dkg-paidservices-tabpane">
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Title</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <input
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue={pageHeading != "" ? pageHeading : ""}
                      onChange={(e) => setPageHeading(e.target.value)}
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                        onClick={(e) => SubmitBtn(22)}
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Subtitle</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <textarea
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue={pageSubHeading != "" ? pageSubHeading : ""}
                      onChange={(e) => setPageSubHeading(e.target.value)}
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                        onClick={(e) => SubmitBtn(23)}
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="description"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Subtitle</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <textarea
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue={
                        pageDescritiong != "" ? pageDescritiong : ""
                      }
                      onChange={(e) => setPageDescritiong(e.target.value)}
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                        onClick={(e) => SubmitBtn(33)}
                      />
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default LegalTab;
