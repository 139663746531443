import React from 'react'
import { Modal } from "react-bootstrap";
import Submit from '../../../../../ui/submitButton'
import  './upload-video.scss'


const UpdateModal = ({ onHide }) => { 
  return (
    <>
        <Modal show={true} onHide={onHide} className="dkg-pagetype-uploadModal" centered>
        <Modal.Header closeButton className='dkg-video-upload-Header'>
          <Modal.Title>Add Video</Modal.Title>
        </Modal.Header>
        <Modal.Body className='dkg-video-upload-Body'>
          <form action="">
            <div className="dkg-upload-frmgrp">
                <label>Youtube URL</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                />
            </div>
            <div className="dkg-upload-frmgrp">
                <label>OR</label>
                <input
                  type="file"
                  className="form-control"
                  defaultValue=""
                />
            </div>
            <div className="dkg-upload-frmgrp">
                <div className="dkg-submit-BtnCon">
                    {/* <Submit
                    txt="Submit"
                    className="btn  dkg-submit-Btn"
                    position="justify-content-center"
                    /> */}
                     <button className='btn  dkg-submit-Btn'>Submit</button>
                    <button className='btn dkg-close-btn'>Close</button>
                </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UpdateModal