import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";

import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import Submit from "../../../../../ui/submitButton";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../../components/utils/messages/messageSlice";
import {
  getRoleTypeCategory,
  subCatReorderData,
  clearState,
} from "../../../../../../slice/clientRole";

const DragHandle = sortableHandle(() => (
  <span
    className="dk-handle"
    style={{
      backgroundColor: "#363f4c",
      height: "35px !important",
      width: "50px",
      textAlign: "center",
      lineHeight: "35px",
      color: "white",
      cursor: "all-scroll",
    }}
  >
    <i className="fas fa-bars"></i>
  </span>
));

const SortableItem = SortableElement(({ value }) => {
  return (
    <React.Fragment>
      <div className="Mukul">
        <div className="dk-valueFields d-flex" key={value.id} id={value.id}>
          <DragHandle />
          <input
            type="text"
            style={{
              background: value.bgColor,
              color: value.textColor,
              height: "35px",
              borderRadius: "0",
              border: "none",
              boxShadow: "none !important",
            }}
            value={value.subCategoryName}
            name=""
            id=""
            className="form-control"
          />
        </div>
      </div>
    </React.Fragment>
  );
});

const SortableList = SortableContainer(({ items }) => {
  return (
    <ul>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </ul>
  );
});

const ReorderCategory = ({ dataList, onHide, catIdds, activeEditTab }) => {
  const dispatch = useDispatch();
  let { isSubReoder, isError, msg } = useSelector(
    (state) => state.clientRolesType
  );
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setList(dataList);
  }, [dataList]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setList(arrayMoveImmutable(list, oldIndex, newIndex));
  };

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const reorderFunction = async () => {
    setLoading(true);
    setSuccess(false);
    let reorderListArray = [];
    list.map((data) => {
      reorderListArray.push(data.id);
    });

    await dispatch(
      subCatReorderData({
        ids: reorderListArray,
        catId: catIdds,
        tabName: activeEditTab,
      })
    );
  };

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isSubReoder) {
      setLoading(false);
      setSuccess(false);
      //setShow(false);
      fetchData();
      dispatch(showSuccess({ msg }));
      dispatch(clearState());
      onHide();
    }
    async function fetchData() {
      await dispatch(getRoleTypeCategory({}));
    }

    if (isError) {
      setLoading(false);
      setSuccess(false);
      dispatch(showError({ msg }));
      dispatch(clearState());
    }
  }, [isSubReoder, isError, dispatch]);

  return (
    <>
      <React.Fragment>
        <Modal
          className="dkg-script-modal-2345"
          show={true}
          onHide={onHide}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Reorder Sub Category</Modal.Title>
          </Modal.Header>
          <Modal.Body className="dkg-todayads-reorderModalBody">
            <SortableList
              lockAxis="y"
              items={list}
              onSortEnd={onSortEnd}
              useDragHandle
            />
            <div className="d-flex justify-content-center dk-res-reorder-save-btnCon">
              <Submit
                txt="Save Order"
                loading={loading}
                success={success}
                onClick={reorderFunction}
                position="justify-content-center"
                className="dk-toolsPanelSaveBtn"
              />
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default ReorderCategory;
