import React from 'react'
import AdminHeader from '../../../../elements/header/Header'
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../../elements/submenu/mainmenu/CandAccSubmenu';
import { Nav, Tab } from "react-bootstrap";
// import  './compliance.scss'
import SecondSidemenu from "./SecondSidemenu"; 
import SecondSideResume from "./SecondSideResume";
import TitleSidemenu from "./TitleSidemenu";
import ActiveAds from './active-ads/ActiveAds';
import ClosedAds from './active-ads/ClosedAds';


const index = () => {
  return (
    <>
        <AdminHeader />
        <Navbar />
        <Submenu />
        <div className="dkg-contact-mainCon">
        <div className="dkg-contact-main">
          <Tab.Container id="left-tabs-example" defaultActiveKey="dkg-live-ads-title">
            <div className="row justify-content-center dkg-contact-mainRow">
              <div className="col-md-2 dkg-contact-main-sideCol">
                <Nav
                  variant="pills dkg-aboutus-navpills dkg-paid-services-navpills"
                  className="flex-column"
                >
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-live-ads-title"
                      className="dkg-aboutus-navlink"
                    >
                      Title
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-live-ads"
                      className="dkg-aboutus-navlink"
                    >
                      Active Ads
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-closed-ads"
                      className="dkg-aboutus-navlink"
                    >
                      Closed Ads
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-search-job"
                      className="dkg-aboutus-navlink"
                    >
                      Create New
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-logos"
                      className="dkg-aboutus-navlink"
                    >
                     Administration
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-10 col-10 dkg-contact-mainCol">
                <Tab.Content className="dkg-aboutus-tabcontent"> 
                <Tab.Pane
                    eventKey="dkg-live-ads-title"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Title</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <TitleSidemenu />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-live-ads"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Overview</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      {/* <SecondSidemenu /> */}
                      <ActiveAds/>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-closed-ads"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Closed Ads</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <ClosedAds />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-search-job"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Job Search</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-logos"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Interviews</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-social"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Assessment</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-study-abroad"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Communication</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-plan-career"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Using Internet</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="consultation-work-aboard"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Work Aboard</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="consultation-study-aboard"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Study Aboard</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="consultation-career-planning"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Plan Career</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  )
}

export default index