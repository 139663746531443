import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav } from "react-bootstrap";
import SubmitButton from "../../../../../ui/submitButton";

import { updateData } from "../../../../../../slice/cmsSlice";

const MyContactTab = ({ data }) => {
    const dispatch = useDispatch();
    const [overview, setOverview] = useState("");
    useEffect(() => {
        setOverview(data != "" ? data[52].description : "");
    }, [data]);

    const SubmitBtn = (id, value, key) => {
        dispatch(
            updateData({
                id,
                key,
                value,
            })
        );
    };

    return (
        <>
            <div className="dkg-cand-services-Side">
                <Tab.Container defaultActiveKey="first">
                    <div className="row m-0 dkg-payment-qt-tab-mainCon">
                        <div className="col-md-2 pl-0 dkg-payment-qt-tabCon dkg-profile-task-sideMenu">
                            <Nav
                                variant="pills"
                                className="flex-column dkg-payment-qt-tab-navpills"
                            >
                                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                                    <Nav.Link
                                        eventKey="first"
                                        className="dkg-payment-qt-tab-navlink"
                                    >
                                        Instructions
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="col-md-10  dkg-paidservices-rightCon">
                            <Tab.Content className="dkg-paidservices-tabcontent">
                                <Tab.Pane eventKey="first" className="dkg-paidservices-tabpane">
                                    <div className="dkg-paidservices-header">
                                        <h4 className="dkg-paidservices-title">Required</h4>
                                    </div>
                                    <div className="dkg-paidservices-body">
                                        <textarea
                                            type="text"
                                            className="dkg-cand-dash-input"
                                            name="cand-dash-input"
                                            id="cand-dash-input"
                                            defaultValue={overview != "" ? overview : ""}
                                            onChange={(e) => setOverview(e.target.value)}
                                        />
                                        <div className="dkg-cand-submit-btnCon">
                                            <SubmitButton
                                                txt="SAVE"
                                                className="dkg-cand-submit-btn"
                                                onClick={(e) => SubmitBtn(53, overview, "description")}
                                            />
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    );
};

export default MyContactTab;
