import React from "react";
import { VariableSizeList as List } from "react-window";
import { Link } from "react-router-dom";
import AdminHeader from "../../../../elements/header/Header";
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../elements/submenu/mainmenu/CandidateSubmenu";
import Pagination from "@mui/material/Pagination";
import { Dropdown, DropdownButton } from "react-bootstrap";
import RightModalFilter from "./rightModalFilter/RightModalFilter";
import { FaUserFriends } from "react-icons/fa";

import TableComponent from "./VirtualComp";

// import  "./active-acc.scss";

const VirtualizeTblComp = ({ columns, data }) => {
  const rowHeight = 600; // Height of each row in the table
  const itemCount = data.length; // Total number of rows
  // console.log(data);
  // console.log(data[index]);
  const rowCount = data.length;
  //const tableHeight = rowHeight * rowCount;

  //return false;
  //   const Row = ({ index, style }) => {
  //     const item = data[index];
  //     return (
  //       // <div style={style} className={index % 2 === 0 ? "even-row" : "odd-row"}>
  //       //   <div>{item.username}</div>
  //       //   <div>{item.email}</div>
  //       //   <div>{item.password}</div>
  //       //   {/* Add more columns as needed */}
  //       // </div>
  //       <>
  //         {/* <tr>
  //           <td>
  //             <div className="dkg-active-acc-serNo">
  //               {item.id}
  //               <CheckboxDummy />
  //             </div>
  //           </td>

  //           <td>30 Oct 2023</td>
  //           <td>
  //             <div className="dkg-statusDrodownCon">
  //               <Dropdown className="dkg-statusDrodown">
  //                 <Dropdown.Toggle
  //                   variant=""
  //                   className="dkg-statusdropdwn-toggle"
  //                 >
  //                   Active
  //                 </Dropdown.Toggle>
  //                 <Dropdown.Menu className="dkg-statusdropdwn-menu">
  //                   <Dropdown.Item
  //                     href="#"
  //                     className="dkg-status-dropitem"
  //                     style={{ backgroundColor: "#3a9469", color: "#fff" }}
  //                   >
  //                     Active
  //                   </Dropdown.Item>
  //                   <Dropdown.Item
  //                     href="#"
  //                     className="dkg-status-dropitem"
  //                     style={{ backgroundColor: "#dc3545", color: "#fff" }}
  //                   >
  //                     Inactive
  //                   </Dropdown.Item>
  //                 </Dropdown.Menu>
  //               </Dropdown>
  //             </div>
  //           </td>
  //           <td>
  //             <SwitchTab />
  //           </td>
  //           <td>Candidate</td>
  //           <td> {item.userId}</td>
  //           <td>{item.username}</td>
  //           <td>{item.email}</td>
  //           <td>{item.password}</td>
  //           <td>India</td>
  //           <td>Noida</td>
  //           <td>12</td>
  //           <td>DKER211014@dkglobalrecruiters.com</td>
  //         </tr> */}
  //       </>
  //     );
  //   };

  return (
    <>
      <AdminHeader />
      <Navbar />
      <Submenu />
      <div className="dkg-candidate-acc-mainCon">
        <div className="dkg-candidate-acc-header">
          <div className="dkg-cand-acc-leftCon">
            <div className="dk-SrchInputBox mr-2">
              <div className="input-group">
                <input
                  className="form-control"
                  placeholder="Search..."
                  value=""
                />
                <div className="input-group-append">
                  <button className="btn btn-secondary" type="button">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="dkg-plusicon-Con mr-2">
              <Link to="#" className="dkg-plusicon">
                <i className="fas fa-plus"></i>
              </Link>
            </div>
          </div>
          <div className="dkg-cand-acc-centreCon">
            <h3 className="title">Active Accounts</h3>
          </div>
          <div className="dkg-cand-acc-rightCon">
            <button className="dk-reoderBtn mr-2">
              <FaUserFriends />
            </button>
            <RightModalFilter />
            <div className="dkg-casestus-filter">
              <DropdownButton className="dropdownFilter" title="User Type">
                <Dropdown.Item
                  href="#"
                  style={{ backgroundColor: "#eaeaea", color: "#333" }}
                >
                  Select 1<span className="dropdown-filter-count">5</span>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  style={{ backgroundColor: "#eaeaea", color: "#333" }}
                >
                  Select 1<span className="dropdown-filter-count">5</span>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  style={{ backgroundColor: "#eaeaea", color: "#333" }}
                >
                  Select 1<span className="dropdown-filter-count">5</span>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  style={{ backgroundColor: "#eaeaea", color: "#333" }}
                >
                  Select 1<span className="dropdown-filter-count">5</span>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  style={{ backgroundColor: "#eaeaea", color: "#333" }}
                >
                  Select 1<span className="dropdown-filter-count">5</span>
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </div>
        <div className="dkg-candidate-acc-tableCon table-responsive table-container">
          {/* <table className="table table-bordered dkg-candidate-acc-table mb-0">
            <thead>
              <tr>
                <th>Sn.</th>
                <th>Created On</th>
                <th>Status</th>
                <th>A/C Access</th>
                <th>User Type</th>
                <th>Case ID</th>
                <th>Name of User</th>
                <th>User Account Email</th>
                <th>Account Password</th>
                <th>Country</th>
                <th>Town/City</th>
                <th>Subscription</th>
                <th>System Email</th>
              </tr>
            </thead>
            <tbody>
              <List
                height={800}
                itemCount={itemCount}
                itemSize={(index) => rowHeight}
                width="100%"
              >
                {Row}
              </List>
            </tbody>
          </table> */}

          <List
            height={400}
            itemCount={rowCount}
            // itemSize={rowHeight}
            itemSize={(index) => rowHeight}
            width={"100%"}
          >
            {({ index, style }) => (
              <div style={style}>
                {/* <TableComponent columns={columns} data={[data[index]]} /> */}
              </div>
            )}
          </List>
        </div>
      </div>
    </>
  );
};

export default VirtualizeTblComp;
