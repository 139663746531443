import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
//import { Column, List, Table } from 'react-virtualized';
//import "react-virtualized/styles.css"; // only needs to be imported once
import { faker } from "@faker-js/faker";
import Table from "./TableVirtualizeTbl";

const VirtualizeComponent = () => {
  const [people, setPepole] = useState([]);
  useEffect(() => {
    setPepole(
      [...Array(5).keys()].map((key) => {
        return {
          id: key,
          userId: faker.string.uuid(),
          username: faker.internet.userName(),
          email: faker.internet.email(),
          avatar: faker.image.avatar(),
          password: faker.internet.password(),
          birthdate: faker.date.birthdate(),
          registeredAt: faker.date.past(),
        };
      })
    );
  }, []);

  const columns = [
    { Header: "ID", accessor: "userId" },
    { Header: "Name of User", accessor: "username" },
    { Header: "User Account Email", accessor: "email" },
    { Header: "Password", accessor: "password" },
    // { Header: "Created On", accessor: "name" },
    // { Header: "Status", accessor: "name" },
    // { Header: "A/C Access", accessor: "name" },
    // { Header: "User Type", accessor: "name" },
    // { Header: "Case ID", accessor: "name" },
    // { Header: "Account Password", accessor: "name" },
    // { Header: "Country", accessor: "name" },
    // { Header: "Town/City", accessor: "name" },
    // { Header: "Subscription", accessor: "name" },
    // { Header: "System Email", accessor: "name" },
    // Add more columns as needed
  ];

  return (
    <>
      {people.length > 0 ? (
        <Table columns={columns} data={people} />
      ) : (
        <p>Loading data...</p>
      )}
    </>
  );
};

export default VirtualizeComponent;
