import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal'
import { FaEdit } from "react-icons/fa";
import SubmitButton from '../../../../../ui/submitButton'
import { updateSubCategory, clearState } from "../../../../../../slice/demand/demandSlice";
import { showSuccess, showError, clearMessage } from "../../../../../../components/utils/messages/messageSlice";

const EditSubCategory = ({ categoryId, tabName, subCategory, catRowId, activeEditTab }) => {
    const dispatch = useDispatch();
    const { isSuccess, isError, isSubCatUpdate, subCategoryList } = useSelector(state => state.demands);

    const [categgoryId, setCateggoryId] = useState('');
    const [categoryName, setCategoryName] = useState('');

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setCateggoryId(categoryId)
        setCategoryName(subCategory)
    }, [categoryId, subCategory])

    const updateCategory = (e) => {
        e.preventDefault()
        setSuccess(false);
        setLoading(true);
        if (!categoryName) {
            dispatch(showError({ msg: 'Please enter category name' }))
            setLoading(false);
        } else {
            setLoading(true);
            dispatch(updateSubCategory({ id: catRowId, categoryId: categgoryId, subCategoryName: categoryName, keys: tabName }))
        }
    }


    useEffect(() => {
        if (isError) {
            dispatch(clearState())
            setShow(false)
            setLoading(false);
            setSuccess(false);
        }
        if (isSubCatUpdate) {
            dispatch(clearState())
            setShow(false)
            setLoading(false);
            setSuccess(false);
        }
    }, [isError, isSubCatUpdate])

    return (
        <>
            {
                activeEditTab ? <span className="dk-resCrcleIconCon" onClick={handleShow}><FaEdit /></span> : <span className="dk-resCrcleIconCon" style={{ cursor: "not-allowed" }}><FaEdit /></span>
            }

            <Modal show={show} onHide={handleClose} className="dkg-script-modal-2345">
                <Modal.Header closeButton>
                    <Modal.Title>Sub-Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label htmlFor="">Name of SubCategory</label>
                            <input type="text" className='form-control' defaultValue={categoryName} onChange={(e) => setCategoryName(e.target.value)} />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {/* <button className='dkg-closebtn'>
                        Close
                    </button> */}
                    <SubmitButton txt="Update" loading={loading} success={success} onClick={updateCategory} position="justify-content-center" className="dkg-submitbtn" />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditSubCategory