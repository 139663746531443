import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import {
  updateCompany,
  clearState,
} from "../../../../../../../slice/faq/jobsFaqSlice";
import SubmitButton from "../../../../../../../components/ui/submitButton";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../../../components/utils/messages/messageSlice";
import SearchDropDownFilter from "../../../../../../ui/SelectPicker/searchTypeSelect";

const EditCompany = ({ rowid, companyname, seqNo }) => {
  const dispatch = useDispatch();
  const {
    isSuccess,
    isError,
    isUpdate,
    companyList,
    bkCompanylist,
    isLoading,
  } = useSelector((state) => state.jobsfaqs);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [rowsId, setRowsId] = useState("");
  const [seqqNo, setSequenceNo] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [listOfCompny, setListOfCompny] = useState([]);

  useEffect(() => {
    if (!isLoading && bkCompanylist && bkCompanylist.length > 0) {
      setListOfCompny(bkCompanylist);
    }
  }, [isLoading]);

  useEffect(() => {
    setSequenceNo(seqNo);
    setCompanyName(companyname);
    setRowsId(rowid);
  }, [seqNo, companyname, rowid]);

  useEffect(() => {
    if (isUpdate) {
      setLoading(false);
      setShow(false);
    }
  }, [isUpdate]);

  const updateCompanyDetails = (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);
    if (!companyName) {
      dispatch(showError({ msg: "Please enter company name" }));
      setLoading(false);
    } else {
      setLoading(true);
      dispatch(updateCompany({ id: rowsId, name: companyName }));
    }
  };

  const getCompanyValue = (data) => {
    setCompanyName(data);
  };

  return (
    <>
      <Link to="#" onClick={handleShow} className="dkg-faq-edit-btn mr-2">
        <i className="fa fa-edit " aria-hidden="true"></i>
      </Link>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="dkg-faq-addnew-catModal"
      >
        <Modal.Header closeButton className="dk-faq-addcategory-modal-header">
          <Modal.Title>Edit Company</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dk-faq-addcategory-modal-body">
          <div className="form-group mb-0">
            <label>Category Name</label>
            {/* <input
              type="text"
              className="form-control"
              onChange={(e) => setCompanyName(e.target.value)}
              value={companyName}
            /> */}
            <SearchDropDownFilter
              text={companyName}
              list={listOfCompny}
              updateValue={getCompanyValue}
            />
          </div>
          {/* <div className="form-group mb-0">
            <label>Sequence</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setSequenceNo(e.target.value)}
              value={seqqNo}
            />
          </div> */}
        </Modal.Body>
        <Modal.Footer className="dk-faq-addcategory-modal-footer">
          <Link to="#" className="dkg-close-btn" onClick={handleShow}>
            Close
          </Link>
          <SubmitButton
            txt="Update"
            loading={loading}
            success={success}
            onClick={updateCompanyDetails}
            position="justify-content-center"
            className="dkg-submitbtn"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditCompany;
