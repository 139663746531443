import React from 'react'
import AdminHeader from '../../../../elements/header/Header'
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../../elements/submenu/mainmenu/PagetypeSubmenu';
import Facebook from "../../../../../assets/images/facebook.png";
import Linkedin from "../../../../../assets/images/linkedin.png";
import Instagram from "../../../../../assets/images/instagram.png";
import Youtube from "../../../../../assets/images/youtube.png";
import TwitterLogo from "../../../../../assets/images/twitter.png";
import SubmitButton from '../../../../ui/submitButton'
import  './socail-media.scss'


const SocailMedia = () => {
  return (
    <>

       <AdminHeader />
        <Navbar />
        <Submenu />
        <div className="dkg-sm-mainCon">
            <div className="dkg-sm-Con">
                <div className="dkg-pagetype-mainRow row">
                  <div className="col-12 dkg-pagetype-mainCol">
                    <div className="dkg-social-box-cols">
                      <div className="dkg-social-media-box">
                        <div className="dkg-socail-media-icon">
                          <div className="dkg-sm-imgcon">
                            <img src={Facebook} alt="Facebook" className="dkg-sm-img" />
                          </div>
                        </div>
                        <div className="dkg-sm-nameurl-con">
                          <div className="dkg-timeflex">
                            <small className="dkg-social-media-name">
                              <input
                                type="text"
                                className="form-control dkg-social-name-iput"
                                defaultValue=""
                              />
                            </small>
                          </div>
                        </div>
                        <div className="dkg-send-urlbtn-con">
                          <div className="dkg-send-urlbtn-flex">
                            <small className="dkg-social-media-name-btn">
                              <SubmitButton
                                txt="Save"
                                className="dkg-send-urlbtn btn"
                              />
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="dkg-social-media-box">
                        <div className="dkg-socail-media-icon">
                          <div className="dkg-sm-imgcon">
                            <img src={Linkedin} alt="Linkedin" className="dkg-sm-img" />
                          </div>
                        </div>
                        <div className="dkg-sm-nameurl-con">
                          <div className="dkg-timeflex">
                            <small className="dkg-social-media-name">
                              <input
                                type="text"
                                className="form-control dkg-social-name-iput"
                                defaultValue=""
                              />
                            </small>
                          </div>
                        </div>
                        <div className="dkg-send-urlbtn-con">
                          <div className="dkg-send-urlbtn-flex">
                            <small className="dkg-social-media-name-btn">
                              <SubmitButton
                                txt="Save"
                                className="dkg-send-urlbtn btn"
                              />
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="dkg-social-media-box">
                        <div className="dkg-socail-media-icon">
                          <div className="dkg-sm-imgcon">
                            <img src={Instagram} alt="Youtube" className="dkg-sm-img" />
                          </div>
                        </div>
                        <div className="dkg-sm-nameurl-con">
                          <div className="dkg-timeflex">
                            <small className="dkg-social-media-name">
                              <input
                                type="text"
                                className="form-control dkg-social-name-iput"
                                defaultValue=""
                              />
                            </small>
                            
                          </div>
                        </div>
                        <div className="dkg-send-urlbtn-con">
                          <div className="dkg-send-urlbtn-flex">
                            <small className="dkg-social-media-name-btn">
                              <SubmitButton
                                txt="Save"
                                className="dkg-send-urlbtn btn"
                              />
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="dkg-social-media-box">
                        <div className="dkg-socail-media-icon">
                          <div className="dkg-sm-imgcon">
                            <img src={Youtube} alt="Youtube" className="dkg-sm-img" />
                          </div>
                        </div>
                        <div className="dkg-sm-nameurl-con">
                          <div className="dkg-timeflex">
                            <small className="dkg-social-media-name">
                              <input
                                type="text"
                                className="form-control dkg-social-name-iput"
                                defaultValue=""
                              />
                            </small>
                          </div>
                        </div>
                        <div className="dkg-send-urlbtn-con">
                          <div className="dkg-send-urlbtn-flex">
                            <small className="dkg-social-media-name-btn">
                              <SubmitButton
                                txt="Save"
                                className="dkg-send-urlbtn btn"
                              />
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="dkg-social-media-box">
                        <div className="dkg-socail-media-icon">
                          <div className="dkg-sm-imgcon">
                            <img
                              src={TwitterLogo}
                              alt="DK Global Website"
                              className="dkg-sm-img"
                            />
                          </div>
                        </div>
                        <div className="dkg-sm-nameurl-con">
                          <div className="dkg-timeflex">
                            <small className="dkg-social-media-name">
                              <input
                                type="text"
                                className="form-control dkg-social-name-iput"
                                defaultValue=""
                              />
                            </small>
                          </div>
                        </div>
                        <div className="dkg-send-urlbtn-con">
                          <div className="dkg-send-urlbtn-flex">
                            <small className="dkg-social-media-name-btn">
                              <SubmitButton
                                txt="Save"
                                className="dkg-send-urlbtn btn"
                              />
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default SocailMedia