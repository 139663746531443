import React, { useState, useCallback, useRef, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { FaEdit } from "react-icons/fa";
import SubmitButton from '../../../../../../ui/submitButton'
import './edittitle.scss'

const EditTitle = ({headingTitle,callUpdate}) => {

    const [titleDesc, setTitleDesc] = useState('')
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {   
    setShow(false); 
    setTitleDesc(headingTitle)
  }, [headingTitle]);


    const submitDetails = ()=>{
        callUpdate(titleDesc,1,'title')
    }

    return (
        <>

            <span className="dkg-editIcon" ><FaEdit onClick={handleShow} /></span>
            <Modal show={show} onHide={handleClose} centered className='dkg-edittitle-modal'>
                <Modal.Header closeButton className='dkg-edittitle-modalHeader'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Title
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='dkg-edittitle-modalBody'>
                    <div className="form-group">                        
                        <label htmlFor="">                            
                            <input type="text" className='form-control mt-2' name='title' value={titleDesc} onChange={(e) => setTitleDesc(e.target.value)} />
                        </label>
                    </div>
                    <div className="dkg-edittitle-updatebtnCn">
                        <SubmitButton txt="Update Title" className="dk-userAddBtn" position="justify-content-center" onClick={submitDetails} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditTitle