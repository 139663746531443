import React from "react";
//import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
//import Submit from "../../../../../../../ui/submitButton";
// import  './upload-video.scss'
import ReactPlayer from "react-player/youtube";
//import { updateVideoComJobSpec } from "../../../../../../../../slice/cmsSlice";
const AddNewModal = ({ onHide, companyId, vid, yurl, type, title }) => {
  //const dispatch = useDispatch();
  // const [videoUrl, setVideoUrl] = useState("");
  // const [titlesUrl, setTitles] = useState(title);

  // const submitBtn = (e) => {
  //   e.preventDefault();
  //   dispatch(
  //     updateVideoComJobSpec({
  //       id: vid,
  //       youtubeurl: videoUrl,
  //       videoTitle: "",
  //     })
  //   );
  // };

  return (
    <>
      <Modal
        show={true}
        onHide={onHide}
        className="dkg-pagetype-uploadModal dkg-vedioplayer-viewModal"
        centered
      >
        <Modal.Header closeButton className="dkg-video-upload-Header">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-video-upload-Body">
          <ReactPlayer url={`${yurl}&ab_channel=fotios`} width="100%" />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewModal;
