import React from "react";
import AdminHeader from "../../../../elements/header/Header";
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../elements/submenu/mainmenu/CandAccSubmenu";
import { Nav, Tab } from "react-bootstrap";
// import  './compliance.scss'
import SecondSidemenu from "./SecondSidemenu";
import SecondSideResume from "./SecondSideResume";
import OverviewTab from "./OverviewTab";
import TestimonialTab from "./TestimonialTab";
import ConsultantsTab from "./ConsultantsTab";
import FeedbackTab from "./FeedbackTab";
import LegalTab from "./LegalTab";
import PaidServiceFaqTab from "./paidservicefaq/PaidServiceFaq";
import AboutUsTab from './AboutUsTab'


const Service = () => {
  return (
    <>
      <AdminHeader />
      <Navbar />
      <Submenu />
      <div className="dkg-contact-mainCon">
        <div className="dkg-contact-main">
          <Tab.Container id="left-tabs-example" defaultActiveKey="dkg-overview">
            <div className="row justify-content-center dkg-contact-mainRow">
              <div className="col-md-2 dkg-contact-main-sideCol">
                <Nav
                  variant="pills dkg-aboutus-navpills dkg-paid-services-navpills"
                  className="flex-column"
                >
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-overview"
                      className="dkg-aboutus-navlink"
                    >
                      Home
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem d-none">
                    <Nav.Link
                      eventKey="dkg-about-us"
                      className="dkg-aboutus-navlink"
                    >
                      About us
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-company"
                      className="dkg-aboutus-navlink"
                    >
                      Services
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-consultants"
                      className="dkg-aboutus-navlink"
                    >
                      Consultants
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-services"
                      className="dkg-aboutus-navlink"
                    >
                      Pricing
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-testimonials"
                      className="dkg-aboutus-navlink"
                    >
                      Testimonials
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-legal"
                      className="dkg-aboutus-navlink"
                    >
                      Legal
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-feedback"
                      className="dkg-aboutus-navlink"
                    >
                      Contact us
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-services-faq"
                      className="dkg-aboutus-navlink"
                    >
                      FAQ’s
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-social"
                      className="dkg-aboutus-navlink"
                    >
                      Assessment
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-study-abroad"
                      className="dkg-aboutus-navlink"
                    >
                      Communication
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-plan-career"
                      className="dkg-aboutus-navlink"
                    >
                      Using Internet
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="consultation-work-aboard"
                      className="dkg-aboutus-navlink"
                    >
                      Work Aboard
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="consultation-study-aboard"
                      className="dkg-aboutus-navlink"
                    >
                      Study Aboard
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="consultation-career-planning"
                      className="dkg-aboutus-navlink"
                    >
                      Plan Career
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </div>
              <div className="col-md-10 col-10 dkg-contact-mainCol">
                <Tab.Content className="dkg-aboutus-tabcontent">
                  <Tab.Pane
                    eventKey="dkg-overview"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Home</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <OverviewTab />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-company"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Overview</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSidemenu />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-services"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Resume</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-consultants"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Consultants</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <ConsultantsTab />
                    </div>
                  </Tab.Pane> 
                  <Tab.Pane
                    eventKey="dkg-about-us"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">About Us</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <AboutUsTab />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-testimonials"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Testimonials</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <TestimonialTab />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-feedback"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Contact us</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <FeedbackTab />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-legal"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Legal</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <LegalTab />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-services-faq"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">FAQ’s</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <PaidServiceFaqTab />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-social"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Assessment</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-study-abroad"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Communication</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-plan-career"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Using Internet</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="consultation-work-aboard"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Work Aboard</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="consultation-study-aboard"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Study Aboard</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="consultation-career-planning"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Plan Career</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default Service;
