import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminHeader from "../../../../../elements/header/Header";
import Navbar from "../../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../../elements/submenu/mainmenu/CandAccSubmenu";
import { Nav, Tab } from "react-bootstrap";
import SummaryComponent from "./SummaryComponent";
import AppliedJobs from "./AppliedJobs";
import InterviewsTab from "./InterviewsTab";
import JobofferedTab from "./JobofferedTab";
import JoinnedTab from "./JoinedTab";
//import MyContactTab from "./MyContactTab";
import { listData, clearState } from "../../../../../../slice/cmsSlice";

import {
  showSuccess,
  showError,
} from "../../../../../utils/messages/messageSlice";

const JobsTab = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList, isUpdate } = useSelector((state) => state.cms);
  const [summary, setSummaryDescription] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(listData({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setSummaryDescription(getcmsList);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState({}));
      dispatch(showSuccess({ msg: "Information updated successfully." }));
    }
  }, [isUpdate]);

  return (
    <>
      <AdminHeader />
      <Navbar />
      <Submenu />
      <div className="dkg-contact-mainCon">
        <div className="dkg-contact-main">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="dkg-open-jobs"
          >
            <div className="row justify-content-center dkg-contact-mainRow">
              <div className="col-md-2 dkg-contact-main-sideCol">
                <Nav
                  variant="pills dkg-aboutus-navpills dkg-paid-services-navpills"
                  className="flex-column"
                >
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-open-jobs"
                      className="dkg-aboutus-navlink"
                    >
                      Open Jobs
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-applied"
                      className="dkg-aboutus-navlink"
                    >
                      Applied
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-interviews"
                      className="dkg-aboutus-navlink"
                    >
                      Interviews
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-offered"
                      className="dkg-aboutus-navlink"
                    >
                      Offered
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-joined"
                      className="dkg-aboutus-navlink"
                    >
                      Joined
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-10 col-10 dkg-contact-mainCol">
                <Tab.Content className="dkg-aboutus-tabcontent">
                  <Tab.Pane
                    eventKey="dkg-open-jobs"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Open Jobs </h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SummaryComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-applied"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Applied</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <AppliedJobs data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-interviews"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Interviews</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <InterviewsTab data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-offered"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Offered</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <JobofferedTab data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-joined"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Joined</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <JoinnedTab data={summary} />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default JobsTab;
