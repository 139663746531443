import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import VirtualizedTable from "./deletedUser/DeleteTableComp";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { FaUserFriends } from "react-icons/fa";
import RightModalFilter from "./rightModalFilter/RightModalFilter";
import AdminHeader from "../../../../elements/header/Header";
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../submenu/SettingSubmenu";
import {
  allCandidate,
  fetchYear,
} from "../../../../../slice/candidate/candidateSlice";
import { useDispatch, useSelector } from "react-redux";
// import LinearBuffer from './LinearBuffer'

const DeleteTblVirtualize = () => {
  const dispatch = useDispatch();
  const { isSuccess, isLoading, candidateList, isStatus, yearList } =
    useSelector((state) => state.candidate);
  //http://localhost:3005/api/v1/candidates/list/pipeline/all-year  //APi for get dynamic year
  const currentYear = new Date().getFullYear();
  const [data, setData] = useState([]);
  const [statusCounts, setStatusCounts] = useState({});
  const [yearsList, setYearsList] = useState([]);
  const [yearData, setYearData] = useState("Year");

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(fetchYear());
        await dispatch(allCandidate({ year: currentYear, status: 0 }));
      } catch (err) {
        // dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (isStatus == true) {
      dispatch(allCandidate({ year: currentYear, status: 0 }));
    }
  }, [isStatus]);

  useEffect(() => {
    if (isSuccess && candidateList.length > 0) {
      setData(candidateList.filter((item) => item.accountStatus === 0));
      setYearsList(yearList);
      const countStatuses = () => {
        const countObject = data.reduce((acc, item) => {
          acc[item.accountStatus] = (acc[item.accountStatus] || 0) + 1;
          return acc;
        }, {});
        setStatusCounts(countObject);
      };
      countStatuses();
    }
  }, [isLoading]);

  const columns = [
    {
      Header: "Case ID",
      accessor: "id",
    },
    {
      Header: "Year",
      accessor: "year",
    },
    {
      Header: "Created On",
      accessor: "createdAt",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "A/C Status",
      accessor: "accountStatus",
    },
    {
      Header: "Comments",
      accessor: "comments",
    },
    {
      Header: "Full Name",
      accessor: "name",
    },
    {
      Header: "Account Email",
      accessor: "email1",
    },
    {
      Header: "Password",
      accessor: "canId",
    },
    {
      Header: "Country",
      accessor: "c_country",
    },
    {
      Header: "Nationality",
      accessor: "nationality",
    },
    {
      Header: "System Email",
      accessor: "system_email",
    },

    { Header: "firstName", accessor: "first_name" },
    { Header: "lastName", accessor: "last_name" },
    { Header: "phoneName", accessor: "phone1" },
    { Header: "Town/City", accessor: "c_city" },
    // { Header: "country", accessor: "c_country" },
    { Header: "profile", accessor: "profileImage" },
  ];

  const [crossYearbtn, setCrossYearbtn] = useState(false);
  const [accontStatus, setAccontStatus] = useState("STATUS");
  const [crossbtn, setCrossbtn] = useState(false);
  const [crossSearchbtn, setCrossSearchbtn] = useState(false);
  const [searchText, setSearchText] = useState("");
  const selectValue = (value, bgcolor) => {
    setAccontStatus(value);
    // let v;
    // if (value == "Active") {
    //   v = 1;
    // } else {
    //   v = 0;
    // }
    setData(candidateList.filter((item) => item.accountStatus === 0));
    setCrossbtn(true);
  };

  const closeBtn = () => {
    setAccontStatus("STATUS");
    setCrossbtn(false);
    setData(candidateList.filter((item) => item.accountStatus === 0));
  };

  const searchData = () => {
    setCrossSearchbtn(true);
    let query = searchText.toLowerCase();
    setData(
      data.filter((item) => {
        const nameMatch = item.name.toLowerCase().includes(query);
        const emailMatch = item.email1.toLowerCase().includes(query);
        //const canId = item.canId.toLowerCase().includes(query);
        const country = item.c_country.toLowerCase().includes(query);
        const nationality = item.nationality.toLowerCase().includes(query);
        const systemEmail = item.system_email.toLowerCase().includes(query);
        return nameMatch || emailMatch || country || nationality || systemEmail;
      })
    );
  };

  const clearBtn = () => {
    setSearchText("");
    setCrossSearchbtn(false);
    setData(candidateList.filter((item) => item.accountStatus === 0));
  };

  const closeYearBtn = () => {
    setYearData("Year");
    setCrossYearbtn(false);
    dispatch(allCandidate({ year: currentYear, status: 0 }));
  };
  const filterByYear = (year) => {
    setData([]);
    setCrossYearbtn(true);
    setYearData(year);
    dispatch(allCandidate({ year, status: 0 }));
  };

  return (
    <>
      <AdminHeader />
      <Navbar />
      <Submenu />
      <div className="dkg-candidate-acc-mainCon">
        <div className="dkg-candidate-acc-header">
          <div className="dkg-cand-acc-leftCon">
            <div className="dk-SrchInputBox mr-2">
              <div className="input-group">
                <input
                  className="form-control"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                {!crossSearchbtn ? (
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={searchData}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {crossSearchbtn ? (
                <div className="dkg-crossicon-Con">
                  <i
                    className="fas fa-times-circle crossUserFilter"
                    data-type="search text"
                    onClick={clearBtn}
                  ></i>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="dkg-plusicon-Con mr-2">
              <Link to="#" className="dkg-plusicon">
                <FaUserFriends />
              </Link>
            </div>
            {/* <button className="dk-reoderBtn mr-2">
              <FaUserFriends />
            </button> */}
            <RightModalFilter />
          </div>
          <div className="dkg-cand-acc-centreCon">
            <h3 className="title">Deleted Accounts</h3>
          </div>
          <div className="dkg-cand-acc-rightCon">
            <div className="dkg-casestus-filter mr-2">
              <DropdownButton className="dropdownFilter" title={accontStatus}>
                <Dropdown.Item
                  href="#"
                  style={{
                    backgroundColor: "#3a9469",
                    color: "#fff",
                  }}
                  onClick={() => selectValue("Active", "#3a9469")}
                >
                  Active
                  <span className="dropdown-filter-count">
                    {statusCounts["1"] || 0}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  style={{
                    backgroundColor: "#d91010",
                    color: "#fff",
                  }}
                  onClick={() => selectValue("Inactive", "#d91010")}
                >
                  Inactive
                  <span className="dropdown-filter-count">
                    {statusCounts["0"] || 0}
                  </span>
                </Dropdown.Item>
              </DropdownButton>
              {crossbtn == true ? (
                <>
                  <i
                    className="fas fa-times-circle crossUserFilter"
                    data-type="account-status"
                    onClick={closeBtn}
                  ></i>
                </>
              ) : null}
            </div>
            <div className="dkg-casestus-filter">
              <DropdownButton className="dropdownFilter" title={yearData}>
                {yearsList.length > 0
                  ? yearsList.map((data, index) => (
                      <Dropdown.Item
                        href="#"
                        key={index + 1}
                        onClick={() => filterByYear(data.year)}
                      >
                        {data.year}
                      </Dropdown.Item>
                    ))
                  : ""}
              </DropdownButton>
              {crossYearbtn == true ? (
                <>
                  <i
                    className="fas fa-times-circle crossUserFilter"
                    data-type="account-status"
                    onClick={closeYearBtn}
                  ></i>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="dkg-candidate-acc-tableCon">
          {data.length > 0 ? (
            <VirtualizedTable columns={columns} data={data} />
          ) : (
            <div className="dkg-cand-loader-Con">
              {/* <p>Loading data...</p> */}
              {/* <LinearBuffer /> */}
              <table className="table table-borderd dkg-candidate-acc-table">
                <thead>
                  <tr>
                    <th>Case ID</th>
                    <th>Year</th>
                    <th>Created On</th>
                    <th>A/C Status</th>
                    <th>Comments</th>
                    <th>Full Name</th>
                    <th>Account Email</th>
                    <th>Country</th>
                    <th>Nationality</th>
                    <th>System Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <h5>Please wait . . .</h5>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DeleteTblVirtualize;
