import axios from 'axios';

export function PostData(isAuthorized, apiUrl, data) {
    let axiosConfig;
    if (isAuthorized) {
        const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                'platform': 'web',
                'Authorization': `Bearer ${token}`
            }
        }
    } else {
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                'platform': 'web'
            }
        }
    }
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.post(process.env.REACT_APP_BASE_URL + apiUrl, data, axiosConfig);
            resolve({ status: res.status, payload: res.data });
        } catch (error) {
            reject(error);
        }
        // axios.post(process.env.REACT_APP_BASE_URL + apiUrl, JSON.stringify(data), axiosConfig).then(function (response) {
        //     resolve(response.data.result);
        // }).catch(function (error) {
        //     reject(error);
        // });
    })
}

export function PutData(isAuthorized, apiUrl, data) {
    let axiosConfig;
    if (isAuthorized) {
        const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                'platform': 'web',
                'Authorization': `Bearer ${token}`
            }
        }
    } else {
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                'platform': 'web'
            }
        }
    }
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.put(process.env.REACT_APP_BASE_URL + apiUrl, data, axiosConfig);
            resolve({ status: res.status, payload: res.data });
        } catch (error) {
            reject(error);
        }
        // axios.post(process.env.REACT_APP_BASE_URL + apiUrl, JSON.stringify(data), axiosConfig).then(function (response) {
        //     resolve(response.data.result);
        // }).catch(function (error) {
        //     reject(error);
        // });
    })
}

export function GetData(isAuthorized, apiUrl) {
    let axiosConfig;
    if (isAuthorized) {
        const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                'platform': 'web',
                'Authorization': `Bearer ${token}`
            }
        }
    } else {
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                'platform': 'web'
            }
        }
    }
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.get(process.env.REACT_APP_BASE_URL + apiUrl, axiosConfig);
            resolve({ status: res.status, payload: res.data });
        } catch (error) {
            reject(error);
        }
        // axios.post(process.env.REACT_APP_BASE_URL + apiUrl, JSON.stringify(data), axiosConfig).then(function (response) {
        //     resolve(response.data.result);
        // }).catch(function (error) {
        //     reject(error);
        // });
    })
}

export function addCandidate(isAuthorized, apiUrl, data) {
    let axiosConfig;
    if (isAuthorized) {
        const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                'platform': 'web',
                'Authorization': `Bearer ${token}`
            }
        }
    } else {
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                'platform': 'web'
            }
        }
    }
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.post(process.env.REACT_APP_BASE_URL + apiUrl, data, axiosConfig);
            resolve({ status: res.status, payload: res.data });
        } catch (error) {
            reject(error);
        }

    })
}

export function DeleteData(isAuthorized, apiUrl) {
    let axiosConfig;
    if (isAuthorized) {
        const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                'platform': 'web',
                'Authorization': `Bearer ${token}`
            }
        }
    } else {
        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                'platform': 'web'
            }
        }
    }
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.delete(process.env.REACT_APP_BASE_URL + apiUrl, axiosConfig);
            resolve({ status: res.status, payload: res.data });
        } catch (error) {
            reject(error);
        }
        // axios.post(process.env.REACT_APP_BASE_URL + apiUrl, JSON.stringify(data), axiosConfig).then(function (response) {
        //     resolve(response.data.result);
        // }).catch(function (error) {
        //     reject(error);
        // });
    })
}

export function PostFormData(isAuthorized, apiUrl, data) {
    let axiosConfig;
    if (isAuthorized) {
        const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
        axiosConfig = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                'platform': 'web',
                'Authorization': `Bearer ${token}`
            }
        }
    } else {
        axiosConfig = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                'platform': 'web'
            }
        }
    }
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.post(process.env.REACT_APP_BASE_URL + apiUrl, data, axiosConfig);
            resolve({ status: res.status, payload: res.data });
        } catch (error) {
            reject(error);
        }
    
    })
}