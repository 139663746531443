import React, { useState, useMemo, useEffect } from 'react';
import { Route, Link } from "react-router-dom";
import AdminHeader from "../../../../components/elements/header/Header";
import AdminNavbar from "../../../../components/elements/navbar/mainmenu/MainNavbar";
import SettingSubmenu from '../../submenu/SettingSubmenu';
import './bkvalues.scss';
import EditBkValuesModal from './EditCategoryModal';
import AddCategoryModal from './AddCategoryModal';

import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import { makeStyles } from '@material-ui/core/styles';
import { categoryList, valuesList, addValue, clearBox2, clearBox3, clearBox4, clearBox5 } from "../../../../slice/backendValues/backendValuesSlice";
import Skeleton from '@material-ui/lab/Skeleton';
//import Authentication from "../../helpers/authenticate";
import ValueList from "./ValueList";
import ReorderValue from "./ReorderValue";

const useStyles = makeStyles({
    root: {
        width: 300,
    },
});

const BkValues = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { box1List, box2List, box3List, box4List, box5List, isLoadingBox1, isLoadingBox2, isLoadingBox3, isLoadingBox4, isLoadingBox5, backendValuesList, isDelete } = useSelector(state => state.backendValues)

    const [selectCategory, setSelectCategory] = useState('0')

    const [mainCategoryId, setMainCategoryId] = useState('0')
    const [subCategoryBox2Id, setSubCategoryBox2Id] = useState('0')
    const [subCategoryBox3Id, setSubCategoryBox3Id] = useState('0')
    const [subCategoryBox4Id, setSubCategoryBox4Id] = useState('0')
    const [subCategoryBox5Id, setSubCategoryBox5Id] = useState('0')

    const [valueCategoryId, setValueCategoryId] = useState('0')

    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(categoryList({ boxNo: 1, parentId: 0 }))
            } catch (err) {
                dispatch(showError({ msg: err }))
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (mainCategoryId != '0') {
            async function fetchData() {
                try {
                    await dispatch(categoryList({ boxNo: 2, parentId: mainCategoryId }))
                    await dispatch(valuesList({ categoryId: mainCategoryId }))
                } catch (err) {
                    dispatch(showError({ msg: err }))
                }
            }
            fetchData()
        }
    }, [mainCategoryId])

    useEffect(() => {
        if (subCategoryBox2Id != '0') {
            async function fetchData() {
                try {
                    await dispatch(categoryList({ boxNo: 3, parentId: subCategoryBox2Id }))
                    await dispatch(valuesList({ categoryId: subCategoryBox2Id }))
                } catch (err) {
                    dispatch(showError({ msg: err }))
                }
            }
            fetchData()
        }
    }, [subCategoryBox2Id])

    useEffect(() => {
        if (subCategoryBox3Id != '0') {
            async function fetchData() {
                try {
                    await dispatch(categoryList({ boxNo: 4, parentId: subCategoryBox3Id }))
                    await dispatch(valuesList({ categoryId: subCategoryBox3Id }))
                } catch (err) {
                    dispatch(showError({ msg: err }))
                }
            }
            fetchData()
        }
    }, [subCategoryBox3Id])

    useEffect(() => {
        if (subCategoryBox4Id != '0') {
            async function fetchData() {
                try {
                    await dispatch(categoryList({ boxNo: 5, parentId: subCategoryBox4Id }))
                    await dispatch(valuesList({ categoryId: subCategoryBox4Id }))
                } catch (err) {
                    dispatch(showError({ msg: err }))
                }
            }
            fetchData()
        }
    }, [subCategoryBox4Id])

    useEffect(() => {
        if (subCategoryBox5Id != '0') {
            async function fetchData() {
                try {
                    await dispatch(valuesList({ categoryId: subCategoryBox5Id }))
                } catch (err) {
                    dispatch(showError({ msg: err }))
                }
            }
            fetchData()
        }
    }, [subCategoryBox5Id])

    const clickMainCategory = async (id) => {

        setSelectCategory(id)
        setMainCategoryId(id)
        setSubCategoryBox2Id('0')
        setSubCategoryBox3Id('0')
        setSubCategoryBox4Id('0')
        setSubCategoryBox5Id('0')
        setValueCategoryId(id)
        dispatch(clearBox3())
        dispatch(clearBox4())
        dispatch(clearBox5())
    }

    const clickSubCategoryBox2 = (id) => {
        setSelectCategory(id)
        setSubCategoryBox2Id(id)
        setSubCategoryBox3Id('0')
        setSubCategoryBox4Id('0')
        setSubCategoryBox5Id('0')
        setValueCategoryId(id)
        dispatch(clearBox4())
        dispatch(clearBox5())
    }

    const clickSubCategoryBox3 = (id) => {
        setSelectCategory(id)
        setSubCategoryBox3Id(id)
        setSubCategoryBox4Id('0')
        setSubCategoryBox5Id('0')
        setValueCategoryId(id)
        dispatch(clearBox5())

    }

    const clickSubCategoryBox4 = (id) => {
        setSelectCategory(id)
        setSubCategoryBox5Id('0')
        setSubCategoryBox4Id(id)
        setValueCategoryId(id)
    }

    const clickSubCategoryBox5 = (id) => {
        setSelectCategory(id)
        setSubCategoryBox5Id(id)
        setValueCategoryId(id)
    }

    const addNewValues = () => {
        dispatch(addValue({ categoryId: selectCategory, bgColor: '#fff', textColor: '#333', value: '' }))
    }

    return (
        <React.Fragment>
            <AdminHeader />
            <AdminNavbar />
            <SettingSubmenu />

            <div className="dk-toolsBkValues">
                <div className="dk-toolsValues-flex">
                    <div className="dk-toolsValues-cols">
                        <div className="dk-valueTitle">
                            <div className="d-flex align-items-center justify-content-between">
                                {/* <EditCategoryModal /> */}
                                <EditBkValuesModal boxNo="1" parentId1="0" parentId2="0" parentId3="0" parentId4="0" parentId5="0" headingText="Main Category" />
                                <h6 className="subTitle">Main Category</h6>
                                <AddCategoryModal boxNo="1" parentId1="0" parentId2="0" parentId3="0" parentId4="0" parentId5="0" headingText="Add Main Category" />

                            </div>
                        </div>
                        <div className="dk-toolsValueBox">
                            <ul>
                                {
                                    (isLoadingBox1) ? <li>
                                        <div class="clickBody">
                                            <Skeleton animation="wave" />
                                        </div>
                                    </li> :
                                        box1List.map((item, indx) => (
                                            <li><div class={(mainCategoryId == item.id) ? 'clickBody activeTabs' : 'clickBody'} onClick={() => clickMainCategory(item.id)}>{item.name}</div></li>
                                        ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="dk-toolsValues-cols">
                        <div className="dk-valueTitle">
                            <div className="d-flex align-items-center justify-content-between">
                                {/* <EditCategoryModal /> */}
                                {
                                    (mainCategoryId != '0') ? <EditBkValuesModal boxNo="2" parentId1={mainCategoryId} parentId2="0" parentId3="0" parentId4="0" parentId5="0" headingText="1st Sub Category" /> : <Link to="#" onClick={() => dispatch(showError({ msg: 'Select Main Category' }))}><i class="far fa-edit"></i></Link>
                                }
                                <h6 className="subTitle">1st Sub Category</h6>
                                {/* <AddCategoryModal /> */}
                                {
                                    (mainCategoryId != '0') ? <AddCategoryModal boxNo="2" parentId1={mainCategoryId} parentId2="0" parentId3="0" parentId4="0" parentId5="0" headingText="Add 1st Sub Category" /> : <Link to="#" onClick={() => dispatch(showError({ msg: 'Select Main Category' }))}><i class="fas fa-plus"></i></Link>
                                }
                            </div>
                        </div>
                        <div className="dk-toolsValueBox">
                            <ul>
                                {
                                    (isLoadingBox2) ? <li>
                                        <div class="clickBody">
                                            <Skeleton animation="wave" />
                                        </div>
                                    </li> :
                                        box2List.map((item, indx) => (
                                            <li><div class={(subCategoryBox2Id == item.id) ? 'clickBody activeTabs' : 'clickBody'} onClick={() => clickSubCategoryBox2(item.id)}>{item.name}</div></li>
                                        ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="dk-toolsValues-cols">
                        <div className="dk-valueTitle">
                            <div className="d-flex align-items-center justify-content-between">
                                {/* <EditCategoryModal />
                                <h6 className="subTitle">2nd Sub Category</h6>
                                <AddCategoryModal /> */}
                                {
                                    (mainCategoryId != '0' && subCategoryBox2Id != '0') ? <EditBkValuesModal boxNo="3" parentId1={mainCategoryId} parentId2={subCategoryBox2Id} parentId3="0" parentId4="0" parentId5="0" headingText="2nd Sub Category" /> : <Link to="#" onClick={() => dispatch(showError({ msg: 'Select 1st Sub Category' }))}><i class="far fa-edit"></i></Link>
                                }
                                <h6 className="subTitle">2nd Sub Category</h6>
                                {
                                    (mainCategoryId != '0' && subCategoryBox2Id != '0') ? <AddCategoryModal boxNo="3" parentId1={mainCategoryId} parentId2={subCategoryBox2Id} parentId3="0" parentId4="0" parentId5="0" headingText="Add 2nd Sub Category" /> : <Link to="#" onClick={() => dispatch(showError({ msg: 'Select 1st Sub Category' }))}><i class="fas fa-plus"></i></Link>
                                }
                            </div>
                        </div>
                        <div className="dk-toolsValueBox">

                            <ul>
                                {
                                    (isLoadingBox3) ? <li>
                                        <div class="clickBody">
                                            <Skeleton animation="wave" />
                                        </div>
                                    </li> :
                                        box3List.map((item, indx) => (
                                            <li><div class={(subCategoryBox3Id === item.id) ? 'clickBody activeTabs' : 'clickBody'} onClick={() => clickSubCategoryBox3(item.id)}>{item.name}</div></li>
                                        ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="dk-toolsValues-cols">
                        <div className="dk-valueTitle">
                            <div className="d-flex align-items-center justify-content-between">
                                {/* <EditCategoryModal />
                                <h6 className="subTitle">3rd Sub Category</h6>
                                <AddCategoryModal /> */}
                                {
                                    (mainCategoryId != '0' && subCategoryBox2Id != '0' && subCategoryBox3Id != '0') ? <EditBkValuesModal boxNo="4" parentId1={mainCategoryId} parentId2={subCategoryBox2Id} parentId3={subCategoryBox3Id} parentId4="0" parentId5="0" headingText="3rd Sub Category" /> : <Link to="#" onClick={() => dispatch(showError({ msg: 'Select 2nd Sub Category' }))}><i class="far fa-edit"></i></Link>
                                }
                                <h6 className="subTitle">3rd Sub Category</h6>
                                {
                                    (mainCategoryId != '0' && subCategoryBox2Id != '0' && subCategoryBox3Id != '0') ? <AddCategoryModal boxNo="4" parentId1={mainCategoryId} parentId2={subCategoryBox2Id} parentId3={subCategoryBox3Id} parentId4="0" parentId5="0" headingText="Add 3rd Sub Category" /> : <Link to="#" onClick={() => dispatch(showError({ msg: 'Select 2nd Sub Category' }))}><i class="fas fa-plus"></i></Link>
                                }
                            </div>
                        </div>
                        <div className="dk-toolsValueBox">
                            <ul>
                                {
                                    (isLoadingBox4) ? <li>
                                        <div class="clickBody">
                                            <Skeleton animation="wave" />
                                        </div>
                                    </li> :
                                        box4List.map((item, indx) => (
                                            <li><div class={(subCategoryBox4Id == item.id) ? 'clickBody activeTabs' : 'clickBody'} onClick={() => clickSubCategoryBox4(item.id)}>{item.name}</div></li>
                                        ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="dk-toolsValues-cols">
                        <div className="dk-valueTitle">
                            <div className="d-flex align-items-center justify-content-between">

                                {
                                    (mainCategoryId != '0' && subCategoryBox2Id != '0' && subCategoryBox3Id != '0' && subCategoryBox4Id != '0') ? <EditBkValuesModal boxNo="5" parentId1={mainCategoryId} parentId2={subCategoryBox2Id} parentId3={subCategoryBox3Id} parentId4={subCategoryBox4Id} parentId5="0" headingText="4th Sub Category" /> : <Link to="#" onClick={() => dispatch(showError({ msg: 'Select 3rd Sub Category' }))}><i class="far fa-edit"></i></Link>
                                }
                                <h6 className="subTitle">4th Sub Category</h6>
                                {
                                    (mainCategoryId != '0' && subCategoryBox2Id != '0' && subCategoryBox3Id != '0' && subCategoryBox4Id != '0') ? <AddCategoryModal boxNo="5" parentId1={mainCategoryId} parentId2={subCategoryBox2Id} parentId3={subCategoryBox3Id} parentId4={subCategoryBox4Id} parentId5="0" headingText="Add 4th Sub Category" /> : <Link to="#" onClick={() => dispatch(showError({ msg: 'Select 3rd Sub Category' }))}><i class="fas fa-plus"></i></Link>
                                }
                            </div>
                        </div>
                        <div className="dk-toolsValueBox">
                            <ul>
                                {
                                    (isLoadingBox5) ? <li>
                                        <div class="clickBody">
                                            <Skeleton animation="wave" />
                                        </div>
                                    </li> :
                                        box5List.map((item, indx) => (
                                            <li><div class={(subCategoryBox5Id == item.id) ? 'clickBody activeTabs' : 'clickBody'} onClick={() => clickSubCategoryBox5(item.id)}>{item.name}</div></li>
                                        ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="dk-toolsValues-cols dk-toolsLastValue ml-3">
                        <div className="dk-valueTitle">
                            <div className="d-flex align-items-center justify-content-between">
                                <ReorderValue cateId={valueCategoryId} />
                                <h6 className="subTitle">Values</h6>
                                {
                                    (selectCategory != '0') ? <Link to="#" onClick={addNewValues}><i class="fas fa-plus"></i></Link> : <Link to="#" onClick={() => dispatch(showError({ msg: 'Select Category' }))}><i class="fas fa-plus"></i></Link>
                                }
                            </div>
                        </div>
                        <div className="dk-toolsValueField">
                            <ul>
                                <div className="dk-valueFieldCols">
                                    {

                                        (valueCategoryId > 0) ? <ValueList cateId={valueCategoryId} /> : null
                                    }
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default BkValues;

