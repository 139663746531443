import React, { useRef, useEffect, useState } from "react";
// import "./switch.scss";

const Switch = ({
  handleToggle,
  id,
  value,
  isChecked,
  name,
  clearCheckbox,
  companyNm,
}) => {
  const [chcked, setIsChecked] = useState(false);

  const ref = useRef(null);

  // useEffect(() => {
  //      setIsChecked(ref.current.checked == isChecked ? false : true);
  // }, []);

  //console.log(clearCheckbox);
  // console.log(isChecked);
  // console.log(chcked);

  const handleOnChange = (e) => {
    // if (ref.current.checked) {
    //   setIsChecked((current) => !current);
    //   //console.log("Checked");
    // } else {
    //   setIsChecked(false);
    //   //setIsChecked(!chcked);
    //   //console.log("Not Checked");
    // }
    setIsChecked((current) => !current);
    handleToggle(e);
    // console.log(isChecked);
  };

  return (
    <>
      <div className="dkg-feature-column">
        <span className="dkg-off-label mr-2">OFF</span>
        <input
          // ref={ref}
          onChange={handleOnChange}
          className="react-switch-checkbox"
          id={`react-switch-new` + id + value}
          type="checkbox"
          data-id={id}
          data-value={value}
          data-name={name}
          key={id + 1 + value}
          checked={isChecked == chcked ? false : true}
          //checked={isChecked == chcked ? true : false}
        />
        <label
          className="react-switch-label"
          htmlFor={`react-switch-new` + id + value}
        >
          <span className="react-switch-button" />
        </label>
        <span className="dkg-off-label ml-2">ON</span>
      </div>
    </>
  );
};

export default Switch;
