import React from 'react';
import { Link, useHistory } from 'react-router-dom'; 
import './visitsites.scss';
import AdminHeader from '../../../elements/header/Header'
import Navbar from "../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../elements/submenu/mainmenu/DashboardSubmenu';
import DkgIcon from '../../../../assets/images/dk_icon_white.png';
import BusinessDevelopment from '../../../../assets/images/company-white.png';
import Recruitment from '../../../../assets/images/recruitment.png';
import Marketing from '../../../../assets/images/ad-campaign.png';
import ResourceImg from '../../../../assets/images/recruitment.png';
import Finance from '../../../../assets/images/finance.png';
import LoginsImg from '../../../../assets/images/login.png';
import HrImg from '../../../../assets/images/hr_white.png';
import Training from '../../../../assets/images/training.png';
import ItTeamImg from '../../../../assets/images/software-dev.png';
import FaqImg from '../../../../assets/images/information_white.png';
import { useDispatch, useSelector } from "react-redux";
// import { setMyModule } from "../../../../slice/modules/moduleSlice";
// import { showSuccess, showError } from "../../../../components/utils/messages/messageSlice";


const VisitModule = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    // const { userInfo } = useSelector(state => state.auth);

    // const setModule = async (myModule, moduleColor) => {

    //     await dispatch(setMyModule({ module: myModule, color: moduleColor }))

    //     if (userInfo.role === "User" || userInfo.role === "Admin") {

    //         if (myModule === "Logins" || myModule === "dkg-global" || myModule === "main-faq") {
    //             switch (myModule) {
    //                 case 'Logins':
    //                     history.push("/logins/summary");
    //                     break;
    //                 case 'dkg-global':
    //                     history.push("/departs/dkg-global/dashboard");
    //                     break;

    //                 case 'main-faq':
    //                     history.push("/maindepartment/main-faq/dashboard");
    //                 default:
    //                     break;
    //             }
    //         } else {
    //             dispatch(showError({ msg: 'You are not authorized to view' }))

    //         }

    //     } else {


    //         switch (myModule) {

    //             case 'it-teams':
    //                 history.push("/dkglobaladmin/it-teams/module");
    //                 break;

    //             case 'trainingmodule':
    //                 history.push("/dkglobaladmin/trainingmodule/dashboard");
    //                 break;

    //             case 'dkglobal':
    //                 history.push("/dkglobaladmin/dkglobal/information");
    //                 break;

    //             case 'clients':
    //                 history.push("/dashboard/clients");
    //                 break;

    //             case 'hr':
    //                 history.push("/dkglobaladmin/hr/dashboard");
    //                 break;

    //             case 'manage-ment':
    //                 history.push("/dashboard/manage-ment");
    //                 break;

    //             case 'supplier':
    //                 history.push("/dkglobaladmin/supplier/dashboard");
    //                 break;

    //             case 'Logins':
    //                 history.push("/logins/summary");
    //                 break;

    //             case 'legalmodule':
    //                 history.push("/legalmodule/legal-module");
    //                 break;

    //             case 'main-faq':
    //                 history.push("/maindepartment/main-faq/dashboard");
    //                 break;

    //             case 'dkg-global':
    //                 history.push("/departs/dkg-global/dashboard");
    //                 break;

    //             case 'dkglobaladmin':
    //                 history.push("/dkglobaladmin/hr/dashboard");
    //                 break;


    //             default:
    //                 break;
    //         }
    //     }
    // }
    return (
        <>
            <AdminHeader />
            <Navbar />
            <Submenu />
            <div className="dkg-adminpanelMain dkg-adminpanel-modulesCon">
                <div className="w-100" style={{ paddingTop: '130px' }}>
                    <div className="dkg-adminpanel-cols">
                        <div className="dkg-adminpanel-box dkgcrm-acc-finanancebox">
                            <Link to="/dashboard/businesss-overview" style={{ background: '#884c5e' }}>
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={DkgIcon} alt="" />
                                    </span>
                                    MANAGEMENT
                                </div>
                            </Link>
                        </div>
                        <div className="dkg-adminpanel-box dkgcrm-recquirementdbox">
                            <Link to="/dashboard/recruitment" style={{ background: '#2b8faf' }}>
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={Recruitment} alt="" />
                                    </span>
                                    RECRUITER
                                </div>
                            </Link>
                        </div>
                        <div className="dkg-adminpanel-box dkgcrm-acc-finanancebox">
                            <Link to="#">
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={Finance} alt="" />
                                    </span>
                                    PARTNER
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="dkg-adminpanel-cols">
                        <div className="dkg-adminpanel-box dkgcrm-dashboardbox">
                            <Link to="/dashboard/clients">
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={BusinessDevelopment} alt="" />
                                    </span>
                                    CLIENT
                                </div>
                            </Link>
                        </div>
                        <div className="dkg-adminpanel-box dkgcrm-supplierbox">
                            <Link to="/dashboard/suppliers">
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={ResourceImg} alt="" />
                                    </span>
                                    SUPPLIER
                                </div>
                            </Link>
                        </div>
                        <div className="dkg-adminpanel-box dkgcrm-marketingdbox">
                            <Link to="#">
                                <div className="dk-visitLogo">
                                    <span>
                                        <img src={Marketing} alt="" />
                                    </span>
                                    JOBSEEKERS
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VisitModule;
