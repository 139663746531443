import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminHeader from "../../../../elements/header/Header";
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../elements/submenu/mainmenu/CandAccSubmenu";
import "./cand-dash.scss";
import { Tab, Nav, InputGroup, FormControl } from "react-bootstrap";
import Switch from "./Switch";
//import AccManager from "../../../../../assets/images/account_manager.png";
import {
  getTextHeading,
  updateCMSText,
  getService,
  updateServices,
  getBenifits,
  updateBenifits,
  updateKeyBenifits,
  getAboutService,
  clearState,
} from "../../../../../slice/candidate/frontendCms/frontendCmsSlice";
import SubmitButton from "../../../../ui/submitButton";
import {
  showError,
  showSuccess,
} from "../../../../../components/utils/messages/messageSlice";
import { FaEdit } from "react-icons/fa";
import AWS from "aws-sdk";
import { Buffer } from "buffer";
import "react-image-crop/dist/ReactCrop.css";
import $ from "jquery";
import SecondSideMenu from "./SecondSideMenu";

const Index = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    getcmsList,
    serviceList,
    isSuccess,
    benifitsLists,
    isBenifits,
    isUpdate,
    aboutServiceLists,
    isAboutService,
  } = useSelector((state) => state.cmslist);

  const [firstImg, setFirstImg] = useState(true);
  const [firstImgbase64, setFirstImgbase64] = useState("");

  const [secondImg, setSecondImg] = useState(true);
  const [secondImgbase64, setSecondImgbase64] = useState("");

  const [thirdImg, setThirdImg] = useState(true);
  const [thirdImgbase64, setThirdImgbase64] = useState("");

  const [benifits, setBenifits] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [pageHeading, setPageHeading] = useState([]);
  const [aboutuService, setAboutService] = useState([]);

  // const [benifitHeading, setBenifitHeading] = useState("");
  // const [benifitDescription, setBenifitDescription] = useState("");

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setPageHeading(getcmsList[0]);
    }
    if (!isSuccess && serviceList && serviceList.length > 0) {
      setServiceData(serviceList);
    }
    if (!isBenifits && benifitsLists && benifitsLists.length > 0) {
      setBenifits(benifitsLists);
    }
    if (!isAboutService && aboutServiceLists && aboutServiceLists.length > 0) {
      setAboutService(aboutServiceLists);
    }
  }, [isLoading, isSuccess, isBenifits, isAboutService]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState({}));
      dispatch(showSuccess({ msg: "Information updated successfully" }));
    }
  }, [isUpdate]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getTextHeading({}));
        await dispatch(getBenifits({}));
        await dispatch(getService({}));
        await dispatch(getAboutService({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  const SubmitBtn = () => {
    dispatch(
      updateCMSText({
        id: 1,
        key: "page_description",
        value: pageHeading,
      })
    );
  };

  function getValueByName(name) {
    var elements = document.getElementsByName(name);
    if (elements.length > 0) {
      var value = elements[0].value;
      return value;
    } else {
      return null;
    }
  }

  const updateBenifitsHandle = (id) => {
    var heading = getValueByName("heading_" + id);
    var descriptions = getValueByName("description_" + id);
    dispatch(
      updateBenifits({
        id,
        heading,
        descriptions,
      })
    );
  };
  const updateHandle = (id, val, key) => {
    dispatch(
      updateServices({
        id,
        key,
        value: val,
      })
    );
  };
  const handleToggle = (e) => {
    const dataId = e.target.getAttribute("data-id");
    if (e.target.checked === true) {
      dispatch(updateServices({ id: dataId, key: "status", value: 1 }));
    } else {
      dispatch(updateServices({ id: dataId, key: "status", value: 0 }));
    }
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        if (e.target.name === "secondImg") {
          $(".changeSecondImageDynamic" + e.target.id).attr(
            "src",
            reader.result
          );
        } else if (e.target.name === "thirdImg") {
          $(".changeThirdImageDynamic" + e.target.id).attr(
            "src",
            reader.result
          );
        } else {
          $(".changeImageDynamic" + e.target.id).attr("src", reader.result);
        }

        let s3bucket = new AWS.S3({
          accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
          secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
          Bucket: process.env.REACT_APP_BUCKET_NAME,
        });

        let buf = new Buffer(
          reader.result.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );
        s3bucket.createBucket(function () {
          var params = {
            Bucket: process.env.REACT_APP_BUCKET_NAME + "/users/images",
            Key: Date.now() + ".png",
            ContentEncoding: "base64",
            ContentType: "image/png",
            Body: buf,
          };
          s3bucket.upload(params, function (err, data) {
            if (err) {
              console.log(err);
            } else {
              if (e.target.name === "thirdImg") {
                console.log("thirdimg", e.target.id);
                dispatch(
                  updateServices({
                    id: e.target.id,
                    key: "image",
                    value: data.key,
                  })
                );
              } else {
                dispatch(
                  updateKeyBenifits({
                    id: e.target.id,
                    key:
                      e.target.name === "secondImg"
                        ? "second_image"
                        : "image_name",
                    value: data.key,
                  })
                );
              }
            }
          });
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <>
      <AdminHeader />
      <Navbar />
      <Submenu />
      <div className="dkg-contact-mainCon">
        <div className="dkg-contact-main">
          <Tab.Container id="left-tabs-example" defaultActiveKey="dkg-company">
            <div className="row justify-content-center dkg-contact-mainRow">
              <div className="col-md-2 dkg-contact-main-sideCol">
                <Nav
                  variant="pills dkg-aboutus-navpills dkg-paid-services-navpills"
                  className="flex-column"
                >
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-company"
                      className="dkg-aboutus-navlink"
                    >
                      Header Titles
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-benefits"
                      className="dkg-aboutus-navlink"
                    >
                      Basic Account
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-premium-acc"
                      className="dkg-aboutus-navlink"
                    >
                      Premium Account
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-paidservices"
                      className="dkg-aboutus-navlink"
                    >
                      Paid Services
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </div>
              <div className="col-md-10 col-10 dkg-contact-mainCol">
                <Tab.Content className="dkg-aboutus-tabcontent">
                  <Tab.Pane
                    eventKey="dkg-company"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-candidate-header">
                      <h1 className="dkg-candidate-title">Overview</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideMenu />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-benefits"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-candidate-mainCon dkg-edit-cand-benfitsCon">
                      <div className="dkg-cn-benefits-Row row">
                        <div className="col-md-12 col-sm-12 dkg-cn-benefits-leftCon dkg-basic-acc-table">
                          {benifits.length > 0
                            ? benifits.map((item, index) => (
                                <div
                                  className="dkg-cand-form-group form-group"
                                  key={index + 1}
                                >
                                  <InputGroup className="dkg-cn-benefits-inputgrp">
                                    <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                      <InputGroup.Text className="dkg-inputgrp-text">
                                        <div className="dkg-benefit-iconCon">
                                          <input
                                            type="file"
                                            accept="image/*"
                                            name="firstImg"
                                            id={item.id}
                                            onChange={onSelectFile}
                                          />
                                          <span className="dk-myprofile-userImgCon">
                                            <figure id="edit_image">
                                              {firstImg ? (
                                                <img
                                                  alt=""
                                                  className={
                                                    `dkg-changIcon changeImageDynamic` +
                                                    item.id
                                                  }
                                                  style={{ width: "35px" }}
                                                  src={
                                                    item.image_name != ""
                                                      ? process.env
                                                          .REACT_APP_IMG_CLOUD_BASE_URL +
                                                        "/" +
                                                        item.image_name
                                                      : ""
                                                  }
                                                />
                                              ) : (
                                                <img
                                                  alt=""
                                                  className={
                                                    `dkg-changIcon changeImageDynamic` +
                                                    item.id
                                                  }
                                                  src={firstImgbase64}
                                                  style={{ width: "35px" }}
                                                />
                                              )}
                                            </figure>
                                          </span>
                                          <span className="dk-myprofile-editicon">
                                            <FaEdit />
                                          </span>
                                        </div>
                                        <FormControl
                                          aria-label="nameofcompany"
                                          className="dkg-inputgrp-frmcontrol"
                                          defaultValue={item.heading}
                                          name={`heading_` + item.id}
                                        />
                                        <div className="dkg-trick-iconCon">
                                          <input
                                            type="file"
                                            accept="image/*"
                                            name="secondImg"
                                            id={item.id}
                                            onChange={onSelectFile}
                                          />
                                          <span className="dk-trick-click-userImgCon">
                                            <figure id="edit_image">
                                              {secondImg ? (
                                                <img
                                                  alt=""
                                                  className={
                                                    `dkg-changIcon changeSecondImageDynamic` +
                                                    item.id
                                                  }
                                                  style={{ width: "35px" }}
                                                  src={
                                                    item.second_image != ""
                                                      ? process.env
                                                          .REACT_APP_IMG_CLOUD_BASE_URL +
                                                        "/" +
                                                        item.second_image
                                                      : ""
                                                  }
                                                />
                                              ) : (
                                                <img
                                                  alt=""
                                                  className={
                                                    `dkg-changIcon changeSecondImageDynamic` +
                                                    item.id
                                                  }
                                                  src={secondImgbase64}
                                                  style={{ width: "35px" }}
                                                />
                                              )}
                                            </figure>
                                          </span>
                                          <span className="dk-trick-click-editicon">
                                            <FaEdit />
                                          </span>
                                        </div>
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      aria-label="nameofcompany"
                                      className="dkg-cand-frmcontrol"
                                      defaultValue={item.descriptions}
                                      name={`description_` + item.id}
                                    />
                                    <div className="dkg-cn-benefits-btnCon">
                                      <SubmitButton
                                        txt="SAVE"
                                        onClick={(e) =>
                                          updateBenifitsHandle(item.id)
                                        }
                                        className="dkg-cand-submit-btn"
                                      />
                                    </div>
                                  </InputGroup>
                                </div>
                              ))
                            : ""}

                          {/* <div className="dkg-cand-form-group form-group">
                            <InputGroup className="dkg-cn-benefits-inputgrp">
                              <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                <InputGroup.Text className="dkg-inputgrp-text">
                                  <FormControl
                                    placeholder="Registration At"
                                    aria-label="nameofcompany"
                                    className="dkg-inputgrp-frmcontrol"
                                    defaultValue=""
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="Dublin, Ireland"
                                aria-label="head-office"
                                className="dkg-cand-frmcontrol"
                                defaultValue=""
                                // onChange={(e) =>
                                //   updateHandle(
                                //     2,
                                //     e.target.value,
                                //     "registrationAt"
                                //   )
                                // }
                              />
                            </InputGroup>
                          </div>
                          <div className="dkg-cand-form-group form-group">
                            <InputGroup className="dkg-cn-benefits-inputgrp">
                              <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                <InputGroup.Text className="dkg-inputgrp-text">
                                  <FormControl
                                    placeholder="Registration No."
                                    aria-label="nameofcompany"
                                    className="dkg-inputgrp-frmcontrol"
                                    defaultValue=""
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="590583"
                                aria-label="registration"
                                className="dkg-cand-frmcontrol"
                                defaultValue=""
                                // onChange={(e) =>
                                //   updateHandle(
                                //     2,
                                //     e.target.value,
                                //     "registrationNo"
                                //   )
                                // }
                              />
                            </InputGroup>
                          </div>
                          <div className="dkg-cand-form-group form-group">
                            <InputGroup className="dkg-cn-benefits-inputgrp">
                              <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                <InputGroup.Text className="dkg-inputgrp-text">
                                  <FormControl
                                    placeholder="Company Vat No."
                                    aria-label="nameofcompany"
                                    className="dkg-inputgrp-frmcontrol"
                                    defaultValue=""
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="IE 3438468BH"
                                aria-label="vat-number"
                                className="dkg-cand-frmcontrol"
                                defaultValue=""
                                // onChange={(e) =>
                                //   updateHandle(2, e.target.value, "vatNo")
                                // }
                              />
                            </InputGroup>
                          </div>
                          <div className="dkg-cand-form-group form-group">
                            <InputGroup className="dkg-cn-benefits-inputgrp">
                              <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                <InputGroup.Text className="dkg-inputgrp-text">
                                  <FormControl
                                    placeholder="Head Office"
                                    aria-label="nameofcompany"
                                    className="dkg-inputgrp-frmcontrol"
                                    defaultValue=""
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="Dublin, Ireland"
                                aria-label="registered"
                                className="dkg-cand-frmcontrol"
                                defaultValue=""
                                // onChange={(e) =>
                                //   updateHandle(2, e.target.value, "headOffice")
                                // }
                              />
                            </InputGroup>
                          </div>
                          <div className="dkg-cand-form-group form-group">
                            <InputGroup className="dkg-cn-benefits-inputgrp">
                              <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                <InputGroup.Text className="dkg-inputgrp-text">
                                  <FormControl
                                    placeholder="Work Industry"
                                    aria-label="nameofcompany"
                                    className="dkg-inputgrp-frmcontrol"
                                    defaultValue=""
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="Professionals Services"
                                aria-label="industry"
                                className="dkg-cand-frmcontrol"
                                defaultValue=""
                                // onChange={(e) =>
                                //   updateHandle(2, e.target.value, "industry")
                                // }
                              />
                            </InputGroup>
                          </div>
                          <div className="dkg-cand-form-group form-group">
                            <InputGroup className="dkg-cn-benefits-inputgrp">
                              <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                <InputGroup.Text className="dkg-inputgrp-text">
                                  <FormControl
                                    placeholder=" Business Type"
                                    aria-label="nameofcompany"
                                    className="dkg-inputgrp-frmcontrol"
                                    defaultValue=""
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="Recruitment Services"
                                aria-label="business-type"
                                className="dkg-cand-frmcontrol"
                                defaultValue=""
                                // onChange={(e) =>
                                //   updateHandle(2, e.target.value, "businessType")
                                // }
                              />
                            </InputGroup>
                          </div>
                          <div className="dkg-cand-form-group form-group">
                            <InputGroup className="dkg-cn-benefits-inputgrp">
                              <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                <InputGroup.Text className="dkg-inputgrp-text">
                                  <FormControl
                                    placeholder=" Recruitment In"
                                    aria-label="nameofcompany"
                                    className="dkg-inputgrp-frmcontrol"
                                    defaultValue=""
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="Multilingual Recruitment"
                                aria-label="specialized-in"
                                className="dkg-cand-frmcontrol"
                                defaultValue=""
                                // onChange={(e) =>
                                //   updateHandle(2, e.target.value, "recruitmentIn")
                                // }
                              />
                            </InputGroup>
                          </div>
                          <div className="dkg-cand-form-group form-group">
                            <InputGroup className="dkg-cn-benefits-inputgrp">
                              <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                <InputGroup.Text className="dkg-inputgrp-text">
                                  <FormControl
                                    placeholder="Other Services"
                                    aria-label="other-service"
                                    className="dkg-inputgrp-frmcontrol"
                                    defaultValue=""
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="Globally,Mainly Europe"
                                aria-label="services-area"
                                className="dkg-cand-frmcontrol"
                                defaultValue=""
                                // onChange={(e) =>
                                //   updateHandle(2, e.target.value, "otherServices")
                                // }
                              />
                            </InputGroup>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-premium-acc"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-candidate-mainCon dkg-edit-cand-benfitsCon">
                      <div className="dkg-cn-benefits-Row row">
                        <div className="col-md-12 col-sm-12 dkg-cn-benefits-leftCon dkg-premium-acc-table">
                          {benifits.length > 0
                            ? benifits.map((item, index) => (
                                <div
                                  className="dkg-cand-form-group form-group"
                                  key={index + 1}
                                >
                                  <InputGroup className="dkg-cn-benefits-inputgrp">
                                    <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                      <InputGroup.Text className="dkg-inputgrp-text">
                                        <div className="dkg-benefit-iconCon">
                                          <input
                                            type="file"
                                            accept="image/*"
                                            name="firstImg"
                                            id={item.id}
                                            onChange={onSelectFile}
                                          />
                                          <span className="dk-myprofile-userImgCon">
                                            <figure id="edit_image">
                                              {firstImg ? (
                                                <img
                                                  alt=""
                                                  className={
                                                    `dkg-changIcon changeImageDynamic` +
                                                    item.id
                                                  }
                                                  style={{ width: "35px" }}
                                                  src={
                                                    item.image_name != ""
                                                      ? process.env
                                                          .REACT_APP_IMG_CLOUD_BASE_URL +
                                                        "/" +
                                                        item.image_name
                                                      : ""
                                                  }
                                                />
                                              ) : (
                                                <img
                                                  alt=""
                                                  className={
                                                    `dkg-changIcon changeImageDynamic` +
                                                    item.id
                                                  }
                                                  src={firstImgbase64}
                                                  style={{ width: "35px" }}
                                                />
                                              )}
                                            </figure>
                                          </span>
                                          <span className="dk-myprofile-editicon">
                                            <FaEdit />
                                          </span>
                                        </div>
                                        <FormControl
                                          aria-label="nameofcompany"
                                          className="dkg-inputgrp-frmcontrol"
                                          defaultValue={item.heading}
                                          name={`heading_` + item.id}
                                        />
                                        <div className="dkg-trick-iconCon">
                                          <input
                                            type="file"
                                            accept="image/*"
                                            name="secondImg"
                                            id={item.id}
                                            onChange={onSelectFile}
                                          />
                                          <span className="dk-trick-click-userImgCon">
                                            <figure id="edit_image">
                                              {secondImg ? (
                                                <img
                                                  alt=""
                                                  className={
                                                    `dkg-changIcon changeSecondImageDynamic` +
                                                    item.id
                                                  }
                                                  style={{ width: "35px" }}
                                                  src={
                                                    item.second_image != ""
                                                      ? process.env
                                                          .REACT_APP_IMG_CLOUD_BASE_URL +
                                                        "/" +
                                                        item.second_image
                                                      : ""
                                                  }
                                                />
                                              ) : (
                                                <img
                                                  alt=""
                                                  className={
                                                    `dkg-changIcon changeSecondImageDynamic` +
                                                    item.id
                                                  }
                                                  src={secondImgbase64}
                                                  style={{ width: "35px" }}
                                                />
                                              )}
                                            </figure>
                                          </span>
                                          <span className="dk-trick-click-editicon">
                                            <FaEdit />
                                          </span>
                                        </div>
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      aria-label="nameofcompany"
                                      className="dkg-cand-frmcontrol"
                                      defaultValue={item.descriptions}
                                      name={`description_` + item.id}
                                    />
                                    <div className="dkg-cn-benefits-btnCon">
                                      <SubmitButton
                                        txt="SAVE"
                                        onClick={(e) =>
                                          updateBenifitsHandle(item.id)
                                        }
                                        className="dkg-cand-submit-btn"
                                      />
                                    </div>
                                  </InputGroup>
                                </div>
                              ))
                            : ""}

                          {/* <div className="dkg-cand-form-group form-group">
                            <InputGroup className="dkg-cn-benefits-inputgrp">
                              <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                <InputGroup.Text className="dkg-inputgrp-text">
                                  <FormControl
                                    placeholder="Registration At"
                                    aria-label="nameofcompany"
                                    className="dkg-inputgrp-frmcontrol"
                                    defaultValue=""
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="Dublin, Ireland"
                                aria-label="head-office"
                                className="dkg-cand-frmcontrol"
                                defaultValue=""
                                // onChange={(e) =>
                                //   updateHandle(
                                //     2,
                                //     e.target.value,
                                //     "registrationAt"
                                //   )
                                // }
                              />
                            </InputGroup>
                          </div>
                          <div className="dkg-cand-form-group form-group">
                            <InputGroup className="dkg-cn-benefits-inputgrp">
                              <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                <InputGroup.Text className="dkg-inputgrp-text">
                                  <FormControl
                                    placeholder="Registration No."
                                    aria-label="nameofcompany"
                                    className="dkg-inputgrp-frmcontrol"
                                    defaultValue=""
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="590583"
                                aria-label="registration"
                                className="dkg-cand-frmcontrol"
                                defaultValue=""
                                // onChange={(e) =>
                                //   updateHandle(
                                //     2,
                                //     e.target.value,
                                //     "registrationNo"
                                //   )
                                // }
                              />
                            </InputGroup>
                          </div>
                          <div className="dkg-cand-form-group form-group">
                            <InputGroup className="dkg-cn-benefits-inputgrp">
                              <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                <InputGroup.Text className="dkg-inputgrp-text">
                                  <FormControl
                                    placeholder="Company Vat No."
                                    aria-label="nameofcompany"
                                    className="dkg-inputgrp-frmcontrol"
                                    defaultValue=""
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="IE 3438468BH"
                                aria-label="vat-number"
                                className="dkg-cand-frmcontrol"
                                defaultValue=""
                                // onChange={(e) =>
                                //   updateHandle(2, e.target.value, "vatNo")
                                // }
                              />
                            </InputGroup>
                          </div>
                          <div className="dkg-cand-form-group form-group">
                            <InputGroup className="dkg-cn-benefits-inputgrp">
                              <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                <InputGroup.Text className="dkg-inputgrp-text">
                                  <FormControl
                                    placeholder="Head Office"
                                    aria-label="nameofcompany"
                                    className="dkg-inputgrp-frmcontrol"
                                    defaultValue=""
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="Dublin, Ireland"
                                aria-label="registered"
                                className="dkg-cand-frmcontrol"
                                defaultValue=""
                                // onChange={(e) =>
                                //   updateHandle(2, e.target.value, "headOffice")
                                // }
                              />
                            </InputGroup>
                          </div>
                          <div className="dkg-cand-form-group form-group">
                            <InputGroup className="dkg-cn-benefits-inputgrp">
                              <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                <InputGroup.Text className="dkg-inputgrp-text">
                                  <FormControl
                                    placeholder="Work Industry"
                                    aria-label="nameofcompany"
                                    className="dkg-inputgrp-frmcontrol"
                                    defaultValue=""
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="Professionals Services"
                                aria-label="industry"
                                className="dkg-cand-frmcontrol"
                                defaultValue=""
                                // onChange={(e) =>
                                //   updateHandle(2, e.target.value, "industry")
                                // }
                              />
                            </InputGroup>
                          </div>
                          <div className="dkg-cand-form-group form-group">
                            <InputGroup className="dkg-cn-benefits-inputgrp">
                              <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                <InputGroup.Text className="dkg-inputgrp-text">
                                  <FormControl
                                    placeholder=" Business Type"
                                    aria-label="nameofcompany"
                                    className="dkg-inputgrp-frmcontrol"
                                    defaultValue=""
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="Recruitment Services"
                                aria-label="business-type"
                                className="dkg-cand-frmcontrol"
                                defaultValue=""
                                // onChange={(e) =>
                                //   updateHandle(2, e.target.value, "businessType")
                                // }
                              />
                            </InputGroup>
                          </div>
                          <div className="dkg-cand-form-group form-group">
                            <InputGroup className="dkg-cn-benefits-inputgrp">
                              <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                <InputGroup.Text className="dkg-inputgrp-text">
                                  <FormControl
                                    placeholder=" Recruitment In"
                                    aria-label="nameofcompany"
                                    className="dkg-inputgrp-frmcontrol"
                                    defaultValue=""
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="Multilingual Recruitment"
                                aria-label="specialized-in"
                                className="dkg-cand-frmcontrol"
                                defaultValue=""
                                // onChange={(e) =>
                                //   updateHandle(2, e.target.value, "recruitmentIn")
                                // }
                              />
                            </InputGroup>
                          </div>
                          <div className="dkg-cand-form-group form-group">
                            <InputGroup className="dkg-cn-benefits-inputgrp">
                              <InputGroup.Prepend className="dkg-inputgrp-prepared">
                                <InputGroup.Text className="dkg-inputgrp-text">
                                  <FormControl
                                    placeholder="Other Services"
                                    aria-label="other-service"
                                    className="dkg-inputgrp-frmcontrol"
                                    defaultValue=""
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="Globally,Mainly Europe"
                                aria-label="services-area"
                                className="dkg-cand-frmcontrol"
                                defaultValue=""
                                // onChange={(e) =>
                                //   updateHandle(2, e.target.value, "otherServices")
                                // }
                              />
                            </InputGroup>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-paidservices"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-candidate-mainCon">
                      <div className="dkg-company-list-mainCon">
                        <div className="dkg-company-list-tableCon table-responsive">
                          <table className="dkg-company-list-table table table-borderded">
                            <thead>
                              <tr>
                                <th>Icon</th>
                                <th>Status</th>
                                <th>Category</th>
                                <th>Type</th>
                                <th>Services</th>
                                <th style={{ width: "90px" }}>Price (€)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {serviceData.map((item, index) => (
                                <tr key={index + 1}>
                                  <td>
                                    <div className="dkg-benefit-iconCon">
                                      <input
                                        type="file"
                                        accept="image/*"
                                        name="thirdImg"
                                        id={item.id}
                                        onChange={onSelectFile}
                                      />
                                      <span className="dk-myprofile-userImgCon">
                                        <figure id="edit_image">
                                          {thirdImg ? (
                                            <img
                                              alt=""
                                              className={
                                                `dkg-changIcon changeThirdImageDynamic` +
                                                item.id
                                              }
                                              style={{ width: "35px" }}
                                              src={
                                                item.image != ""
                                                  ? process.env
                                                      .REACT_APP_IMG_CLOUD_BASE_URL +
                                                    "/" +
                                                    item.image
                                                  : ""
                                              }
                                            />
                                          ) : (
                                            <img
                                              alt=""
                                              className={
                                                `dkg-changIcon changeThirdImageDynamic` +
                                                item.id
                                              }
                                              src={thirdImgbase64}
                                              style={{ width: "35px" }}
                                            />
                                          )}
                                        </figure>
                                      </span>
                                      <span className="dk-myprofile-editicon">
                                        <FaEdit />
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="dkg-clentlinked-switch">
                                      <Switch
                                        handleToggle={handleToggle}
                                        id={item.id}
                                        value={item.status}
                                        isChecked={item.status}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue={item.categoryName}
                                      onChange={(e) =>
                                        updateHandle(
                                          item.id,
                                          e.target.value,
                                          "categoryName"
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue={item.type}
                                      onChange={(e) =>
                                        updateHandle(
                                          item.id,
                                          e.target.value,
                                          "type"
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue={item.service_name}
                                      onChange={(e) =>
                                        updateHandle(
                                          item.id,
                                          e.target.value,
                                          "service_name"
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control text-center"
                                      defaultValue={item.price}
                                      onChange={(e) =>
                                        updateHandle(
                                          item.id,
                                          e.target.value,
                                          "price"
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default Index;
