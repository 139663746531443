import React from 'react';
import "./App.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { Switch, Route } from "react-router-dom";
import LoginRoute from "./components/hocs/LoginRoute";
import PrivateRoute from "./components/hocs/PrivateRoute";
import CheckLogin from "./components/hocs/checkLogin";
//Start Dashboard Page
import DashboardSuMM from './components/pages/dashboard/vistesite/Dashboard1';
import MainDashSuMM from './components/pages/mainmenu/dashboard/VisitModule'
import DashPrincipales from './components/pages/dkgcrm/mainmenu/dashboard/Principales';
import DashTeams from './components/pages/dkgcrm/mainmenu/dashboard/teams/Teams'
import DashGetStarted from './components/pages/dkgcrm/mainmenu/dashboard/GetStarted';
import DashCheckin from './components/pages/dkgcrm/mainmenu/dashboard/Checkin'
import DashMyMoods from './components/pages/dkgcrm/mainmenu/dashboard/MyMoods'
import DashCheckList from './components/pages/dkgcrm/mainmenu/dashboard/CheckList'
import DashSolutions from './components/pages/dkgcrm/mainmenu/dashboard/solutions/Overview';
import DashMyProfile from './components/pages/dkgcrm/mainmenu/dashboard/MyProfile';
import DashTodayWork from './components/pages/dkgcrm/mainmenu/dashboard/TodayWork';
import DashReports from './components/pages/dkgcrm/mainmenu/dashboard/Reports';

import DashboardMailbox from './components/pages/dashboard/Mailbox';
import DashboardTasks from './components/pages/dashboard/Tasks';
import DashboardActions from './components/pages/dashboard/Actions';
import DashboardNotification from './components/pages/dashboard/Notification';
import DashboardWorkplan from './components/pages/dashboard/Workplan';
import DashboardClosure from './components/pages/dashboard/Closure';
import DashboardDkGlobal from './components/pages/dashboard/DkGlobal';
// End Dashboard Page
// Start Business Overview Page
import MainBusinessModules from './components/pages/dkgcrm/businessoverview/BusinessModules';
//End Business Overview Page
// Start Client Info  Page
import MainClientsModule from './components/pages/dkgcrm/clients/ClientsModule';
import ClientinfoOverview from './components/pages/dkgcrm/client-info/demands/Overview'
import ClientinfoList from './components/pages/dkgcrm/client-info/clientlist/Overview'
// import ClientinfoCompany from './components/pages/dkgcrm/client-info/companies/Overview'
import ClientinfoCompany from './components/pages/dkgcrm/client-info/companies-new/Overview'


import ClientinfoLanguage from './components/pages/dkgcrm/client-info/language/Overview'
import ClientinfoProjects from './components/pages/dkgcrm/client-info/projects/Overview'
import ClientinfoRoletype from './components/pages/dkgcrm/client-info/roletype/Overview'
import ClientinfoRequirtments from './components/pages/dkgcrm/client-info/requirtments/Overview'
import ClientinfoMainFaq from './components/pages/dkgcrm/client-info/faq/MainFaq'
// import ClientinfoSalaries from './components/pages/dkgcrm/client-info/salaries/Overview'
import ClientinfoSalaries from './components/pages/dkgcrm/training/comman/process/Overview'
import ClientinfoBenefits from './components/pages/dkgcrm/client-info/benefits/Overview'
import ClientinfoIndustry from './components/pages/dkgcrm/client-info/industry/Overview'
import ClientinfoCategory from './components/pages/dkgcrm/client-info/category/Overview'

import ClientinfoPortals from './components/pages/dkgcrm/client-info/portals/Overview'
import ClientRelocation from './components/pages/dkgcrm/client-info/relocations/Overview'
import ClientinfoSendouts from './components/pages/dkgcrm/client-info/sendouts/Overview'
import ClientinfoInterviews from './components/pages/dkgcrm/client-info/interviews/Overview'
import ClientinfoFeedbacks from './components/pages/dkgcrm/client-info/feedbacks/Overview'


// End Cient Info Page
// Start Recuiertment Page
import MainRecruitmentModule from './components/pages/dkgcrm/recruitment/RecruitmentModule';
// End Recuiertment Page
// Start Advertisements Page
import MainAdvertisementsModule from './components/pages/dkgcrm/advertisements/AdvertisementsModule';
// End Advertiseemnts Page
// Start Suppliers Page
import MainSuppliersModule from './components/pages/dkgcrm/suppliers/SuppliersModule';
// End Suppliers Page
// Start Accounting Page
import MainAccountingModule from './components/pages/dkgcrm/accounts/AccountsModule';
// End Accounting Page
// Start HR Page
import MainHrModule from './components/pages/dkgcrm/hr/HrModule';
// End HR Page
// Start Training Page
import MainTrainingModule from './components/pages/dkgcrm/training/TrainingModule';
import CommanTrainigDash from './components/pages/dkgcrm/training/comman/CommanTrainig'
import CommanTrainigCRM from './components/pages/dkgcrm/training/comman/dkgcrm/Overview'
import CommanTrainigProcess from './components/pages/dkgcrm/training/comman/process/Overview'

// End Training Page
// Start IT Teams Page
import MainItTeamModule from './components/pages/dkgcrm/itteam/ItTeamModule'
// End IT Teams Page
// Start Mailbox Page
import MailboxInbox from './components/pages/mainmenu/mailbox';
// End Mailbox Page
// Start Client Info Page
import ClientinfoSuMM from './components/pages/mainmenu/clientinfo';
// End Client Info Page
// Start  Jobs Page
import JobsSummary from './components/pages/mainmenu/jobs/summary';
import ApplyJob from "./components/pages/mainmenu/jobs/applyjob/ApplyJob";
//import JobsFaq from './components/pages/mainmenu/jobs/faq/category/CategoryTable'
import JobsFaq from './components/pages/mainmenu/jobs/faq/JobsFaq'
import Job2Faq from './components/pages/mainmenu/jobs/jobsfaq/JobsFaq'
// End jobs Page
// Start Jobspec
import JobspecSuMM from './components/pages/mainmenu/jobspec/summary';
import Jobspecinstruction from './components/pages/mainmenu/jobspec/instructions/JobspecInstruction'
// Start Jobspec  Page
// Start Intspec Page
import IntspecSuMM from './components/pages/mainmenu/intspec/summary';
// End Intspec Page
// Start  Accounts Summary
import AccountsSummary from './components/pages/mainmenu/candidate/summary';
import CandActiveAccount from './components/pages/mainmenu/candidate/active/ActiveAccount';
import virtualizeActiveAccount from './components/pages/mainmenu/candidate/active/VirtualComp';
// import newvirtualizeActiveAccount from './components/pages/mainmenu/candidate/active/NewTblVirtualize';
import DeletevitualAccount from './components/pages/mainmenu/candidate/active/DeleteTblVirtualize';
// End Accounts Summary
//Start Candidate Summary
import CandidatesSummary from './components/pages/mainmenu/candidates/summary';
import CandidatesDashboard from './components/pages/mainmenu/candidates/dashboard'
import CandidatesPromotions from './components/pages/mainmenu/candidates/promotuions'
import CandidatesAboutus from './components/pages/mainmenu/candidates/about-us/AboutUs';
import CandidatesComplince from './components/pages/mainmenu/candidates/compliances';
import CandidatesFaq from './components/pages/mainmenu/jobs/candifaq/CandidateFaq';
import Candidatesdkservice from './components/pages/mainmenu/candidates/dkservices/Service';
import CandidatesDesiredJob from './components/pages/mainmenu/candidates/myprofile/MyProfile';
import CandidateCreateCV from './components/pages/mainmenu/candidates/createcv/CreateCV'; 
import CandidateMyProfile from './components/pages/mainmenu/candidates/cn-account/my-profile/MyProfile'
import CandidateMailboxTab from './components/pages/mainmenu/candidates/cn-account/mailbox/MailboxTab'
import CandidateJpbsTab from './components/pages/mainmenu/candidates/cn-account/jobs/JobsTab'


//End Candidate Summary 
// Start  Legal Components
import LegalComp from './components/pages/mainmenu/legal'
import TechnicalComp from './components/pages/mainmenu/technical' 
import PaymentsComp from './components/pages/mainmenu/payments'
import LoginformComp from './components/pages/mainmenu/jobs/loginfaq/LoginFaq'

// End  Legal Components
// Start Site User Components
import SiteUserSummary from './components/pages/mainmenu/siteusers/summary'
import SiteUserRecruiters  from './components/pages/settings/usermanagement/UsersManagement'
import SiteUserManagements   from './components/pages/mainmenu/siteusers/managements'
import SiteUserParteners from './components/pages/mainmenu/siteusers/parteners'
import SiteUserClients  from './components/pages/mainmenu/siteusers/clients'
import SiteUserSuppliers  from './components/pages/mainmenu/siteusers/suppliers'
import SiteUserJobseeker from './components/pages/mainmenu/candidate/active/NewTblVirtualize'


// End Site User Components

// Start Page Type Components 
import PageTypeVideo from './components/pages/mainmenu/pagetype/video/VideoAlbum';
import PageTypeAlbum from './components/pages/mainmenu/pagetype/album/PagetypeAlbum';
import PageTypesocailmedia from './components/pages/mainmenu/pagetype/socail/SocailMedia'
import PageTypeAboutUs from './components/pages/mainmenu/pagetype/about-us/AboutUs'
import PageTypeCompanyServices from './components/pages/mainmenu/pagetype/services/CompanyServices'

import PageCompanyinfo from './components/pages/mainmenu/pagetype/company/CompanyInfo';

//End Page Type Components
//Start Interviews Tab
import InterviewsSummary from './components/pages/mainmenu/interviews/summary';

//End Interviews Tab
// Start KPI Page
import KPISummary from './components/pages/mainmenu/kpi/summary';
// End KPI Page
// Start Task Page
import TaskSummary from './components/pages/mainmenu/tasks/summary';
// End Task Page
// Start Notificatons Page
import NotificationSummary from './components/pages/mainmenu/notifications/summary';
// End Notifications Page
// End Jobspec Summaryimport JobspecSuMM from './components/pages/mainmenu/jobspec/summary';
// End Jobspec Page
// Admin Start
import BkValues from "./components/pages/tools/bkvalues/BkValues";
import SettingLegal from './components/pages/settings/legal'
// import UsersManagement from "./components/pages/settings/usermanagement/UsersManagement";
import AdminSummary from "./components/pages/training/summary/AdminSummary";
import AdminOnboarding from "./components/pages/training/onboarding/AdminOnboarding";
import AdminGetStarted from "./components/pages/training/getstarted/AdminGetStarted";
import AdminTools from "./components/pages/training/tools/AdminTools";
import AdminMasterbox from "./components/pages/training/masterbox/AdminMasterbox";
import AdminCommon from "./components/pages/training/common/AdminCommon";
import Admin from "./components/pages/training/admins/Admins";
import AdminRecruiters from "./components/pages/training/recruiters/AdminRecruiters";
import AdminManagers from "./components/pages/training/managers/AdminManagers";
import AccountSummary from "./components/pages/accounts/AccountSummary";
import AccountProfitLoss from "./components/pages/accounts/AccountProfitLoss";
import AccountIncomes from "./components/pages/accounts/AccountIncomes";
import AccountExpenses from "./components/pages/accounts/AccountExpenses";
import AccountCashflows from "./components/pages/accounts/AccountCashflows";
// End
import forgetPassword from "./components/pages/forgetPassword";
import resetPassword from "./components/pages/forgetPassword/resetPassword";

import adminLogin from './components/pages/login';

function App() {
  return (
    <React.Fragment>
      <div className="App">
        <Switch>
          <LoginRoute exact path="/" component={adminLogin} />
          {/* Start Business Overview Page */}
          <PrivateRoute path="/dashboard/businesss-overview" component={MainBusinessModules} />
          {/* End Business Overview Page */}
          {/* Start CLients Page MainClientsModule  */}
          <PrivateRoute path="/dashboard/clients" component={MainClientsModule} />
          <PrivateRoute path="/client-info/demands" component={ClientinfoOverview} />
          <PrivateRoute path="/client-info/client-list" component={ClientinfoList} />
          <PrivateRoute path="/job-specs/requirements" component={ClientinfoRequirtments} />
          <PrivateRoute path="/job-specs/faq" component={ClientinfoMainFaq} />
          <PrivateRoute path="/job-specs/salaries" component={ClientinfoSalaries} />
          <PrivateRoute path="/client-info/industry" component={ClientinfoIndustry} />
          <PrivateRoute path="/client-info/category" component={ClientinfoCategory} />
          <PrivateRoute path="/job-specs/projects" component={ClientinfoProjects} />
          <PrivateRoute path="/job-specs/role-types" component={ClientinfoRoletype} />
          <PrivateRoute path="/job-specs/languages" component={ClientinfoLanguage} />
          <PrivateRoute path="/job-specs/company" component={ClientinfoCompany} />
          <PrivateRoute path="/job-specs/benefits" component={ClientinfoBenefits} />
          <PrivateRoute path="/job-specs/relocation" component={ClientRelocation} />
          <PrivateRoute path="/client-info/portals" component={ClientinfoPortals} />
          <PrivateRoute path="/client-info/sendouts" component={ClientinfoSendouts} />
          <PrivateRoute path="/client-info/interviews" component={ClientinfoInterviews} />
          <PrivateRoute path="/client-info/feedbacks" component={ClientinfoFeedbacks} />
          {/* End Clients Page */}
          {/* Start Recuiterment Page  */}
          <PrivateRoute path="/dashboard/recruitment" component={MainRecruitmentModule} />
          {/* End Recuiterment Page */}
          {/* Start Advertisements Page  */}
          <PrivateRoute path="/dashboard/advertisements" component={MainAdvertisementsModule} />
          {/* End Advertisements Page */}
          {/* Start Suppliers Page  */}
          <PrivateRoute path="/dashboard/suppliers" component={MainSuppliersModule} />
          {/* End Suppliers Page */}
          {/* Start Accounting Module  */}
          <PrivateRoute path="/dashboard/accounting" component={MainAccountingModule} />
          {/* End Accounting Module */}
          {/* Start HR Module  */}
          <PrivateRoute path="/dashboard/hr" component={MainHrModule} />
          {/* End HR Module */}
          {/* Start Training Module   */}
          <PrivateRoute path="/training/commoan-training/dashboard" component={CommanTrainigDash} />
          <PrivateRoute path="/training/commoan-training/dkg-overivew" component={CommanTrainigCRM} />
          <PrivateRoute path="/training/commoan-training/process" component={CommanTrainigProcess} />


          <PrivateRoute path="/dashboard/training" component={MainTrainingModule} />

          {/* End Training Module  */}
          {/* Start IT Teams Module  */}
          <PrivateRoute path="/dashboard/it-teams" component={MainItTeamModule} />

          {/* Start IT Teams Module */}
          {/* Start Dashboard Page */}
          <PrivateRoute path="/dashboard/summary" component={MainDashSuMM} />
          <PrivateRoute path="/dashboard/principles" component={DashPrincipales} />
          <PrivateRoute path="/dashboard/teams" component={DashTeams} />
          <PrivateRoute path="/dashboard/get-start" component={DashGetStarted} />
          <PrivateRoute path="/dashboard/check-in" component={DashCheckin} />
          <PrivateRoute path="/dashboard/my-moods" component={DashMyMoods} />
          <PrivateRoute path="/dashboard/checklist" component={DashCheckList} />
          <PrivateRoute path="/dashboard/job-profiles" component={DashSolutions} />
          <PrivateRoute path="/dashboard/my-profiles" component={DashMyProfile} />
          <PrivateRoute path="/dashboard/today-work" component={DashTodayWork} />
          <PrivateRoute path="/dashboard/reports" component={DashReports} />

          <PrivateRoute path="/dashboard/mailbox" component={DashboardMailbox} />
          <PrivateRoute path="/dashboard/tasks" component={DashboardTasks} />
          <PrivateRoute path="/dashboard/actions" component={DashboardActions} />
          <PrivateRoute path="/dashboard/notification" component={DashboardNotification} />
          <PrivateRoute path="/dashboard/workplan" component={DashboardWorkplan} />
          <PrivateRoute path="/dashboard/closure" component={DashboardClosure} />
          <PrivateRoute path="/dashboard/dk-global" component={DashboardDkGlobal} />
          <PrivateRoute path="/dashboard" component={DashboardSuMM} />
          {/* End Dashboard Page */}
          {/* Start Mailbox Page */}
          <PrivateRoute path="/mailbox/inbox" component={MailboxInbox} />
          {/* End Mailbox Page */}
          {/* Start Client Info Page */}
          <PrivateRoute path="/client-info/summary" component={ClientinfoSuMM} />
          {/* End Client Info Page */}
          {/* Start Jobs Page */}
          <PrivateRoute path="/jobs/summary" component={JobsSummary} />
          <PrivateRoute path="/jobs/apply-job" component={ApplyJob} />
          <PrivateRoute path="/job/faq" component={JobsFaq} />
          <PrivateRoute path="/jobs/faqs" component={Job2Faq} />

          {/* End Jobs Page */}
          {/* Start Jobspec Page */}
          <PrivateRoute path="/job-spec/summary" component={JobspecSuMM} />
          <PrivateRoute path="/job-specs/instructions" component={Jobspecinstruction} />
          {/* End Jobspec Page */}
          {/* Start Intspec Page */}
          <PrivateRoute path="/int-spec/summary" component={IntspecSuMM} />
          {/* End Intspec Page */}
          {/* Start Accounts page */}
          <PrivateRoute path="/accounts/summary" component={AccountsSummary} />
          {/* <PrivateRoute path="/accounts/active-account" component={newvirtualizeActiveAccount} /> */}
          <PrivateRoute path="/accounts/delete-account" component={DeletevitualAccount} />
          <PrivateRoute path="/accounts/virtualize" component={virtualizeActiveAccount} />
          <PrivateRoute path="/accounts/virtualize-1" component={CandActiveAccount} />
          {/* End Accounts page */}
          {/* Start Candidate Page */}
          <PrivateRoute path="/candidate/compliance" component={CandidatesComplince} />
          <PrivateRoute path="/candidate/dk-services" component={Candidatesdkservice} />  
          <PrivateRoute path="/candidate/my-profile" component={CandidateMyProfile} />
          <PrivateRoute path="/candidate/mailbox" component={CandidateMailboxTab} />
          <PrivateRoute path="/candidate/jobs" component={CandidateJpbsTab} />

          <PrivateRoute path="/candidate/desired-job" component={CandidatesDesiredJob} />
          <PrivateRoute path="/candidate/dk-resume" component={CandidateCreateCV} />
          <PrivateRoute path="/candidate/faq" component={CandidatesFaq} />
          <PrivateRoute path="/candidate/about-us" component={CandidatesAboutus} />
          <PrivateRoute path="/candidate/dash-board" component={CandidatesDashboard} />
          <PrivateRoute path="/candidate/promotions" component={CandidatesPromotions} /> 
          <PrivateRoute path="/candidate/summary" component={CandidatesSummary} />

          {/* End  Candidate Page */}  
          {/* Start Legal Pages */}
          <PrivateRoute path="/legal" component={LegalComp} /> 
          <PrivateRoute path="/technical" component={TechnicalComp} />
          <PrivateRoute path="/payments" component={PaymentsComp} />
          <PrivateRoute path="/settings/login-faq" component={LoginformComp} />

          {/* End Legal Pages */}
          {/* Start Site Users Components  */} 
          <PrivateRoute path="/site-users/summary" component={SiteUserSummary} />
          <PrivateRoute path="/site-users/recruiters" component={SiteUserRecruiters} />
          <PrivateRoute path="/site-users/management" component={SiteUserManagements} />
          <PrivateRoute path="/site-users/partners" component={SiteUserParteners} />
          <PrivateRoute path="/site-users/clients" component={SiteUserClients} />
          <PrivateRoute path="/site-users/suppliers" component={SiteUserSuppliers} />
          <PrivateRoute path="/site-users/jobseekers" component={SiteUserJobseeker} />


          {/* Start Site Users Components */} 

          {/* Start Page Type Components */} 
          <PrivateRoute path="/page-type/video" component={PageTypeVideo} /> 
          <PrivateRoute path="/page-type/album" component={PageTypeAlbum} />
          <PrivateRoute path="/page-type/company-info" component={PageCompanyinfo} /> 
          <PrivateRoute path="/page-type/social-media" component={PageTypesocailmedia} />
          <PrivateRoute path="/page-type/about-us" component={PageTypeAboutUs} />
          <PrivateRoute path="/page-type/services" component={PageTypeCompanyServices} />


          {/* End Page Type  Components*/}
          {/* Start Interviews Page */}
          <PrivateRoute path="/interviews/summary" component={InterviewsSummary} />
          {/* End Interviews Page */}
          {/* Start KPI Page */}
          <PrivateRoute path="/kpis/summary" component={KPISummary} />
          {/* End KPI Page */}
          {/* Start Tasks Page */}
          <PrivateRoute path="/tasks/summary" component={TaskSummary} />
          {/* End Tasks Page */}
          {/* Start Notifications Page */}
          <PrivateRoute path="/notifications/summary" component={NotificationSummary} />
          {/* End Notifications Page */}
          {/* Admin Pages Start */}
          {/* <PrivateRoute path="/dkg-users" component={UsersManagement}   /> */}
          <PrivateRoute path="/settings/bkvalues" component={BkValues} />
          <PrivateRoute path="/settings/legal" component={SettingLegal} />
          <PrivateRoute path="/settings/deleted" component={DeletevitualAccount} />
          <PrivateRoute path="/admin/training/summary" component={AdminSummary} />
          <PrivateRoute path="/admin/training/onboarding" component={AdminOnboarding} />
          <PrivateRoute path="/admin/training/get-started" component={AdminGetStarted} />
          <PrivateRoute path="/admin/training/training-tools" component={AdminTools} />
          <PrivateRoute path="/admin/training/masterbox" component={AdminMasterbox} />
          <PrivateRoute path="/admin/training/common" component={AdminCommon} />
          <PrivateRoute path="/admin/training/admins" component={Admin} />
          <PrivateRoute path="/admin/training/recruiters" component={AdminRecruiters} />
          <PrivateRoute path="/admin/training/managers" component={AdminManagers} />
          <PrivateRoute path="/admin/accounts/summary" component={AccountSummary} />
          <PrivateRoute path="/admin/accounts/profit-loss" component={AccountProfitLoss} />
          <PrivateRoute path="/admin/accounts/incomes" component={AccountIncomes} />
          <PrivateRoute path="/admin/accounts/expenses" component={AccountExpenses} />
          <PrivateRoute path="/admin/accounts/cashflows" component={AccountCashflows} />
          <LoginRoute exact path="/admin/login" component={adminLogin} />
          {/* Admin Pages End */}
          <Route path="/forget-password/:role" component={forgetPassword} />
          <Route path="/reset-password/:roleType/:email/:accessToken" component={resetPassword} />
          <Route path="/check-auth/:slug" component={CheckLogin} />
        </Switch>
      </div>
    </React.Fragment>
  );
}

export default App;
