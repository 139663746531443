import React,{useState} from 'react'
import AdminHeader from '../../../../elements/header/Header'
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../../elements/submenu/mainmenu/PagetypeSubmenu';
import  './page-typealbum.scss'
import Pagetypealbums from '../../.././../../assets/images/album.jpg'
import UpdateModal from './modal/UpdateModal'

const PagetypeAlbum = () => {
    const [isUpload , setIsUpload]= useState(false);
    
    const showuploadModal = () =>{
        setIsUpload(true);
    }
    const hideuploadModal = () =>{
        setIsUpload(false);
    }
  return (
    <>
        {
            (isUpload) ? <UpdateModal onHide={hideuploadModal} />: null
        }
        <AdminHeader />
        <Navbar />
        <Submenu />
        <div className="dkg-pagetype-album-mainCon">
            <div className="dkg-pagetype-albumCon">
                <div className="dkg-pagetype-mainRow row">
                    <div className="col-lg-4 col-md-4 col-sm-12 dkg-pagetype-album-cols">
                        <div className="dkg-pagetype-album-box ">
                            <figure className='dkg-pagetype-album-figure'>
                                <div className='dkg-pagetype-album-imgCon'>
                                    <img src={Pagetypealbums} className='dkg-pagetype-album-img' alt='album' />
                                </div>
                            </figure>
                            <div className="dkg-pagetype-bottomCon">
                                <button className='btn dkg-upload-mediabtn' onClick={showuploadModal}>Upload</button>
                                <button className='btn dkg-edit-mediabtn'>Edit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 dkg-pagetype-album-cols">
                        <div className="dkg-pagetype-album-box ">
                            <figure className='dkg-pagetype-album-figure'>
                                <div className='dkg-pagetype-album-imgCon'>
                                    <img src={Pagetypealbums} className='dkg-pagetype-album-img' alt='album' />
                                </div>
                            </figure>
                            <div className="dkg-pagetype-bottomCon">
                                <button className='btn dkg-upload-mediabtn' onClick={showuploadModal}>Upload</button>
                                <button className='btn dkg-edit-mediabtn'>Edit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 dkg-pagetype-album-cols">
                        <div className="dkg-pagetype-album-box ">
                            <figure className='dkg-pagetype-album-figure'>
                                <div className='dkg-pagetype-album-imgCon'>
                                    <img src={Pagetypealbums} className='dkg-pagetype-album-img' alt='album' />
                                </div>
                            </figure>
                            <div className="dkg-pagetype-bottomCon">
                                <button className='btn dkg-upload-mediabtn' onClick={showuploadModal}>Upload</button>
                                <button className='btn dkg-edit-mediabtn'>Edit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 dkg-pagetype-album-cols">
                        <div className="dkg-pagetype-album-box ">
                            <figure className='dkg-pagetype-album-figure'>
                                <div className='dkg-pagetype-album-imgCon'>
                                    <img src={Pagetypealbums} className='dkg-pagetype-album-img' alt='album' />
                                </div>
                            </figure>
                            <div className="dkg-pagetype-bottomCon">
                                <button className='btn dkg-upload-mediabtn' onClick={showuploadModal}>Upload</button>
                                <button className='btn dkg-edit-mediabtn'>Edit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 dkg-pagetype-album-cols">
                        <div className="dkg-pagetype-album-box ">
                            <figure className='dkg-pagetype-album-figure'>
                                <div className='dkg-pagetype-album-imgCon'>
                                    <img src={Pagetypealbums} className='dkg-pagetype-album-img' alt='album' />
                                </div>
                            </figure>
                            <div className="dkg-pagetype-bottomCon">
                                <button className='btn dkg-upload-mediabtn' onClick={showuploadModal}>Upload</button>
                                <button className='btn dkg-edit-mediabtn'>Edit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 dkg-pagetype-album-cols">
                        <div className="dkg-pagetype-album-box ">
                            <figure className='dkg-pagetype-album-figure'>
                                <div className='dkg-pagetype-album-imgCon'>
                                    <img src={Pagetypealbums} className='dkg-pagetype-album-img' alt='album' />
                                </div>
                            </figure>
                            <div className="dkg-pagetype-bottomCon">
                                <button className='btn dkg-upload-mediabtn' onClick={showuploadModal}>Upload</button>
                                <button className='btn dkg-edit-mediabtn'>Edit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
  )
}

export default PagetypeAlbum