import React from "react";
import { useTable } from "react-table";
import { FixedSizeList } from "react-window";
import CustomdateFormate from "../../../../../utils/dateFormats/dateFo";
import SwitchTab from "../Switch";
import CasestageDropdwn from "../CasestageDropdwn";
import CommentsPopup from "../modal/CommentsPopup";
import DeleteCheckbox from "../DeleteCheckbox";

const DeleteTableComp = ({ columns, data }) => {
  // Initialize react-table instance
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table
      {...getTableProps()}
      className="virtualized-table table table-bordered dkg-candidate-acc-table mb-0"
    >
      <thead className="thead">
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="tr"
            key={headerGroup.id}
          >
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="th" key={column.id}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="tbody">
        <FixedSizeList
          height={540}
          itemCount={rows.length}
          itemSize={50} // Set the height of each row
          width="150"
          // layout="horizontal"
        >
          {({ index, style }) => {
            const row = rows[index];
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="tr"
                style={{ ...style }}
                key={row.id}
              >
                {row.cells.map((cell, cellIndex) => (
                  <td
                    {...cell.getCellProps()}
                    className={`td ${cell.value}`}
                    key={cell.column.id}
                  >
                    {cell.column.id === "createdAt" && cellIndex === 2 && (
                      // Customize the rendering for the "createdAt" column in the 1st <td>
                      <CustomDatesComponent dates={cell.value} />
                    )}

                    {cell.column.id === "accountStatus" && cellIndex === 4 && (
                      // Customize the rendering for the "accountStatus" column in the 4th <td>
                      <CustomAccountComponent
                        value={cell.value}
                        id={row.id}
                        canid={row.values.id}
                      />
                    )}
                    {cell.column.id === "status" && cellIndex === 2 && (
                      <CaseStageComponent />
                    )}
                    {cell.column.id === "comments" && cellIndex === 5 && (
                      <CaseComments />
                    )}
                    {cell.column.id === "profileImage" && cellIndex === 10}

                    {!(cell.column.id === "id" && cellIndex === 0) &&
                      !(cell.column.id === "year" && cellIndex === 2) &&
                      !(cell.column.id === "createdAt" && cellIndex === 2) &&
                      // !(cell.column.id === "status" && cellIndex === 3) &&
                      // !(cell.column.id === "id" && cellIndex === 0) &&
                      !(
                        cell.column.id === "accountStatus" && cellIndex === 4
                      ) &&
                      !(cell.column.id === "profileImage" && cellIndex === 10) &&
                      cell.render("Cell")}

                    {cell.column.id === "id" && cellIndex === 0 && (
                      <CaseIdCheckboxComponent id={row.id} canid={row.values} />
                    )}
                  </td>
                ))}
              </tr>
            );
          }}
        </FixedSizeList>
      </tbody>
    </table>
  );
};

const CustomDatesComponent = ({ dates }) => {
  return <CustomdateFormate date={dates} format="DD MMM YY" />;
};
const CustomAccountComponent = ({ value, id, canid }) => {
  return <SwitchTab value={value} canid={canid} />;
};

const CaseStageComponent = ({}) => {
  return <CasestageDropdwn />;
};
const CaseComments = ({}) => {
  return <CommentsPopup />;
};
const CaseIdCheckboxComponent = ({ id, canid }) => {
  return (
    <>
      <DeleteCheckbox canid={canid} />
    </>
  );
};

export default DeleteTableComp;
