import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./candidateprescreen.scss";
import { Tab, Nav, Modal } from "react-bootstrap";
//import CategoryTable from "./faq/category/CategoryTable";
import CompanyTable from "./faq/category/CompanyTable";
import ScriptOverview from "./allscript/Overview";
import GuidelinesOverview from './guidelines/Overview'
import ArchivedTable from "./archived/ArchivedTable";
import FirstLevelTable from "./levels/FirstLevelTable";
import SecondLevelTable from "./levels/SecondLevelTable";

const CandidatePrescreen = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  return (
    <>
      <Link to="#" onClick={handleShow}>
        <i className="far fa-user"></i>
      </Link>
      <Modal show={show} onHide={handleClose} className="dk-candprescreenModal">
        <Modal.Header closeButton className="dk-candprescreenModalHeader">
          <Modal.Title>CANDIDATE SCREENING FORM</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dk-candprescreenModalBody">
          <Tab.Container defaultActiveKey="prescreen-form">
            <div className="dkg-canpresrcreen-tab-mainCon">
              <div className="dkg-canpresrcreen-tabCon">
                <Nav
                  variant="pills"
                  className="flex-row dkg-canpresrcreen-tab-navpills"
                >
                  <Nav.Item className="dkg-canpresrcreen-tab-nav-item">
                    <Nav.Link
                      eventKey="prescreen-form"
                      className="dkg-canpresrcreen-tab-navlink"
                    >
                      1st Level
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-canpresrcreen-tab-nav-item">
                    <Nav.Link
                      eventKey="prescreen-save"
                      className="dkg-canpresrcreen-tab-navlink"
                    >
                      2nd Level
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item className="dkg-canpresrcreen-tab-nav-item">
                    <Nav.Link
                      eventKey="dkg-prescreen-archived"
                      className="dkg-canpresrcreen-tab-navlink"
                    >
                      ARCHIVED{" "}
                    </Nav.Link>
                  </Nav.Item> */}
                  {/* <Nav.Item className="dkg-canpresrcreen-tab-nav-item">
                    <Nav.Link
                      eventKey="prescreen-guideline-tab"
                      className="dkg-canpresrcreen-tab-navlink"
                    >
                      GUIDELINES
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item className="dkg-canpresrcreen-tab-nav-item">
                    <Nav.Link
                      eventKey="prescreen-callscript"
                      className="dkg-canpresrcreen-tab-navlink"
                    >
                      TEMPLATES
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item className="dkg-canpresrcreen-tab-nav-item">
                    <Nav.Link
                      eventKey="prescreen-questions"
                      className="dkg-canpresrcreen-tab-navlink"
                    >
                      CANDIDATE QUERY
                    </Nav.Link>
                  </Nav.Item> */}
                  {/* <Nav.Item className="dkg-canpresrcreen-tab-nav-item">
                    <Nav.Link
                      eventKey="prescreen-faq"
                      className="dkg-canpresrcreen-tab-navlink"
                    >
                      Jobs FAQ 
                    </Nav.Link>
                  </Nav.Item> */}
                  {/* <Nav.Item className="dkg-canpresrcreen-tab-nav-item">
                    <Nav.Link
                      eventKey="prescreen-unknow"
                      className="dkg-canpresrcreen-tab-navlink"
                    >
                      
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
                <Tab.Content className="dkg-canpresrcreen-tabcontent">
                  <Tab.Pane
                    eventKey="prescreen-form"
                    className="dkg-canpresrcreen-tabpane"
                    style={{ padding: "15px 0" }}
                  >
                    <FirstLevelTable />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="prescreen-save"
                    className="dkg-canpresrcreen-tabpane"
                    style={{ padding: "15px 0" }}
                  >
                    <SecondLevelTable />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="prescreen-requrements"
                    className="dkg-canpresrcreen-tabpane"
                  >
                    RequirementTab
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-prescreen-archived"
                    className="dkg-canpresrcreen-tabpane"
                  >
                    <ArchivedTable />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="prescreen-guideline-tab"
                    className="dkg-canpresrcreen-tabpane dkg-script-tabpane"
                  >
                    {/* <GuidelinesOverview /> */}
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="prescreen-callscript"
                    className="dkg-canpresrcreen-tabpane dkg-script-tabpane"
                  >
                    <ScriptOverview />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="prescreen-questions"
                    className="dkg-canpresrcreen-tabpane"
                  >
                    {/* <GuidelinesOverview /> */}
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="prescreen-faq"
                    className="dkg-canpresrcreen-tabpane"
                  >
                    {/* <CompanyTable /> */}
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CandidatePrescreen;
