import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const updateData = createAsyncThunk(
    "candidatelist/updatestatus",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL +
                "/candidates/list/pipeline/key/" +
                id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        key,
                        value,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const fetchCandidate = createAsyncThunk(
    "candidatelist/list/details",
    async ({ canId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL +
                "/candidates/profile/trackers/" + canId,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const allCandidate = createAsyncThunk(
    "candidatelist/list",
    async ({ year, status }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL +
                "/candidates/list/pipeline/allyearlycandidate/" + year + '/' + status,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const fetchYear = createAsyncThunk(
    "candidatelist/year/list",
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL +
                "/candidates/list/pipeline/all-year",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const usersSlice = createSlice({
    name: "candidateData",
    initialState: {
        candidateList: [],
        candidateDetails: [],
        yearList: [],
        isSuccess: false,
        isStatus: false,
        isGetcan: false,
        isLoading: false,
        isError: false
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isStatus = false;
            state.isGetcan = false;
            state.isLoading = false;
        },
    },
    extraReducers: {

        [updateData.pending]: (state) => {
            state.isStatus = false;
        },
        [updateData.fulfilled]: (state, { payload }) => {
            state.isStatus = true;
            state.candidateList = payload.result;
        },
        [updateData.rejected]: (state, { payload }) => {
            state.isStatus = false;
        },

        [fetchYear.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
        },
        [fetchYear.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.yearList = payload.result;
        },
        [fetchYear.rejected]: (state, { payload }) => {
            state.isError = true;
            // state.isLoading = false;
            // state.isSuccess = false;
            state.isGetcan = false;
        },
        [allCandidate.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
        },
        [allCandidate.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.candidateList = payload.result;
        },
        [allCandidate.rejected]: (state, { payload }) => {
            state.isError = true;
            // state.isLoading = false;
            // state.isSuccess = false;
            state.isGetcan = false;
        },
        [fetchCandidate.pending]: (state) => {
            state.isGetcan = false;
            state.isError = false;
        },
        [fetchCandidate.fulfilled]: (state, { payload }) => {
            state.isGetcan = true;
            state.candidateDetails = payload.result;
        },
        [fetchCandidate.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isGetcan = false;
        }
    },
});
export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;