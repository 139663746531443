import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import { useSelector } from "react-redux";

const CommanTraining = () => {
    const pathname = window.location.pathname;
    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | " + "DK MasterBox";
    }, []);

    const { allBadge } = useSelector(state => state.tasks);
    return (
        <React.Fragment>
            <div className="dk-navBarwrapper">
                <div className="dk-navBarMain dk-adminNavbar">
                    <Route>
                        <ul>
                            <li>
                                <Link to="/training/commoan-training/dashboard" className={`${pathname.match('/dashboard') ? 'active' : ''}`}>Dashboard </Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="#/mailbox/inbox" className={`${pathname.match('/mailbox') ? 'active' : ''}`}>Business</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="/training/commoan-training/dkg-overivew" className={`${pathname.match('/dkg-overivew') ? 'active' : ''}`}>Recruitment</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="#/training/commoan-training" className={`${pathname.match('/jobs') ? 'active' : ''}`}>Clients</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="#/training/commoan-training" className={`${pathname.match('/job-spec') ? 'active' : ''}`}>Customers</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="#/training/commoan-training" className={`${pathname.match('/int-spec') ? 'active' : ''}`}>Suppliers</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="#/training/commoan-training" className={`${pathname.match('/candidate') ? 'active' : ''}`}>Resources</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="/training/commoan-training/process" className={`${pathname.match('/process') ? 'active' : ''}`}>Process</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="#/training/commoan-training/csf-kpi" className={`${pathname.match('/csf-kpi') ? 'sub-active' : ''}`}>CSF/KPI</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="#/training/commoan-training/work-tools" className={`${pathname.match('/tasks') ? 'sub-active' : ''}`}>Work Tools</Link>
                            </li>

                        </ul>
                    </Route>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CommanTraining;
