import React, { useState, useEffect } from "react";
import AdminHeader from '../../../../elements/header/Header'
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../../elements/submenu/mainmenu/PagetypeSubmenu';
import { useDispatch, useSelector } from "react-redux";
import { showError } from "../../../../utils/messages/messageSlice";
import SubmitButton from "../../../../ui/submitButton";
import { listData, updateData } from "../../../../../slice/cmsSlice";
import MessageBox from "../../../../utils/joditEditor/MessageBox";

const CompanyServices = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList } = useSelector((state) => state.cms);
  const [overviewData, setOverviewData] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(listData({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setOverviewData(getcmsList[0].description);
    }
  }, [isLoading]);

  const SubmitBtn = () => {
    dispatch(
      updateData({
        id: 1,
        key: "description",
        value: overviewData,
      })
    );
  };
  return (
    <>
        <AdminHeader />
        <Navbar />
        <Submenu />
        <div className="dkg-jobs-instrctions-main">
        <div className="dkg-instructions-editorCon">
          <MessageBox
            value={overviewData}
            onChange={(value) => setOverviewData(value)}
          />
          <div className="dkg-submit-btnCon">
            <SubmitButton
              txt="SAVE"
              className="dkg-cand-submit-btn"
              onClick={SubmitBtn}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanyServices;