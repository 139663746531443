import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// function start
export const getTextGetStart = createAsyncThunk(
  "principals/textchange",
  async (thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/principals/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer" + " " + token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateGetstartText = createAsyncThunk(
  "principals/updateTxt",
  async ({ id, key, value }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/principals/update/" + id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer" + " " + token,
          },
          body: JSON.stringify({
            key,
            value,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const usersSlice = createSlice({
  name: "principals",
  initialState: {
    getPrincipalsList: [],
    isSuccess: false,
    isUpdate: false,
    isLoading: false,
    isError: false,
    isDelete: false,
  },
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.isUpdate = false;
      state.isDelete = false;
    },
  },
  extraReducers: {
    [updateGetstartText.pending]: (state) => {
      state.isLoading = true;
      state.isUpdate = false;
      state.isError = false;
    },
    [updateGetstartText.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.isUpdate = true;
      let mainArray = state.getPrincipalsList;
      let finalResult = mainArray.map((r) => {
        if (r.id === payload.result.id) {
          return payload.result;
        } else {
          return r;
        }
      });
      state.getPrincipalsList = finalResult;
    },
    [updateGetstartText.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },
    [getTextGetStart.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [getTextGetStart.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.getPrincipalsList = payload.result;
    },
    [getTextGetStart.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});
export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
