import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminHeader from "../../../../elements/header/Header";
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../elements/submenu/mainmenu/CandAccSubmenu";
import { Nav, Tab } from "react-bootstrap";
// import  './compliance.scss'
import SocialmediaTab from "./social-media/SocialmediaTab";
import SecondSideMenu from "./SecondSideMenu ";
import OthersSideMenu from "./OthersSideMenu";
import SendusSideMenu from "./SendusSideMenu";
import ServicesSideMenu from "./ServicesSideMenu";
// import { getprojectData } from "../../../../../slice/cmsSlice";
// import { showError } from "../../../../utils/messages/messageSlice";
const AboutUs = () => {
  const dispatch = useDispatch();

  return (
    <>
      <AdminHeader />
      <Navbar />
      <Submenu />
      <div className="dkg-contact-mainCon">
        <div className="dkg-contact-main">
          <Tab.Container id="left-tabs-example" defaultActiveKey="dkg-company">
            <div className="row justify-content-center dkg-contact-mainRow">
              <div className="col-md-2 dkg-contact-main-sideCol">
                <Nav
                  variant="pills dkg-aboutus-navpills dkg-paid-services-navpills"
                  className="flex-column"
                >
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-company"
                      className="dkg-aboutus-navlink"
                    >
                      Company
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-services"
                      className="dkg-aboutus-navlink"
                    >
                      Our Services
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-logos"
                      className="dkg-aboutus-navlink"
                    >
                      Social Media
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-social"
                      className="dkg-aboutus-navlink"
                    >
                      Our Projects
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem d-none">
                    <Nav.Link
                      eventKey="dkg-sendus-message"
                      className="dkg-aboutus-navlink"
                    >
                      Send us Message
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-10 col-10 dkg-contact-mainCol">
                <Tab.Content className="dkg-aboutus-tabcontent">
                  <Tab.Pane
                    eventKey="dkg-company"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-candidate-header">
                      <h1 className="dkg-candidate-title">
                        ABOUT DK GLOBAL RECRUITMENT
                      </h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideMenu />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-services"
                    className="dkg-aboutus-tabpane dkg-cn-anus-tabpane"
                  >
                    <div className="dkg-candidate-header">
                      <h1 className="dkg-candidate-title">Our Services</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <ServicesSideMenu />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-logos"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-candidate-header">
                      <h1 className="dkg-candidate-title">Social Media</h1>
                    </div>
                    <div className="dkg-candidate-mainCon dkg-socailmedia-mainCon">
                      <SocialmediaTab />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-social"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-candidate-header">
                      <h1 className="dkg-candidate-title">Our Projects</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <OthersSideMenu />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-sendus-message"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-candidate-header">
                      <h1 className="dkg-candidate-title">Send us Message</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SendusSideMenu />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
