import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, DeleteData, PutData } from "../../../services";
import { SYSTEM_CONSTANTS } from "../../../constants";

/*************Company JOBS START**************** */
export const companyDoc = createAsyncThunk(
    'companyClient/doc-details',
    async ({ clientId }, thunkAPI) => {
        const res = await GetData(true, '/jobs-files-company/document/' + clientId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const reorderFilesRecord = createAsyncThunk(
    'companyClient/files/count',
    async ({ ids }, thunkAPI) => {
        const res = await PostData(true, '/jobs-files-company/reorder', { ids })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const updatenewfile = createAsyncThunk(
    'companyClient/project/update',
    async ({ id, name, fileType, file }, thunkAPI) => {
        const res = await PutData(true, '/jobs-files-company/' + id, { name, fileType, file })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const deleteProjectValue = createAsyncThunk(
    'companyClient/projectlist/delete',
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, '/jobs-files-company/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const addnewfile = createAsyncThunk(
    'companyClient/addfile/',
    async ({ companyId, name, fileType, file }, thunkAPI) => {
        const res = await PostData(true, '/jobs-files-company/', { companyId, name, fileType, file })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const detailsbyid = createAsyncThunk(
    'companyClient/detailsbyid/list',
    async ({ companyId }, thunkAPI) => {
        const res = await GetData(true, '/jobs-files-company/fetch/' + companyId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const getprojectlist = createAsyncThunk(
    'companyClient/getproject/list',
    async ({ companyId }, thunkAPI) => {
        const res = await GetData(true, '/jobs-files-company/' + companyId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const reorderRoleRecord = createAsyncThunk(
    'companyClient/rolereorder/count',
    async ({ ids }, thunkAPI) => {
        const res = await PostData(true, '/jobs-roletype-company/reorder', { ids })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const reorderProjectRecord = createAsyncThunk(
    'companyClient/projectreorder/count',
    async ({ ids }, thunkAPI) => {
        const res = await PostData(true, '/jobs-project-company/reorder', { ids })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const reorderRecord = createAsyncThunk(
    'companyClient/reorder/count',
    async ({ ids }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/reorder', { ids })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)


export const companyTaxNo = createAsyncThunk(
    'companyClient/taxNo/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + SYSTEM_CONSTANTS.JOBS.TaxId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const companyVatNo = createAsyncThunk(
    'companyClient/vatNo/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + SYSTEM_CONSTANTS.JOBS.VatId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const jobsType = createAsyncThunk(
    'companyClient/Type/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + SYSTEM_CONSTANTS.JOBS.TypeId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const jobsIndustry = createAsyncThunk(
    'companyClient/Industry/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + SYSTEM_CONSTANTS.JOBS.IndustryId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const jobsBusiness = createAsyncThunk(
    'companyClient/Business/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + SYSTEM_CONSTANTS.JOBS.BusinessId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const jobsCountry = createAsyncThunk(
    'companyClient/Country/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + SYSTEM_CONSTANTS.JOBS.CountryId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const jobsCity = createAsyncThunk(
    'companyClient/City/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + SYSTEM_CONSTANTS.JOBS.CityId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const addRoleCompany = createAsyncThunk(
    'companyClient/addRolecompany',
    async ({ companyId, name, description }, thunkAPI) => {
        const res = await PostData(true, '/jobs-roletype-company', { companyId, name, description })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const addProjectCompany = createAsyncThunk(
    'companyClient/addProjectcompany',
    async ({ companyId, name, description }, thunkAPI) => {
        const res = await PostData(true, '/jobs-project-company', { companyId, name, description })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const checkCompany = createAsyncThunk(
    'companyClient/checkduplicatecompany',
    async ({ name }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/isduplicate', { name })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const addNewClient = createAsyncThunk(
    'companyClient/company_addclinet',
    async ({ name, type, logo, foundedOn, city, country, industry, business, incomeTax, vatNo, address, collaboration, renewal, phone1, phone2, email1, email2, website1, website2, cvURL }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company', { name, type, logo, foundedOn, city, country, industry, business, collaboration, renewal, incomeTax, vatNo, address, phone1, phone2, email1, email2, website1, website2, cvURL })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const companyListData = createAsyncThunk(
    'company/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/jobs-company')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const statusCountsFun = createAsyncThunk(
    'companyClient/status/count',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/status', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const industryCountsFun = createAsyncThunk(
    'companyClient/industry/count',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/industry', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const feetypeCountsFun = createAsyncThunk(
    'companyClient/feetype/count',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/feetype', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const renewalCountsFun = createAsyncThunk(
    'companyClient/renewal/count',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/renewal', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const contractCountsFun = createAsyncThunk(
    'companyClient/contract/count',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/contract', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const geographyCountsFun = createAsyncThunk(
    'companyClient/geography/count',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/geography', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const businessCountsFun = createAsyncThunk(
    'companyClient/business/count',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/business', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const cityCountsFun = createAsyncThunk(
    'companyClient/city/count',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/city', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const countryCountsFun = createAsyncThunk(
    'companyClient/country/count',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/country', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const companyCountsFun = createAsyncThunk(
    'companyClient/company/count',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/company', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const companyRoleData = createAsyncThunk(
    'company/role/list-data',
    async ({ name }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/roledata/' + name)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const companyProjectData = createAsyncThunk(
    'company/project/list-data',
    async ({ name }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/projectdata/' + name)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const companyData = createAsyncThunk(
    'company/list-data',
    async (thunkAPI) => {
        const res = await GetData(true, '/jobs-company/')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const accountMangerFun = createAsyncThunk(
    'companyClient/accountManger/count',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/account-manager/', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const categoryCountFun = createAsyncThunk(
    'companyClient/category/count',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/category-type/', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const fetchByRoleId = createAsyncThunk(
    'companyClient/singleRolelist',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/jobs-roletype-company/fetch/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const fetchByProjectId = createAsyncThunk(
    'companyClient/singleProjectlist',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/jobs-project-company/fetch/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const fetchCompanyRole = createAsyncThunk(
    'companyClient/roletlist',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/jobs-roletype-company/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const fetchCompanyProject = createAsyncThunk(
    'companyClient/projectlist',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/jobs-project-company/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const clientscomp = createAsyncThunk(
    'companyClient/fetechByIdcompany',
    async ({ id, clientname }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/clientcomp/' + id + '/' + clientname)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const clientCompanyUpdate = createAsyncThunk(
    'companyClient/updateclientsubcategory',
    async ({ companyId, companyName, clientId, clientName, value }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/update-client-comp/', { companyId, companyName, clientId, clientName, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const clientProjectsUpdate = createAsyncThunk(
    'companyClient/clientupprojectsubcategory',
    async ({ projectsId, projectsName, clientId, clientName, value }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/update-project-comp/', { projectsId, projectsName, clientId, clientName, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const clientRoleUpdate = createAsyncThunk(
    'companyClient/upprojectsubcategory',
    async ({ roleTypeId, roleTypeName, clientId, clientName, value }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/update-role-comp/', { roleTypeId, roleTypeName, clientId, clientName, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const clientLangUpdate = createAsyncThunk(
    'companyClient/langsubcategory',
    async ({ languageId, languageName, clientId, clientName, value }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/update-lang-comp/', { languageId, languageName, clientId, clientName, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const clientRelocationUpdate = createAsyncThunk(
    'companyClient/relocationubcategory',
    async ({ relocationId, relocationName, clientId, clientName, value }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/update-relocation-comp/', { relocationId, relocationName, clientId, clientName, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const clientFaqUpdate = createAsyncThunk(
    'companyClient/faqcategory',
    async ({ portalsId, portalsName, clientId, clientName, value }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/update-portals-comp/', { portalsId, portalsName, clientId, clientName, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const clientPortalUpdate = createAsyncThunk(
    'companyClient/portalscategory',
    async ({ portalsId, portalsName, clientId, clientName, value }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/update-portals-comp/', { portalsId, portalsName, clientId, clientName, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const clientFaqsUpdate = createAsyncThunk(
    'companyClient/faqscategory',
    async ({ faqsId, faqsName, clientId, clientName, value }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/update-faqs-comp/', { faqsId, faqsName, clientId, clientName, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const clientJobOfferUpdate = createAsyncThunk(
    'companyClient/jobofferubcategory',
    async ({ joboffersId, joboffersName, clientId, clientName, value }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/update-joboffer-comp/', { joboffersId, joboffersName, clientId, clientName, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const clientInterviewUpdate = createAsyncThunk(
    'companyClient/interviewsubcategory',
    async ({ interviewsId, interviewsName, clientId, clientName, value }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/update-interview-comp/', { interviewsId, interviewsName, clientId, clientName, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const clientSendoutUpdate = createAsyncThunk(
    'companyClient/sendoutsubcategory',
    async ({ sendoutsId, sendoutsName, clientId, clientName, value }, thunkAPI) => {
        const res = await PostData(true, '/jobs-company/update-sendouts-comp/', { sendoutsId, sendoutsName, clientId, clientName, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const fetechById = createAsyncThunk(
    'companyClient/fetechById',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const updateDetails = createAsyncThunk(
    'companyClient/company/update',
    async ({ id, name, logo, foundedOn, city, country, industry, business, type, incomeTax, vatNo, address, collaboration, renewal, phone1, phone2, email1, email2, website1, website2, cvURL }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/' + id, { name, logo, foundedOn, city, country, industry, business, type, incomeTax, vatNo, address, collaboration, renewal, phone1, phone2, email1, email2, website1, website2, cvURL })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


export const deleteRoleDataValue = createAsyncThunk(
    'companyClient/roledata/delete',
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, '/jobs-roletype-company/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const deleteDataValue = createAsyncThunk(
    'companyClient/projectdata/delete',
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, '/jobs-project-company/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const deleteCompany = createAsyncThunk(
    'companyClient/company/delete',
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, '/jobs-company/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateRoleDataValue = createAsyncThunk(
    'companyClient/RoleValueUupdate',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/jobs-roletype-company/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateDataValue = createAsyncThunk(
    'companyClient/ValueUupdate',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/jobs-project-company/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateValue = createAsyncThunk(
    'companyClient/update',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const jobsCompany = createAsyncThunk(
    'jobs/Company/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + SYSTEM_CONSTANTS.JOBS.CompanyId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const fetchLastClientId = createAsyncThunk(
    'companyClient/clientid',
    async (thunkAPI) => {
        const res = await GetData(true, '/jobs-company/fetch-last-client-id/')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

/*************Company JOBS END**************** */

const clientsSlice = createSlice({
    name: 'clients',
    initialState: {
        companyRoleDetails: {},
        companyProjectDetails: {},
        companyProjectList: [],
        companyRoleList: [],
        companyTaxList: [],
        companyVatList: [],
        companyList: [],
        companyProjtList: [],
        companyRolList: [],
        jobsTypeList: [],
        jobsIndustryList: [],
        jobsBusinessList: [],
        jobsCountryList: [],
        jobsCityList: [],
        clientInfo: [],
        statusList: [],
        industryList: [],
        businessList: [],
        geographyList: [],
        contractList: [],
        renewalList: [],
        feetypeList: [],
        cityList: [],
        countryList: [],
        accountManagerList: [],
        categoryList: [],
        clientAllData: [],
        companyDetails: [],
        updateCompanyDetails: [],
        newProjectList: [],
        projectdetails: [],
        jobsCompanyList: [],
        clientsid: '',
        isError: false,
        isClientCompUpdate: false,
        isClientProjectUpdate: false,
        isClientRoleUpdate: false,
        isClientLangUpdate: false,
        isClientSendoutUpdate: false,
        isClientInterviewUpdate: false,
        isClientJobUpdate: false,
        isClientRelUpdate: false,
        isClientPortalUpdate: false,
        isClietFaqUpdate: false,
        isClientFaqUpdate: false,
        message: '',
        isInsert: false,
        isUpdate: false,
        isLoading: false,
        issLoading: false,
        isDelete: false,
        isCompanyDelete: false,
        isReoder: false,
        isProjectReoder: false,
        isfetch: false,
        isDuplicate: false,
        isProfileLoading: false,
        isProjectInsert: false
    },
    reducers: {
        clearState: (state) => {
            state.isProfileLoading = false;
            state.isClientCompUpdate = false;
            state.isClientProjectUpdate = false;
            state.isClientRoleUpdate = false;
            state.isClientLangUpdate = false;
            state.isClientSendoutUpdate = false;
            state.isClientInterviewUpdate = false;
            state.isClientRelUpdate = false;
            state.isClientPortalUpdate = false;
            state.isClietFaqUpdate = false;
            state.isClientFaqUpdate = false;
            state.isClientJobUpdate = false;
            state.isInsert = false;
            state.isProjectInsert = false;
            state.isDuplicate = false;
            state.isCompanyDelete = false;
            state.isLoading = false;
            state.issLoading = false;
            state.isProjectReoder = false;
            state.isReoder = false;
            state.isUpdate = false;
            state.isError = false;
            state.isDelete = false;
            state.isfetch = false;
            state.message = '';
        }
    },
    extraReducers: (builder) => {

        //  builder.addCase(
        //     companyDoc.pending, (state) => {
        //     state.isLoading = true;
        //     });
        // builder.addCase(
        //     companyDoc.fulfilled, (state, { payload }) => {
        //     state.isLoading = false;
        //     });
        // builder.addCase(
        //     companyDoc.rejected, (state, action) => {
        //     state.isLoading = false;
        //     });

        builder.addCase(
            reorderFilesRecord.pending, (state) => {
                state.isLoading = true;
                state.isProjectReoder = false;
            });
        builder.addCase(
            reorderFilesRecord.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isProjectReoder = true;
                state.msg = "Reorder successfully.";
            });
        builder.addCase(
            reorderFilesRecord.rejected, (state, action) => {
                state.isLoading = false;
            });

        builder.addCase(
            updatenewfile.pending, (state) => {
                state.isUpdate = false
            });
        builder.addCase(
            updatenewfile.fulfilled, (state, { payload }) => {
                let mainArray = state.newProjectList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.newProjectList = finalResult;
                //state.newProjectList = payload.result;
                state.message = payload.message
            });
        builder.addCase(
            updatenewfile.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });


        builder.addCase(
            deleteProjectValue.pending, (state) => {
            });
        builder.addCase(
            deleteProjectValue.fulfilled, (state, action) => {
                state.newProjectList = state.newProjectList.filter((item) => item.id !== action.meta.arg.id);
                state.isDelete = true;
            });
        builder.addCase(
            deleteProjectValue.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addnewfile.pending, (state) => {
                state.isLoading = true;
                state.isProjectInsert = false;
            });
        builder.addCase(
            addnewfile.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isProjectInsert = true;
                state.newProjectList = payload.result;
                // state.newProjectList.push(payload.result)
                state.message = payload.message
            });
        builder.addCase(
            addnewfile.rejected, (state, action) => {
                state.isLoading = false;
                state.isProjectInsert = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            detailsbyid.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            detailsbyid.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.projectdetails = payload.result
            });
        builder.addCase(
            detailsbyid.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            getprojectlist.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            getprojectlist.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.newProjectList = payload.result
            });
        builder.addCase(
            getprojectlist.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });


        builder.addCase(
            reorderRoleRecord.pending, (state) => {
                state.isLoading = true;
                state.isProjectReoder = false;
            });
        builder.addCase(
            reorderRoleRecord.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isProjectReoder = true;
                state.msg = "Reorder successfully.";
            });
        builder.addCase(
            reorderRoleRecord.rejected, (state, action) => {
                state.isLoading = false;
            });

        builder.addCase(
            reorderProjectRecord.pending, (state) => {
                state.isLoading = true;
                state.isProjectReoder = false;
            });
        builder.addCase(
            reorderProjectRecord.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isProjectReoder = true;
                state.msg = "Reorder successfully.";
            });
        builder.addCase(
            reorderProjectRecord.rejected, (state, action) => {
                state.isLoading = false;
            });

        builder.addCase(
            reorderRecord.pending, (state) => {
                state.isLoading = true;
                state.isReoder = false;
            });
        builder.addCase(
            reorderRecord.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isReoder = true;
                state.msg = "Reorder successfully.";
            });
        builder.addCase(
            reorderRecord.rejected, (state, action) => {
                state.isLoading = false;
            });


        builder.addCase(
            companyTaxNo.pending, (state) => {
            });
        builder.addCase(
            companyTaxNo.fulfilled, (state, { payload }) => {
                state.companyTaxList = payload.result
            });
        builder.addCase(
            companyTaxNo.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            companyVatNo.pending, (state) => {
            });
        builder.addCase(
            companyVatNo.fulfilled, (state, { payload }) => {
                state.companyVatList = payload.result
            });
        builder.addCase(
            companyVatNo.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            jobsType.pending, (state) => {
            });
        builder.addCase(
            jobsType.fulfilled, (state, { payload }) => {
                state.jobsTypeList = payload.result
            });
        builder.addCase(
            jobsType.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            jobsIndustry.pending, (state) => {
            });
        builder.addCase(
            jobsIndustry.fulfilled, (state, { payload }) => {
                state.jobsIndustryList = payload.result
            });
        builder.addCase(
            jobsIndustry.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            jobsBusiness.pending, (state) => {
            });
        builder.addCase(
            jobsBusiness.fulfilled, (state, { payload }) => {
                state.jobsBusinessList = payload.result
            });
        builder.addCase(
            jobsBusiness.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            jobsCountry.pending, (state) => {
            });
        builder.addCase(
            jobsCountry.fulfilled, (state, { payload }) => {
                state.jobsCountryList = payload.result
            });
        builder.addCase(
            jobsCountry.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            jobsCity.pending, (state) => {
            });
        builder.addCase(
            jobsCity.fulfilled, (state, { payload }) => {
                state.jobsCityList = payload.result
            });
        builder.addCase(
            jobsCity.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            checkCompany.pending, (state) => {
                state.isLoading = true;
                state.isDuplicate = false;
            });
        builder.addCase(
            checkCompany.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isDuplicate = payload.status;
                state.message = payload.message
            });
        builder.addCase(
            checkCompany.rejected, (state, action) => {
                state.isLoading = false;
                state.isDuplicate = false;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addNewClient.pending, (state) => {
                state.isLoading = true;
                state.isInsert = false;
            });
        builder.addCase(
            addNewClient.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isInsert = true;
                // state.companyList = payload.result
                state.companyList.push(payload.result)
                //state.companyList.unshift(payload.result)
                state.message = payload.message
            });
        builder.addCase(
            addNewClient.rejected, (state, action) => {
                state.isLoading = false;
                state.isInsert = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addRoleCompany.pending, (state) => {
                state.isLoading = true;
                state.isInsert = false;
            });
        builder.addCase(
            addRoleCompany.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isInsert = true;
                state.companyRoleList.push(payload.result)
                //state.companyList.unshift(payload.result)
                state.message = payload.message
            });
        builder.addCase(
            addRoleCompany.rejected, (state, action) => {
                state.isLoading = false;
                state.isInsert = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addProjectCompany.pending, (state) => {
                state.isLoading = true;
                state.isInsert = false;
            });
        builder.addCase(
            addProjectCompany.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isInsert = true;
                state.companyProjectList.push(payload.result)
                //state.companyList.unshift(payload.result)
                state.message = payload.message
            });
        builder.addCase(
            addProjectCompany.rejected, (state, action) => {
                state.isLoading = false;
                state.isInsert = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });


        builder.addCase(
            companyListData.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            companyListData.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.companyList = payload.result
            });
        builder.addCase(
            companyListData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            statusCountsFun.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            statusCountsFun.fulfilled, (state, { payload }) => {
                state.isLoading = false;

                state.statusList = payload.result
                state.message = payload.message
            });
        builder.addCase(
            statusCountsFun.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            industryCountsFun.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            industryCountsFun.fulfilled, (state, { payload }) => {
                state.isLoading = false;

                state.industryList = payload.result

                state.message = payload.message
            });
        builder.addCase(
            industryCountsFun.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            businessCountsFun.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            businessCountsFun.fulfilled, (state, { payload }) => {
                state.isLoading = false;

                state.businessList = payload.result
                state.message = payload.message
            });
        builder.addCase(
            businessCountsFun.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            feetypeCountsFun.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            feetypeCountsFun.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.feetypeList = payload.result
                state.message = payload.message
            });
        builder.addCase(
            feetypeCountsFun.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            renewalCountsFun.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            renewalCountsFun.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.renewalList = payload.result
                state.message = payload.message
            });
        builder.addCase(
            renewalCountsFun.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            contractCountsFun.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            contractCountsFun.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.contractList = payload.result
                state.message = payload.message
            });
        builder.addCase(
            contractCountsFun.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            geographyCountsFun.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            geographyCountsFun.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.geographyList = payload.result
                state.message = payload.message
            });
        builder.addCase(
            geographyCountsFun.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            cityCountsFun.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            cityCountsFun.fulfilled, (state, { payload }) => {
                state.isLoading = false;

                state.cityList = payload.result
                state.message = payload.message
            });
        builder.addCase(
            cityCountsFun.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            countryCountsFun.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            countryCountsFun.fulfilled, (state, { payload }) => {
                state.isLoading = false;

                state.countryList = payload.result
                state.message = payload.message
            });
        builder.addCase(
            countryCountsFun.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            companyCountsFun.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            companyCountsFun.fulfilled, (state, { payload }) => {
                state.isLoading = false;

                state.companyList = payload.result
                state.message = payload.message
            });
        builder.addCase(
            companyCountsFun.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            companyRoleData.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            companyRoleData.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.companyRolList = payload.result
            });
        builder.addCase(
            companyRoleData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            companyProjectData.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            companyProjectData.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.companyProjtList = payload.result
            });
        builder.addCase(
            companyProjectData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            companyData.pending, (state) => {
                state.isLoading = true;
                state.issLoading = true;
            });
        builder.addCase(
            companyData.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.issLoading = false;
                state.companyList = payload.result
            });
        builder.addCase(
            companyData.rejected, (state, action) => {
                state.isLoading = false;
                state.issLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            accountMangerFun.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            accountMangerFun.fulfilled, (state, { payload }) => {
                state.accountManagerList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            accountMangerFun.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            categoryCountFun.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            categoryCountFun.fulfilled, (state, { payload }) => {
                state.categoryList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            categoryCountFun.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            deleteRoleDataValue.pending, (state) => {
            });
        builder.addCase(
            deleteRoleDataValue.fulfilled, (state, action) => {
                state.companyRoleList = state.companyRoleList.filter((item) => item.id !== action.meta.arg.id);
                state.isDelete = true;
            });
        builder.addCase(
            deleteRoleDataValue.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            deleteDataValue.pending, (state) => {
            });
        builder.addCase(
            deleteDataValue.fulfilled, (state, action) => {
                state.companyProjectList = state.companyProjectList.filter((item) => item.id !== action.meta.arg.id);
                state.isDelete = true;
            });
        builder.addCase(
            deleteDataValue.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            deleteCompany.pending, (state) => {
            });
        builder.addCase(
            deleteCompany.fulfilled, (state, action) => {
                state.companyList = state.companyList.filter((item) => item.id !== action.meta.arg.id);
                state.isDelete = true;
                state.isCompanyDelete = true;
            });
        builder.addCase(
            deleteCompany.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            fetchByProjectId.pending, (state) => {
                state.isfetch = true;
            });
        builder.addCase(
            fetchByProjectId.fulfilled, (state, { payload }) => {
                state.companyProjectDetails = payload.result
                state.isfetch = false;
            });
        builder.addCase(
            fetchByProjectId.rejected, (state, action) => {
                //state.isLoading = false;
                state.isfetch = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            fetchByRoleId.pending, (state) => {
                state.isfetch = true;
            });
        builder.addCase(
            fetchByRoleId.fulfilled, (state, { payload }) => {
                state.companyRoleDetails = payload.result
                state.isfetch = false;
            });
        builder.addCase(
            fetchByRoleId.rejected, (state, action) => {
                //state.isLoading = false;
                state.isfetch = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            jobsCompany.pending, (state) => {
            });
        builder.addCase(
            jobsCompany.fulfilled, (state, { payload }) => {
                state.jobsCompanyList = payload.result
            });
        builder.addCase(
            jobsCompany.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            fetchCompanyRole.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            fetchCompanyRole.fulfilled, (state, { payload }) => {
                state.companyRoleList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            fetchCompanyRole.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            fetchCompanyProject.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            fetchCompanyProject.fulfilled, (state, { payload }) => {
                state.companyProjectList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            fetchCompanyProject.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            clientscomp.pending, (state) => {
                state.isProfileLoading = false;
            });
        builder.addCase(
            clientscomp.fulfilled, (state, { payload }) => {
                state.isProfileLoading = true;
                state.clientAllData = payload.result
            });
        builder.addCase(
            clientscomp.rejected, (state, action) => {
                state.isProfileLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            clientCompanyUpdate.pending, (state) => {
                state.isClientCompUpdate = true;
            });
        builder.addCase(
            clientCompanyUpdate.fulfilled, (state, { payload }) => {
                state.isClientCompUpdate = false;
                //state.companyDetails = payload.result
            });
        builder.addCase(
            clientCompanyUpdate.rejected, (state, action) => {
                state.isClientCompUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            clientFaqUpdate.pending, (state) => {
                state.isClientFaqUpdate = true;
            });
        builder.addCase(
            clientFaqUpdate.fulfilled, (state, { payload }) => {
                state.isClientFaqUpdate = false;
                //state.companyDetails = payload.result
            });
        builder.addCase(
            clientFaqUpdate.rejected, (state, action) => {
                state.isClientFaqUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            clientPortalUpdate.pending, (state) => {
                state.isClientPortalUpdate = true;
            });
        builder.addCase(
            clientPortalUpdate.fulfilled, (state, { payload }) => {
                state.isClientPortalUpdate = false;
                //state.companyDetails = payload.result
            });
        builder.addCase(
            clientPortalUpdate.rejected, (state, action) => {
                state.isClientPortalUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            clientFaqsUpdate.pending, (state) => {
                state.isClietFaqUpdate = true;
            });
        builder.addCase(
            clientFaqsUpdate.fulfilled, (state, { payload }) => {
                state.isClietFaqUpdate = false;
            });
        builder.addCase(
            clientFaqsUpdate.rejected, (state, action) => {
                state.isClietFaqUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            clientRelocationUpdate.pending, (state) => {
                state.isClientRelUpdate = true;
            });
        builder.addCase(
            clientRelocationUpdate.fulfilled, (state, { payload }) => {
                state.isClientRelUpdate = false;
                //state.companyDetails = payload.result
            });
        builder.addCase(
            clientRelocationUpdate.rejected, (state, action) => {
                state.isClientRelUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            clientJobOfferUpdate.pending, (state) => {
                state.isClientJobUpdate = true;
            });
        builder.addCase(
            clientJobOfferUpdate.fulfilled, (state, { payload }) => {
                state.isClientJobUpdate = false;
                //state.companyDetails = payload.result
            });
        builder.addCase(
            clientJobOfferUpdate.rejected, (state, action) => {
                state.isClientJobUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            clientInterviewUpdate.pending, (state) => {
                state.isClientInterviewUpdate = true;
            });
        builder.addCase(
            clientInterviewUpdate.fulfilled, (state, { payload }) => {
                state.isClientInterviewUpdate = false;
                //state.companyDetails = payload.result
            });
        builder.addCase(
            clientInterviewUpdate.rejected, (state, action) => {
                state.isClientInterviewUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            clientSendoutUpdate.pending, (state) => {
                state.isClientSendoutUpdate = true;
            });
        builder.addCase(
            clientSendoutUpdate.fulfilled, (state, { payload }) => {
                state.isClientSendoutUpdate = false;
                //state.companyDetails = payload.result
            });
        builder.addCase(
            clientSendoutUpdate.rejected, (state, action) => {
                state.isClientSendoutUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            clientLangUpdate.pending, (state) => {
                state.isClientLangUpdate = true;
            });
        builder.addCase(
            clientLangUpdate.fulfilled, (state, { payload }) => {
                state.isClientLangUpdate = false;
                //state.companyDetails = payload.result
            });
        builder.addCase(
            clientLangUpdate.rejected, (state, action) => {
                state.isClientLangUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            clientRoleUpdate.pending, (state) => {
                state.isClientRoleUpdate = true;
            });
        builder.addCase(
            clientRoleUpdate.fulfilled, (state, { payload }) => {
                state.isClientRoleUpdate = false;
                //state.companyDetails = payload.result
            });
        builder.addCase(
            clientRoleUpdate.rejected, (state, action) => {
                state.isClientRoleUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            clientProjectsUpdate.pending, (state) => {
                state.isClientProjectUpdate = true;
            });
        builder.addCase(
            clientProjectsUpdate.fulfilled, (state, { payload }) => {
                state.isClientProjectUpdate = false;
                //state.companyDetails = payload.result
            });
        builder.addCase(
            clientProjectsUpdate.rejected, (state, action) => {
                state.isClientProjectUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            fetechById.pending, (state) => {
                state.isProfileLoading = true;
            });
        builder.addCase(
            fetechById.fulfilled, (state, { payload }) => {
                state.isProfileLoading = false;
                state.companyDetails = payload.result
            });
        builder.addCase(
            fetechById.rejected, (state, action) => {
                state.isProfileLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateDetails.pending, (state) => {
                state.isUpdate = false
            });
        builder.addCase(
            updateDetails.fulfilled, (state, { payload }) => {
                let mainArray = state.companyList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.companyList = finalResult;
                state.message = payload.message
            });
        builder.addCase(
            updateDetails.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateRoleDataValue.pending, (state) => {
                state.isUpdate = false
            });
        builder.addCase(
            updateRoleDataValue.fulfilled, (state, { payload }) => {
                let mainArray = state.companyRoleList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.companyRoleList = finalResult;
                state.message = payload.message
            });

        builder.addCase(
            updateRoleDataValue.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateDataValue.pending, (state) => {
                state.isUpdate = false
            });
        builder.addCase(
            updateDataValue.fulfilled, (state, { payload }) => {
                let mainArray = state.companyProjectList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.companyProjectList = finalResult;
                state.message = payload.message
            });
        builder.addCase(
            updateDataValue.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateValue.pending, (state) => {
                state.isUpdate = false
            });
        builder.addCase(
            updateValue.fulfilled, (state, { payload }) => {
                let mainArray = state.companyList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.companyList = finalResult;
                state.message = payload.message
            });
        builder.addCase(
            updateValue.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            fetchLastClientId.pending, (state) => {
            });
        builder.addCase(
            fetchLastClientId.fulfilled, (state, { payload }) => {
                state.clientsid = payload.result
            });
        builder.addCase(
            fetchLastClientId.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

    }
});

export const { clearState } = clientsSlice.actions;
export default clientsSlice.reducer;