import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import {
  addCategories,
  clearState,
} from "../../../../../../../../slice/faq/candidateServiceFaqSlice";
import SubmitButton from "../../../../../../../ui/submitButton";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../../../utils/messages/messageSlice";

const AddNewCategory = ({ companyId, comName }) => {
  const dispatch = useDispatch();
  const { isSuccess, isError, isCategoryInsert, companyList } = useSelector(
    (state) => state.candidateServiceFaqs
  );
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [categoryName, setCategoryName] = useState("");
  const [sequenceNo, setSequenceNo] = useState("");

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setShow(false);
      setLoading(false);
      setSuccess(false);
    }
    if (isCategoryInsert) {
      dispatch(clearState());
      setCategoryName("");
      setSequenceNo("");
      setShow(false);
      setLoading(false);
      setSuccess(false);
    }
  }, [isError, isCategoryInsert]);

  const addCategory = (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);
    if (!categoryName) {
      dispatch(showError({ msg: "Please enter category name" }));
      setLoading(false);
    } else {
      setLoading(true);
      dispatch(
        addCategories({
          companyId: "0",
          companyName: "jobsFAQ",
          name: categoryName,
        })
      );
    }
  };

  return (
    <>
      <Link to="#" onClick={handleShow} className="dk-faq-addcategory-btn">
        <i className="fa fa-plus mr-1"></i> Add Category
      </Link>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="dkg-faq-addnew-catModal"
      >
        <Modal.Header closeButton className="dk-faq-addcategory-modal-header">
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dk-faq-addcategory-modal-body">
          <div className="form-group mb-0">
            <label>Category Name</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setCategoryName(e.target.value)}
              value={categoryName}
            />
          </div>
          {/* <div className="form-group mb-0">
            <label>Sequence</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setSequenceNo(e.target.value)}
              value={sequenceNo}
            />
          </div> */}
        </Modal.Body>
        <Modal.Footer className="dk-faq-addcategory-modal-footer">
          <Link to="#" onClick={handleClose} className="dkg-close-btn">
            Close
          </Link>
          <SubmitButton
            txt="Create"
            loading={loading}
            success={success}
            onClick={addCategory}
            position="justify-content-center"
            className="dkg-submitbtn"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNewCategory;
