import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './managementdashboard.scss';
import AdminHeader from '../../../elements/header/Header'
import Navbar from "../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../elements/submenu/mainmenu/DashboardSubmenu';
import DKGIconImg from '../../../../assets/images/dk_icon_white.png';
import OpsManagerImg from "../../../../assets/images/prepare-interview.png";
import ResManagerImg from "../../../../assets/images/resourcemanagement_icon.png";
import RecruitmentImg from "../../../../assets/images/building_icon.png";
import WiningTrophyImg from "../../../../assets/images/trophy.png";
import ChecklistImg from "../../../../assets/images/checklist-white.png";
import DocumentsImg from "../../../../assets/images/document-white.png";
import MarketingManagerImg from "../../../../assets/images/get-money.png";
import CompanyImg from "../../../../assets/images/company-white.png";

const BusinessModules = () => {
    return (
        <>
            <AdminHeader />
            <Navbar />
            <Submenu />
            <div className="dk-mngDashboard-main">
                <div className="dk-mngDashboard-container">
                    <div className="title mb-2">BUSINESS OVERVIEW DEPARTMENT</div>
                    <div className="d-flex">
                        <div className="dk-mngModule-box">
                            <Link to="/dashboard/businesss-overview" style={{ background: '#884c5e' }}>
                                <img src={DKGIconImg} alt="" />
                                <p>Business Overview</p>
                            </Link>
                        </div>
                        <div className="dk-mngModule-box">
                            <Link to="#" style={{ background: '#3c97b6' }}>
                                <img src={OpsManagerImg} alt="" />
                                <p>Management</p>
                            </Link>
                        </div>
                        <div className="dk-mngModule-box">
                            <Link to="#" style={{ background: '#3c4c62' }}>
                                <img src={ResManagerImg} alt="" />
                                <p>Employees</p>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="dk-mngModule-box">
                            <Link to="#" style={{ background: '#5C737D' }}>
                                <img src={RecruitmentImg} alt="" />
                                <p>Business Model</p>
                            </Link>
                        </div>
                        <div className="dk-mngModule-box">
                            <Link to="#" style={{ background: '#26596d' }}>
                                <img src={WiningTrophyImg} alt="" />
                                <p>CFI</p>
                            </Link>
                        </div>
                        <div className="dk-mngModule-box">
                            <Link to="#" style={{ background: '#144773' }}>
                                <img src={ChecklistImg} alt="" />
                                <p>Checklist</p>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="dk-mngModule-box">
                            <Link to="#" style={{ background: '#535255' }}>
                                <img src={DocumentsImg} alt="" />
                                <p>Legal Documents</p>
                            </Link>
                        </div>
                        <div className="dk-mngModule-box">
                            <Link to="#" style={{ background: '#009688' }}>
                                <img src={MarketingManagerImg} alt="" />
                                <p>Profit & Loss</p>
                            </Link>
                        </div>
                        <div className="dk-mngModule-box">
                            <Link to="#" style={{ background: '#5A4C72' }}>
                                <img src={CompanyImg} alt="" />
                                <p>DKG Projects</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessModules