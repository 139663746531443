import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getTextGetStart, updateGetstartText} from "../../../../../slice/getStart/getstart"
import { showError } from "../../../../../components/utils/messages/messageSlice";
import AdminHeader from "../../../../elements/header/Header";
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../elements/submenu/mainmenu/DashboardSubmenu";
import "./get-started.scss";

const GetStarted = () => {
  const dispatch = useDispatch();
  const { isLoading, getStartList } = useSelector(
    (state) => state.getStart
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!isLoading && getStartList && getStartList.length > 0) {
      setData(getStartList);
    }
  }, [isLoading]);

   useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getTextGetStart({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  const updateData = (val, id) => {
    dispatch(
      updateGetstartText({
        id: id,
        key: "title",
        value: val,
      })
    );
  };

  return (
    <>
      <AdminHeader />
      <Navbar />
      <Submenu />
      <div className="dkg-getstarted-mainCon container-fluid">
        <div className="row dkg-getstarted-mainRow">
          <div className="col-12 dkg-getstarted-mainCol">
            <div className="dkg-getstarted-tableCon table-responsive">
              <table className="dkg-getstarted-table table table-bordered">
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>TITLE</th>
                    <th>SUBTITLE</th>
                    <th>MAIN TEXT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[0].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[0].title,
                              1
                            )
                          }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[1].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[1].title,
                              2
                            )
                          }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[2].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[2].title,
                              3
                            )
                          }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[3].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[3].title,
                              4
                            )
                          }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[4].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[4].title,
                              5
                            )
                          }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[5].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[5].title,
                              6
                            )
                          }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[6].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[6].title,
                              7
                            )
                          }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[7].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[7].title,
                              8
                            )
                          }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[8].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[8].title,
                              9
                            )
                          }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[9].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[9].title,
                              10
                            )
                          }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[10].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[10].title,
                              11
                            )
                          }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[11].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[11].title,
                              12
                            )
                          }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[12].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[12].title,
                              13
                            )
                          }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[13].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[13].title,
                              14
                            )
                          }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[14].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[14].title,
                              15
                            )
                          }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[15].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[15].title,
                              16
                            )
                          }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[16].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[16].title,
                              17
                            )
                          }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[17].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[17].title,
                              18
                            )
                          }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[18].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[18].title,
                              19
                            )
                          }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[19].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[19].title,
                              20
                            )
                          }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[20].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[20].title,
                              21
                            )
                          }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[21].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[21].title,
                              22
                            )
                          }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[22].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[22].title,
                              23
                            )
                          }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control getstarted-formcontrol"
                        defaultValue={data != "" ? data[23].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[23].title,
                              24
                            )
                          }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetStarted;
