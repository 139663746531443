import React, { useState, useEffect } from "react";
import { Tab, Nav, InputGroup, FormControl } from "react-bootstrap";
import SubmitButton from "../../../../ui/submitButton";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { useDispatch, useSelector } from "react-redux";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { listData, updateData } from "../../../../../slice/cmsSlice";
import {
  showError,
  showSuccess,
} from "../../../../utils/messages/messageSlice";
import {
  updateAboutUsService,
  getAboutService,
  clearState,
} from "../../../../../slice/candidate/frontendCms/frontendCmsSlice";

const ServicesSideMenu = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList } = useSelector((state) => state.cms);
  const { aboutServiceLists, isAboutService, isAboutUpdate } = useSelector(
    (state) => state.cmslist
  );
  const [aboutuService, setAboutService] = useState([]);

  useEffect(() => {
    if (isAboutUpdate) {
      dispatch(clearState({}));
      dispatch(getAboutService({}));
      dispatch(showSuccess({ msg: "Information updated successfully" }));
    }
  }, [isAboutUpdate]);

  // const [loading, setLoading] = useState(false);
  // const [success, setSuccess] = useState(false);
  const [clientData, setClientData] = useState("");
  const [editorCLientState, setEditorClientState] = useState(
    EditorState.createEmpty()
  );
  const [JobseekerData, setJobseekerData] = useState("");
  const [editorJobseekerState, setEditorJobseekerState] = useState(
    EditorState.createEmpty()
  );

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(listData({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!isAboutService && aboutServiceLists && aboutServiceLists.length > 0) {
      setAboutService(aboutServiceLists);
    }
  }, [isAboutService]);

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      //setData(getcmsList);
      setJobseekerData(getcmsList[1].serviceJobseekers);
      setClientData(getcmsList[1].serviceClient);
    }
  }, [isLoading]);

  /*******************/
  let clientsDetails =
    clientData !== undefined && clientData !== null ? clientData : "";
  const html = clientsDetails;
  const contentBlock = htmlToDraft(html);
  const contentState_1 = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  useEffect(() => {
    setEditorClientState(EditorState.createEmpty());
    if (clientsDetails !== "") {
      setEditorClientState(EditorState.createWithContent(contentState_1));
    }
  }, [clientsDetails]);

  const onClientEditorStateChange = async (state) => {
    setEditorClientState(state);
  };
  /*******************/
  /********jobseeker***********/
  let jobseekerDetails =
    JobseekerData !== undefined && JobseekerData !== null ? JobseekerData : "";
  const html_2 = jobseekerDetails;
  const contentBlock_2 = htmlToDraft(html_2);
  const contentState_2 = ContentState.createFromBlockArray(
    contentBlock_2.contentBlocks
  );
  useEffect(() => {
    setEditorJobseekerState(EditorState.createEmpty());
    if (JobseekerData !== "") {
      setEditorJobseekerState(EditorState.createWithContent(contentState_2));
    }
  }, [JobseekerData]);

  const onJobseekerEditorStateChange = async (state) => {
    setEditorJobseekerState(state);
  };
  /*******************/

  const SubmitBtn = (data) => {
    if (data === "client") {
      let currentContentAsHTML = draftToHtml(
        convertToRaw(editorCLientState.getCurrentContent())
      );
      if (currentContentAsHTML == "<p></p>\n") {
        dispatch(updateData({ id: 2, key: "serviceClient", value: "" }));
      } else {
        dispatch(
          updateData({
            id: 2,
            key: "serviceClient",
            value: currentContentAsHTML,
          })
        );
      }
    } else {
      let jobseekerContentAsHTML = draftToHtml(
        convertToRaw(editorJobseekerState.getCurrentContent())
      );
      if (jobseekerContentAsHTML == "<p></p>\n") {
        dispatch(updateData({ id: 2, key: "serviceJobseekers", value: "" }));
      } else {
        dispatch(
          updateData({
            id: 2,
            key: "serviceJobseekers",
            value: jobseekerContentAsHTML,
          })
        );
      }
    }
  };

  const submitServiceForm = (id) => {
    var srNo = getValueByName("srNo_" + id);
    var descriptions = getValueByName("description_" + id);

    dispatch(
      updateAboutUsService({
        id,
        srNo,
        descriptions,
      })
    );
  };

  function getValueByName(name) {
    var elements = document.getElementsByName(name);
    if (elements.length > 0) {
      var value = elements[0].value;
      return value;
    } else {
      return null;
    }
  }

  return (
    <>
      <div className="dkg-cand-services-Side">
        <Tab.Container defaultActiveKey="second">
          <div className="row m-0 dkg-payment-qt-tab-mainCon">
            <div className="col-md-2 pl-0 dkg-payment-qt-tabCon dkg-profile-task-sideMenu pr-0">
              <Nav
                variant="pills"
                className="flex-column dkg-payment-qt-tab-navpills"
              >
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="second"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Clients
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="clients-services"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Clients Services
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="first"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Jobseekers
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="jobseekers-services"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Jobseekers Services
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-md-10  dkg-paidservices-rightCon pl-0 pr-0">
              <Tab.Content className="dkg-paidservices-tabcontent">
                <Tab.Pane eventKey="first" className="dkg-paidservices-tabpane">
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Summary</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <Editor
                      editorState={editorJobseekerState}
                      onEditorStateChange={onJobseekerEditorStateChange}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                      toolbar={{
                        options: [
                          "inline",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "image",
                        ],
                        inline: { inDropdown: false },
                        list: { inDropdown: false },
                        textAlign: { inDropdown: false },
                        link: { inDropdown: false },
                        history: { inDropdown: false },
                      }}
                    />

                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                        onClick={(e) => SubmitBtn("jobseeker")}
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Information</h4>
                  </div>
                  <div className="dkg-paidservices-body dkg-admin-setting-body">
                    <div className="dkg-instructions-editorCon">
                      <Editor
                        editorState={editorCLientState}
                        onEditorStateChange={onClientEditorStateChange}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                          options: [
                            "inline",
                            "fontSize",
                            "fontFamily",
                            "list",
                            "textAlign",
                            "colorPicker",
                            "link",
                            "image",
                          ],
                          inline: { inDropdown: false },
                          list: { inDropdown: false },
                          textAlign: { inDropdown: false },
                          link: { inDropdown: false },
                          history: { inDropdown: false },
                        }}
                      />
                      <div className="dkg-submit-btnCon">
                        <SubmitButton
                          txt="SAVE"
                          className="dkg-cand-submit-btn"
                          onClick={(e) => SubmitBtn("client")}
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="clients-services"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Clients Services</h4>
                  </div>
                  <div className="dkg-edit-cand-services-Con">
                    <div className="dkg-cn-service-Row row">
                      <div className="col-md-12 col-sm-12 dkg-cn-service-leftCon">
                        {aboutuService
                          .filter((item) => item.type === "Clients Services")
                          .map((data, index) => (
                            <div
                              className="dkg-cand-form-group form-group"
                              key={index + 1}
                            >
                              <InputGroup className="dkg-cn-service-inputgrp">
                                <InputGroup.Prepend className="dkg-service-inputgrp-prepared">
                                  <InputGroup.Text className="dkg-service-inputgrp-text">
                                    <FormControl
                                      aria-label="serial-number"
                                      className="dkg-service-inputgrp-frmcontrol"
                                      defaultValue={data.srNo}
                                      name={`srNo_` + data.id}
                                    />
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  aria-label="service-title"
                                  className="dkg-cand-frmcontrol"
                                  defaultValue={data.descriptions}
                                  name={`description_` + data.id}
                                />
                                <div className="dkg-cn-service-btnCon">
                                  <SubmitButton
                                    txt="SAVE"
                                    className="dkg-cand-submit-btn"
                                    onClick={(e) => submitServiceForm(data.id)}
                                  />
                                </div>
                              </InputGroup>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="jobseekers-services"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">
                      Jobseekers Services
                    </h4>
                  </div>
                  <div className="dkg-edit-cand-services-Con">
                    <div className="dkg-cn-service-Row row">
                      <div className="col-md-12 col-sm-12 dkg-cn-service-leftCon">
                        {aboutuService
                          .filter((item) => item.type === "Jobseekers Services")
                          .map((data, index) => (
                            <div className="dkg-cand-form-group form-group">
                              <InputGroup className="dkg-cn-service-inputgrp">
                                <InputGroup.Prepend className="dkg-service-inputgrp-prepared">
                                  <InputGroup.Text className="dkg-service-inputgrp-text">
                                    <FormControl
                                      aria-label="serial-number"
                                      className="dkg-service-inputgrp-frmcontrol"
                                      defaultValue={data.srNo}
                                      name={`srNo_` + data.id}
                                    />
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  aria-label="service-title"
                                  className="dkg-cand-frmcontrol"
                                  defaultValue={data.descriptions}
                                  name={`description_` + data.id}
                                />
                                <div className="dkg-cn-service-btnCon">
                                  <SubmitButton
                                    txt="SAVE"
                                    className="dkg-cand-submit-btn"
                                    onClick={(e) => submitServiceForm(data.id)}
                                  />
                                </div>
                              </InputGroup>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default ServicesSideMenu;
