import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getteamMembses = createAsyncThunk(
  "teammembers/sol/subcatlist",
  async (thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL +
          "/teamsmembers/" ,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer" + " " + token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateValue = createAsyncThunk(
  "teammembers/sol/keypair",
  async ({ id, key, value }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL +
          "/teamsmembers/" +
          id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer" + " " + token,
          },
          body: JSON.stringify({
            key,
            value,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const usersSlice = createSlice({
  name: "teamMembers",
  initialState: {   
    teammemberlist: [],
    isSuccess: false,
    isUpdate: false,
    isLoading: false,
    isError: false
  },
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.isUpdate = false;
    },
  },
  extraReducers: {

  [getteamMembses.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [getteamMembses.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.teammemberlist = payload.result;
    },
    [getteamMembses.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },
    [updateValue.pending]: (state) => {
      state.isLoading = true;
      state.isUpdate = false;
      state.isError = false;
    },
    [updateValue.fulfilled]: (state, { payload }) => {    
    state.isLoading = false;    
    state.teammemberlist = payload.result;
    state.isUpdate = true;
    },
    [updateValue.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    }
  },
});
export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
