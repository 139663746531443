import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import "./upload-video.scss";
//import { FaEdit } from "react-icons/fa";
import AWS from "aws-sdk";
import { Buffer } from "buffer";
import "react-image-crop/dist/ReactCrop.css";
import {
  updateAlbumImg,
  updateTitleAlbum,
} from "../../../../../../../../slice/cmsSlice";
import $ from "jquery";
import SubmitButton from "../../../../../../../ui/submitButton";
import { FaPlus, FaTimes } from "react-icons/fa";
//import Pagetypealbums from "../../../../../../../../assets/images/album.jpg";

const EditModal = ({ onHide, albumimg, albumnme, rowid }) => {
  const dispatch = useDispatch();

  const { issSuccess, isLoading, isAlbum, imgAlbumList } = useSelector(
    (state) => state.cms
  );

  //const [albumsImg, setAlbumImg] = useState(true);
  const [base64AlbumsImg, setBase64AlbumImg] = useState("");
  const [titleName, setTitleName] = useState(albumnme);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [crossBtn, setCrossBtn] = useState(false);
  // const [plusBtn, setPlusBtn] = useState(false);

  useEffect(() => {
    if (isAlbum) {
      setSuccess(true);
      setLoading(false);
    }
  }, [isAlbum]);

  const canclledBtn = (id) => {
    $(".changeThirdImageDynamic" + id).css("height", "1px");
    $(".changeThirdImageDynamic" + id).attr("src", "");
    $(".cancleBtn" + id).css("display", "none");
    $(".showplusicone" + id).css("display", "block");

    dispatch(
      updateAlbumImg({
        id,
        key: "imageName",
        value: "",
      })
    );
  };
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      $(".changeThirdImageDynamic" + e.target.id).css("height", "90%");
      $(".cancleBtn" + e.target.id).css("display", "block");
      $(".showplusicone" + e.target.id).css("display", "none");

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        $(".changeThirdImageDynamic" + e.target.id).attr("src", reader.result);

        let s3bucket = new AWS.S3({
          accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
          secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
          Bucket: process.env.REACT_APP_BUCKET_NAME,
        });

        let buf = new Buffer(
          reader.result.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );
        s3bucket.createBucket(function () {
          var params = {
            Bucket: process.env.REACT_APP_BUCKET_NAME + "/users/images",
            Key: Date.now() + ".png",
            ContentEncoding: "base64",
            ContentType: "image/png",
            Body: buf,
          };
          s3bucket.upload(params, function (err, data) {
            if (err) {
              console.log(err);
            } else {
              dispatch(
                updateAlbumImg({
                  id: e.target.id,
                  key: "imageName",
                  value: data.key,
                })
              );
            }
          });
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const updateAlbum = (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);
    dispatch(
      updateTitleAlbum({
        id: rowid,
        key: "albumName",
        value: titleName,
      })
    );
  };

  return (
    <>
      <Modal
        show={true}
        onHide={onHide}
        className="dkg-pagetype-album-ablum-Modal"
        centered
      >
        <Modal.Header closeButton className="dkg-video-ablum-Header">
          <Modal.Title className="w-100 text-center">{titleName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-image-ablum-Body dkg-edit-ablum-Body">
          <div className="dkg-album-ablum-mainCon dkg-edit-ablum-mainCon">
            <div className="dkg-edit-album-inputCon">
              <div className="dkg-edit-album-inputgrp">
                <input
                  type="text"
                  name="edit-album"
                  id="edit-album"
                  className="dkg-edit-album-input"
                  defaultValue={titleName}
                  onChange={(e) => setTitleName(e.target.value)}
                />
                <div className="dkg-edit-album-btnCon">
                  <SubmitButton
                    txt="SAVE"
                    className="btn dkg-edit-album-btn "
                    loading={loading}
                    success={success}
                    onClick={updateAlbum}
                  />
                </div>
              </div>
            </div>
            <div className="dkg-album-ablum-boxs">
              {albumimg.map((item, index) => (
                <div className="dkg-album-ablum-cols" key={index + 1}>
                  <div
                    className={`dkg-album-file-wrapper  dkg-edit-album-filewrapper`}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      name="imageName"
                      id={item.id}
                      onChange={onSelectFile}
                    />
                    {item.imageName != "" && item.imageName != null ? (
                      <span
                        className={`dk-myprofile-editicon cancleBtn` + item.id}
                        onClick={() => canclledBtn(item.id)}
                      >
                        <FaTimes />
                      </span>
                    ) : (
                      <span className={`dkg-plus-icon showplusicone` + item.id}>
                        <FaPlus />
                      </span>
                    )}

                    <span
                      className={`dkg-plus-icon showplusicone` + item.id}
                      style={{ display: "none" }}
                    >
                      <FaPlus />
                    </span>

                    <span
                      className={`dk-myprofile-editicon cancleBtn` + item.id}
                      style={{ display: "none" }}
                      onClick={() => canclledBtn(item.id)}
                    >
                      <FaTimes />
                    </span>

                    <span className={`dk-myprofile-userImgCon` + item.id}>
                      <figure id="edit_image">
                        {item.imageName != "" && item.imageName != null ? (
                          <img
                            alt=""
                            className={
                              `dkg-changIcon changeThirdImageDynamic` + item.id
                            }
                            style={{ width: "90%", height: "80%" }}
                            src={
                              process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              "/" +
                              item.imageName
                            }
                          />
                        ) : (
                          <img
                            alt=""
                            className={
                              `dkg-changIcon changeThirdImageDynamic` + item.id
                            }
                            src={base64AlbumsImg}
                            style={{ width: "90%" }}
                          />
                        )}
                      </figure>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditModal;
