import React, { useState, useCallback, useRef, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import SubmitButton from "../../../../ui/submitButton";

const SecondSideResume  = () => {
  return (
    <>
      <div className="dkg-cand-services-Side">
        <Tab.Container defaultActiveKey="first">
          <div className="row m-0 dkg-payment-qt-tab-mainCon">
            <div className="col-md-2 pl-0 dkg-payment-qt-tabCon dkg-profile-task-sideMenu">
              <Nav
                variant="pills"
                className="flex-column dkg-payment-qt-tab-navpills"
              >
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="first"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Summary
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="second"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Description
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-md-10  dkg-paidservices-rightCon">
              <Tab.Content className="dkg-paidservices-tabcontent">
                <Tab.Pane eventKey="first" className="dkg-paidservices-tabpane">
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Summary</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Description</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default SecondSideResume ;
