module.exports = {
    SYSTEM_CONSTANTS: {
        SYSTEM_ID: {
            Id: "123456"
        },
        SYSTEM_EMAIL: {
            Email: "system@dkmasterbox.com"
        },
        COMPANY:{
            companyid:246
        },
        QUALIFY: {
            CATEGORY_ID_QUALIFY: "617005c27a79b5156417a9a2",
            FREQUENCY_LIST: "617007f87a79b5156417a9b6"
        },
        Apps_Tracker: {
            JOBBOARD_LIST: {
                ELJ: "61696de780926e10cc2bb462",
                MLV: "61696e6280926e10cc2bb46e",
                HIPO: "61696e6b80926e10cc2bb471",
                DK_WEBSITE: "61696e5980926e10cc2bb46b",
                LINKEDIN: "61696e7480926e10cc2bb474",
                INDEED: "61729803d5f71d3524db6e17",
                EJOBS: "61696e5080926e10cc2bb468",
                FACEBOOK: "61696e8080926e10cc2bb477",
                DIRECT_EMAIL: "6172976bd5f71d3524db6e13",
                LEARN4GOOD: "6172982cd5f71d3524db6e1a",
                EUROJOBS: "6172985dd5f71d3524db6e1d",
                CAREERS4A: "61729889d5f71d3524db6e20",
                JOBFIND: "617298b1d5f71d3524db6e23",
                BAKECA: "617298f3d5f71d3524db6e26",
                BESTJOBS: "6172a151d5f71d3524db6e29",
                PROFESIA_CZ: "6172a171d5f71d3524db6e2c",
                PROFESIA_SK: "6172a19cd5f71d3524db6e2f",
                JOOBLE: "6172a1e3d5f71d3524db6e32",
                NET_EMPREGOS: "6172a206d5f71d3524db6e35",
                PRACUJ_PL: "6172a226d5f71d3524db6e38",
                PURE_CHAT: "6172a24dd5f71d3524db6e3b",
                CAREER_BUILDER: "6172a272d5f71d3524db6e3e",
                MIN_JOB: "6172a2bfd5f71d3524db6e41",
                SOF_TEST: "6172a307d5f71d3524db6e44"
            },
            USERS_EXCLUDE: ['615d4975ddd63e0b5cf10577', '615d4975ddd63e0b5cf1057e', '615d4975ddd63e0b5cf10580', , '615d4975ddd63e0b5cf10581', '615d4975ddd63e0b5cf1057f', '615d4975ddd63e0b5cf10584', '615d4975ddd63e0b5cf10585', '615d4975ddd63e0b5cf10586', '615d4975ddd63e0b5cf10587', '615d4975ddd63e0b5cf10588']
        },

        //integration test
        JOBS: {
      JobId: "58",
      CategoryID: "322",
      CompanyId: "246",
      ProjectId: "247",
      RoleTypeId: "248",
      LocalityId: "63",
      NoOfJobsId: "64",
      CountryId: "321",
      CityId: "323",
      LanguagesId: "250",
      currencyId: "395",
      JobProfileId: "67",
      WorkPermitId: "68",
      WorkPlaceId: "70",
      WorkDaysId: "71",
      ShiftsWorkId: "72",
      ContractTypeId: "73",
      SalaryTypeId: "74",
      AvailabilityId: "75",
      OutsideofEUId: "76",
      FlightTicketId: "77",
      TaxifromAirportId: "78",
      AccommodationtId: "79",
      RelocationBudgetId: "80",
      ExperiencetId: "139",
      IndustryId: "398",
      BusinessId: "397",
      TypeId: "322",
      TaxId: "87",
      VatId: "88",
      Level: "396",
      jobInterrviewId:"385",
      jobInterviewMethodId:"386",
      itSkillsId:"380",
      jobsSkillsId:"381",
      sendoutMethodId:"383",
      sendoutStepId:"384",
      jobStatusId:"356",
    },

    }
}
