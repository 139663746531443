import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const loginUser = createAsyncThunk(
    'auth/login',
    async ({ role, email, password }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/auth/login/user',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web'
                    },
                    body: JSON.stringify({
                        role,
                        email,
                        password,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                localStorage.setItem(
                    "dkmasterBoxAdmin",
                    JSON.stringify({
                        token: data.token,
                        refreshToken: data.refreshToken
                    })
                );
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const adminlogin = createAsyncThunk(
    'auth/adminlogin',
    async ({ role, email, password }, thunkAPI) => {
        try {

            const response = await fetch(process.env.REACT_APP_BASE_URL + '/admin/auth/login',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web'
                    },
                    body: JSON.stringify({
                        role,
                        email,
                        password,
                    }),
                }
            );
            let data = await response.json();
            
            if (response.status === 200 || response.status === 401) {
                localStorage.setItem(
                    "dkmasterBoxAdmin",
                    JSON.stringify({
                        token: data.result.token.access_token,
                        refreshToken: data.result.token.refresh_token
                    })
                );
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const logoutUser = createAsyncThunk(
    'auth/logout',
    async (thunkAPI) => {
        try {
            localStorage.removeItem("dkmasterBoxAdmin");
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const changePassword = createAsyncThunk(
    'auth/password',
    async ({ email, roleType }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/auth/resetlink/forget-password',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web'
                    },
                    body: JSON.stringify({
                        email, roleType
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateUserPassword = createAsyncThunk(
    'auth/update_password',
    async ({ email, accessToken, confirmPassword, password, roleType }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/auth/changepwd/resetpassword/' + roleType + '/' + email + '/' + accessToken,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web'
                    },
                    body: JSON.stringify({
                        confirmPassword,
                        password
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        userInfo: {},
        isAuthenticate: false,
        isSuccess: false,
        isError: false,
        isLoading: false,
        resendEmail: false,
        errorMessage: '',
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.resendEmail = false;
            state.errorMessage = '';
            return state;
        },
        doLogin: (state, { payload }) => {
            state.isAuthenticate = true;
            state.userInfo = payload;
        },
        updateHeaderDetails: (state, { payload }) => {
            state.userInfo.name = payload.name;
            state.userInfo.userImg = payload.userImg;
        }
    },
    extraReducers: {
        [loginUser.pending]: (state) => {
            state.isLoading = true;
        },
        [loginUser.fulfilled]: (state, { payload }) => {
            state.isAuthenticate = payload.isAuthenticated
            state.userInfo = payload.result;
            state.isLoading = false;
        },
        [loginUser.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
            state.isLoading = false;
        },
        [adminlogin.pending]: (state) => {
            state.isLoading = true;
        },
        [adminlogin.fulfilled]: (state, { payload }) => {
            state.isAuthenticate = payload.result.isAuthenticated
            state.userInfo = payload.result;
            state.isLoading = false;
        },
        [adminlogin.rejected]: (state, { payload }) => {
            state.isError = true;
            state.errorMessage = "Invalid Login";
            state.isLoading = false;
        },
        [logoutUser.pending]: (state) => {
            state.isLoading = true;
        },
        [logoutUser.fulfilled]: (state, { payload }) => {
            state.isAuthenticate = false
            state.isLoading = false;
        },
        [changePassword.pending]: (state) => {
            state.isLoading = true;
            state.resendEmail = false;
        },
        [changePassword.fulfilled]: (state, { payload }) => {
            state.resendEmail = true;
            state.isLoading = false;
        },
        [changePassword.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message;
            state.isLoading = false;
        },
        [updateUserPassword.pending]: (state) => {
            state.isLoading = false;
        },
        [updateUserPassword.fulfilled]: (state, { payload }) => {
            state.isSuccess = true;
            state.isLoading = false;
        },
        [updateUserPassword.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message;
            state.isLoading = false;
        },
    }
});
export const { clearState, doLogin, updateHeaderDetails } = authSlice.actions;
export default authSlice.reducer;