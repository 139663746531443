import React from 'react'
import {Link} from 'react-router-dom'
import AdminHeader from '../../../../elements/header/Header'
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../../elements/submenu/mainmenu/CandidateSubmenu'; 
import  "./active-acc.scss";
import Pagination from '@mui/material/Pagination';
import {Dropdown, DropdownButton} from 'react-bootstrap'
import RightModalFilter from "./rightModalFilter/RightModalFilter";
import { FaUserFriends } from "react-icons/fa";
import CheckboxDummy from '../../../../ui/checkbox/CheckboxDummy'

import SwitchTab from "./Switch";




const ActiveAccount = () => {
    return (
        <>
            <AdminHeader />
            <Navbar />
            <Submenu />
            <div className='dkg-candidate-acc-mainCon'>
                <div className='dkg-candidate-acc-header'>
                    <div className='dkg-cand-acc-leftCon'>
                        <div className="dk-SrchInputBox mr-2">
                            <div className="input-group">
                                <input className="form-control" placeholder="Search..." value="" />
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" type="button"><i className="fa fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className='dkg-plusicon-Con mr-2'>
                            <Link to="#" className="dkg-plusicon">
                                <i className="fas fa-plus"></i>
                            </Link>
                        </div>
                    </div>
                    <div className='dkg-cand-acc-centreCon'>
                       <h3 className="title">Active Accounts</h3>
                    </div>
                    <div className='dkg-cand-acc-rightCon'>
                        <button className="dk-reoderBtn mr-2">
                            <FaUserFriends />
                        </button>
                       <RightModalFilter />
                        <div className='dkg-casestus-filter'>
                            <DropdownButton className="dropdownFilter" title="User Type">
                                <Dropdown.Item href="#" style={{ backgroundColor: "#eaeaea", color: "#333"}}>
                                    Select 1
                                    <span className="dropdown-filter-count">
                                        5
                                    </span>
                                </Dropdown.Item>
                                <Dropdown.Item href="#" style={{ backgroundColor: "#eaeaea", color: "#333"}}>
                                    Select 1
                                    <span className="dropdown-filter-count">
                                        5
                                    </span>
                                </Dropdown.Item><Dropdown.Item href="#" style={{ backgroundColor: "#eaeaea", color: "#333"}}>
                                    Select 1
                                    <span className="dropdown-filter-count">
                                        5
                                    </span>
                                </Dropdown.Item><Dropdown.Item href="#" style={{ backgroundColor: "#eaeaea", color: "#333"}}>
                                    Select 1
                                    <span className="dropdown-filter-count">
                                        5
                                    </span>
                                </Dropdown.Item><Dropdown.Item href="#" style={{ backgroundColor: "#eaeaea", color: "#333"}}>
                                    Select 1
                                    <span className="dropdown-filter-count">
                                        5
                                    </span>
                                </Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>
                </div>
                <div className='dkg-candidate-acc-tableCon table-responsive'>
                    <table className='table table-bordered dkg-candidate-acc-table mb-0'>
                        <thead>
                            <tr>
                                <th>Sn.</th>
                                <th>Created On</th>
                                <th>Status</th>
                                <th>A/C Access</th>
                                <th>User Type</th>
                                <th>Case ID</th>
                                <th>Name of User</th>
                                <th>User Account Email</th>
                                <th>Account Password</th>
                                <th>Country</th>
                                <th>Town/City</th>
                                <th>Subscription</th>
                                <th>System Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className='dkg-active-acc-serNo'>
                                        1
                                        <CheckboxDummy />
                                    </div>
                                </td>
                               
                                <td>
                                    30 Oct 2023
                                </td>
                                <td>
                                    <div className='dkg-statusDrodownCon'>
                                        <Dropdown  className='dkg-statusDrodown'>
                                            <Dropdown.Toggle variant="" className='dkg-statusdropdwn-toggle'>
                                                Active
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dkg-statusdropdwn-menu'>
                                                <Dropdown.Item href="#" className='dkg-status-dropitem' style={{backgroundColor: "#3a9469" , color: "#fff" }}>Active</Dropdown.Item>
                                                <Dropdown.Item href="#" className='dkg-status-dropitem' style={{backgroundColor: "#dc3545" , color: "#fff" }}>Inactive</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </td>
                                <td>
                                    <SwitchTab />
                                </td>
                                <td>
                                     Candidate
                                </td>
                                <td> DKAC23456</td>
                                <td>Ajay Chauhan</td>
                                <td>ajaychauhan@gmail.com</td>
                                <td>23455</td>
                                <td>India</td>
                                <td>Noida</td>
                                <td>12</td>
                                <td>DKER211014@dkglobalrecruiters.com</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="dk-resPaginationCon pagination">
                    <div className="dk-resPagination">
                        <div className="dk-resLeftPag">
                            <div className="dk-texCounter" style={{ color: '#fff !imporant' }}>
                                Showing 1 to 2 (of 5 entries)
                            </div>
                        </div>
                        <div className="dk-resrightPag pagination-btns">
                            <Pagination count={10} shape="rounded" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActiveAccount;