import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
//import Submit from "../../../../ui/submitButton";
import { FaTrashAlt } from "react-icons/fa";
// import "./script-delete.scss";
import {
  subCatDelete,
  clearState,
} from "../../../../../../slice/clientRole";
import SubmitButton from '../../../../../ui/submitButton'

import { showSuccess, showError, clearMessage } from "../../../../../../components/utils/messages/messageSlice";

import $ from "jquery";

const DeleteDummy = ({ catIdds, activeEditTab, catids, atag }) => {
  const dispatch = useDispatch();
  const { isError, issDelete } = useSelector(
    (state) => state.clientRolesType
  );

  const [show, setShow] = useState(false);
  const [categoryId, setCategoryId] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setCategoryId(catIdds);
  }, [catIdds]);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setShow(false);
      setLoading(false);
      setSuccess(false);
    }
    if (issDelete) {
      dispatch(clearState());
      setShow(false);
      setLoading(false);
      setSuccess(false);
      // $(".dkg-hidewithoutselct").removeClass("activesSubTab");
      // $(".tab_1").removeClass("activeSubTab");
      // $(".tab_2").removeClass("activeSubTab");
      // $(".tab_3").removeClass("activeSubTab");
    }
  }, [isError, issDelete]);

  const deleteCategory = (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);
    if (!categoryId) {
      dispatch(showError({ msg: "Please enter category name" }));
      setLoading(false);
    } else {
      setLoading(true);
      dispatch(subCatDelete({ id: categoryId, catid: catids, tag: atag }));
    }
  };

  return (
    <React.Fragment>
      <span className="dk-resCrcleIconCon" onClick={handleShow}>
        <FaTrashAlt />
      </span>

      <Modal
        className="dk-mlAddNewModal dk-deleteModal dkg-script-deleteModal234"
        show={show}
        onHide={handleClose}
      >
        <Modal.Body className="dk-mlAddModalBody">
          <p>Are you sure that you want to delete this tab ?</p>
          <div className="d-flex justify-content-end">
            <button onClick={handleClose} className="mr-2">No</button>
            {/* <Submit txt="Yes" position="justify-content-center" className="dk-yesBtn" /> */}
            <SubmitButton
              txt="Yes"
              loading={loading}
              success={success}
              onClick={deleteCategory}
              position="justify-content-center"
              className="dkg-submitbtn"
            />
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteDummy;
