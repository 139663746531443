import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav } from "react-bootstrap";
import { FaPlus, FaArrowsAlt, FaTrashAlt, FaEdit } from "react-icons/fa";
import AddNewCategoryModal from "./reportsModel/AddNewCategory";
import EditSubCategory from "./reportsModel/EditSubCategory";
import ReorderSubCategory from "./reportsModel/ReorderSubCategory";
import DeleteModal from "./reportsModel/DeleteDummy";

import {
  getSubCategories,
  getSubCatDetails,
  updateEditorValue,
} from "../../../../../slice/clientRoleType";

import { Link } from "react-router-dom";
import $ from "jquery";

import SubmitButton from "../../../../ui/submitButton";
import MessageBox from "../../../../utils/joditEditor/MessageBox";
import {
  showSuccess
} from "../../../../../components/utils/messages/messageSlice";

const ReportsStep1 = (props) => {
  const dispatch = useDispatch();
  const {
    // isSuccess,
    // isTabActive,
    // isError,
    isSubCatInsert,
    isSubdesc,
    isSubCatUpdate,
    isDetails,
    isLoading,
    //issLoading,
    subCategoryDetails,
    isSubReoder,
    issDelete,
    subCategoryWhatisList,
  } = useSelector((state) => state.clientroletype);

  const [categoryId, setCategoryId] = useState(props.catIdds);
  const [tabName, setTabName] = useState("details");
  const [subCategoryName, setSubCategoryName] = useState("");

  const [data, setData] = useState([]);
  const [catRowId, setCatRowId] = useState("");
  //const [activeSubCat, setActiveSubCat] = useState("");
  const [defaultId, setDefaultId] = useState("");
  //const [detailsSubtab, setDetailsSubtab] = useState("");
  const [showDetailsTab, setShowDetailsTab] = useState(false);
  const [editorDescription, setEditorDescription] = useState("");
  const [overviewData, setOverviewData] = useState("");
  const [activeEditTab, setActiveEditTab] = useState(false);
  const [editorData, setEditorData] = useState("");

  useEffect(() => {
    if (!isLoading && subCategoryWhatisList && subCategoryWhatisList.length > 0) {
      setCategoryId(subCategoryWhatisList[0].id)
      setSubCategoryName(subCategoryWhatisList[0].subCategoryName)
      setData(subCategoryWhatisList);
      setEditorDescription(subCategoryWhatisList[0].description);
      setOverviewData(subCategoryWhatisList[0].description);
      setDefaultId(subCategoryWhatisList[0].id);
      setCatRowId(subCategoryWhatisList[0].id);
    }
  }, [isLoading]);

  useEffect(() => {
    //setActiveEditDelete(false);
    //setAddReorderTab(true);
    $(".addReorderTab").css('display', 'block');
    $(".addnewClass").addClass("activesSubTab");
    setShowDetailsTab(true);
    setActiveEditTab(false);

  }, [props]);

  useEffect(() => {
    if (isDetails) {
      setOverviewData(subCategoryDetails.description);
      setEditorDescription(subCategoryDetails.description);
    }
    if (isSubReoder) {
      setShowDetailsTab(false);

    }
    if (isSubCatUpdate) {
      setShowDetailsTab(false);

    }
    if (issDelete) {
      // setShowDetailsTab(false);
      //
      dispatch(getSubCategories({ categoryId: props.catIdds, keys: 'details' }))
    }

    if (isSubdesc) {
      dispatch(showSuccess({ msg: "Record updated successfully." }));
    }

  }, [
    isSubdesc,
    isSubCatUpdate,
    //props.pagetitle,
    isSubCatInsert,
    isDetails,
    issDelete,
    isSubReoder,
  ]);

  //const [activeEditDelete, setActiveEditDelete] = useState(false);
  //const [addReorderTab, setAddReorderTab] = useState(false);

  const subCategoryFunction = (id, catId, data) => {
    setDefaultId(id)

    if (props.pagetitle != "Overview") {
      $(".dk-tabs-right-navlink-13").removeClass("addActiveClass");
      $(".xyz" + id).addClass("addActiveClass");
    }
    //  setActiveEditDelete(true);
    setShowDetailsTab(true);
    setOverviewData("");
    setEditorDescription("");
    setSubCategoryName(data);
    setCategoryId(catId);
    setCatRowId(id);
    dispatch(getSubCatDetails({ id }));
    setActiveEditTab(true);
  };


  const [isReorderModal, setIReorderModal] = useState(false);

  const showreorderModal = () => {
    setIReorderModal(true);
  };
  const hidereorderModal = () => {
    setIReorderModal(false);
  };

  const SubmitBtn = () => {
    dispatch(
      updateEditorValue({
        id: defaultId,
        key: "description",
        value: editorData
      })
    );
  };

  return (
    <>
      <div className="dkg-payment-qt-rightpanel">
        <div className="dkg-tabs-rightPanel-12">
          <div className="dkg-subcategory-header dkg-jobspec-subcat-cols">
            {props.pagetitle === "Overview" ? (
              <>
                <ul className="headerlist"></ul>
                <div className="dkg-script-overview-title">teeee</div>
              </>
            ) : (
              <>
                <ul className="headerlist">

                  <li className="headeritem mr-2 addReorderTab">
                    <AddNewCategoryModal
                      categoryId={props.catIdds}
                      tabName={tabName}
                    />
                  </li>
                  <li className="headeritem addReorderTab mr-2">
                    <span
                      className="dk-resCrcleIconCon"
                      onClick={showreorderModal}
                    >
                      <FaArrowsAlt />
                    </span>
                    {isReorderModal ? (
                      <ReorderSubCategory
                        onHide={hidereorderModal}
                        dataList={data}
                        catIdds={props.catIdds}
                        activeEditTab={tabName}
                      />
                    ) : null}
                  </li>
                  <li className="headeritem mr-2">
                    <EditSubCategory
                      categoryId={categoryId}
                      tabName={tabName}
                      subCategory={subCategoryName}
                      catRowId={catRowId}
                      // activeEditTab={activeEditTab}
                      list={data}
                    />
                  </li>
                  <li className="headeritem">
                    <DeleteModal
                      catIdds={catRowId}
                      activeEditTab={activeEditTab}
                      atag={tabName}
                      catids={props.catIdds}
                    />
                  </li>

                </ul>
              </>
            )}


          </div>
          <div className="dk-tabs-rightCols-12">
            <div className="dk-tabs-right-detail-12">
              <Tab.Container id="left-tabs-example">

                <div className={`dk-tabs-right-tabpane-12 addnewClass dkg-hidewithoutselct`} >
                  <div className="dk-rightDetail-panel-13">
                    <div className="dk-getStartedRight-detail-13">
                      <Nav
                        variant=""
                        className="dk-tabs-right-nav-13 flex-column"
                      >
                        {data.map((item, key) => (
                          <Nav.Item className="dk-tabs-right-navitem-13">

                            <Link
                              to="#"
                              key={key + 1}
                              className={
                                key === 0 ?
                                  `dk-tabs-right-navlink-13 addActiveClass xyz` + item.id : `dk-tabs-right-navlink-13 xyz` + item.id
                              }
                              onClick={() =>
                                subCategoryFunction(
                                  item.id,
                                  item.categoryId,
                                  item.subCategoryName
                                )
                              }

                            >
                              {item.subCategoryName}{" "}
                            </Link>

                          </Nav.Item>
                        ))}
                      </Nav>

                      {showDetailsTab ? (
                        <Tab.Content className="dk-tabs-right-tabcontent-13">
                          <MessageBox
                            value={editorDescription}
                            onChange={(value) => setEditorData(value)}
                          />
                          <div className="dkg-submit-btnCon">
                            <SubmitButton
                              txt="SAVE"
                              className="dkg-cand-submit-btn"
                              onClick={SubmitBtn}
                            />
                          </div>

                        </Tab.Content>
                      ) : (
                        <>
                          {/* this is for default active tab data */}

                        </>
                      )}
                    </div>
                  </div>
                </div>
                {props.pagetitle === "Overview" ? (
                  <>
                    <Tab.Content className="dk-tabs-right-tabcontent-13">

                      <MessageBox
                        value={overviewData}
                        onChange={(value) => setEditorData(value)}
                      />
                      <div className="dkg-submit-btnCon">
                        <SubmitButton
                          txt="SAVE"
                          className="dkg-cand-submit-btn"
                          onClick={SubmitBtn}
                        />
                      </div>
                    </Tab.Content>
                  </>
                ) : null}
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportsStep1;
