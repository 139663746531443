import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./questionmodal.scss";

const ReadQuestion = ({ onHide, questiontitle, answerTitle }) => {
  const [isQs, setIsQs] = useState("");
  const [isAns, setIsAns] = useState("");
  useEffect(() => {
    setIsQs(questiontitle);
    setIsAns(answerTitle);
  }, [questiontitle, answerTitle]);

  return (
    <>
      <Modal
        show={true}
        onHide={onHide}
        centered
        className="dkg-faq-questionModal"
      >
        <Modal.Header closeButton className="dkg-faq-questionModalHeader">
          <Modal.Title>{isQs}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-faq-questionModalBody">
          {isAns != "" && isAns != null ? (
            <div
              dangerouslySetInnerHTML={{
                __html: isAns,
              }}
            ></div>
          ) : (
            ""
          )}
          {/* <textarea
            name=""
            id=""
            rows="5"
            value={isAns}
            readOnly
            className="w-100 dkg-faq-viewtextArea"
          /> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReadQuestion;
