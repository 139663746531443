import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { FaPlus } from "react-icons/fa";
import {
  subCategoryAdd,
  clearState,
} from "../../../../../../slice/relocations";
import SubmitButton from "../../../../../ui/submitButton";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../../components/utils/messages/messageSlice";

const SubCategory = ({ categoryId, tabName }) => {
  const dispatch = useDispatch();
  const { isError, isSubCatInsert } = useSelector((state) => state.relocation);
  const [show, setShow] = useState(false);

  const [catId, setCategoryId] = useState("");
  const [tabbName, setTabName] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    setCategoryId(categoryId);
    setTabName(tabName);
  }, [categoryId, tabName]);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setShow(false);
      setLoading(false);
      setSuccess(false);
    }
    if (isSubCatInsert) {
      dispatch(clearState());
      setCategoryName("");
      setShow(false);
      setLoading(false);
      setSuccess(false);
    }
  }, [isError, isSubCatInsert]);

  const addSubCategory = (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);
    if (!categoryName) {
      dispatch(showError({ msg: "Please enter category naem  required" }));
      setLoading(false);
    } else {
      setLoading(true);
      dispatch(
        subCategoryAdd({
          categoryId: catId,
          keys: tabbName,
          subCategoryName: categoryName,
        })
      );
    }
  };

  return (
    <>
      <span className="dk-resCrcleIconCon" onClick={handleShow}>
        <FaPlus />
      </span>
      <Modal show={show} onHide={handleClose} className="dkg-script-modal-2345">
        <Modal.Header closeButton>
          {/* <Modal.Title>Add {tabName} sub category</Modal.Title> */}
          <Modal.Title>Add New Sub-Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="">Name of SubCategory</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setCategoryName(e.target.value)}
                value={categoryName}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {/* <button className='dkg-closebtn' onClick={handleClose}>
                        Close
                    </button> */}
          <SubmitButton
            txt="ADD"
            loading={loading}
            success={success}
            onClick={addSubCategory}
            position="justify-content-center"
            className="dkg-submitbtn"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubCategory;
