import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tab, Nav, Dropdown } from "react-bootstrap";
//import AddIcon from '@mui/icons-material/Add';
//import Step1 from "./Step1";
import "./dkg-clientlist.scss";
import Header from "../../../../elements/header/Header";
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../elements/submenu/mainmenu/ClientInfoSubmenu";
import { FaPlus, FaTrashAlt, FaEdit, FaArrowsAlt } from "react-icons/fa";
import AddNewListViewModal from "./addnewmodal/AddNewListView";
import JobFileComponent from "./job-details/JobDetails";
//import ClientFaq from './faq/category/CompanyTable'

import {
  companyData,
  getprojectlist,
  deleteCompany,
  clientscomp,
  updateValue,
  fetchCompanyProject,
  clientCompanyUpdate,
  clientProjectsUpdate,
  clientRoleUpdate,
  clientLangUpdate,
  clientSendoutUpdate,
  clientInterviewUpdate,
  clientJobOfferUpdate,
  clientRelocationUpdate,
  clientPortalUpdate,
  clientFaqsUpdate,
} from "../../../../../slice/companies/clients/clientSlice";

import {
  companyUpdate,
  getCategories,
  getSubCategories,
  clearState,
} from "../../../../../slice/clieninfo/requirnment/requirnmentSlice";
import {
  getProjectCategory,
  projectUpdate,
} from "../../../../../slice/clientProject";
import {
  getRoleTypeCategory,
  roletypeUpdate,
} from "../../../../../slice/clientRole";
import {
  getLangCategory,
  languageUpdate,
} from "../../../../../slice/prescreeningForm";
import { getSendoutCategory } from "../../../../../slice/europe";
import { getInterviewCategory } from "../../../../../slice/commonTraning/processSlice";

import { getJobofferCategory } from "../../../../../slice/clientRoleType";

import { getRelocationCategories } from "../../../../../slice/relocations";
import { getPortalsCategories } from "../../../../../slice/clientPortals";
import { getFaqCategory } from "../../../../../slice/faq/faqSlice";

// import AddNewCategory from "./modal/maincategory/AddNewCategory";
// import EditCategory from "./modal/maincategory/EditCategory";
// import ReorderCategory from "./modal/maincategory/ReorderCategory";
// import DeleteDummy from "./modal/maincategory/DeleteDummy";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../components/utils/messages/messageSlice";
// import { EditorState, ContentState, convertToRaw } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
// import htmlToDraft from "html-to-draftjs";
// import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import $ from "jquery";
import Switch from "./Switch";

const Overview = () => {
  const dispatch = useDispatch();
  const {
    isSuccess,
    isLoading,
    isCatUpdate,
    isError,
    isDelete,
    isReoder,
    isSubCatUpdate,
    isSubReoder,
    isCatInsert,
    categoryReqnmntList,
    isCompanyUpdate,
  } = useSelector((state) => state.requirnments);

  const { projectcategoryList, isProjectUpdate } = useSelector(
    (state) => state.clientproject
  );
  const { roletypecategoryList, isRoleUpdate } = useSelector(
    (state) => state.clientRolesType
  );
  const { langcategoryList, islangUpdate } = useSelector(
    (state) => state.screeningFormScript
  );
  const { categoryWhatisList, isSendouts } = useSelector(
    (state) => state.clientEurope
  );

  const { interviewList, isInterviews } = useSelector((state) => state.process);
  const { joboffersList, isJobofer } = useSelector(
    (state) => state.clientroletype
  );
  const { relocationsList, isRel } = useSelector((state) => state.relocation);

  const { companyPortalsList, isPortal } = useSelector(
    (state) => state.clientportals
  );

  const { faqCategoryList, isFaqs } = useSelector((state) => state.faqs);

  const {
    issLoading,
    companyList,
    isUpdate,
    companyDetails,
    clientAllData,
    message,
    isInsert,
    isProfileLoading,
    isCompanyDelete,
    isClientCompUpdate,
    isClientProjectUpdate,
    isClientRoleUpdate,
    isClientLangUpdate,
    isClientSendoutUpdate,
    isClientInterviewUpdate,
    isClientJobUpdate,
    isClientRelUpdate,
    isClientPortalUpdate,
    isClietFaqUpdate,
  } = useSelector((state) => state.companies);

  const [data, setData] = useState([]);
  //const [companyinfo, setCompanyinfo] = useState([]);
  const [catRowId, setCatRowId] = useState("");
  const [comName, setComName] = useState("Select Company");
  // const [catRowName, setCatRowName] = useState("");
  // const [activeEditTab, setActiveEditTab] = useState(false);
  //const [activeSubCat, setActiveSubCat] = useState("");
  //const [activeSTab, setActiveSTab] = useState(false);
  const [commpanyId, setCompanyId] = useState(0);
  const [projectdata, setProjectdata] = useState([]);
  const [roletypedata, setRoletypedata] = useState([]);
  const [languagedata, setLanguagedata] = useState([]);
  const [clientalldatalist, setClientalldatalist] = useState([]);
  const [sendoutdata, setSendoutdata] = useState([]);
  const [interviewsdata, setInterviewsdata] = useState([]);
  const [joboffersdata, setJobofferdata] = useState([]);
  const [relocationdata, setRelocationData] = useState([]);
  const [portalsdata, setPortalsData] = useState([]);
  const [faqsdata, setFaqsData] = useState([]);

  useEffect(() => {
    if (isProjectUpdate && projectcategoryList) {
      setProjectdata(projectcategoryList);
    }
  }, [isProjectUpdate, isClientProjectUpdate]);

  useEffect(() => {
    if (isRoleUpdate && roletypecategoryList) {
      setRoletypedata(roletypecategoryList);
    }
  }, [isRoleUpdate, isClientRoleUpdate]);

  useEffect(() => {
    if (islangUpdate && langcategoryList) {
      setLanguagedata(langcategoryList);
    }
  }, [islangUpdate, isClientLangUpdate]);

  useEffect(() => {
    if (isSendouts && categoryWhatisList) {
      setSendoutdata(categoryWhatisList);
    }
  }, [isSendouts, isClientSendoutUpdate]);

  useEffect(() => {
    if (isInterviews && interviewList) {
      setInterviewsdata(interviewList);
    }
  }, [isInterviews, isClientInterviewUpdate]);

  useEffect(() => {
    if (isJobofer && joboffersList) {
      setJobofferdata(joboffersList);
    }
  }, [isJobofer, isClientJobUpdate]);

  useEffect(() => {
    if (isRel && relocationsList) {
      setRelocationData(relocationsList);
    }
  }, [isRel, isClientRelUpdate]);

  useEffect(() => {
    if (isPortal && companyPortalsList) {
      setPortalsData(companyPortalsList);
    }
  }, [isPortal, isClientPortalUpdate]);

  useEffect(() => {
    if (isFaqs && faqCategoryList) {
      setFaqsData(faqCategoryList);
    }
  }, [isFaqs, isClietFaqUpdate]);

  useEffect(() => {
    if (!isLoading && categoryReqnmntList && categoryReqnmntList.length > 0) {
      setData(categoryReqnmntList);
    }
  }, [isLoading, isCompanyUpdate, isClientCompUpdate]);

  useEffect(() => {
    if (isInsert && companyList && companyList.length > 0) {
      dispatch(companyData());
    }
  }, [isInsert, issLoading]);

  // useEffect(() => {
  //     if (isDelete) {
  //         setActiveEditTab(false);
  //     }
  // }, [isSubCatUpdate, isCatUpdate, isDelete]);

  useEffect(() => {
    if (isSubReoder) {
      dispatch(clearState());
      dispatch(getSubCategories({ categoryId: catRowId, keys: "details" }));
    }
  }, [isSubReoder]);

  useEffect(() => {
    dispatch(clearState());
    const fetchList = async () => {
      try {
        await dispatch(companyData());
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCategories({}));
        await dispatch(getProjectCategory({}));
        await dispatch(getRoleTypeCategory({}));
        await dispatch(getLangCategory({}));
        await dispatch(getSendoutCategory({}));
        await dispatch(getInterviewCategory({}));
        await dispatch(getJobofferCategory({}));
        await dispatch(getRelocationCategories({}));
        await dispatch(getPortalsCategories({}));
        //await dispatch(getFaqCategory({ id: e.target.getAttribute('data-id') }));
        await dispatch(getFaqCategory({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch, isCatInsert, isDelete]);
  //}, [dispatch, isCatInsert, isDelete, isClientProjectUpdate, isProfileLoading]);

  useEffect(() => {
    if (isProfileLoading) {
      setClientalldatalist(clientAllData);
    }
  }, [isProfileLoading]);

  useEffect(() => {
    // if (isError) {
    //   dispatch(showError({ msg: "Error while creating categories" }));
    // }
    if (isCatInsert) {
      dispatch(showSuccess({ msg: "Category added successful created" }));
    }
  }, [isError, isCatInsert]);

  // const selectCat = async (catid, catName) => {
  //     $(".activeSubTab").removeClass("activeSubTab");
  //     $(".activesSubTab").removeClass("activesSubTab");
  //     setActiveSTab(true);
  //     setCatRowId(catid);
  //     setCatRowName(catName);
  //     setActiveEditTab(true);
  //     await dispatch(getSubCategories({ categoryId: catid, keys: "Overview" }));
  // };

  // const [isReorderModal, setIReorderModal] = useState(false);
  // const showreorderModal = () => {
  //     setIReorderModal(true);
  // };
  // const hidereorderModal = () => {
  //     setIReorderModal(false);
  // };
  const [isAddNewList, setIsAddNewList] = useState(false);
  const showAddNewListView = () => {
    setIsAddNewList(true);
  };
  const hideAddNewListView = () => {
    setIsAddNewList(false);
  };

  //const [countryname, setCountryname] = useState("Select Country")
  const [defaultCountry, setDefaultCountry] = useState("Select Country");

  //const uniqueCountries = Array.from(new Set(companyList.map(item => item.country)));

  // Create an array of objects with id, city, and unique country values
  // const uniqueCountry = uniqueCountries.map(country => {
  //     const { id, city } = companyList.find(item => item.country === country);
  //     return { id, city, country };
  // });
  const [checkBoxDefault, setCheckBoxDefault] = useState(false);

  const selectCompany = async (e) => {
    //setCheckBoxDefault('')
    setCompanyId(0);
    setCompanyId(e.target.getAttribute("data-id"));
    setComName(e.target.getAttribute("data-name"));
    setDefaultCountry("");
    await dispatch(
      clientscomp({
        id: e.target.getAttribute("data-id"),
        clientname: e.target.getAttribute("data-name"),
      })
    );
  };

  const companyArray = data.map((company) => {
    const matchingCategory =
      clientalldatalist.length > 0
        ? clientAllData[0].find(
            (category) => category.companyName === company.categoryName
          )
        : clientalldatalist;
    const matched = matchingCategory ? "Yes" : "No";
    return {
      ...company,
      matched,
    };
  });

  const projectsArray = projectdata.map((project) => {
    const matchingCategory =
      clientalldatalist.length > 0
        ? clientAllData[1].find(
            (clientPrjct) => clientPrjct.projectsName === project.categoryName
          )
        : clientalldatalist;
    const matched = matchingCategory ? "Yes" : "No";
    return {
      ...project,
      matched,
    };
  });

  const roleTypeArray = roletypedata.map((role) => {
    const matchingCategory =
      clientalldatalist.length > 0
        ? clientAllData[2].find(
            (clientRole) => clientRole.roleTypeName === role.categoryName
          )
        : clientalldatalist;
    const matched = matchingCategory ? "Yes" : "No";
    return {
      ...role,
      matched,
    };
  });

  const langArray = languagedata.map((role) => {
    const matchingCategory =
      clientalldatalist.length > 0
        ? clientAllData[3].find(
            (clientRole) => clientRole.languageName === role.categoryName
          )
        : clientalldatalist;
    const matched = matchingCategory ? "Yes" : "No";
    return {
      ...role,
      matched,
    };
  });

  const sendoutsArray = sendoutdata.map((data) => {
    const matchingCategory =
      clientalldatalist.length > 0
        ? clientAllData[4].find(
            (items) => items.sendoutsName === data.categoryName
          )
        : clientalldatalist;
    const matched = matchingCategory ? "Yes" : "No";
    return {
      ...data,
      matched,
    };
  });

  const interviewsArray = interviewsdata.map((data) => {
    const matchingCategory =
      clientalldatalist.length > 0
        ? clientAllData[5].find(
            (items) => items.interviewsName === data.categoryName
          )
        : clientalldatalist;
    const matched = matchingCategory ? "Yes" : "No";
    return {
      ...data,
      matched,
    };
  });
  const jobofferArray = joboffersdata.map((data) => {
    const matchingCategory =
      clientalldatalist.length > 0
        ? clientAllData[6].find(
            (items) => items.joboffersName === data.categoryName
          )
        : clientalldatalist;
    const matched = matchingCategory ? "Yes" : "No";
    return {
      ...data,
      matched,
    };
  });

  const relocationArray = relocationdata.map((data) => {
    const matchingCategory =
      clientalldatalist.length > 0
        ? clientAllData[7].find(
            (items) => items.relocationName === data.categoryName
          )
        : clientalldatalist;
    const matched = matchingCategory ? "Yes" : "No";
    return {
      ...data,
      matched,
    };
  });

  const portalArray = portalsdata.map((data) => {
    const matchingCategory =
      clientalldatalist.length > 0
        ? clientAllData[8].find(
            (items) => items.portalsName === data.categoryName
          )
        : clientalldatalist;
    const matched = matchingCategory ? "Yes" : "No";
    return {
      ...data,
      matched,
    };
  });

  // console.log(portalsdata);
  // console.log(faqsdata);
  // console.log(clientAllData);

  const faqsArray = faqsdata.map((data) => {
    const matchingCategory =
      clientalldatalist.length > 0
        ? clientAllData[9].find((items) => items.faqsName === data.name)
        : clientalldatalist;
    const matched = matchingCategory ? "Yes" : "No";
    return {
      ...data,
      matched,
    };
  });

  // const selectCountry = async (e) => {
  //     setCountryname(e.target.dataset.name)
  // }
  const companyhandleToggle = async (e) => {
    const isChecked = e.target.checked;
    //await dispatch(companyUpdate({ id: e.target.dataset.id, key: 'status', value: isChecked }));
    await dispatch(
      clientCompanyUpdate({
        companyId: e.target.dataset.id,
        companyName: e.target.dataset.name,
        clientId: commpanyId,
        clientName: comName,
        value: isChecked,
      })
    );
  };
  const projectToggle = async (e) => {
    const isChecked = e.target.checked;
    // await dispatch(projectUpdate({ id: e.target.dataset.id, key: 'status', value: isChecked }));
    await dispatch(
      clientProjectsUpdate({
        projectsId: e.target.dataset.id,
        projectsName: e.target.dataset.name,
        clientId: commpanyId,
        clientName: comName,
        value: isChecked,
      })
    );
  };
  const roleTypeToggle = async (e) => {
    const isChecked = e.target.checked;
    await dispatch(
      clientRoleUpdate({
        roleTypeId: e.target.dataset.id,
        roleTypeName: e.target.dataset.name,
        clientId: commpanyId,
        clientName: comName,
        value: isChecked,
      })
    );
    //await dispatch(roletypeUpdate({ id: e.target.dataset.id, key: 'status', value: isChecked }));
  };
  const langToggle = async (e) => {
    const isChecked = e.target.checked;
    await dispatch(
      clientLangUpdate({
        languageId: e.target.dataset.id,
        languageName: e.target.dataset.name,
        clientId: commpanyId,
        clientName: comName,
        value: isChecked,
      })
    );
    //await dispatch(languageUpdate({ id: e.target.dataset.id, key: 'status', value: isChecked }));
  };
  const sendoutsToggle = async (e) => {
    const isChecked = e.target.checked;
    await dispatch(
      clientSendoutUpdate({
        sendoutsId: e.target.dataset.id,
        sendoutsName: e.target.dataset.name,
        clientId: commpanyId,
        clientName: comName,
        value: isChecked,
      })
    );
  };
  const interviewToggle = async (e) => {
    const isChecked = e.target.checked;
    await dispatch(
      clientInterviewUpdate({
        interviewsId: e.target.dataset.id,
        interviewsName: e.target.dataset.name,
        clientId: commpanyId,
        clientName: comName,
        value: isChecked,
      })
    );
  };
  const jobofferToggle = async (e) => {
    const isChecked = e.target.checked;
    await dispatch(
      clientJobOfferUpdate({
        joboffersId: e.target.dataset.id,
        joboffersName: e.target.dataset.name,
        clientId: commpanyId,
        clientName: comName,
        value: isChecked,
      })
    );
  };
  const relocationToggle = async (e) => {
    const isChecked = e.target.checked;
    await dispatch(
      clientRelocationUpdate({
        relocationId: e.target.dataset.id,
        relocationName: e.target.dataset.name,
        clientId: commpanyId,
        clientName: comName,
        value: isChecked,
      })
    );
  };
  const portalsToggle = async (e) => {
    const isChecked = e.target.checked;
    await dispatch(
      clientPortalUpdate({
        portalsId: e.target.dataset.id,
        portalsName: e.target.dataset.name,
        clientId: commpanyId,
        clientName: comName,
        value: isChecked,
      })
    );
  };
  const faqsToggle = async (e) => {
    const isChecked = e.target.checked;
    await dispatch(
      clientFaqsUpdate({
        faqsId: e.target.dataset.id,
        faqsName: e.target.dataset.name,
        clientId: commpanyId,
        clientName: comName,
        value: isChecked,
      })
    );
  };

  return (
    <>
      {isAddNewList ? (
        <AddNewListViewModal handleClose={hideAddNewListView} />
      ) : null}
      <Header dashboardheaderLink="" dashboardLink="/dashboard/summary" />
      <Navbar />
      <Submenu />
      <div className="dkg-clientlist-pageCon">
        <div className="dkg-clientlist-pageHeader">
          <div className="dkg-client-header-leftCon">
            <Link
              to="#"
              className="dkg-addcompany-icon mr-2"
              onClick={showAddNewListView}
            >
              Create New Client
            </Link>
            <ul className="headerlist">
              <li className="headeritem mr-2">
                <span className="dk-resCrcleIconCon">
                  <FaPlus />
                </span>
              </li>
              <li className="headeritem mr-2">
                <span className="dk-resCrcleIconCon">
                  <FaEdit />
                </span>
              </li>
              <li className="headeritem mr-2">
                <span className="dk-resCrcleIconCon">
                  <FaArrowsAlt />
                </span>
              </li>
              <li className="headeritem">
                <span className="dk-resCrcleIconCon">
                  <FaTrashAlt />
                </span>
              </li>
            </ul>
          </div>
          <div className="dkg-client-header-centerCon">
            <div className="dkg-cl-DropdownCon mr-2">
              <Dropdown className="dkg-cl-Dropdown">
                <Dropdown.Toggle variant="" className="dkg-cl-Dropdown-toggle">
                  {comName}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="dkg-cl-Dropdown-Menu"
                  onClick={selectCompany}
                >
                  {companyList
                    .filter((data) => data.inStatus == 1)
                    .map((data) => (
                      <Dropdown.Item
                        href="#"
                        data-id={data.clientId}
                        data-name={data.name}
                        className="dkg-cl-Dropdown-Item"
                      >
                        {data.name}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* <div className="dkg-cl-DropdownCon">
                            <Dropdown className='dkg-cl-Dropdown'>
                                <Dropdown.Toggle variant="" className='dkg-cl-Dropdown-toggle'>
                                    {countryname}
                                </Dropdown.Toggle>
                                {
                                    defaultCountry !== "Select Country" ?
                                        <Dropdown.Menu className='dkg-cl-Dropdown-Menu' >
                                            {

                                                uniqueCountry.map((item, index) =>
                                                    <Dropdown.Item href="#" data-id={item.id} data-name={item.country} className='dkg-cl-Dropdown-Item' onClick={selectCountry}>{item.country}</Dropdown.Item>
                                                )

                                            }

                                        </Dropdown.Menu>
                                        :
                                        null
                                }

                            </Dropdown>
                        </div> */}
          </div>
          <div className="dkg-client-header-rightCon">
            {comName != "Select Company" ? (
              <ul className="dkg-headerlist">
                {/* <li className="dkg-headeritem mr-2">

                                        <span className="dk-resCrcleIconCon" >
                                            <FaPlus />
                                        </span>
                                    </li>
                                    <li className="dkg-headeritem mr-2">
                                        <span className="dk-resCrcleIconCon" >
                                            <FaEdit />
                                        </span>
                                    </li>
                                    <li className="dkg-headeritem mr-2">
                                        <span className="dk-resCrcleIconCon" >
                                            <FaArrowsAlt />
                                        </span>
                                    </li>
                                    <li className="dkg-headeritem">
                                        <span className="dk-resCrcleIconCon" >
                                            <FaTrashAlt />
                                        </span>
                                    </li> */}
              </ul>
            ) : null}
          </div>
        </div>

        <div className="dkg-clientlist-mainsec-Con">
          <div className="dkg-clientlist-main-ulCon">
            {comName != "Select Company" ? (
              <div className="dkg-clientlist-main-ul-tabsCon">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="main-overview-tab"
                >
                  <Nav
                    variant="pills dkg-clientlist-main-ul-navpills"
                    className="flex-row"
                  >
                    <Nav.Item className="dkg-clientlist-main-navitem-2">
                      <Nav.Link
                        eventKey="main-overview-tab"
                        className="dkg-clientlist-main-navlink-2"
                      >
                        Overview
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item className="dkg-clientlist-main-navitem-2">
                                                <Nav.Link eventKey="main-jobs-tab" className="dkg-clientlist-main-navlink-2">Jobs</Nav.Link>
                                            </Nav.Item> */}
                    <Nav.Item className="dkg-clientlist-main-navitem-2">
                      <Nav.Link
                        eventKey="main-files-tab"
                        className="dkg-clientlist-main-navlink-2"
                      >
                        Files
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-clientlist-main-navitem-2">
                      <Nav.Link
                        eventKey="main-company-tab"
                        className="dkg-clientlist-main-navlink-2"
                      >
                        Company
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-clientlist-main-navitem-2">
                      <Nav.Link
                        eventKey="main-projects-tab"
                        className="dkg-clientlist-main-navlink-2"
                      >
                        Project
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-clientlist-main-navitem-2">
                      <Nav.Link
                        eventKey="main-roletype-tab"
                        className="dkg-clientlist-main-navlink-2"
                      >
                        Role Type
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-clientlist-main-navitem-2">
                      <Nav.Link
                        eventKey="main-language-tab"
                        className="dkg-clientlist-main-navlink-2"
                      >
                        Languages
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="dkg-clientlist-main-navitem-2">
                      <Nav.Link
                        eventKey="main-sendouts-tab"
                        className="dkg-clientlist-main-navlink-2"
                      >
                        Sendouts
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-clientlist-main-navitem-2">
                      <Nav.Link
                        eventKey="main-interviews-tab"
                        className="dkg-clientlist-main-navlink-2"
                      >
                        Interviews
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-clientlist-main-navitem-2">
                      <Nav.Link
                        eventKey="main-joboffer-tab"
                        className="dkg-clientlist-main-navlink-2"
                      >
                        Job Offer
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-clientlist-main-navitem-2">
                      <Nav.Link
                        eventKey="main-salaries-tab"
                        className="dkg-clientlist-main-navlink-2"
                      >
                        Relocation
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="dkg-clientlist-main-navitem-2">
                      <Nav.Link
                        eventKey="main-portal-tab"
                        className="dkg-clientlist-main-navlink-2"
                      >
                        Portal
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="dkg-clientlist-main-navitem-2">
                      <Nav.Link
                        eventKey="main-faq-tab"
                        className="dkg-clientlist-main-navlink-2"
                      >
                        FAQ
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="dkg-cl-info-main-tabContent">
                    <Tab.Pane
                      eventKey="main-overview-tab"
                      className="dkg-cl-info-main-tabpane "
                    ></Tab.Pane>
                    <Tab.Pane
                      eventKey="main-jobs-tab"
                      className="dkg-cl-info-main-tabpane"
                    ></Tab.Pane>
                    <Tab.Pane
                      eventKey="main-company-tab"
                      className="dkg-cl-info-main-tabpane"
                    >
                      <div className="dkg-company-list-mainCon">
                        <div className="dkg-company-list-tableCon table-responsive">
                          <table className="dkg-company-list-table table table-borderded">
                            <thead>
                              <tr>
                                <th>Companies</th>
                                <th>Linked</th>
                              </tr>
                            </thead>
                            <tbody>
                              {companyArray.length > 0 &&
                                companyArray.map((item, index) => (
                                  <tr key={index + 1}>
                                    <td>{item.categoryName}</td>
                                    <td>
                                      <div className="dkg-clentlinked-switch">
                                        <Switch
                                          handleToggle={companyhandleToggle}
                                          id={item.id}
                                          value={item.categoryName}
                                          name={item.categoryName}
                                          isChecked={
                                            item.matched == "Yes" ? true : false
                                          }
                                          clearCheckbox={checkBoxDefault}
                                          companyNm={comName}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="main-projects-tab"
                      className="dkg-cl-info-main-tabpane"
                    >
                      <div className="dkg-company-list-mainCon">
                        <div className="dkg-company-list-tableCon table-responsive">
                          <table className="dkg-company-list-table table table-borderded">
                            <thead>
                              <tr>
                                <th>Project</th>
                                <th>Linked</th>
                              </tr>
                            </thead>
                            <tbody>
                              {projectsArray.length > 0 &&
                                projectsArray.map((item, index) => (
                                  <tr key={index + 1}>
                                    <td>{item.categoryName}</td>
                                    <td>
                                      <div className="dkg-clentlinked-switch">
                                        <Switch
                                          handleToggle={projectToggle}
                                          id={item.id}
                                          value="project"
                                          name={item.categoryName}
                                          isChecked={
                                            item.matched === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="main-roletype-tab"
                      className="dkg-cl-info-main-tabpane"
                    >
                      <div className="dkg-company-list-mainCon">
                        <div className="dkg-company-list-tableCon table-responsive">
                          <table className="dkg-company-list-table table table-borderded">
                            <thead>
                              <tr>
                                <th>Role Type</th>
                                <th>Linked</th>
                              </tr>
                            </thead>
                            <tbody>
                              {roleTypeArray.length > 0 &&
                                roleTypeArray.map((item, index) => (
                                  <tr key={index + 1}>
                                    <td>{item.categoryName}</td>
                                    <td>
                                      <div className="dkg-clentlinked-switch">
                                        <Switch
                                          handleToggle={roleTypeToggle}
                                          id={item.id}
                                          value="roletype"
                                          name={item.categoryName}
                                          isChecked={
                                            item.matched === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="main-language-tab"
                      className="dkg-cl-info-main-tabpane"
                    >
                      <div className="dkg-company-list-mainCon">
                        <div className="dkg-company-list-tableCon table-responsive">
                          <table className="dkg-company-list-table table table-borderded">
                            <thead>
                              <tr>
                                <th>Language</th>
                                <th>Linked</th>
                              </tr>
                            </thead>
                            <tbody>
                              {langArray.length > 0 &&
                                langArray.map((item, index) => (
                                  <tr key={index + 1}>
                                    <td>{item.categoryName}</td>
                                    <td>
                                      <div className="dkg-clentlinked-switch">
                                        <Switch
                                          handleToggle={langToggle}
                                          id={item.id}
                                          value="language"
                                          name={item.categoryName}
                                          isChecked={
                                            item.matched === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="main-sendouts-tab"
                      className="dkg-cl-info-main-tabpane"
                    >
                      <div className="dkg-company-list-mainCon">
                        <div className="dkg-company-list-tableCon table-responsive">
                          <table className="dkg-company-list-table table table-borderded">
                            <thead>
                              <tr>
                                <th>Sendouts</th>
                                <th>Linked</th>
                              </tr>
                            </thead>
                            <tbody>
                              {sendoutsArray.length > 0 &&
                                sendoutsArray.map((item, index) => (
                                  <tr key={index + 1}>
                                    <td>{item.categoryName}</td>
                                    <td>
                                      <div className="dkg-clentlinked-switch">
                                        <Switch
                                          handleToggle={sendoutsToggle}
                                          id={item.id}
                                          value="sendouts"
                                          name={item.categoryName}
                                          isChecked={
                                            item.matched === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="main-interviews-tab"
                      className="dkg-cl-info-main-tabpane"
                    >
                      <div className="dkg-company-list-mainCon">
                        <div className="dkg-company-list-tableCon table-responsive">
                          <table className="dkg-company-list-table table table-borderded">
                            <thead>
                              <tr>
                                <th>Interviews</th>
                                <th>Linked</th>
                              </tr>
                            </thead>
                            <tbody>
                              {interviewsArray.length > 0 &&
                                interviewsArray.map((item, index) => (
                                  <tr key={index + 1}>
                                    <td>{item.categoryName}</td>
                                    <td>
                                      <div className="dkg-clentlinked-switch">
                                        <Switch
                                          handleToggle={interviewToggle}
                                          id={item.id}
                                          value="interview"
                                          name={item.categoryName}
                                          isChecked={
                                            item.matched === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="main-joboffer-tab"
                      className="dkg-cl-info-main-tabpane"
                    >
                      <div className="dkg-company-list-mainCon">
                        <div className="dkg-company-list-tableCon table-responsive">
                          <table className="dkg-company-list-table table table-borderded">
                            <thead>
                              <tr>
                                <th>JOb Offer</th>
                                <th>Linked</th>
                              </tr>
                            </thead>
                            <tbody>
                              {jobofferArray.length > 0 &&
                                jobofferArray.map((item, index) => (
                                  <tr key={index + 1}>
                                    <td>{item.categoryName}</td>
                                    <td>
                                      <div className="dkg-clentlinked-switch">
                                        <Switch
                                          handleToggle={jobofferToggle}
                                          id={item.id}
                                          value="joboffer"
                                          name={item.categoryName}
                                          isChecked={
                                            item.matched === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="main-salaries-tab"
                      className="dkg-cl-info-main-tabpane"
                    >
                      <div className="dkg-company-list-mainCon">
                        <div className="dkg-company-list-tableCon table-responsive">
                          <table className="dkg-company-list-table table table-borderded">
                            <thead>
                              <tr>
                                <th>Relocation</th>
                                <th>Linked</th>
                              </tr>
                            </thead>
                            <tbody>
                              {relocationArray.length > 0 &&
                                relocationArray.map((item, index) => (
                                  <tr key={index + 1}>
                                    <td>{item.categoryName}</td>
                                    <td>
                                      <div className="dkg-clentlinked-switch">
                                        <Switch
                                          handleToggle={relocationToggle}
                                          id={item.id}
                                          value="reloction"
                                          name={item.categoryName}
                                          isChecked={
                                            item.matched === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="main-portal-tab"
                      className="dkg-cl-info-main-tabpane"
                    >
                      <div className="dkg-company-list-mainCon">
                        <div className="dkg-company-list-tableCon table-responsive">
                          <table className="dkg-company-list-table table table-borderded">
                            <thead>
                              <tr>
                                <th>Portal</th>
                                <th>Linked</th>
                              </tr>
                            </thead>
                            <tbody>
                              {portalArray.length > 0 &&
                                portalArray.map((item, index) => (
                                  <tr key={index + 1}>
                                    <td>{item.categoryName}</td>
                                    <td>
                                      <div className="dkg-clentlinked-switch">
                                        <Switch
                                          handleToggle={portalsToggle}
                                          id={item.id}
                                          value="portals"
                                          name={item.categoryName}
                                          isChecked={
                                            item.matched === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="main-files-tab"
                      className="dkg-cl-info-main-tabpane"
                    >
                      <JobFileComponent companyid={commpanyId} />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="main-faq-tab"
                      className="dkg-cl-info-main-tabpane"
                    >
                      {/* <ClientFaq companyid={commpanyId} company={comName} /> */}
                      <div className="dkg-company-list-mainCon">
                        <div className="dkg-company-list-tableCon table-responsive">
                          <table className="dkg-company-list-table table table-borderded">
                            <thead>
                              <tr>
                                <th>FAQ</th>
                                <th>Linked</th>
                              </tr>
                            </thead>
                            <tbody>
                              {faqsArray.length > 0 &&
                                faqsArray.map((item, index) => (
                                  <tr key={index + 1}>
                                    <td>{item.name}</td>
                                    <td>
                                      <div className="dkg-clentlinked-switch">
                                        <Switch
                                          handleToggle={faqsToggle}
                                          id={item.id}
                                          value="faqs"
                                          name={item.name}
                                          isChecked={
                                            item.matched === "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
