import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Facebook from "../../../../../../../assets/images/facebook.png";
import Linkedin from "../../../../../../../assets/images/linkedin.png";
import Instagram from "../../../../../../../assets/images/instagram.png";
import Youtube from "../../../../../../../assets/images/youtube.png";
import TwitterLogo from "../../../../../../../assets/images/twitter-new.png";
import SubmitButton from "../../../../../../ui/submitButton";
import { updateCompanyJobSpec } from "../../../../../../../slice/cmsSlice";
import "./socail-media.scss";
import { getCategories } from "../../../../../../../slice/clieninfo/requirnment/requirnmentSlice";
import {
  //showError,
  showSuccess,
} from "../../../../../../../components/utils/messages/messageSlice";

const CompanySocailMedia = ({ companySocialMedia, selectedItem }) => {
  const dispatch = useDispatch();
  const { issUpdate } = useSelector((state) => state.cms);
  useEffect(() => {
    if (issUpdate) {
      dispatch(showSuccess({ msg: "URL updated successfully" }));
      dispatch(getCategories({}));
    }
  }, [issUpdate]);

  /******* */
  const [inputs, setInputs] = useState({
    fbUrl: "",
    instagramUrl: "",
    linkdinUrl: "",
    twitterUrl: "",
    youtubeUrl: "",
  });

  useEffect(() => {
    if (selectedItem) {
      setInputs({
        fbUrl: selectedItem[0].fbUrl != null ? selectedItem[0].fbUrl : "",
        instagramUrl:
          selectedItem[0].instagramUrl != null
            ? selectedItem[0].instagramUrl
            : "",
        linkdinUrl:
          selectedItem[0].linkdinUrl != null ? selectedItem[0].linkdinUrl : "",
        twitterUrl:
          selectedItem[0].twitterUrl != null ? selectedItem[0].twitterUrl : "",
        youtubeUrl:
          selectedItem[0].youtubeUrl != null ? selectedItem[0].youtubeUrl : "",
      });
    }
  }, [selectedItem]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  /******* */

  const [fbUrl, setfbUrl] = useState("");
  const [linkdinUrl, setlinkdinUrl] = useState("");
  const [instaUrl, setInstaUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");

  const SubmitBtn = (data) => {
    switch (data) {
      case "fbUrl":
        dispatch(
          updateCompanyJobSpec({
            id: selectedItem[0].id,
            key: "fbUrl",
            value: fbUrl,
          })
        );
        break;
      case "linkdinUrl":
        dispatch(
          updateCompanyJobSpec({
            id: selectedItem[0].id,
            key: "linkdinUrl",
            value: linkdinUrl,
          })
        );
        break;
      case "instagramUrl":
        dispatch(
          updateCompanyJobSpec({
            id: selectedItem[0].id,
            key: "instagramUrl",
            value: instaUrl,
          })
        );
        break;
      case "youtubeUrl":
        dispatch(
          updateCompanyJobSpec({
            id: selectedItem[0].id,
            key: "youtubeUrl",
            value: youtubeUrl,
          })
        );
        break;
      case "twitterUrl":
        dispatch(
          updateCompanyJobSpec({
            id: selectedItem[0].id,
            key: "twitterUrl",
            value: twitterUrl,
          })
        );
        break;
    }
  };
  return (
    <>
      <div className="dkg-company-sm-mainCon">
        <div className="dkg-jopspec-pageheader">
          <div className="dkg-jopspec-page-title">Media</div>
        </div>
        <div className="dkg-company-sm-Con">
          <div className="dkg-pagetype-mainRow row">
            <div className="col-12 dkg-pagetype-mainCol">
              <div className="dkg-social-box-cols">
                <div className="dkg-social-media-box">
                  <div className="dkg-socail-media-icon">
                    <div className="dkg-company-sm-imgcon">
                      <img
                        src={Facebook}
                        alt="Facebook"
                        className="dkg-company-sm-img"
                      />
                    </div>
                  </div>
                  <div className="dkg-company-sm-nameurl-con">
                    <div className="dkg-timeflex">
                      <small className="dkg-social-media-name">
                        <input
                          type="text"
                          name="fbUrl"
                          className="form-control dkg-social-name-iput"
                          value={inputs.fbUrl}
                          onChange={(e) => {
                            handleChange(e);
                            setfbUrl(e.target.value);
                          }}
                        />
                      </small>
                    </div>
                  </div>
                  <div className="dkg-send-urlbtn-con">
                    <div className="dkg-send-urlbtn-flex">
                      <small className="dkg-social-media-name-btn">
                        <SubmitButton
                          txt="Save"
                          className="dkg-send-urlbtn btn"
                          onClick={() => SubmitBtn("fbUrl")}
                        />
                      </small>
                    </div>
                  </div>
                </div>
                <div className="dkg-social-media-box">
                  <div className="dkg-socail-media-icon">
                    <div className="dkg-company-sm-imgcon">
                      <img
                        src={Linkedin}
                        alt="Linkedin"
                        className="dkg-company-sm-img"
                      />
                    </div>
                  </div>
                  <div className="dkg-company-sm-nameurl-con">
                    <div className="dkg-timeflex">
                      <small className="dkg-social-media-name">
                        <input
                          type="text"
                          className="form-control dkg-social-name-iput"
                          name="linkdinUrl"
                          value={inputs.linkdinUrl}
                          onChange={(e) => {
                            handleChange(e);
                            setlinkdinUrl(e.target.value);
                          }}
                        />
                      </small>
                    </div>
                  </div>
                  <div className="dkg-send-urlbtn-con">
                    <div className="dkg-send-urlbtn-flex">
                      <small className="dkg-social-media-name-btn">
                        <SubmitButton
                          txt="Save"
                          className="dkg-send-urlbtn btn"
                          onClick={() => SubmitBtn("linkdinUrl")}
                        />
                      </small>
                    </div>
                  </div>
                </div>
                <div className="dkg-social-media-box">
                  <div className="dkg-socail-media-icon">
                    <div className="dkg-company-sm-imgcon">
                      <img
                        src={Instagram}
                        alt="Youtube"
                        className="dkg-company-sm-img"
                      />
                    </div>
                  </div>
                  <div className="dkg-company-sm-nameurl-con">
                    <div className="dkg-timeflex">
                      <small className="dkg-social-media-name">
                        <input
                          type="text"
                          className="form-control dkg-social-name-iput"
                          name="instagramUrl"
                          value={inputs.instagramUrl}
                          onChange={(e) => {
                            handleChange(e);
                            setInstaUrl(e.target.value);
                          }}
                        />
                      </small>
                    </div>
                  </div>
                  <div className="dkg-send-urlbtn-con">
                    <div className="dkg-send-urlbtn-flex">
                      <small className="dkg-social-media-name-btn">
                        <SubmitButton
                          txt="Save"
                          className="dkg-send-urlbtn btn"
                          onClick={() => SubmitBtn("instagramUrl")}
                        />
                      </small>
                    </div>
                  </div>
                </div>
                <div className="dkg-social-media-box">
                  <div className="dkg-socail-media-icon">
                    <div className="dkg-company-sm-imgcon">
                      <img
                        src={Youtube}
                        alt="Youtube"
                        className="dkg-company-sm-img"
                      />
                    </div>
                  </div>
                  <div className="dkg-company-sm-nameurl-con">
                    <div className="dkg-timeflex">
                      <small className="dkg-social-media-name">
                        <input
                          type="text"
                          className="form-control dkg-social-name-iput"
                          // defaultValue={
                          //   companySocialMedia.length > 0
                          //     ? companySocialMedia[0].youtubeUrl
                          //     : ""
                          // }
                          // onChange={(e) => setYoutubeUrl(e.target.value)}
                          name="youtubeUrl"
                          value={inputs.youtubeUrl}
                          onChange={(e) => {
                            handleChange(e);
                            setYoutubeUrl(e.target.value);
                          }}
                        />
                      </small>
                    </div>
                  </div>
                  <div className="dkg-send-urlbtn-con">
                    <div className="dkg-send-urlbtn-flex">
                      <small className="dkg-social-media-name-btn">
                        <SubmitButton
                          txt="Save"
                          className="dkg-send-urlbtn btn"
                          onClick={() => SubmitBtn("youtubeUrl")}
                        />
                      </small>
                    </div>
                  </div>
                </div>
                <div className="dkg-social-media-box">
                  <div className="dkg-socail-media-icon">
                    <div className="dkg-company-sm-imgcon">
                      <img
                        src={TwitterLogo}
                        alt="DK Global Website"
                        className="dkg-company-sm-img"
                      />
                    </div>
                  </div>
                  <div className="dkg-company-sm-nameurl-con">
                    <div className="dkg-timeflex">
                      <small className="dkg-social-media-name">
                        <input
                          type="text"
                          className="form-control dkg-social-name-iput"
                          name="twitterUrl"
                          value={inputs.twitterUrl}
                          onChange={(e) => {
                            handleChange(e);
                            setTwitterUrl(e.target.value);
                          }}
                        />
                      </small>
                    </div>
                  </div>
                  <div className="dkg-send-urlbtn-con">
                    <div className="dkg-send-urlbtn-flex">
                      <small className="dkg-social-media-name-btn">
                        <SubmitButton
                          txt="Save"
                          className="dkg-send-urlbtn btn"
                          onClick={() => SubmitBtn("twitterUrl")}
                        />
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanySocailMedia;
