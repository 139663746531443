import React, { useEffect, useState, useRef } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import { Dropdown } from 'react-bootstrap';
import "./selectdropdown.scss"

// const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
//     < a
//         href="/#"
//         ref={ref}
//         onClick={(e) => {
//             e.preventDefault();
//             onClick(e);
//         }}
//     >
//         {children}
//     </a >
// ));

const CustomMenu = React.forwardRef(
    ({ isSelected, children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const searchInput = useRef(null);
        const [value, setValue] = useState('');

        useEffect(() => {
            if (isSelected) {
                setValue('')
                searchInput.current.focus();
            }
        }, [isSelected])

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className=""
                    placeholder="Search Text..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    ref={searchInput}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toString().toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);

function SearchableDropdown(props) {
    const [data, setData] = useState(false)
    return (
        <>
            <Dropdown className="ddl-main" onSelect={function (evt, value) {
                setData(true)
                props.updateValue(evt);
            }}>
                <Dropdown.Toggle id={props.id} className="ddl-btn" variant="">{props.text}</Dropdown.Toggle>
                <Dropdown.Menu variant="" className='visible menu dkg-rec-srch-drop-menu' as={CustomMenu} isSelected={data}>
                    {props.list.filter((data) => data.inStatus == 1).map((item, index) => (
                        <Dropdown.Item eventKey={item.name} key={index + 1}>
                            {item.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default SearchableDropdown = React.memo(SearchableDropdown);