import React from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../../../elements/header/Header'
import Navbar from "../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../elements/submenu/mainmenu/DashboardSubmenu';
// import './trainingmodule.scss';
import FaqImgIcon from "../../../../assets/images/information_white.png";
import RecruitmentImg from "../../../../assets/images/recruitment.png";
import AdvertisingImg from "../../../../assets/images/ads.png";
import ManagementIcon from "../../../../assets/images/girlmanager.png";
import AccountingFinanceImg from "../../../../assets/images/finance.png";
import CommonImgIcon from "../../../../assets/images/onboarding_white.png";
import CalendarImg from "../../../../assets/images/calendar-white.png";
import LegalDoc from "../../../../assets/images/legal-doc.png";
import ClientsImg from "../../../../assets/images/clientsmanagement_icon.png";


const TrainingModule = () => {
    return (
        <>
            <AdminHeader dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="Training" moduleLink="/dkglobaladmin/supplier/dashboard" linkcolor="#3c4c62" />
            <Navbar />
            <Submenu />
            <div className="dk-master-moduleCon">
                <div className="dk-module-blocksContainer dkg-newtraining-department-234">
                    <h4 className="title">Training Department</h4>
                    <div className="dk-portalCols">
                        <div className="dk-portalBox" style={{ background: '#3c97b6' }}>
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={CalendarImg} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}>Schedule</div>
                                </div>
                            </Link>
                        </div>

                        <div className="dk-portalBox" style={{ background: '#3c97b6' }}>
                            <Link to="/training/commoan-training/dashboard">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={CommonImgIcon} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}> Common</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox" style={{ background: '#3c97b6' }}>
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={FaqImgIcon} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}> FAQ</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="dk-portalCols">
                        <div className="dk-portalBox" style={{ background: '#396a95' }}>
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={RecruitmentImg} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}> Recruitment</div>
                                </div>
                            </Link>
                        </div>

                        <div className="dk-portalBox" style={{ background: '#396a95' }}>
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={AccountingFinanceImg} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}> Accounting</div>
                                </div>
                            </Link>
                        </div>

                        <div className="dk-portalBox" style={{ background: '#396a95' }}>
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={ClientsImg} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}>Clients</div>
                                </div>
                            </Link>
                        </div>

                    </div>
                    <div className="dk-portalCols">
                        <div className="dk-portalBox" style={{ background: '#396a95' }}>
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={AdvertisingImg} alt="" />

                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}>Advertisement</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox" style={{ background: '#396a95' }}>
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={ManagementIcon} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}>Employees</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox" style={{ background: '#396a95' }}>
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={LegalDoc} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}>Legal</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrainingModule