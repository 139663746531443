import React from 'react'
import AdminHeader from '../../../../elements/header/Header'
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../../elements/submenu/mainmenu/CandAccSubmenu';
import FAQComponents from './category/CompanyTable'
import './job-faq.scss'

const CandidateFaq = () => {
    return (
        <>
            <AdminHeader />
            <Navbar />
            <Submenu />
            <div className='dkg-jobfaq-mainCon dkg-jobseeker-faq-maincon'>
                <FAQComponents />
            </div>
        </>
    )
}

export default CandidateFaq;