import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./referrals.scss";
import { Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import { FaInfo, FaSearch } from "react-icons/fa";
import TeleperformanceLogo from '../../../../../../assets/images/teleperformance.png'
import RightModalFilter from "./modal/rightModalFilter";
import ViewtermsModal from "./modal/view/ViewtermsModal";
import ApplytermsModal from "./modal/view/ApplytermsModal";
// import MoreInfoPopover from "./MoreInfoPopover";
import { getCMSHeadingText } from "../../../../../../slice/frontendCms/frontendCmsSlice";
import { FaInfoCircle } from "react-icons/fa";


const ClosedAds = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList } = useSelector((state) => state.cmslist);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setData(getcmsList);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCMSHeadingText({}));
      } catch (err) {}
    }
    fetchData();
  }, [dispatch]);

  const [isViewTerms, setIsViewTerms] = useState(false);
  const [isApplyTerm, setIsApplyTerm] = useState(false);
  const showtermsviewModal = () => {
    setIsViewTerms(true);
  };
  const hidetermsviewModal = () => {
    setIsViewTerms(false);
  };
  const showtermsapplyModal = () => {
    setIsApplyTerm(true);
  };
  const hidetermsapplyModal = () => {
    setIsApplyTerm(false);
  };
  return (
    <>
      {isViewTerms ? <ViewtermsModal onHide={hidetermsviewModal} /> : null}
      {isApplyTerm ? <ApplytermsModal onHide={hidetermsapplyModal} /> : null}
      <div className="dkg-referrals-mainCon">
        <div className="dkg-referrals-content">
          <div className="dkg-referrals-mainRow">
            <div className="col-md-12 col-12 dkg-referrals-mainCol">
              <div className="dkg-refreral-srchinputCon">
                <div className="dkg-refreral-srchinput">
                  <input
                    type="text"
                    className="dkg-search-input"
                    placeholder="Search…"
                  />
                  <span className="dkg-search-icon">
                    <FaSearch />
                  </span>
                </div>
              </div>
              <div className="dkg-prefrefreal-page-header">
                <h2 className="dkg-services-page-title">
                  CLOSED REFERRAL
                   {" "}
                  <span className='dkg-infoicon'><FaInfoCircle /></span> 
                </h2>
              </div>
              <div className="dkg-referrals-filter-Con">
                <RightModalFilter />
              </div>
            </div>
            <div className="col-md-12 col-12 dkg-referrals-Cols">
              <div className="dkg-referrals-tableCon">
                <table className="table dkg-referrals-table table-bordered">
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Posted</th>
                      <th>Company</th>
                      <th>Job Title</th>
                      <th>Amount</th>
                      <th>Payments</th>
                      <th>Terms</th>
                      <th>Apply</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div
                          className="dkg-referal-status-bg-cols"
                          style={{ backgroundColor: "#CA464F", color: "#fff" }}
                        >
                          <div className="dkg-referal-status-title">Closed</div>
                        </div>
                      </td>
                      <td>Yesterday</td>
                      <td>
                        <div className="dkg-refreral-comapny-logoCon">
                          <img
                            src={TeleperformanceLogo}
                            className="dkg-refreral-comapny-logo"
                            alt="Company Logo"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="dkg-referal-jobtitle-cols">
                          <h5 className="dkg-referal-jobtitle">
                            German Technical Support Role in Greece
                          </h5>
                          <div className="dkg-referal-bottom-sec">
                            <ul className="dkg-referal-bottom-ul">
                              <li className="dkg-referal-bottom-li">
                                <span className="dkg-referal-li-icon">
                                  <i className="far fa fa-building"></i>
                                </span>
                                WH Greece
                              </li>
                              <li className="dkg-referal-bottom-li">
                                <span className="dkg-referal-li-icon">
                                  <i className="far fa fa-building"></i>
                                </span>
                                Elastic Search
                              </li>
                            </ul>
                            <ul className="dkg-referal-bottom-ul">
                              <li className="dkg-referal-bottom-li">
                                <span className="dkg-referal-li-icon">
                                  <i className="far fa fa-user"></i>
                                </span>
                                Sales Advisor
                              </li>
                              <li className="dkg-referal-bottom-li">
                                <span className="dkg-referal-li-icon">
                                  <i className="far fa fa-language"></i>
                                </span>
                                French
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>

                      <td>3 Months</td>
                      <td>3 Installment</td>
                      <td>
                        <div className="dkg-buy-btn-con text-center w-100">
                          <Link
                            to="#"
                            className="btn dkg-buy-btn"
                            onClick={showtermsviewModal}
                          >
                            View
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div className="dkg-buy-btn-con text-center w-100">
                          <Link
                            to="#"
                            className="btn dkg-buy-btn"
                            onClick={showtermsapplyModal}
                          >
                            Apply
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div
                          className="dkg-referal-status-bg-cols"
                          style={{ backgroundColor: "#CA464F", color: "#fff" }}
                        >
                          <div className="dkg-referal-status-title">Closed</div>
                        </div>
                      </td>
                      <td>5 days</td>
                      <td>
                        <div className="dkg-refreral-comapny-logoCon">
                          <img
                            src={TeleperformanceLogo}
                            className="dkg-refreral-comapny-logo"
                            alt="Company Logo"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="dkg-referal-jobtitle-cols">
                          <h5 className="dkg-referal-jobtitle">
                            German Technical Support Role in Greece
                          </h5>
                          <div className="dkg-referal-bottom-sec">
                            <ul className="dkg-referal-bottom-ul">
                              <li className="dkg-referal-bottom-li">
                                <span className="dkg-referal-li-icon">
                                  <i className="far fa fa-building"></i>
                                </span>
                                WH Greece
                              </li>
                              <li className="dkg-referal-bottom-li">
                                <span className="dkg-referal-li-icon">
                                  <i className="far fa fa-building"></i>
                                </span>
                                Elastic Search
                              </li>
                            </ul>
                            <ul className="dkg-referal-bottom-ul">
                              <li className="dkg-referal-bottom-li">
                                <span className="dkg-referal-li-icon">
                                  <i className="far fa fa-user"></i>
                                </span>
                                Sales Advisor
                              </li>
                              <li className="dkg-referal-bottom-li">
                                <span className="dkg-referal-li-icon">
                                  <i className="far fa fa-language"></i>
                                </span>
                                French
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>

                      <td>3 Months</td>
                      <td>3 Installment</td>
                      <td>
                        <div className="dkg-buy-btn-con text-center w-100">
                          <Link
                            to="#"
                            className="btn dkg-buy-btn"
                            onClick={showtermsviewModal}
                          >
                            View
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div className="dkg-buy-btn-con text-center w-100">
                          <Link
                            to="#"
                            className="btn dkg-buy-btn"
                            onClick={showtermsapplyModal}
                          >
                            Apply
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClosedAds;
