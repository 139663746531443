import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// function start

export const getcompanydetails = createAsyncThunk(
    "cmslist/companydetails",
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/jobSpec/company/companyd/" + id,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const listData = createAsyncThunk(
    "cmslist/textchange",
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/mangecontent",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);


export const getAlbumCover = createAsyncThunk(
    "cmslist/getcoverimg",
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/requirnments-category/coverimg/" + id,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getAlbumImgJobSpec = createAsyncThunk(
    "cmslist/getimg",
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/requirnments-category/albumimg/" + id,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateTitleAlbum = createAsyncThunk(
    "cmslist/updateTitleAlb",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/requirnments-category/updatealbumtitle/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                    body: JSON.stringify({
                        key, value
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateAlbumImg = createAsyncThunk(
    "cmslist/updateAlbmimg",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/requirnments-category/updateimg/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                    body: JSON.stringify({
                        key, value
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateVideoCompanyJobSpec = createAsyncThunk(
    "cmslist/videocompspec",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/jobSpec/company/viedo/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                    body: JSON.stringify({
                        key, value
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateVideoComJobSpec = createAsyncThunk(
    "cmslist/videoCompcompspec",
    async ({ id, youtubeurl, videoTitle }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/jobSpec/company/update-spec/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                    body: JSON.stringify({
                        youtubeurl, videoTitle
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateCompanyJobSpec = createAsyncThunk(
    "cmslist/compspec",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/jobSpec/company/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                    body: JSON.stringify({
                        key, value
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateData = createAsyncThunk(
    "cmslist/headinText",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/mangecontent/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                    body: JSON.stringify({
                        key, value
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateNotification = createAsyncThunk(
    "cmslist/notification/headinText",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/mangecontent/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                    body: JSON.stringify({
                        key, value
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const bulkUpdateData = createAsyncThunk(
    "cmslist/headinText",
    async ({ id, sendusCompany, sendusAddress, sendusEmail, sendusPhone }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/mangecontent/" + id,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                    body: JSON.stringify({
                        id, sendusCompany, sendusAddress, sendusEmail, sendusPhone
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateProjectData = createAsyncThunk(
    "cmslist/updateprojects",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/mangecontent/project-info/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                    body: JSON.stringify({
                        key, value
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const projectUpdateData = createAsyncThunk(
    "cmslist/updatesprojects",
    async ({ id, title, details }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/mangecontent/projects-update/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                    body: JSON.stringify({
                        title, details
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getprojectData = createAsyncThunk(
    "cmslist/project",
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/mangecontent/project-info",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const usersSlice = createSlice({
    name: "frontendcms",
    initialState: {
        getcmsList: [],
        companyjobspList: {},
        imgAlbumList: [],
        projectDataList: [],
        videoAlbumList: [],
        coverAlbumList: [],
        issSuccess: false,
        isSuccess: false,
        isUpdate: false,
        isLoading: false,
        isError: false,
        isDelete: false,
        isAlbum: false,
        issUpdate: false,
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.issSuccess = false;
            state.isSuccess = false;
            state.isError = false;
            state.isUpdate = false;
            state.issUpdate = false;
            state.isDelete = false;
            state.isAlbum = false;
        },
    },
    extraReducers: {

        [bulkUpdateData.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
            state.issUpdate = false;
            state.isError = false;
        },
        [bulkUpdateData.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.isUpdate = true;
            state.getcmsList = payload.result;
        },
        [bulkUpdateData.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateData.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
            state.isError = false;
        },
        [updateData.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = false;
            state.isUpdate = true;
            state.getcmsList = payload.result;
        },
        [updateData.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateCompanyJobSpec.pending]: (state) => {
            state.isLoading = true;
            state.issUpdate = false;
            state.isError = false;
        },
        [updateCompanyJobSpec.fulfilled]: (state, { payload }) => {
            //state.isLoading = false;
            state.isError = false;
            state.issUpdate = true;
        },
        [updateCompanyJobSpec.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.issSuccess = false;
        },

        [updateNotification.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updateNotification.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = false;
            state.getcmsList = payload.result;
        },
        [updateNotification.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [projectUpdateData.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
            state.isError = false;
        },
        [projectUpdateData.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = false;
            state.isUpdate = true;
            state.projectDataList = payload.result;
        },
        [projectUpdateData.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateProjectData.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
            state.isError = false;
        },
        [updateProjectData.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = false;
            state.isUpdate = true;
            state.projectDataList = payload.result;
        },
        [updateProjectData.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateTitleAlbum.pending]: (state) => {
            state.issSuccess = true;
            state.isError = false;
            state.isAlbum = false;
        },
        [updateTitleAlbum.fulfilled]: (state, { payload }) => {
            state.issSuccess = false;
            state.isAlbum = true;
            state.imgAlbumList = payload.result;
        },
        [updateTitleAlbum.rejected]: (state, { payload }) => {
            state.isError = true;
            state.issSuccess = false;
            state.isAlbum = false;
        },
        [updateAlbumImg.pending]: (state) => {
            // state.isLoading = true;
            state.issSuccess = true;
            state.isError = false;
            state.isAlbum = false;
        },
        [updateAlbumImg.fulfilled]: (state, { payload }) => {
            //state.isLoading = false;
            state.issSuccess = false;
            state.isAlbum = true;
            state.imgAlbumList = payload.result;
        },
        [updateAlbumImg.rejected]: (state, { payload }) => {
            state.isError = true;
            //state.isLoading = false;
            state.issSuccess = false;
            state.isAlbum = false;
        },

        [updateVideoComJobSpec.pending]: (state) => {
            // state.isLoading = true;
            state.issSuccess = true;
            state.isError = false;
        },
        [updateVideoComJobSpec.fulfilled]: (state, { payload }) => {
            //state.isLoading = false;
            state.issSuccess = false;
            state.videoAlbumList = payload.result;
        },
        [updateVideoComJobSpec.rejected]: (state, { payload }) => {
            state.isError = true;
            //state.isLoading = false;
            state.issSuccess = false;
        },
        [updateVideoCompanyJobSpec.pending]: (state) => {
            // state.isLoading = true;
            state.issSuccess = true;
            state.isError = false;
        },
        [updateVideoCompanyJobSpec.fulfilled]: (state, { payload }) => {
            //state.isLoading = false;
            state.issSuccess = false;
            state.videoAlbumList = payload.result;
        },
        [updateVideoCompanyJobSpec.rejected]: (state, { payload }) => {
            state.isError = true;
            //state.isLoading = false;
            state.issSuccess = false;
        },
        [getAlbumCover.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getAlbumCover.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.coverAlbumList = payload.result;
        },
        [getAlbumCover.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
        },
        [getAlbumImgJobSpec.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            //state.isAlbum = false;
        },
        [getAlbumImgJobSpec.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            //state.isAlbum = true;
            state.imgAlbumList = payload.result;
        },
        [getAlbumImgJobSpec.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            //state.isAlbum = false;
        },

        [getcompanydetails.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getcompanydetails.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.companyjobspList = payload.result;
        },
        [getcompanydetails.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [listData.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [listData.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.getcmsList = payload.result;
        },
        [listData.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [getprojectData.pending]: (state) => {
            state.isSuccess = true;
            state.isError = false;
        },
        [getprojectData.fulfilled]: (state, { payload }) => {
            state.isSuccess = false;
            state.projectDataList = payload.result;
        },
        [getprojectData.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isSuccess = false;
        },
    },
});
export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
