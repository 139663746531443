import React from 'react'
import AdminHeader from "../../../../components/elements/header/Header";
import AdminNavbar from "../../../elements/navbar/mainmenu/MainNavbar";
import SettingSubmenu from '../../submenu/SettingSubmenu';

const index = () => {
  return (
    <>
        <AdminHeader />
        <AdminNavbar />
        <SettingSubmenu />
    </>
  )
}

export default index