import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav, InputGroup, FormControl } from "react-bootstrap";
import SubmitButton from "../../../../ui/submitButton";
import { listData, updateData } from "../../../../../slice/cmsSlice";
import { showError } from "../../../../utils/messages/messageSlice";

const SecondSideResume = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList } = useSelector((state) => state.cms);
  const [overviewData, setOverviewData] = useState("");

  const [company, setCompany] = useState("");
  const [registrationAt, setRegistrationAt] = useState("");
  const [registrationNo, setRegistrationNo] = useState("");
  const [vatNo, setVatNo] = useState("");
  const [headOffice, setHeadOffice] = useState("");
  const [industry, setIndustry] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [recruitmentIn, setRecruitmentIn] = useState("");
  const [otherServices, setOtherServices] = useState("");
  const [clientsLocation, setClientsLocation] = useState("");
  const [servicesLocation, setServicesLocation] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(listData({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);
  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setOverviewData(getcmsList[1].description);
      setCompany(getcmsList[1].companyName);
      setRegistrationAt(getcmsList[1].registrationAt);
      setRegistrationNo(getcmsList[1].registrationNo);
      setVatNo(getcmsList[1].vatNo);
      setHeadOffice(getcmsList[1].headOffice);
      setIndustry(getcmsList[1].industry);
      setBusinessType(getcmsList[1].businessType);
      setRecruitmentIn(getcmsList[1].recruitmentIn);
      setOtherServices(getcmsList[1].otherServices);
      setClientsLocation(getcmsList[1].clientsLocation);
      setServicesLocation(getcmsList[1].servicesLocation);
      setCompanyWebsite(getcmsList[1].companyWebsite);
    }
  }, [isLoading]);

  const SubmitBtn = () => {
    dispatch(
      updateData({
        id: 2,
        key: "description",
        value: overviewData,
      })
    );
  };

  const updateHandle = (id, val, key) => {
    dispatch(
      updateData({
        id,
        key,
        value: val,
      })
    );
  };
  return (
    <>
      <div className="dkg-cand-services-Side">
        <Tab.Container defaultActiveKey="first">
          <div className="row m-0 dkg-payment-qt-tab-mainCon">
            <div className="col-md-2 pl-0 dkg-payment-qt-tabCon dkg-profile-task-sideMenu">
              <Nav
                variant="pills"
                className="flex-column dkg-payment-qt-tab-navpills"
              >
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="first"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Instructions
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="second"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Information
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-md-10  dkg-paidservices-rightCon">
              <Tab.Content className="dkg-paidservices-tabcontent">
                <Tab.Pane eventKey="first" className="dkg-paidservices-tabpane">
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Summary</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <textarea
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue={overviewData != "" ? overviewData : ""}
                      onChange={(e) => setOverviewData(e.target.value)}
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                        onClick={SubmitBtn}
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Information</h4>
                  </div>
                  <div className="dkg-paidservices-body dkg-admin-setting-body pt-3">
                    <div className="dkg-candidate-Row row">
                      <div className="col-md-12 col-sm-12 dkg-candidate-leftCon">
                        <div className="dkg-cand-form-group form-group">
                          <InputGroup className="dkg-candidate-inputgrp">
                            <InputGroup.Prepend className="dkg-inputgrp-prepared">
                              <InputGroup.Text className="dkg-inputgrp-text">
                                Name of Company
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="DK Global Recruitment Limited"
                              aria-label="nameofcompany"
                              className="dkg-cand-frmcontrol"
                              defaultValue={company}
                              onChange={(e) =>
                                updateHandle(2, e.target.value, "companyName")
                              }
                            />
                          </InputGroup>
                        </div>
                        <div className="dkg-cand-form-group form-group">
                          <InputGroup className="dkg-candidate-inputgrp">
                            <InputGroup.Prepend className="dkg-inputgrp-prepared">
                              <InputGroup.Text className="dkg-inputgrp-text">
                                Registration At
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="Dublin, Ireland"
                              aria-label="head-office"
                              className="dkg-cand-frmcontrol"
                              defaultValue={registrationAt}
                              onChange={(e) =>
                                updateHandle(
                                  2,
                                  e.target.value,
                                  "registrationAt"
                                )
                              }
                            />
                          </InputGroup>
                        </div>
                        <div className="dkg-cand-form-group form-group">
                          <InputGroup className="dkg-candidate-inputgrp">
                            <InputGroup.Prepend className="dkg-inputgrp-prepared">
                              <InputGroup.Text className="dkg-inputgrp-text">
                                Registration No.
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="590583"
                              aria-label="registration"
                              className="dkg-cand-frmcontrol"
                              defaultValue={registrationNo}
                              onChange={(e) =>
                                updateHandle(
                                  2,
                                  e.target.value,
                                  "registrationNo"
                                )
                              }
                            />
                          </InputGroup>
                        </div>
                        <div className="dkg-cand-form-group form-group">
                          <InputGroup className="dkg-candidate-inputgrp">
                            <InputGroup.Prepend className="dkg-inputgrp-prepared">
                              <InputGroup.Text className="dkg-inputgrp-text">
                                Company Vat No.
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="IE 3438468BH"
                              aria-label="vat-number"
                              className="dkg-cand-frmcontrol"
                              defaultValue={vatNo}
                              onChange={(e) =>
                                updateHandle(2, e.target.value, "vatNo")
                              }
                            />
                          </InputGroup>
                        </div>
                        <div className="dkg-cand-form-group form-group">
                          <InputGroup className="dkg-candidate-inputgrp">
                            <InputGroup.Prepend className="dkg-inputgrp-prepared">
                              <InputGroup.Text className="dkg-inputgrp-text">
                                Head Office
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="Dublin, Ireland"
                              aria-label="registered"
                              className="dkg-cand-frmcontrol"
                              defaultValue={headOffice}
                              onChange={(e) =>
                                updateHandle(2, e.target.value, "headOffice")
                              }
                            />
                          </InputGroup>
                        </div>
                        <div className="dkg-cand-form-group form-group">
                          <InputGroup className="dkg-candidate-inputgrp">
                            <InputGroup.Prepend className="dkg-inputgrp-prepared">
                              <InputGroup.Text className="dkg-inputgrp-text">
                                Work Industry
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="Professionals Services"
                              aria-label="industry"
                              className="dkg-cand-frmcontrol"
                              defaultValue={industry}
                              onChange={(e) =>
                                updateHandle(2, e.target.value, "industry")
                              }
                            />
                          </InputGroup>
                        </div>
                        <div className="dkg-cand-form-group form-group">
                          <InputGroup className="dkg-candidate-inputgrp">
                            <InputGroup.Prepend className="dkg-inputgrp-prepared">
                              <InputGroup.Text className="dkg-inputgrp-text">
                                Business Type
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="Recruitment Services"
                              aria-label="business-type"
                              className="dkg-cand-frmcontrol"
                              defaultValue={businessType}
                              onChange={(e) =>
                                updateHandle(2, e.target.value, "businessType")
                              }
                            />
                          </InputGroup>
                        </div>
                        <div className="dkg-cand-form-group form-group">
                          <InputGroup className="dkg-candidate-inputgrp">
                            <InputGroup.Prepend className="dkg-inputgrp-prepared">
                              <InputGroup.Text className="dkg-inputgrp-text">
                                Recruitment In
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="Multilingual Recruitment"
                              aria-label="specialized-in"
                              className="dkg-cand-frmcontrol"
                              defaultValue={recruitmentIn}
                              onChange={(e) =>
                                updateHandle(2, e.target.value, "recruitmentIn")
                              }
                            />
                          </InputGroup>
                        </div>
                        <div className="dkg-cand-form-group form-group">
                          <InputGroup className="dkg-candidate-inputgrp">
                            <InputGroup.Prepend className="dkg-inputgrp-prepared">
                              <InputGroup.Text className="dkg-inputgrp-text">
                                Other Services
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="Globally,Mainly Europe"
                              aria-label="services-area"
                              className="dkg-cand-frmcontrol"
                              defaultValue={otherServices}
                              onChange={(e) =>
                                updateHandle(2, e.target.value, "otherServices")
                              }
                            />
                          </InputGroup>
                        </div>
                        <div className="dkg-cand-form-group form-group">
                          <InputGroup className="dkg-candidate-inputgrp">
                            <InputGroup.Prepend className="dkg-inputgrp-prepared">
                              <InputGroup.Text className="dkg-inputgrp-text">
                                Clients Location
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="Bucharest,Romania"
                              aria-label="branch-office"
                              className="dkg-cand-frmcontrol"
                              defaultValue={clientsLocation}
                              onChange={(e) =>
                                updateHandle(
                                  2,
                                  e.target.value,
                                  "clientsLocation"
                                )
                              }
                            />
                          </InputGroup>
                        </div>
                        <div className="dkg-cand-form-group form-group">
                          <InputGroup className="dkg-candidate-inputgrp">
                            <InputGroup.Prepend className="dkg-inputgrp-prepared">
                              <InputGroup.Text className="dkg-inputgrp-text">
                                Services Location
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="Globally,Mainly Europe"
                              aria-label="client-base"
                              className="dkg-cand-frmcontrol"
                              defaultValue={servicesLocation}
                              onChange={(e) =>
                                updateHandle(
                                  2,
                                  e.target.value,
                                  "servicesLocation"
                                )
                              }
                            />
                          </InputGroup>
                        </div>
                        <div className="dkg-cand-form-group form-group">
                          <InputGroup className="dkg-candidate-inputgrp">
                            <InputGroup.Prepend className="dkg-inputgrp-prepared">
                              <InputGroup.Text className="dkg-inputgrp-text">
                                Company Website
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="https://www.dkglobalrecruitment.com/"
                              aria-label="website"
                              className="dkg-cand-frmcontrol"
                              defaultValue={companyWebsite}
                              onChange={(e) =>
                                updateHandle(
                                  2,
                                  e.target.value,
                                  "companyWebsite"
                                )
                              }
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default SecondSideResume;
