import React from 'react'
import { Modal } from "react-bootstrap";
import Submit from '../../../../../ui/submitButton'
import  './upload-video.scss'


const UpdateModal = ({ onHide }) => { 
  return (
    <>
        <Modal show={true} onHide={onHide} className="dkg-pagetype-uploadModal dkg-pagetype-album-uploadModal" centered>
        <Modal.Header closeButton className='dkg-video-upload-Header'>
          <Modal.Title className='w-100 text-center'>Add Images</Modal.Title>
        </Modal.Header>
        <Modal.Body className='dkg-video-upload-Body dkg-image-upload-Body'>
          <div className="dkg-album-upload-mainCon">
            <div className="dkg-album-upload-boxs">
              <div className="dkg-album-upload-cols">
                <div className="dkg-album-file-wrapper">
                  <input type="file" name="upload-img" accept="image/*" />
                </div>
              </div>
              <div className="dkg-album-upload-cols">
                <div className="dkg-album-file-wrapper">
                  <input type="file" name="upload-img" accept="image/*" />
                </div>
              </div>
              <div className="dkg-album-upload-cols">
                <div className="dkg-album-file-wrapper">
                  <input type="file" name="upload-img" accept="image/*" />
                </div>
              </div>
            </div>
            <div className="dkg-album-upload-boxs">
              <div className="dkg-album-upload-cols">
                <div className="dkg-album-file-wrapper">
                  <input type="file" name="upload-img" accept="image/*" />
                </div>
              </div>
              <div className="dkg-album-upload-cols">
                <div className="dkg-album-file-wrapper">
                  <input type="file" name="upload-img" accept="image/*" />
                </div>
              </div>
              <div className="dkg-album-upload-cols">
                <div className="dkg-album-file-wrapper">
                  <input type="file" name="upload-img" accept="image/*" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UpdateModal