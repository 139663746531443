import React from 'react';
import { Route, Link } from "react-router-dom";

const NotificationSubmenu = () => {
    const pathname = window.location.pathname;
    return (
        <div>
            <div className="dk-adminSubmenuMain">
                <Route>
                    <ul>
                        <li><Link to="/notifications/summary" className={`${pathname.match('/notifications/summary') ? 'sub-active' : ''}`}>Summary</Link></li>
                        <li><Link to="#/mailbox/mailbox" className={`${pathname.match('/mailbox/report') ? 'sub-active' : ''}`}><span className='dk-notiBadge mr-1'>0</span>Company</Link></li>
                        <li><Link to="#/mailbox/tasks" className={`${pathname.match('/mailbox/validation') ? 'sub-active' : ''}`}><span className='dk-notiBadge mr-1'>0</span>Employee</Link></li>
                        <li><Link to="#/mailbox/actions" className={`${pathname.match('/mailbox/mailshot') ? 'sub-active' : ''}`}><span className='dk-notiBadge mr-1'>0</span>Supplier</Link></li>
                        <li><Link to="#/mailbox/notification" className={`${pathname.match('/dashboard/templates') ? 'sub-active' : ''}`}><span className='dk-notiBadge mr-1'>0</span>Resource</Link></li>
                        <li><Link to="#/mailbox/summary" className={`${pathname.match('/mailbox/inbox') ? 'sub-active' : ''}`}><span className='dk-notiBadge mr-1'>0</span>Clients</Link></li>
                        <li><Link to="#/mailbox/summary" className={`${pathname.match('/mailbox/inbox') ? 'sub-active' : ''}`}> <span className='dk-notiBadge mr-1'>0</span>Jobs</Link></li>
                        <li><Link to="#/mailbox/notification" className={`${pathname.match('/dashboard/templates') ? 'sub-active' : ''}`}><span className='dk-notiBadge mr-1'>0</span>CRM</Link></li>
                        <li><Link to="#/mailbox/summary" className={`${pathname.match('/mailbox/inbox') ? 'sub-active' : ''}`}><span className='dk-notiBadge mr-1'>0</span>Traning</Link></li>
                    </ul>
                </Route>
            </div>
        </div>
    )
}

export default NotificationSubmenu;
