import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminHeader from "../../../../elements/header/Header";
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../elements/submenu/mainmenu/DashboardSubmenu";
import { FaEdit } from "react-icons/fa";
import ChangeIcon from "../../../../../assets/images/complain.png";
import "./main-pricipales.scss";
import EditTitleModal from "./modal/principale/EditTitle";
import {
  getTextGetStart,
  updateGetstartText,
} from "../../../../../slice/principals/principalsSlice";
import { showError } from "../../../../../components/utils/messages/messageSlice";
import AWS from "aws-sdk";
import { Buffer } from "buffer";
//import ReactCrop from 'react-image-crop';
import "react-image-crop/dist/ReactCrop.css";
// import SubmitButton from "../../../../../components/ui/submitButton";
// import Modal from 'react-bootstrap/Modal'

const Principales = () => {
  const dispatch = useDispatch();
  const { isLoading, getPrincipalsList } = useSelector(
    (state) => state.principals
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!isLoading && getPrincipalsList && getPrincipalsList.length > 0) {
      setData(getPrincipalsList);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getTextGetStart({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  const updateData = (val, id, keyVal) => {
    dispatch(
      updateGetstartText({
        id: id,
        key: keyVal,
        value: val,
      })
    );
  };

  const [firstImg, setFirstImg] = useState(true);
  const [firstImgbase64, setFirstImgbase64] = useState("");

  const [secondImg, setSecondImg] = useState(true);
  const [secondImgbase64, setSecondImgbase64] = useState("");

  const [thirdImg, setthirdImg] = useState(true);
  const [thirdImgbase64, setthirdImgbase64] = useState("");

  const [fourthImg, setfourthImg] = useState(true);
  const [fourthImgbase64, setfourthImgbase64] = useState("");

  const [fifthImg, setfifthImg] = useState(true);
  const [fifthImgbase64, setfifthImgbase64] = useState("");

  const [sixthImg, setSixthImg] = useState(true);
  const [sixthImgbase64, setSixthImgbase64] = useState("");

  const [sevenImg, setSevenImg] = useState(true);
  const [sevenImgbase64, setSevenImgbase64] = useState("");

  const [eightImg, seteightImg] = useState(true);
  const [eightImgbase64, seteightImgbase64] = useState("");

  const [nineImg, setnineImg] = useState(true);
  const [nineImgbase64, setnineImgbase64] = useState("");

  const [tenImg, settenImg] = useState(true);
  const [tenImgbase64, settenImgbase64] = useState("");

  const [ImgName, setImgName] = useState("");

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        if (e.target.name == "renImg") {
          settenImg(false);
          settenImgbase64(reader.result);
        }
        if (e.target.name == "nineImg") {
          setnineImg(false);
          setnineImgbase64(reader.result);
        }
        if (e.target.name == "eightImg") {
          seteightImg(false);
          seteightImgbase64(reader.result);
        }
        if (e.target.name == "sevenImg") {
          setSevenImg(false);
          setSevenImgbase64(reader.result);
        }
        if (e.target.name == "sixthImg") {
          setSixthImg(false);
          setSixthImgbase64(reader.result);
        }
        if (e.target.name == "firstImg") {
          setFirstImg(false);
          setFirstImgbase64(reader.result);
        }
        if (e.target.name == "secondImg") {
          setSecondImg(false);
          setSecondImgbase64(reader.result);
        }
        if (e.target.name == "thirdImg") {
          setthirdImg(false);
          setthirdImgbase64(reader.result);
        }
        if (e.target.name == "fourthImg") {
          setfourthImg(false);
          setfourthImgbase64(reader.result);
        }
        if (e.target.name == "fifthImg") {
          setfifthImg(false);
          setfifthImgbase64(reader.result);
        }

        let s3bucket = new AWS.S3({
          accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
          secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
          Bucket: process.env.REACT_APP_BUCKET_NAME,
        });

        let buf = new Buffer(
          reader.result.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );
        s3bucket.createBucket(function () {
          var params = {
            Bucket: process.env.REACT_APP_BUCKET_NAME + "/users/images",
            Key: Date.now() + ".png",
            ContentEncoding: "base64",
            ContentType: "image/png",
            Body: buf,
          };
          s3bucket.upload(params, function (err, data) {
            if (err) {
              console.log(err);
            } else {
              dispatch(
                updateGetstartText({
                  id: e.target.id,
                  key: "iconsName",
                  value: data.key,
                })
              );
            }
          });
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  // Targets all textareas with class "txta"
  let textareas = document.querySelectorAll(".txta"),
    hiddenDiv = document.createElement("div"),
    content = null;

  // Adds a class to all textareas
  for (let j of textareas) {
    j.classList.add("txtstuff");
  }

  // Build the hidden div's attributes

  // The line below is needed if you move the style lines to CSS
  // hiddenDiv.classList.add('hiddendiv');

  // Add the "txta" styles, which are common to both textarea and hiddendiv
  // If you want, you can remove those from CSS and add them via JS
  hiddenDiv.classList.add("txta");

  // Add the styles for the hidden div
  // These can be in the CSS, just remove these three lines and uncomment the CSS
  hiddenDiv.style.display = "none";
  hiddenDiv.style.whiteSpace = "pre-wrap";
  hiddenDiv.style.wordWrap = "break-word";

  // Loop through all the textareas and add the event listener
  for (let i of textareas) {
    (function (i) {
      // Note: Use 'keyup' instead of 'input'
      // if you want older IE support
      i.addEventListener("input", function () {
        // Append hiddendiv to parent of textarea, so the size is correct
        i.parentNode.appendChild(hiddenDiv);

        // Remove this if you want the user to be able to resize it in modern browsers
        i.style.resize = "none";

        // This removes scrollbars
        i.style.overflow = "hidden";

        // Every input/change, grab the content
        content = i.value;

        // Add the same content to the hidden div

        // This is for old IE
        content = content.replace(/\n/g, "<br>");

        // The <br ..> part is for old IE
        // This also fixes the jumpy way the textarea grows if line-height isn't included
        hiddenDiv.innerHTML = content + '<br style="line-height: 3px;">';

        // Briefly make the hidden div block but invisible
        // This is in order to read the height
        hiddenDiv.style.visibility = "hidden";
        hiddenDiv.style.display = "block";
        i.style.height = hiddenDiv.offsetHeight + "px";

        // Make the hidden div display:none again
        hiddenDiv.style.visibility = "visible";
        hiddenDiv.style.display = "none";
      });
    })(i);
  }
  return (
    <>
      <AdminHeader />
      <Navbar />
      <Submenu />

      <div className="container-fluid dkg-mainprinciple-mainCon">
        <div className="row dkg-mainprinciple-Row">
          <div className="col-12 dkg-mainprinciple-Col">
            <div className="dkg-principale-header">
              <h2 className="dkg-principale-title">
                {data != "" ? data[0].title : ""}
                <EditTitleModal
                  headingTitle={data != "" ? data[0].title : ""}
                  callUpdate={updateData}
                />
              </h2>
            </div>
            <div className="dkg-principale-tableCon">
              <table className="table table-bordered dkg-principale-table">
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      <div className="dkg-uplaod-imageCon">
                        <div className="d-flex align-items-center">
                          <div className="dk-edit-userImgCon">
                            <input
                              type="file"
                              accept="image/*"
                              name="sixthImg"
                              id="7"
                              onChange={onSelectFile}
                            />
                            <span className="dk-myprofile-userImgCon">
                              <figure id="edit_image">
                                {sixthImg ? (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={
                                      data != ""
                                        ? process.env
                                            .REACT_APP_IMG_CLOUD_BASE_URL +
                                          "/" +
                                          data[6].iconsName
                                        : ChangeIcon
                                    }
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={sixthImgbase64}
                                  />
                                )}
                              </figure>
                            </span>
                            <span className="dk-myprofile-editicon">
                              <FaEdit />
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        defaultValue={data != "" ? data[6].title : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[6].title,
                            7,
                            "title"
                          )
                        }
                      />
                    </td>
                    <td>
                      <textarea
                        className="form-control txta"
                        name="title"
                        defaultValue={data != "" ? data[6].description : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[6].description,
                            7,
                            "description"
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      <div className="dkg-uplaod-imageCon">
                        <div className="d-flex align-items-center">
                          <div className="dk-edit-userImgCon">
                            <input
                              type="file"
                              accept="image/*"
                              name="firstImg"
                              id="2"
                              onChange={onSelectFile}
                            />
                            <span className="dk-myprofile-userImgCon">
                              <figure id="edit_image">
                                {firstImg ? (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={
                                      data != ""
                                        ? process.env
                                            .REACT_APP_IMG_CLOUD_BASE_URL +
                                          "/" +
                                          data[1].iconsName
                                        : ChangeIcon
                                    }
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={firstImgbase64}
                                  />
                                )}
                              </figure>
                            </span>
                            <span className="dk-myprofile-editicon">
                              <FaEdit />
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        defaultValue={data != "" ? data[1].title : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[1].title,
                            2,
                            "title"
                          )
                        }
                      />
                    </td>
                    <td>
                      <textarea
                        className="form-control txta"
                        name="title"
                        defaultValue={data != "" ? data[1].description : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[1].description,
                            2,
                            "description"
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      <div className="dkg-uplaod-imageCon">
                        <div className="d-flex align-items-center">
                          <div className="dk-edit-userImgCon">
                            <input
                              type="file"
                              accept="image/*"
                              name="secondImg"
                              id="3"
                              onChange={onSelectFile}
                            />
                            <span className="dk-myprofile-userImgCon">
                              <figure id="edit_image">
                                {secondImg ? (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={
                                      data != ""
                                        ? process.env
                                            .REACT_APP_IMG_CLOUD_BASE_URL +
                                          "/" +
                                          data[2].iconsName
                                        : ChangeIcon
                                    }
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={secondImgbase64}
                                  />
                                )}
                              </figure>
                            </span>
                            <span className="dk-myprofile-editicon">
                              <FaEdit />
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        defaultValue={data != "" ? data[2].title : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[2].title,
                            3,
                            "title"
                          )
                        }
                      />
                    </td>
                    <td>
                      <textarea
                        className="form-control txta"
                        name="title"
                        defaultValue={data != "" ? data[2].description : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[2].description,
                            3,
                            "description"
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      <div className="dkg-uplaod-imageCon">
                        <div className="d-flex align-items-center">
                          <div className="dk-edit-userImgCon">
                            <input
                              type="file"
                              accept="image/*"
                              name="thirdImg"
                              id="4"
                              onChange={onSelectFile}
                            />
                            <span className="dk-myprofile-userImgCon">
                              <figure id="edit_image">
                                {thirdImg ? (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={
                                      data != ""
                                        ? process.env
                                            .REACT_APP_IMG_CLOUD_BASE_URL +
                                          "/" +
                                          data[3].iconsName
                                        : ChangeIcon
                                    }
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={thirdImgbase64}
                                  />
                                )}
                              </figure>
                            </span>
                            <span className="dk-myprofile-editicon">
                              <FaEdit />
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        defaultValue={data != "" ? data[3].title : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[3].title,
                            4,
                            "title"
                          )
                        }
                      />
                    </td>
                    <td>
                      <textarea
                        className="form-control txta"
                        name="title"
                        defaultValue={data != "" ? data[3].description : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[3].description,
                            4,
                            "description"
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>
                      <div className="dkg-uplaod-imageCon">
                        <div className="d-flex align-items-center">
                          <div className="dk-edit-userImgCon">
                            <input
                              type="file"
                              accept="image/*"
                              name="sevenImg"
                              id="8"
                              onChange={onSelectFile}
                            />
                            <span className="dk-myprofile-userImgCon">
                              <figure id="edit_image">
                                {sevenImg ? (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={
                                      data != ""
                                        ? process.env
                                            .REACT_APP_IMG_CLOUD_BASE_URL +
                                          "/" +
                                          data[7].iconsName
                                        : ChangeIcon
                                    }
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={sevenImgbase64}
                                  />
                                )}
                              </figure>
                            </span>
                            <span className="dk-myprofile-editicon">
                              <FaEdit />
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        defaultValue={data != "" ? data[7].title : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[7].title,
                            8,
                            "title"
                          )
                        }
                      />
                    </td>
                    <td>
                      <textarea
                        className="form-control txta"
                        name="title"
                        defaultValue={data != "" ? data[7].description : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[7].description,
                            8,
                            "description"
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>
                      <div className="dkg-uplaod-imageCon">
                        <div className="d-flex align-items-center">
                          <div className="dk-edit-userImgCon">
                            <input
                              type="file"
                              accept="image/*"
                              name="fourthImg"
                              id="5"
                              onChange={onSelectFile}
                            />
                            <span className="dk-myprofile-userImgCon">
                              <figure id="edit_image">
                                {fourthImg ? (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={
                                      data != ""
                                        ? process.env
                                            .REACT_APP_IMG_CLOUD_BASE_URL +
                                          "/" +
                                          data[4].iconsName
                                        : ChangeIcon
                                    }
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={fourthImgbase64}
                                  />
                                )}
                              </figure>
                            </span>
                            <span className="dk-myprofile-editicon">
                              <FaEdit />
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        defaultValue={data != "" ? data[4].title : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[4].title,
                            5,
                            "title"
                          )
                        }
                      />
                    </td>
                    <td>
                      <textarea
                        className="form-control txta"
                        name="title"
                        defaultValue={data != "" ? data[4].description : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[4].description,
                            5,
                            "description"
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>
                      <div className="dkg-uplaod-imageCon">
                        <div className="d-flex align-items-center">
                          <div className="dk-edit-userImgCon">
                            <input
                              type="file"
                              accept="image/*"
                              name="fifthImg"
                              id="6"
                              onChange={onSelectFile}
                            />
                            <span className="dk-myprofile-userImgCon">
                              <figure id="edit_image">
                                {fifthImg ? (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={
                                      data != ""
                                        ? process.env
                                            .REACT_APP_IMG_CLOUD_BASE_URL +
                                          "/" +
                                          data[5].iconsName
                                        : ChangeIcon
                                    }
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={fifthImgbase64}
                                  />
                                )}
                              </figure>
                            </span>
                            <span className="dk-myprofile-editicon">
                              <FaEdit />
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        defaultValue={data != "" ? data[5].title : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[5].title,
                            6,
                            "title"
                          )
                        }
                      />
                    </td>
                    <td>
                      <textarea
                        className="form-control txta"
                        name="title"
                        defaultValue={data != "" ? data[5].description : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[5].description,
                            6,
                            "description"
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>
                      <div className="dkg-uplaod-imageCon">
                        <div className="d-flex align-items-center">
                          <div className="dk-edit-userImgCon">
                            <input
                              type="file"
                              accept="image/*"
                              name="eightImg"
                              id="9"
                              onChange={onSelectFile}
                            />
                            <span className="dk-myprofile-userImgCon">
                              <figure id="edit_image">
                                {eightImg ? (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={
                                      data != ""
                                        ? process.env
                                            .REACT_APP_IMG_CLOUD_BASE_URL +
                                          "/" +
                                          data[8].iconsName
                                        : ChangeIcon
                                    }
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={eightImgbase64}
                                  />
                                )}
                              </figure>
                            </span>
                            <span className="dk-myprofile-editicon">
                              <FaEdit />
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        defaultValue={data != "" ? data[8].title : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[8].title,
                            9,
                            "title"
                          )
                        }
                      />
                    </td>
                    <td>
                      <textarea
                        className="form-control txta"
                        name="title"
                        defaultValue={data != "" ? data[8].description : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[8].description,
                            9,
                            "description"
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>
                      <div className="dkg-uplaod-imageCon">
                        <div className="d-flex align-items-center">
                          <div className="dk-edit-userImgCon">
                            <input
                              type="file"
                              accept="image/*"
                              name="nineImg"
                              id="10"
                              onChange={onSelectFile}
                            />
                            <span className="dk-myprofile-userImgCon">
                              <figure id="edit_image">
                                {nineImg ? (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={
                                      data != ""
                                        ? process.env
                                            .REACT_APP_IMG_CLOUD_BASE_URL +
                                          "/" +
                                          data[9].iconsName
                                        : ChangeIcon
                                    }
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={nineImgbase64}
                                  />
                                )}
                              </figure>
                            </span>
                            <span className="dk-myprofile-editicon">
                              <FaEdit />
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        defaultValue={data != "" ? data[9].title : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[9].title,
                            10,
                            "title"
                          )
                        }
                      />
                    </td>
                    <td>
                      <textarea
                        className="form-control txta"
                        name="title"
                        defaultValue={data != "" ? data[9].description : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[9].description,
                            10,
                            "description"
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>
                      <div className="dkg-uplaod-imageCon">
                        <div className="d-flex align-items-center">
                          <div className="dk-edit-userImgCon">
                            <input
                              type="file"
                              accept="image/*"
                              name="tenImg"
                              id="11"
                              onChange={onSelectFile}
                            />
                            <span className="dk-myprofile-userImgCon">
                              <figure id="edit_image">
                                {tenImg ? (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={
                                      data != ""
                                        ? process.env
                                            .REACT_APP_IMG_CLOUD_BASE_URL +
                                          "/" +
                                          data[10].iconsName
                                        : ChangeIcon
                                    }
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    className="dkg-changIcon"
                                    src={tenImgbase64}
                                  />
                                )}
                              </figure>
                            </span>
                            <span className="dk-myprofile-editicon">
                              <FaEdit />
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        defaultValue={data != "" ? data[10].title : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[10].title,
                            11,
                            "title"
                          )
                        }
                      />
                    </td>
                    <td>
                      <textarea
                        className="form-control txta"
                        name="title"
                        defaultValue={data != "" ? data[10].description : ""}
                        onChange={(e) =>
                          updateData(
                            data != "" ? e.target.value : data[10].description,
                            11,
                            "description"
                          )
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Principales;
