import React from 'react'
import AdminHeader from '../../../../elements/header/Header'
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../../elements/submenu/mainmenu/JobsSubmenu';
import {Tab, Nav} from 'react-bootstrap'
import  "./applyjob.scss";
import JobEditor from './editor/JobEditor';



const ApplyJob = () => {
    return (
        <>
            <AdminHeader />
            <Navbar />
            <Submenu />
            <div className='dkg-applyjob-mainCon' style={{marginTop: "160px"}}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="instructions">
                    <div className='row dkg-applyjob-mainRow'> 
                        <div className='col-md-2 col-sm-12 dkg-applyjob-leftSide pr-0'>
                            <Nav variant="pills" className="flex-column dkg-applyjob-navpills">
                                <Nav.Item className='dkg-applyjob-navitem'>
                                    <Nav.Link eventKey="instructions" className='dkg-applyjob-navlink'>Instructions</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='dkg-applyjob-navitem'>
                                    <Nav.Link eventKey="confirmation" className='dkg-applyjob-navlink'>Confirmation</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='dkg-applyjob-navitem'>
                                    <Nav.Link eventKey="consents" className='dkg-applyjob-navlink'>Consents</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='dkg-applyjob-navitem'>
                                    <Nav.Link eventKey="questions" className='dkg-applyjob-navlink'>Questions</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='dkg-applyjob-navitem'>
                                    <Nav.Link eventKey="contact-details" className='dkg-applyjob-navlink'>Contact Details</Nav.Link>
                                </Nav.Item>
                                 <Nav.Item className='dkg-applyjob-navitem'>
                                    <Nav.Link eventKey="applied-confirmation" className='dkg-applyjob-navlink'>Applied Confirmation</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className='col-md-10 col-sm-12 dkg-applyjob-mainCon pl-0'>
                            <Tab.Content className='dkg-jobapply-tabcontent'>
                                <Tab.Pane eventKey="instructions" className='dkg-jobapply-tabpane'>
                                    <div className='dkg-jobapply-body'>
                                        <JobEditor/> 
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="confirmation" className='dkg-jobapply-tabpane'>
                                    <div className='dkg-jobapply-body'>
                                        <JobEditor/>
                                    </div>
                                </Tab.Pane>
                                 <Tab.Pane eventKey="consents" className='dkg-jobapply-tabpane'>
                                    <div className='dkg-jobapply-body'>
                                      <JobEditor/>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="questions" className='dkg-jobapply-tabpane'>
                                    <div className='dkg-jobapply-body'>
                                    </div>
                                </Tab.Pane>
                                 <Tab.Pane eventKey="contact-details" className='dkg-jobapply-tabpane'>
                                    <div className='dkg-jobapply-body'>Contact  Details</div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="applied-confirmation" className='dkg-jobapply-tabpane'>
                                    <div className='dkg-jobapply-body'>
                                      <JobEditor/>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default ApplyJob;