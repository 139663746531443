import React from 'react'
import AdminHeader from '../../../../elements/header/Header'
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../../elements/submenu/mainmenu/DashboardSubmenu';


const Checkin = () => {
    return (
        <>
            <AdminHeader />
            <Navbar />
            <Submenu />
        </>
    )
}

export default Checkin