import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PagetypeVideos from "../../../../../../../assets/images/album.jpg";
import UpdateModal from "./modal/UpdateModal";
import AddNewModal from "./modal/AddNewModal";
import { getCategories } from "../../../../../../../slice/clieninfo/requirnment/requirnmentSlice";
import ReactPlayer from "react-player/youtube";
import "./company-video.scss";
import {
  //showError,
  showSuccess,
} from "../../../../../../../components/utils/messages/messageSlice";

const CompanyyVideo = ({ compVideo, selectedItem }) => {
  const dispatch = useDispatch();
  const { issSuccess, videoAlbumList } = useSelector((state) => state.cms);

  const [isUpload, setIsUpload] = useState(false);
  const [isAddNew, setIsAddNew] = useState(false);
  const [commpvideo, setCommpvideo] = useState([]);

  const [rowId, setRowId] = useState(
    compVideo.length > 0 ? compVideo[0].compId : 0
  );

  const [vId, setVid] = useState("");
  const [yId, setYid] = useState("");
  const [type, setType] = useState("");
  const [titles, setTitles] = useState("");

  useEffect(() => {
    if (selectedItem) {
      setCommpvideo(selectedItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (!issSuccess && videoAlbumList.length > 0) {
      setCommpvideo(videoAlbumList);
      dispatch(showSuccess({ msg: "Youtube URL updated successfully" }));
      dispatch(getCategories({}));
    }
  }, [issSuccess]);

  const showuploadModal = (id, comp, youturl, title, type) => {
    setVid(id);
    setRowId(comp);
    setYid(youturl);
    setType(type);
    setTitles(title);
    setIsUpload(true);
  };
  const hideuploadModal = () => {
    setIsUpload(false);
  };
  const showaddnewModal = (id, comp, youturl, title, type) => {
    setVid(id);
    setRowId(comp);
    setYid(youturl);
    setType(type);
    setTitles(title);
    setIsAddNew(true);
  };
  const hideaddnewModal = () => {
    setIsAddNew(false);
  };
  return (
    <>
      {isUpload ? (
        <UpdateModal
          onHide={hideuploadModal}
          companyId={rowId}
          vid={vId}
          yurl={yId}
          type={type}
          title={titles}
        />
      ) : null}
      {isAddNew ? (
        <AddNewModal
          onHide={hideaddnewModal}
          companyId={rowId}
          vid={vId}
          yurl={yId}
          type={type}
          title={titles}
        />
      ) : null}
      <div className="dkg-company-video-mainCon">
        <div className="dkg-jopspec-pageheader">
          <div className="dkg-jopspec-page-title">Videos</div>
        </div>
        <div className="dkg-company-videoCon">
          <div className="dkg-company-mainRow row">
            {commpvideo.length > 0 &&
              commpvideo.map((data, index) => (
                <div
                  className="col-lg-4 col-md-4 col-sm-12 dkg-company-video-cols"
                  key={index + 1}
                >
                  <div className="dkg-company-video-box ">
                    <div className="dkg-company-album-box-header">
                      <h2 className="dkg-album-box-header-title">
                        {data.videoTitle != "" && data.videoTitle != null
                          ? data.videoTitle
                          : "Video title"}
                      </h2>
                    </div>
                    <figure className="dkg-company-video-figure">
                      <div className="dkg-company-video-imgCon">
                        {data.youtubeurl != "" && data.youtubeurl != null ? (
                          <>
                            <ReactPlayer
                              url={`${data.youtubeurl}&ab_channel=fotios`}
                              width="100%"
                              height="210px"
                            />
                          </>
                        ) : (
                          <img
                            src={PagetypeVideos}
                            className="dkg-company-video-img"
                            alt="Video"
                          />
                        )}
                      </div>
                    </figure>
                    <div className="dkg-company-bottomCon">
                      <button
                        className="btn dkg-upload-mediabtn"
                        onClick={() =>
                          showaddnewModal(
                            data.id,
                            data.compId,
                            data.youtubeurl,
                            data.videoTitle,
                            "Add"
                          )
                        }
                      >
                        VIEW
                      </button>
                      <button
                        className="btn dkg-edit-mediabtn"
                        onClick={() =>
                          showuploadModal(
                            data.id,
                            data.compId,
                            data.youtubeurl,
                            data.videoTitle,
                            "update"
                          )
                        }
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyyVideo;
