import React, { useState, useCallback, useRef, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import SubmitButton from '../../../../ui/submitButton'

const SecondSideMenu  = () => {
  return (
    <>
      <div className="dkg-cand-services-Side">
        <Tab.Container defaultActiveKey="dkg-open-jobs">
          <div className="row m-0 dkg-payment-qt-tab-mainCon">
            <div className="col-md-2 pl-0 dkg-payment-qt-tabCon dkg-profile-task-sideMenu">
              <Nav
                variant="pills"
                className="flex-column dkg-payment-qt-tab-navpills"
              >
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="dkg-open-jobs"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Unread Emails
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="dkg-applied-jobs"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Open Jobs
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="dkg-interviews"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Applied Jobs
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="dkg-offered-jobs"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Interviews
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="dkg-referral-bonus"
                    className="dkg-payment-qt-tab-navlink"
                  >
                   Offered Jobs
                  </Nav.Link>
                </Nav.Item> 
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="dkg-paid-services"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Joined Jobs
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="dkg-account-benefits"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Account Benefits
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-md-10  dkg-paidservices-rightCon">
              <Tab.Content className="dkg-paidservices-tabcontent">
                <Tab.Pane eventKey="dkg-open-jobs" className="dkg-paidservices-tabpane">
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Summary</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <input
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue=""
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                      />
                    </div>
                  </div>
                </Tab.Pane> 
                <Tab.Pane
                  eventKey="dkg-applied-jobs"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Description</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <input
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue=""
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                      />
                    </div>
                  </div>
                </Tab.Pane> 
                <Tab.Pane
                  eventKey="dkg-interviews"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Interviews</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <input
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue=""
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                      />
                    </div>
                  </div>
                </Tab.Pane> 
                <Tab.Pane
                  eventKey="dkg-offered-jobs"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Offered Jobs</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <input
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue=""
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                      />
                    </div>
                  </div>
                </Tab.Pane> 
                <Tab.Pane
                  eventKey="dkg-referral-bonus"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Offered Jobs</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <input
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue=""
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                      />
                    </div>
                  </div>
                </Tab.Pane> 
                <Tab.Pane
                  eventKey="dkg-paid-services"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Paid Services</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <input
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue=""
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="dkg-account-benefits"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Account Benefits</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <input
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue=""
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                      />
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default SecondSideMenu ;
