import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav, InputGroup, FormControl } from "react-bootstrap";
import Switch from "./Switch";
import {
  getTextHeading,
  updateCMSText,
  getService,
  updateServices,
  getBenifits,
  //updateBenifits,
  updateKeyBenifits,
  clearState,
} from "../../../../../slice/candidate/frontendCms/frontendCmsSlice";
import SubmitButton from "../../../../ui/submitButton";
import {
  showError,
  showSuccess,
} from "../../../../../components/utils/messages/messageSlice";
import { FaEdit } from "react-icons/fa";
import AWS from "aws-sdk";
import { Buffer } from "buffer";
import "react-image-crop/dist/ReactCrop.css";
import $ from "jquery";

const SecondSideResume = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    getcmsList,
    serviceList,
    isSuccess,
    benifitsLists,
    isBenifits,
    isUpdate,
  } = useSelector((state) => state.cmslist);

  const [thirdImg, setThirdImg] = useState(true);
  const [thirdImgbase64, setThirdImgbase64] = useState("");

  //const [benifits, setBenifits] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [pageHeading, setPageHeading] = useState([]);
  const [pageSubHeading, setPageSubHeading] = useState([]);

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setPageHeading(getcmsList[0]);
      setPageSubHeading(getcmsList[16]);
    }
    if (!isSuccess && serviceList && serviceList.length > 0) {
      setServiceData(serviceList);
    }
    // if (!isBenifits && benifitsLists && benifitsLists.length > 0) {
    //   setBenifits(benifitsLists);
    // }
  }, [isLoading, isSuccess, isBenifits]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState({}));
      dispatch(showSuccess({ msg: "Information updated successfully" }));
    }
  }, [isUpdate]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getTextHeading({}));
        await dispatch(getBenifits({}));
        await dispatch(getService({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  const SubmitBtn = (id) => {
    if (id === 17) {
      dispatch(
        updateCMSText({
          id,
          key: "page_description",
          value: pageSubHeading,
        })
      );
    } else {
      dispatch(
        updateCMSText({
          id,
          key: "page_description",
          value: pageHeading,
        })
      );
    }
  };

  // function getValueByName(name) {
  //   var elements = document.getElementsByName(name);
  //   if (elements.length > 0) {
  //     var value = elements[0].value;
  //     return value;
  //   } else {
  //     return null;
  //   }
  // }

  // const updateBenifitsHandle = (id) => {
  //   var heading = getValueByName("heading_" + id);
  //   var descriptions = getValueByName("description_" + id);
  //   dispatch(
  //     updateBenifits({
  //       id,
  //       heading,
  //       descriptions,
  //     })
  //   );
  // };
  const updateHandle = (id, val, key) => {
    dispatch(
      updateServices({
        id,
        key,
        value: val,
      })
    );
  };
  const handleToggle = (e) => {
    const dataId = e.target.getAttribute("data-id");
    if (e.target.checked === true) {
      dispatch(updateServices({ id: dataId, key: "status", value: 1 }));
    } else {
      dispatch(updateServices({ id: dataId, key: "status", value: 0 }));
    }
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        if (e.target.name === "secondImg") {
          $(".changeSecondImageDynamic" + e.target.id).attr(
            "src",
            reader.result
          );
        } else if (e.target.name === "thirdImg") {
          $(".changeThirdImageDynamic" + e.target.id).attr(
            "src",
            reader.result
          );
        } else {
          $(".changeImageDynamic" + e.target.id).attr("src", reader.result);
        }

        let s3bucket = new AWS.S3({
          accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
          secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
          Bucket: process.env.REACT_APP_BUCKET_NAME,
        });

        let buf = new Buffer(
          reader.result.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );
        s3bucket.createBucket(function () {
          var params = {
            Bucket: process.env.REACT_APP_BUCKET_NAME + "/users/images",
            Key: Date.now() + ".png",
            ContentEncoding: "base64",
            ContentType: "image/png",
            Body: buf,
          };
          s3bucket.upload(params, function (err, data) {
            if (err) {
              console.log(err);
            } else {
              if (e.target.name === "thirdImg") {
                console.log("thirdimg", e.target.id);
                dispatch(
                  updateServices({
                    id: e.target.id,
                    key: "image",
                    value: data.key,
                  })
                );
              } else {
                dispatch(
                  updateKeyBenifits({
                    id: e.target.id,
                    key:
                      e.target.name === "secondImg"
                        ? "second_image"
                        : "image_name",
                    value: data.key,
                  })
                );
              }
            }
          });
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <>
      <div className="dkg-cand-services-Side">
        <Tab.Container defaultActiveKey="first">
          <div className="row m-0 dkg-payment-qt-tab-mainCon">
            <div className="col-md-2 pl-0 dkg-payment-qt-tabCon dkg-profile-task-sideMenu">
              <Nav
                variant="pills"
                className="flex-column dkg-payment-qt-tab-navpills"
              >
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="first"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Title
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="second"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Subtitle
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="price-table"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Price Table
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-md-10  dkg-paidservices-rightCon">
              <Tab.Content className="dkg-paidservices-tabcontent">
                <Tab.Pane eventKey="first" className="dkg-paidservices-tabpane">
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Title</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <input
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue={
                        pageHeading != "" ? pageHeading.page_description : ""
                      }
                      onChange={(e) => setPageHeading(e.target.value)}
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                        onClick={(e) => SubmitBtn(1)}
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Subtitle</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <textarea
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue={
                        pageSubHeading != ""
                          ? pageSubHeading.page_description
                          : ""
                      }
                      onChange={(e) => setPageSubHeading(e.target.value)}
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                        onClick={(e) => SubmitBtn(17)}
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="price-table"
                  className="dkg-paidservices-tabpane"
                >
                  {/* <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Price Table</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                  </div> */}
                  <div className="dkg-candidate-mainCon dkg-paidservice-mainCon">
                    <div className="dkg-company-list-mainCon dkg-paidservice-list-mainCon">
                      <div className="dkg-company-list-tableCon table-responsive dkg-paidservice-table-Con">
                        <table className="dkg-company-list-table table table-borderded">
                          <thead>
                            <tr>
                              <th>Icon</th>
                              <th>Status</th>
                              <th>Category</th>
                              <th>Type</th>
                              <th>Services</th>
                              <th style={{ width: "90px" }}>Price (€)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {serviceData.map((item, index) => (
                              <tr key={index + 1}>
                                <td>
                                  <div className="dkg-benefit-iconCon">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="thirdImg"
                                      id={item.id}
                                      onChange={onSelectFile}
                                    />
                                    <span className="dk-myprofile-userImgCon">
                                      <figure id="edit_image">
                                        {thirdImg ? (
                                          <img
                                            alt=""
                                            className={
                                              `dkg-changIcon changeThirdImageDynamic` +
                                              item.id
                                            }
                                            style={{ width: "35px" }}
                                            src={
                                              item.image != ""
                                                ? process.env
                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                  "/" +
                                                  item.image
                                                : ""
                                            }
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            className={
                                              `dkg-changIcon changeThirdImageDynamic` +
                                              item.id
                                            }
                                            src={thirdImgbase64}
                                            style={{ width: "35px" }}
                                          />
                                        )}
                                      </figure>
                                    </span>
                                    <span className="dk-myprofile-editicon">
                                      <FaEdit />
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="dkg-clentlinked-switch">
                                    <Switch
                                      handleToggle={handleToggle}
                                      id={item.id}
                                      value={item.status}
                                      isChecked={item.status}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={item.categoryName}
                                    onChange={(e) =>
                                      updateHandle(
                                        item.id,
                                        e.target.value,
                                        "categoryName"
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={item.type}
                                    onChange={(e) =>
                                      updateHandle(
                                        item.id,
                                        e.target.value,
                                        "type"
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={item.service_name}
                                    onChange={(e) =>
                                      updateHandle(
                                        item.id,
                                        e.target.value,
                                        "service_name"
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control text-center"
                                    defaultValue={item.price}
                                    onChange={(e) =>
                                      updateHandle(
                                        item.id,
                                        e.target.value,
                                        "price"
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default SecondSideResume;
