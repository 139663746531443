import React, { useState, useEffect } from "react";
// import { Link } from 'react-router-dom'
// import { Tab, Nav, Dropdown } from "react-bootstrap";
import Header from "../../../../elements/header/Header";
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../elements/submenu/mainmenu/JobspecSubmenu";
import ClientFaq from "../clientlist/faq/category/CompanyTable";

const MainFaq = () => {
  return (
    <>
      <Header dashboardheaderLink="DKG MASTER ADMIN" dashboardLink="/dashboard/summary" />
      <Navbar />
      <Submenu />
      <div className="dkg-mainfaq-Con" style={{ marginTop: "140px" }}>
        <ClientFaq />
      </div>
    </>
  );
};

export default MainFaq;
