import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminHeader from "../../../../elements/header/Header";
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../elements/submenu/mainmenu/CandAccSubmenu";
import { Nav, Tab } from "react-bootstrap";
import SecondSideResume from "./SecondSideResume ";
import CommonFileComponent from "./CommonComponent";
import SummaryComponent from "./Summary";
// import CoverComponent from "./Cover";
import CountryComponent from "./Country";
import IndustryComponent from "./Industry";
import PositionsComponent from "./Positions";
import JobTitlesComponent from "./JobTitles";
import SalaryComponent from "./Salary";
import BenifitesComponent from "./Benifits";
import { listData, clearState } from "../../../../../slice/cmsSlice";

import {
  showSuccess,
  showError,
} from "../../../../utils/messages/messageSlice";

const CreateCV = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList, isUpdate } = useSelector((state) => state.cms);
  const [summary, setSummaryDescription] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(listData({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setSummaryDescription(getcmsList);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState({}));
      dispatch(showSuccess({ msg: "Information updated successfully." }));
    }
  }, [isUpdate]);

  return (
    <>
      <AdminHeader />
      <Navbar />
      <Submenu />
      <div className="dkg-contact-mainCon">
        <div className="dkg-contact-main">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="dkg-cv-preview"
          >
            <div className="row justify-content-center dkg-contact-mainRow">
              <div className="col-md-2 dkg-contact-main-sideCol">
                <Nav
                  variant="pills dkg-aboutus-navpills dkg-paid-services-navpills"
                  className="flex-column"
                >
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-preview"
                      className="dkg-aboutus-navlink"
                    >
                     DK Resume
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-contact-details"
                      className="dkg-aboutus-navlink"
                    >
                      Contact Details
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-profile-summary"
                      className="dkg-aboutus-navlink"
                    >
                      Profile Summary
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-work-exp"
                      className="dkg-aboutus-navlink"
                    >
                      Work Experience
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-qualifications"
                      className="dkg-aboutus-navlink"
                    >
                      Qualifications
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-job-skills"
                      className="dkg-aboutus-navlink"
                    >
                      Workplace Skills
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-technical-skills"
                      className="dkg-aboutus-navlink"
                    >
                      Technical Skills
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-langauge-skils"
                      className="dkg-aboutus-navlink"
                    >
                      Language Skills
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-achievements"
                      className="dkg-aboutus-navlink"
                    >
                      Achievements
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-work-taining"
                      className="dkg-aboutus-navlink"
                    >
                     Workplace Training
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-hobbies"
                      className="dkg-aboutus-navlink"
                    >
                      Hobbies & Interests
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-work-references"
                      className="dkg-aboutus-navlink"
                    >
                      Employer Reference
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-gdpr-consent"
                      className="dkg-aboutus-navlink"
                    >
                     Give GDPR Consent
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-10 col-10 dkg-contact-mainCol">
                <Tab.Content className="dkg-aboutus-tabcontent">
                  <Tab.Pane
                    eventKey="dkg-cv-preview"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Overview</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CommonFileComponent
                        data={summary != "" ? summary[34].description : ""}
                        rowid={35}
                      />
                    </div>
                  </Tab.Pane>

                  <Tab.Pane
                    eventKey="dkg-profile-summary"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Profile Summary</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CommonFileComponent
                        data={summary != "" ? summary[36].description : ""}
                        rowid={37}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-work-exp"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Work Experience</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CommonFileComponent
                        data={summary != "" ? summary[37].description : ""}
                        rowid={38}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-qualifications"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Qualifications</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CommonFileComponent
                        data={summary != "" ? summary[38].description : ""}
                        rowid={39}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-job-skills"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Workplace Skills</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CommonFileComponent
                        data={summary != "" ? summary[39].description : ""}
                        rowid={40}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-technical-skills"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Technical Skills</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CommonFileComponent
                        data={summary != "" ? summary[40].description : ""}
                        rowid={41}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-langauge-skils"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Language Skills</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CommonFileComponent
                        data={summary != "" ? summary[41].description : ""}
                        rowid={42}
                      />
                    </div>
                  </Tab.Pane> 
                  <Tab.Pane
                    eventKey="dkg-cv-achievements"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Achievements</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CommonFileComponent
                        data={summary != "" ? summary[42].description : ""}
                        rowid={43}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-work-taining"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Work Training</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CommonFileComponent
                        data={summary != "" ? summary[42].description : ""}
                        rowid={43}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-hobbies"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Hobbies & Interests</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CommonFileComponent
                        data={summary != "" ? summary[43].description : ""}
                        rowid={44}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-work-references"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Employer Reference</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CommonFileComponent
                        data={summary != "" ? summary[44].description : ""}
                        rowid={45}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-contact-details"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Contact Details</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CommonFileComponent
                        data={summary != "" ? summary[35].description : ""}
                        rowid={36}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-gdpr-consent"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Give GDPR Consent</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CommonFileComponent
                        data={summary != "" ? summary[45].description : ""}
                        rowid={46}
                      />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default CreateCV;
