import React from "react";
import { Route, Link } from "react-router-dom";

const SiteuserSubmenu = () => {
  const pathname = window.location.pathname;
  return (
    <div>
      <div className="dk-adminSubmenuMain">
        <Route>
          <ul style={{backgroundColor : "#fff"}}>
            <li>
              <Link
                to="/site-users/summary"
                className={`${
                  pathname.match("/site-users/summary") ? "grey-active" : ""
                }`}
              >
                Summary
              </Link>
            </li>
            <li>
              <Link
                to="/site-users/management"
                className={`${
                  pathname.match("/site-users/management") ? "grey-active" : ""
                }`}
              >
                Management 
              </Link>
            </li>
            <li>
              <Link
                to="/site-users/recruiters"
                className={`${
                  pathname.match("/site-users/recruiters") ? "grey-active" : ""
                }`}
              >
                Recruiters 
              </Link>
            </li>
            <li>
              <Link
                to="/site-users/jobseekers"
                className={`${
                  pathname.match("/site-users/jobseekers") ? "grey-active" : ""
                }`}
              >
                Candidates
              </Link>
            </li>
            <li>
              <Link
                to="/site-users/clients"
                className={`${
                  pathname.match("/site-users/clients") ? "grey-active" : ""
                }`}
              >
                Clients
              </Link>
            </li>
            <li>
              <Link
                to="/site-users/suppliers"
                className={`${
                  pathname.match("/site-users/suppliers") ? "grey-active" : ""
                }`}
              >
                Suppliers
              </Link>
            </li>
            <li>
              <Link
                to="/site-users/partners"
                className={`${
                  pathname.match("/site-users/partners") ? "grey-active" : ""
                }`}
              >
                Partners
              </Link>
            </li>
          </ul>
        </Route>
      </div>
    </div>
  );
};

export default SiteuserSubmenu;
