import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav, Dropdown, FormControl } from "react-bootstrap";
//import Step1 from "./Step1";
import Header from "../../../../elements/header/Header";
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../elements/submenu/mainmenu/JobspecSubmenu";
import { FaArrowsAlt } from "react-icons/fa";
import AddNewListViewModal from "./addnewmodal/AddNewListView";
//import { Link } from 'react-router-dom'
//import AddIcon from '@mui/icons-material/Add';
// import './dkg-clientlist.scss'
// import JobFileComponent from './job-details/JobDetails'
// import ClientFaq from './faq/category/CompanyTable'
// import './companies.scss'
import "./companies.scss";
import {
  companyData,
  fetechById,
} from "../../../../../slice/companies/clients/clientSlice";
// import {
//   getcompanydetails,
//   updateCompanyJobSpec
// } from "../../../../../slice/cmsSlice";

import {
  getCategories,
  getSubCategories,
  getCompanyById,
  clearState,
} from "../../../../../slice/clieninfo/requirnment/requirnmentSlice";
import AddNewCategory from "./modal/maincategory/AddNewCategory";
import EditCategory from "./modal/maincategory/EditCategory";
import ReorderCategory from "./modal/maincategory/ReorderCategory";
import DeleteDummy from "./modal/maincategory/DeleteDummy";
import {
  showSuccess,
  showError,
} from "../../../../../components/utils/messages/messageSlice";
// import { EditorState, ContentState, convertToRaw } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
// import htmlToDraft from "html-to-draftjs";
// import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import $ from "jquery";
import CompanyInfo from "./pagetype/CompanyInfo";
import AboutUs from "./pagetype/AboutUs";
import Services from "./pagetype/Services";
import CompanyVideo from "./pagetype/video/CompanyVideo";
import CompanyAlbum from "./pagetype/album/CompanyAlbum";
import CompanySocailMedia from "./pagetype/socail/CompanySocailMedia";
import LocationTab from "./pagetype/LocationTab";

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className=""
          placeholder="Search Text..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value ||
              child.props.children.toString().toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

const Overview = () => {
  const dispatch = useDispatch();
  const {
    //isSuccess,
    isLoading,
    isCatUpdate,
    isError,
    isDelete,
    // isReoder,
    isSubCatUpdate,
    isSubReoder,
    isCatInsert,
    categoryReqnmntList,
  } = useSelector((state) => state.requirnments);

  const {
    issLoading,
    companyList,
    companyDetails,
    message,
    isInsert,
    isProfileLoading,
    isCompanyDelete,
  } = useSelector((state) => state.companies);

  //const { issUpdate, issSuccess } = useSelector(state => state.cms);

  const [data, setData] = useState([]);
  const [catRowId, setCatRowId] = useState(0);
  //const [comName, setComName] = useState("Select Company");
  const [catRowName, setCatRowName] = useState("");
  const [companyyName, setCompanyyName] = useState("Select Company");
  const [activeEditTab, setActiveEditTab] = useState(false);
  //const [activeSTab, setActiveSTab] = useState(false);
  //const [commpanyId, setCompanyId] = useState(0)

  //const [companyinfo, setCompanyinfo] = useState([]);
  //const [activeSubCat, setActiveSubCat] = useState("");
  // useEffect(()=>{
  //   if(!isProfileLoading && companyDetails){
  //     setCompanyId(companyDetails.clientId)
  //   }
  // },[isProfileLoading])

  useEffect(() => {
    if (!isLoading && categoryReqnmntList && categoryReqnmntList.length > 0) {
      setData(categoryReqnmntList);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInsert && companyList && companyList.length > 0) {
      dispatch(companyData());
    }
  }, [isInsert, issLoading]);

  useEffect(() => {
    if (isDelete) {
      window.location.reload();
      setActiveEditTab(false);
    }
    if (isCatUpdate) {
      window.location.reload();
    }
  }, [isSubCatUpdate, isCatUpdate, isDelete]);

  useEffect(() => {
    if (isSubReoder) {
      dispatch(clearState());
      dispatch(getSubCategories({ categoryId: catRowId, keys: "details" }));
    }
  }, [isSubReoder]);

  useEffect(() => {
    dispatch(clearState());
    const fetchList = async () => {
      try {
        await dispatch(companyData());
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCategories({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch, isCatInsert, isDelete]);

  useEffect(() => {
    // if (isError) {
    //   dispatch(showError({ msg: "Error while creating categories" }));
    // }
    if (isCatInsert) {
      dispatch(showSuccess({ msg: "Category added successful created" }));
    }
  }, [isError, isCatInsert]);

  const [comjobsp, setComjobsp] = useState([]);
  const [comvidjobsp, setComvidjobsp] = useState([]);
  const [comalbumjobsp, setComalbumjobsp] = useState([]);
  const [comcimgjobsp, setComimgjobsp] = useState([]);
  const [companyyData, setCompanyData] = useState({});
  const [primaryId, setPrimaryId] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState(null);
  /** */
  const [selectedItem, setSelectedItem] = useState(null);
  const handleSelect = async (item) => {
    setCatRowId(item.id);
    setSelectedItem(item);
    setCompanyyName("Company tabs");
    //await dispatch(getCompanyById({ id: item.id }));
    await dispatch(getCategories({}));
  };
  /** */
  // const selectCat = async (catid, catName, cjobsp, cvidjobsp, calbumjobsp, cimgjobsp) => {
  //   //console.log(cjobsp)
  //   //console.log(catid)
  //   //setActiveSTab(true);
  //   // await dispatch(getcompanydetails({ id: cjobsp[0].id }))
  //   setPrimaryId(cjobsp[0].id)
  //   setSelectedCompany(cjobsp[0])
  //   setCompanyData(cjobsp[0])
  //   setCompanyyName(catName);
  //   setCatRowId(catid);
  //   setCatRowName(catName);
  //   setComjobsp(cjobsp ? cjobsp : [])
  //   setComvidjobsp(cvidjobsp ? cvidjobsp : [])
  //   setComalbumjobsp(calbumjobsp ? calbumjobsp : [])
  //   setComimgjobsp(cimgjobsp ? cimgjobsp : [])
  //   setActiveEditTab(true);
  //   //console.log(cvidjobsp)
  // };

  const [isReorderModal, setIReorderModal] = useState(false);
  const showreorderModal = () => {
    setIReorderModal(true);
  };
  const hidereorderModal = () => {
    setIReorderModal(false);
  };
  const [isAddNewList, setIsAddNewList] = useState(false);
  // const showAddNewListView = () => {
  //   setIsAddNewList(true)
  // }
  const hideAddNewListView = () => {
    setIsAddNewList(false);
  };
  // const selectCompany = async (e) => {
  //   setCompanyId(e.target.getAttribute('data-id'))
  //   setComName(e.target.getAttribute('data-name'))
  //   await dispatch(fetechById({ id: e.target.getAttribute('data-id') }))
  // }

  const updateddata = (data) => {
    setSelectedItem(data);
  };
  const [eventKey, SetEventKey] = useState("dkg-company-info");

  return (
    <>
      {isAddNewList ? (
        <AddNewListViewModal handleClose={hideAddNewListView} />
      ) : null}

      <Header
        dashboardheaderLink="DKG MASTER ADMIN"
        dashboardLink="/dashboard/summary"
      />
      <Navbar />
      <Submenu />
      <div
        className="dkg-clientlist-pageCon dkg-payment-qt-pageCon"
        style={{ marginTop: "140px", height: "calc(100vh - 140px)" }}
      >
        <div className="dkg-maincategory-header">
          <ul className="headerlist">
            <li className="headeritem mr-2">
              <AddNewCategory />
            </li>
            <li className="headeritem mr-2">
              <EditCategory catName={selectedItem} />
            </li>
            <li className="headeritem mr-2">
              <span className="dk-resCrcleIconCon" onClick={showreorderModal}>
                <FaArrowsAlt />
              </span>
              {isReorderModal ? (
                <ReorderCategory onHide={hidereorderModal} dataList={data} />
              ) : null}
            </li>
            <li className="headeritem">
              <DeleteDummy catIdds={catRowId} activeEditTab={activeEditTab} />
            </li>
          </ul>
          <div className="dkg-jobspec-header-centerCon">
            <div className="dkg-jobpsec-DropdownCon mr-2">
              <Dropdown className="dkg-jobpsec-Dropdown">
                <Dropdown.Toggle
                  variant=""
                  className="dkg-jobpsec-Dropdown-toggle"
                >
                  {/* {companyyName} */}
                  {selectedItem ? selectedItem.categoryName : "Select company"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  as={CustomMenu}
                  className="dkg-jobpsec-Dropdown-Menu w-100"
                >
                  {/* {
                    data.map((item, i) => (
                      <Dropdown.Item href="#" className='dkg-jobpsec-Dropdown-Item' key={i + 1} onClick={() => selectCat(item.id, item.categoryName, item.companyJobSp, item.companyVideoJobSp, item.companyAlbumsJobSp, item.companyImageJobSp)}>{item.categoryName}</Dropdown.Item>
                    ))
                  } */}
                  {data.map((item, i) => (
                    <Dropdown.Item
                      href="#"
                      className="dkg-jobpsec-Dropdown-Item"
                      key={i + 1}
                      onClick={() => handleSelect(item)}
                    >
                      {item.categoryName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div
          className="dkg-jobspec-compainesCon"
          style={{
            height: "calc(100vh - 178px)",
            backgroundColor: "#eaeaea",
            marginTop: "6px",
          }}
        >
          {companyyName !== "Select Company" ? (
            <>
              <div className="col-md-12 dkg-jobspec-rt-tabCon-12 pl-0">
                <Tab.Content className="dkg-jobspec-qt-tabcontent">
                  <div className="dkg-jobspec-qt-rightpanel">
                    <div className="dkg-tabs-rightPanel-12">
                      <div className="dkg-subcategory-header d-none">
                        <div className="title dkg-page-title dkg-overview-title">
                          {companyyName != "" ? <>{companyyName}</> : null}
                        </div>
                      </div>
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="dkg-company-info"
                      >
                        <div className="dk-company-new-panel-13">
                          <div className="dk-company-new-detail-13">
                            <Nav
                              variant="pills"
                              className="flex-column dkg-company-new-navpills"
                              onSelect={(selectedKey) =>
                                SetEventKey(selectedKey)
                              }
                            >
                              <Nav.Item className="dkg-company-new-navitem">
                                <Nav.Link
                                  eventKey="dkg-company-info"
                                  className="dkg-company-new-navlink"
                                >
                                  Overview
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="dkg-company-new-navitem d-none">
                                <Nav.Link
                                  eventKey="dkg-company-location"
                                  className="dkg-company-new-navlink"
                                >
                                  Location
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="dkg-company-new-navitem">
                                <Nav.Link
                                  eventKey="dkg-company-aboutus"
                                  className="dkg-company-new-navlink"
                                >
                                  Company
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="dkg-company-new-navitem">
                                <Nav.Link
                                  eventKey="dkg-company-services"
                                  className="dkg-company-new-navlink"
                                >
                                  Services
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="dkg-company-new-navitem">
                                <Nav.Link
                                  eventKey="dkg-company-video"
                                  className="dkg-company-new-navlink"
                                >
                                  Videos
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="dkg-company-new-navitem">
                                <Nav.Link
                                  eventKey="dkg-company-album"
                                  className="dkg-company-new-navlink"
                                >
                                  Images
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="dkg-company-new-navitem">
                                <Nav.Link
                                  eventKey="dkg-company-socail-media"
                                  className="dkg-company-new-navlink"
                                >
                                  Media
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <Tab.Content className="dk-company-new-tabcontent-13">
                              <Tab.Pane
                                eventKey="dkg-company-info"
                                className="dkg-company-new-tabpane"
                              >
                                <CompanyInfo
                                  selectedItem={selectedItem.companyJobSp}
                                />
                              </Tab.Pane>
                              <Tab.Pane
                                eventKey="dkg-company-location"
                                className="dkg-company-new-tabpane dkg-location-tabpane"
                              >
                                <LocationTab />
                              </Tab.Pane>
                              <Tab.Pane
                                eventKey="dkg-company-aboutus"
                                className="dkg-company-new-tabpane dkg-company-abouts-tabpane"
                              >
                                <AboutUs
                                  selectedItem={selectedItem.companyJobSp}
                                />
                              </Tab.Pane>
                              <Tab.Pane
                                eventKey="dkg-company-services"
                                className="dkg-company-new-tabpane dkg-company-abouts-tabpane"
                              >
                                <Services
                                  selectedItem={selectedItem.companyJobSp}
                                />
                              </Tab.Pane>

                              <Tab.Pane
                                eventKey="dkg-company-video"
                                className="dkg-company-new-tabpane"
                              >
                                <CompanyVideo
                                  compVideo={comvidjobsp}
                                  selectedItem={selectedItem.companyVideoJobSp}
                                />
                              </Tab.Pane>
                              <Tab.Pane
                                eventKey="dkg-company-album"
                                className="dkg-company-new-tabpane"
                              >
                                <CompanyAlbum
                                  compAlbum={selectedItem.companyAlbumsJobSp}
                                  albumImgs={selectedItem.companyImageJobSp}
                                />
                              </Tab.Pane>

                              <Tab.Pane
                                eventKey="dkg-company-socail-media"
                                className="dkg-company-new-tabpane"
                              >
                                <CompanySocailMedia
                                  companySocialMedia={comjobsp}
                                  selectedItem={selectedItem.companyJobSp}
                                />
                              </Tab.Pane>
                            </Tab.Content>
                          </div>
                        </div>
                      </Tab.Container>
                    </div>
                  </div>
                </Tab.Content>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Overview;
