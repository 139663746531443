import React from 'react';
import { Link } from 'react-router-dom'
import './clientsmodule.scss';
import AdminHeader from '../../../elements/header/Header'
import Navbar from "../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../elements/submenu/mainmenu/DashboardSubmenu';
import CurrentClientsIcon from "../../../../assets/images/clients-info.png";
import ManageClientsIcon from "../../../../assets/images/clientsmanagement_icon.png";
import ProjectionsIcon from "../../../../assets/images/projections.png";
import CompanyIcon from "../../../../assets/images/building_icon.png";
import ContractsIcon from "../../../../assets/images/contracts.png";
import EmployeesIcon from "../../../../assets/images/enterprise.png";
import FAQIcon from "../../../../assets/images/information_white.png";


const ClientsModule = () => {
    return (
        <>
            <AdminHeader />
            <Navbar />
            <Submenu />
            <div className="dk-clientsModulemain">
                <div className="dk-clientsModule-container">
                    <div className="title mb-2">CLIENTS DEPARTMENT</div>
                    <div className="d-flex">
                        <div className="dk-clientsModule-box">
                            <Link to="#" style={{ background: '#144773' }}>
                                <img src={CurrentClientsIcon} alt="" />
                                <p>Current Clients</p>
                            </Link>
                        </div>
                        <div className="dk-clientsModule-box">
                            <Link to="#" style={{ background: '#144773' }}>
                                <img src={ManageClientsIcon} alt="" />
                                <p>Manage Clients</p>
                            </Link>
                        </div>
                        <div className="dk-clientsModule-box">
                            <Link to="#" style={{ background: '#144773' }}>
                                <img src={ProjectionsIcon} alt="" />
                                <p>Projections</p>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="dk-clientsModule-box">
                            <Link to="#" style={{ background: '#144773' }}>
                                <img src={CompanyIcon} alt="" />
                                <p>New Leads</p>
                            </Link>
                        </div>
                        <div className="dk-clientsModule-box">
                            <Link to="#" style={{ background: '#144773' }}>
                                <img src={EmployeesIcon} alt="" />
                                <p>In Progress</p>
                            </Link>
                        </div>
                        <div className="dk-clientsModule-box">
                            <Link to="#" style={{ background: '#144773' }}>
                                <img src={ContractsIcon} alt="" />
                                <p>Closed</p>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="dk-clientsModule-box">
                            <Link to="#" style={{ background: '#144773' }}>
                                <p></p>
                            </Link>
                        </div>
                        <div className="dk-clientsModule-box">
                            <Link to="#" style={{ background: '#144773' }}>
                                <p></p>
                            </Link>
                        </div>
                        <div className="dk-clientsModule-box">
                            <Link to="#" style={{ background: '#144773' }}>

                                <img src={FAQIcon} alt="" />
                                <p>FAQ</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClientsModule