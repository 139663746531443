import React from 'react'

const CommentsPopup = () => {
  return (
    <>
      <span className="dkg-active-commentCon">
        <span className="dkg-number-badge">1</span>
      </span>
    </>
  )
}

export default CommentsPopup