import React from 'react';
import { Route, Link } from "react-router-dom";

const IntspecSubmenu = () => {
    const pathname = window.location.pathname;
    return (
        <div>
            <div className="dk-adminSubmenuMain">
                <Route>
                    <ul>
                        <li><Link to="/int-spec/summary" className={`${pathname.match('/int-spec/summary') ? 'sub-active' : ''}`}>Summary</Link></li>
                        <li><Link to="#/mailbox/mailbox" className={`${pathname.match('/mailbox/report') ? 'sub-active' : ''}`}>Int Spec 1</Link></li>
                        <li><Link to="#/mailbox/tasks" className={`${pathname.match('/mailbox/validation') ? 'sub-active' : ''}`}>Int Spec 2</Link></li>
                        <li><Link to="#/mailbox/actions" className={`${pathname.match('/mailbox/mailshot') ? 'sub-active' : ''}`}>Int Spec 3</Link></li>
                        <li><Link to="#/mailbox/notification" className={`${pathname.match('/dashboard/templates') ? 'sub-active' : ''}`}>Int Spec 4</Link></li>
                        <li><Link to="#/mailbox/summary" className={`${pathname.match('/mailbox/inbox') ? 'sub-active' : ''}`}>Int Spec 5</Link></li>
                        <li><Link to="#/mailbox/summary" className={`${pathname.match('/mailbox/inbox') ? 'sub-active' : ''}`}>Int Spec 6</Link></li>

                    </ul>
                </Route>
            </div>
        </div>
    )
}

export default IntspecSubmenu;
