import React, { useState, useEffect } from "react";
//import { Tab, Nav } from "react-bootstrap";
import SubmitButton from "../../../../../ui/submitButton";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { useDispatch, useSelector } from "react-redux";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { listData, updateData } from "../../../../../../slice/cmsSlice";
import { showError } from "../../../../../utils/messages/messageSlice";

const GdprEditor = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList } = useSelector((state) => state.cms);
  // const [loading, setLoading] = useState(false);
  // const [success, setSuccess] = useState(false);

  const [editorData, setEditorData] = useState("");
  const [editorJobseekerState, setEditorJobseekerState] = useState(
    EditorState.createEmpty()
  );

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(listData({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setEditorData(getcmsList[4].description);
    }
  }, [isLoading]);

  /********jobseeker***********/
  let jobseekerDetails =
    editorData !== undefined && editorData !== null ? editorData : "";
  const html_2 = jobseekerDetails;
  const contentBlock_2 = htmlToDraft(html_2);
  const contentState_2 = ContentState.createFromBlockArray(
    contentBlock_2.contentBlocks
  );
  useEffect(() => {
    setEditorJobseekerState(EditorState.createEmpty());
    if (editorData !== "") {
      setEditorJobseekerState(EditorState.createWithContent(contentState_2));
    }
  }, [editorData]);

  const onJobseekerEditorStateChange = async (state) => {
    setEditorJobseekerState(state);
  };
  /*******************/
  const SubmitBtn = () => {
    let contentAsHTML = draftToHtml(
      convertToRaw(editorJobseekerState.getCurrentContent())
    );
    if (contentAsHTML == "<p></p>\n") {
      dispatch(updateData({ id: 5, key: "description", value: "" }));
    } else {
      dispatch(
        updateData({
          id: 5,
          key: "description",
          value: contentAsHTML,
        })
      );
    }
  };

  return (
    <>
      <div className="dkg-instructions-editorCon">
        <Editor
          editorState={editorJobseekerState}
          onEditorStateChange={onJobseekerEditorStateChange}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          toolbar={{
            options: [
              "inline",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "colorPicker",
              "link",
              "image",
            ],
            inline: { inDropdown: false },
            list: { inDropdown: false },
            textAlign: { inDropdown: false },
            link: { inDropdown: false },
            history: { inDropdown: false },
          }}
        />
        <div className="dkg-submit-btnCon">
          <SubmitButton
            txt="SAVE"
            className="dkg-cand-submit-btn"
            onClick={SubmitBtn}
          />
        </div>
      </div>
    </>
  );
};

export default GdprEditor;
