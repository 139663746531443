import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton, Tab, Nav } from "react-bootstrap";
import "./faq-catprescreen.scss";
import {
  allcompanylist,
  getCompany,
  getCategory,
  updateSequence,
  updateCatSequence,
  updateQueAnsStatus,
  getQueAns,
} from "../../../../../../slice/faq/jobsFaqSlice";
import AddNewCategory from "./modal/AddNewCategory";
import EditCompany from "./modal/EditCompany";
import EditCategory from "./modal/EditCategory";
import DeleteDummy from "./modal/DeleteDummy";
import { FaArrowsAlt } from "react-icons/fa";
import AddNewCompany from "./modal/AddNewCompany";
import ReadQuestionModal from "./modal/ReadQuestion";
import { showError } from "../../../../../../components/utils/messages/messageSlice";
import AddNewQuestions from "../questions/modal/AddNewQuestions";
import EditQuestion from "../questions/modal/EditQuestionAnswer";

//import $ from "jquery";
import QuestionReorder from "../questions/modal/QuestionReorder";
import CompanyReorder from "../questions/modal/CompanyReorder";
import CategoryReorder from "../questions/modal/CategoryReorder";

const CompanyTable = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    isSuccess,
    companyList,
    categoryList,
    quesansList,
    isCompanyInsert,
  } = useSelector((state) => state.jobsfaqs);

  const [isCategoryTable, setisCategoryTable] = useState(false);
  const [isQuestionTable, isSetQuestionTable] = useState(false);
  const [isCompanyTable, setisCompanyTable] = useState(true);
  const [isCompanyData, isSetCompanyData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [quesAns, setQueAns] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [categoryNames, setCategoryNames] = useState("");
  const [isActiveQs, setIsActiveQs] = useState("");
  const [isActiveAns, setIsActiveAns] = useState("");
  const [defaultCatId, setDefaultCatId] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(allcompanylist({}));
        await dispatch(getCompany({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch, isCompanyInsert]);

  useEffect(() => {
    if (!isLoading && companyList && companyList.length > 0) {
      isSetCompanyData(companyList);
      setCategoryData(categoryList);
      setQueAns(quesansList);
    }
  }, [isLoading]);

  const changeQueAnsStatus = async (id, stts) => {
    await dispatch(
      updateQueAnsStatus({
        id: id,
        key: "status",
        value: stts,
      })
    );
  };
  const changeCategoryStatus = async (id, stts) => {
    await dispatch(
      updateCatSequence({
        id: id,
        key: "status",
        value: stts,
      })
    );
  };

  const changeStatusValue = async (id, stts) => {
    await dispatch(
      updateSequence({
        id: id,
        key: "status",
        value: stts,
      })
    );
  };

  const categorypage = async (catid, name) => {
    setCompanyId(catid);
    setCompanyName(name);
    dispatch(getCategory({ id: catid }));
    setisCategoryTable(true);
    setisCompanyTable(false);
    isSetQuestionTable(false);
  };

  const gotoback = () => {
    setisCategoryTable(false);
    isSetQuestionTable(false);
    setisCompanyTable(true);
  };

  const gotoQuestionPage = (id, name) => {
    setCategoryNames(name);
    setDefaultCatId(id);
    isSetQuestionTable(true);
    setisCategoryTable(false);
    setisCompanyTable(false);
    dispatch(getQueAns({ id }));
  };

  const [reorderCompanyModal, setReorderCompanyModal] = useState(false);
  const [isreadModal, setReadModal] = useState(false);
  const [reorderCategoryModal, setReorderCategoryModal] = useState(false);

  const [reorderQueAnsModal, setReorderQueAnsModal] = useState(false);

  const showQueAnsReorderModal = () => {
    setReorderQueAnsModal(true);
  };
  const hideQueAnsModal = () => {
    setReorderQueAnsModal(false);
  };

  const showCategoryReorderModal = () => {
    setReorderCategoryModal(true);
  };
  const hideCategoryModal = () => {
    setReorderCategoryModal(false);
  };

  const showCompReorderModal = () => {
    setReorderCompanyModal(true);
  };
  const hideCompanyModal = () => {
    setReorderCompanyModal(false);
  };

  const showreadModal = (ques, ans) => {
    setIsActiveQs(ques);
    setIsActiveAns(ans);
    setReadModal(true);
  };
  const hidereadModal = () => {
    setReadModal(false);
  };
  return (
    <>
      {isreadModal ? (
        <ReadQuestionModal
          onHide={hidereadModal}
          questiontitle={isActiveQs}
          answerTitle={isActiveAns}
        />
      ) : null}

      {isCompanyTable == true ? (
        <div className="dkg-task-paymentpage12 container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="dk-faq-candprescreen-TopHead">
                <div className="dk-faq-candprescreenLeftTopHead">
                  <div className="dk-SrchInputBox mr-2">
                    <AddNewCompany />
                  </div>
                </div>
                <div className="dk-faq-candprescreen-titleCon">
                  <h2 className="dk-faq-candprescreen-title">
                    Job Company FAQ's
                  </h2>
                </div>
                <div className="dk-faq-right-candprescreenCon">
                  <Link
                    to="#"
                    className="dk-faq-addcategory-btn"
                    onClick={showCompReorderModal}
                  >
                    <FaArrowsAlt /> Reorder
                  </Link>
                  {reorderCompanyModal ? (
                    <CompanyReorder
                      onHide={hideCompanyModal}
                      dataList={isCompanyData}
                    />
                  ) : null}
                </div>
              </div>
              <div className="dkg-faq-candprescreen-tableCon-12 dkg-client-faq-tableCon">
                <table className="table table-borderd dkg-faq-category-table">
                  <thead>
                    <tr>
                      <th>S No.</th>
                      <th>Status</th>
                      <th>Name of Company</th>
                      <th>ADD/EDIT CATEGORY</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {isCompanyData.map((item, key) => (
                      <tr key={key + 1}>
                        <td>{key + 1}</td>
                        <td>
                          <DropdownButton
                            className={
                              item.status == "Active"
                                ? "dkg-status-dropdown activeClass"
                                : "dkg-status-dropdown deActiveClass"
                            }
                            title={item.status}
                          >
                            <Dropdown.Item
                              href="#"
                              className="dkg-status-dropdown-item"
                              style={{
                                backgroundColor: "#3a9469",
                                color: "#fff",
                              }}
                              onSelect={() =>
                                changeStatusValue(item.id, "Active")
                              }
                            >
                              Active
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              className="dkg-status-dropdown-item"
                              style={{
                                backgroundColor: "#625c60",
                                color: "#fff",
                              }}
                              onSelect={() =>
                                changeStatusValue(item.id, "Inactive")
                              }
                            >
                              Inactive
                            </Dropdown.Item>
                          </DropdownButton>
                        </td>
                        <td>{item.name} </td>
                        <td>
                          <div className="dkg-addnew-quest-btnCon">
                            <Link
                              to="#"
                              onClick={(e) => categorypage(item.id, item.name)}
                              className="dkg-addnew-quest-btn"
                            >
                              Go to Categories
                            </Link>
                          </div>
                        </td>

                        <td>
                          <div className="dkg-faq-actions-col">
                            <EditCompany
                              rowid={item.id}
                              companyname={item.name}
                              seqNo={item.orderNo}
                            />
                            <DeleteDummy rowid={item.id} type="company" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {isCategoryTable == true ? (
        <div className="dkg-task-paymentpage12 container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="dk-faq-candprescreen-TopHead">
                <div className="dk-faq-candprescreenLeftTopHead">
                  <div className="dk-SrchInputBox mr-2">
                    <AddNewCategory
                      companyId={companyId}
                      comName={companyName}
                    />
                  </div>
                </div>
                <div className="dk-faq-candprescreen-titleCon">
                  <h2 className="dk-faq-candprescreen-title">
                    LIST OF CATEGORIES
                  </h2>
                </div>
                <div className="dk-faq-right-candprescreenCon">
                  <Link
                    to="#"
                    className="dk-faq-addcategory-btn mr-2"
                    onClick={showCategoryReorderModal}
                  >
                    <FaArrowsAlt /> Reorder
                  </Link>
                  {reorderCategoryModal ? (
                    <CategoryReorder
                      onHide={hideCategoryModal}
                      dataList={categoryData}
                      companyId={companyId}
                    />
                  ) : null}

                  <Link
                    to="#"
                    onClick={(e) => gotoback()}
                    className="dk-faq-addcategory-btn ml-2"
                  >
                    <i className="fa fa-arrow-left"></i> Back
                  </Link>
                </div>
              </div>
              <div className="dkg-faq-candprescreen-tableCon-12 dkg-client-faq-tableCon">
                <table className="table table-borderd dkg-faq-category-table">
                  <thead>
                    <tr>
                      <th>S No.</th>
                      <th>Status</th>
                      <th>Name of Company</th>
                      <th>NAME OF CATEGORIES</th>
                      <th>ADD/EDIT QUESTIONS</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoryData.map((catdata, key) => (
                      <tr>
                        <td>{key + 1}</td>
                        <td>
                          <DropdownButton
                            className={
                              catdata.status == "Active"
                                ? "dkg-status-dropdown activeClass"
                                : "dkg-status-dropdown deActiveClass"
                            }
                            title={catdata.status}
                          >
                            <Dropdown.Item
                              href="#"
                              className="dkg-status-dropdown-item"
                              style={{
                                backgroundColor: "#3a9469",
                                color: "#fff",
                              }}
                              onSelect={() =>
                                changeCategoryStatus(catdata.id, "Active")
                              }
                            >
                              Active
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              className="dkg-status-dropdown-item"
                              style={{
                                backgroundColor: "#625c60",
                                color: "#fff",
                              }}
                              onSelect={() =>
                                changeCategoryStatus(catdata.id, "Inactive")
                              }
                            >
                              Inactive
                            </Dropdown.Item>
                          </DropdownButton>
                        </td>

                        <td>{catdata.categoryInfo.name}</td>
                        <td>{catdata.name}</td>
                        <td>
                          <div className="dkg-addnew-quest-btnCon">
                            <Link
                              to="#"
                              onClick={(e) =>
                                gotoQuestionPage(catdata.id, catdata.name)
                              }
                              className="dkg-addnew-quest-btn"
                            >
                              Go to Questions
                            </Link>
                          </div>
                        </td>

                        <td>
                          <div className="dkg-faq-actions-col">
                            <EditCategory
                              rowid={catdata.id}
                              catname={catdata.name}
                              seqNo={catdata.orderNo}
                            />
                            <DeleteDummy rowid={catdata.id} type="Category" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {isQuestionTable == true ? (
        <>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <div className="row dkg-cand-faq-question-tabCon m-0">
              <div className="col-md-12 dkg-cand-faq-question-rightCon">
                <Tab.Content className="dkg-cand-faq-question-tabContent">
                  <Tab.Pane
                    eventKey="first"
                    className="dkg-cand-faq-question-tabpane"
                  >
                    <div className="dkg-task-paymentpage12 container-fluid">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dk-faq-candprescreen-TopHead">
                            <div className="dk-faq-candprescreenLeftTopHead">
                              <div className="dk-SrchInputBox">
                                <AddNewQuestions
                                  catname={categoryNames}
                                  company={companyName}
                                  categoryid={defaultCatId}
                                  companyId={companyId}
                                />
                              </div>
                            </div>
                            <div className="dk-faq-candprescreen-titleCon">
                              <h2 className="dk-faq-candprescreen-title">
                                LIST OF QUESTIONS
                              </h2>
                            </div>
                            <div className="dk-faq-right-candprescreenCon">
                              <Link
                                to="#"
                                className="dk-faq-addcategory-btn mr-2"
                                onClick={showQueAnsReorderModal}
                              >
                                <FaArrowsAlt /> Reorder
                              </Link>
                              {reorderQueAnsModal ? (
                                <QuestionReorder
                                  onHide={hideQueAnsModal}
                                  dataList={quesAns}
                                  categoryId={defaultCatId}
                                />
                              ) : null}
                              <Link
                                to="#"
                                onClick={(e) =>
                                  categorypage(companyId, companyName)
                                }
                                className="dk-faq-addcategory-btn dkg-faq-back-catbtn"
                              >
                                <i className="fa fa-arrow-left"></i> Back
                              </Link>
                            </div>
                          </div>
                          <div className="dkg-faq-candprescreen-tableCon-12 ">
                            <table className="table table-borderd dkg-faq-category-table dkg-faq-category-table-234">
                              <thead>
                                <tr>
                                  <th>S No.</th>
                                  <th>Status</th>
                                  <th>Company</th>
                                  <th>Category</th>
                                  <th>QUESTIONS/ANSWERS</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {quesAns.map((rec, key) => (
                                  <tr>
                                    <td>{key + 1}</td>
                                    <td>
                                      <DropdownButton
                                        className={
                                          rec.status == "Active"
                                            ? "dkg-status-dropdown activeClass"
                                            : "dkg-status-dropdown deActiveClass"
                                        }
                                        title={rec.status}
                                      >
                                        <Dropdown.Item
                                          href="#"
                                          className="dkg-status-dropdown-item"
                                          style={{
                                            backgroundColor: "#3a9469",
                                            color: "#fff",
                                          }}
                                          onSelect={() =>
                                            changeQueAnsStatus(rec.id, "Active")
                                          }
                                        >
                                          Active
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          className="dkg-status-dropdown-item"
                                          style={{
                                            backgroundColor: "#625c60",
                                            color: "#fff",
                                          }}
                                          onSelect={() =>
                                            changeQueAnsStatus(
                                              rec.id,
                                              "Inactive"
                                            )
                                          }
                                        >
                                          Inactive
                                        </Dropdown.Item>
                                      </DropdownButton>
                                    </td>
                                    <td>{rec.QuesAnsInfo.name}</td>
                                    <td>{rec.CatInfo.name}</td>
                                    <td>
                                      <div
                                        className="dkg-faq-questtitle"
                                        onClick={(e) =>
                                          showreadModal(
                                            rec.question,
                                            rec.answer
                                          )
                                        }
                                      >
                                        {rec.question}
                                      </div>
                                    </td>

                                    <td>
                                      <div className="dkg-faq-actions-col">
                                        <EditQuestion
                                          rowid={rec.id}
                                          qes={rec.question}
                                          anss={rec.answer}
                                        />
                                        <DeleteDummy
                                          rowid={rec.id}
                                          type="QuesAns"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </>
      ) : null}
    </>
  );
};

export default CompanyTable;
