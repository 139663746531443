import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import "./kpireport-sendouts.scss";
import {
  updateQuesAns, 
  clearState,
} from "../../../../../../../../slice/faq/faqSlice";
import SubmitButton from "../../../../../../../ui/submitButton"; 
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../../../utils/messages/messageSlice";

const EditQuestionAnswer = ({ rowid, qes, anss }) => {
  const dispatch = useDispatch();
  const { isSuccess, isError, isUpdate } = useSelector((state) => state.faqs);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [questionS, setIsQuestion] = useState("");
  const [isAnswer, setIsAnswer] = useState("");
  const [rowwId, setRowwId] = useState("");

  useEffect(() => {
    setIsQuestion(qes);
    setIsAnswer(anss);
    setRowwId(rowid);
  }, [qes, anss, rowid]);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setShow(false);
      setLoading(false);
      setSuccess(false);
    }
  }, [isError]);

  useEffect(() => {
    if (isUpdate) {
      setLoading(false);
      setShow(false);
    }
  }, [isUpdate]);

  const updateData = (e) => {
    e.preventDefault();

    setSuccess(false);
    setLoading(true);
    if (!questionS) {
      dispatch(showError({ msg: "Please enter question name" }));
      setLoading(false);
    } else {
      setLoading(true);
      dispatch(
        updateQuesAns({
          id: rowwId,
          question: questionS,
          answer: isAnswer,
        })
      );
    }
  };

  return (
    <>
      <Link to="#" onClick={handleShow} className="dkg-faq-edit-btn mr-2">
        <i className="fa fa-edit " aria-hidden="true"></i>
      </Link>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="dkg-faq-addnew-questionModal"
      >
        <Modal.Header closeButton className="dk-faq-addquestion-modal-header">
          <Modal.Title>Update Faq Question Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dk-faq-addquestion-modal-body">
          <div className="form-group mb-0">
            <label>Question</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setIsQuestion(e.target.value)}
              value={questionS}
            />
          </div>
          <div className="form-group mb-0">
            <label>Answer</label>
            <textarea
              rows="5"
              class="form-control"
              onChange={(e) => setIsAnswer(e.target.value)}
              value={isAnswer}
            ></textarea>
          </div>
          {/* <div className="form-group mb-0">
                        <label>Sequence</label>
                        <input type="text" className="form-control" />
                    </div> */}
        </Modal.Body>
        <Modal.Footer className="dk-faq-addquestion-modal-footer">
          {/* <Link to="#" className="dkg-create-btn mr-2">
            Add
          </Link> */}
          <Link to="#" onClick={handleClose} className="dkg-close-btn">
            Close
          </Link>
          <SubmitButton
            txt="Update"
            loading={loading}
            success={success}
            onClick={updateData}
            position="justify-content-center"
            className="dkg-create-btn mr-2"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditQuestionAnswer;
