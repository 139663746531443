import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import "./view-project.scss";
import { projectUpdateData } from "../../../../../../slice/cmsSlice";
import SubmitButton from "../../../../../ui/submitButton";

const ViewProject = ({ projectdetails }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [newTitle, setTitle] = useState("");
  const [details, setDetails] = useState("");
  useEffect(() => {
    setDetails(projectdetails.details);
    setTitle(projectdetails.title);
  }, [projectdetails]);

  const SubmitBtn = (id, value, key) => {
    dispatch(
      projectUpdateData({
        id,
        title: newTitle,
        details: details,
      })
    );
  };

  return (
    <>
      <span className="dkg-info-icon" onClick={handleShow}>
        Summary
      </span>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="dkg-projects-summModal"
      >
        <Modal.Header closeButton className="dkg-projects-summModalHeader">
          <Modal.Title>Project Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-projects-summModalBody">
          <div className="dkg-projects-frmgrp">
            <input
              type="text"
              className="dkg-projects-input form-control"
              name="project-title"
              id="project-title"
              defaultValue={newTitle != "" ? newTitle : ""}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="dkg-projects-frmgrp">
            <textarea
              name="project-summary"
              id=""
              className="dkg-projects-summtextArea"
              onChange={(e) => setDetails(e.target.value)}
            >
              {details != "" ? details : ""}
            </textarea>
          </div>
          <div className="dkg-cand-submit-btnCon">
            <SubmitButton
              txt="SAVE"
              className="dkg-cand-submit-btn"
              onClick={(e) => SubmitBtn(projectdetails.id, details, "details")}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewProject;
