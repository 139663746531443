import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav,Button } from "react-bootstrap";
import SubmitButton from "../../../../ui/submitButton";
import { updateData, clearState } from "../../../../../slice/cmsSlice";

const Jobtitles = ({ data }) => {
  const dispatch = useDispatch();
  const [overview, setOverview] = useState("");
  useEffect(() => {
    setOverview(data != "" ? data[15].description : "");
  }, [data]);

  const SubmitBtn = (id, value, key) => {
    dispatch(
      updateData({
        id,
        key,
        value,
      })
    );
  };

  return (
    <>
      <div className="dkg-cand-services-Side">
        <Tab.Container defaultActiveKey="first">
          <div className="row m-0 dkg-payment-qt-tab-mainCon">
            <div className="col-md-2 pl-0 pr-0 dkg-payment-qt-tabCon dkg-profile-task-sideMenu">
              <Nav
                variant="pills"
                className="flex-column dkg-payment-qt-tab-navpills"
              >
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="first"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Instructions
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="second"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Saved Tags
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-md-10  dkg-paidservices-rightCon pl-0 pr-0">
              <Tab.Content className="dkg-paidservices-tabcontent">
                <Tab.Pane eventKey="first" className="dkg-paidservices-tabpane">
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Summary</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <textarea
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue={overview != "" ? overview : ""}
                      onChange={(e) => setOverview(e.target.value)}
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                        onClick={(e) => SubmitBtn(16, overview, "description")}
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Saved Tags</h4>
                  </div>
                  <div className="dkg-paidservices-body dkg-desiredjob-modalbody">
                  <div className="dkg-desired-center-sec dkg-desired-job-centersec">
                        <div className="dkg-desired-job-rightCon">
                          <div className="dkg-dj-rightfirst-cols">
                            <div className="dkg-saved-cv-tag-title">TAGS LIBRARY</div>
                            <div className="dkg-desired-job-btn-tagCon">
                              <Button className="dkg-desired-btntext">India <span className="dkg-tag-icon"> <i className="fas fa-times"></i></span></Button>
                              <Button className="dkg-desired-btntext">Ireland <span className="dkg-tag-icon"> <i className="fas fa-times"></i></span></Button>
                              <Button className="dkg-desired-btntext">UK <span className="dkg-tag-icon"> <i className="fas fa-times"></i></span></Button>
                              <Button className="dkg-desired-btntext">USA <span className="dkg-tag-icon"> <i className="fas fa-times"></i></span></Button>
                              <Button className="dkg-desired-btntext">Grecee <span className="dkg-tag-icon"> <i className="fas fa-times"></i></span></Button>
                              <Button className="dkg-desired-btntext">Germany <span className="dkg-tag-icon"> <i className="fas fa-times"></i></span></Button>
                            </div>
                          </div>
                          <div className="dkg-dj-rightsecond-cols">
                            <div className="dkg-desired-job-inputCon">
                              <div className="dkg-createnew-tag-title">CREATE LIBRARY TAG</div>
                              <div className="dkg-desired-job-inputCon">
                                <input type="text" className="dkg-desired-job-input form-control" placeholder="" defaultValue="" />
                                <div className="dkg-desired-job-save-btnCon">
                                  <SubmitButton
                                    txt="Save"
                                    className="dkg-desired-job-save-btn btn"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default Jobtitles;
