import React from "react";
import { Route, Link } from "react-router-dom";

const ClientInfoSubmenu = () => {
  const pathname = window.location.pathname;
  return (
    <div>
      <div className="dk-adminSubmenuMain">
        <Route>
          <ul>
            {/* <li>
              <Link
                to="/client-info/summary"
                className={`${
                  pathname.match("/client-info/summary") ? "sub-active" : ""
                }`}
              >
                Summary
              </Link>
            </li> */}
            <li>
              <Link
                to="/client-info/client-list"
                className={`${
                  pathname.match("/client-info/client-list") ? "sub-active" : ""
                }`}
              >
                Clients List
              </Link>
            </li>
            {/* <li><Link to="/client-info/demands" className={`${pathname.match('/client-info/demands') ? 'sub-active' : ''}`}>Demands</Link></li> */}
            {/* <li>
              <Link
                to="/client-info/company"
                className={`${
                  pathname.match("/client-info/company") ? "sub-active" : ""
                }`}
              >
                Companies
              </Link>
            </li>
            <li>
              <Link
                to="/client-info/projects"
                className={`${
                  pathname.match("/client-info/projects") ? "sub-active" : ""
                }`}
              >
                Projects
              </Link>
            </li>
            <li>
              <Link
                to="/client-info/role-types"
                className={`${
                  pathname.match("/client-info/role-types") ? "sub-active" : ""
                }`}
              >
                Role Types
              </Link>
            </li>

            <li>
              <Link
                to="/client-info/salaries"
                className={`${
                  pathname.match("/client-info/salaries") ? "sub-active" : ""
                }`}
              >
                Interviews
              </Link>
            </li>
            <li>
              <Link
                to="/client-info/benefits"
                className={`${
                  pathname.match("/client-info/benefits") ? "sub-active" : ""
                }`}
              >
                Job Offers
              </Link>
            </li>
            <li>
              <Link
                to="/client-info/relocation"
                className={`${
                  pathname.match("/client-info/relocation") ? "sub-active" : ""
                }`}
              >
                Relocations
              </Link>
            </li>
            <li>
              <Link
                to="/client-info/requirements"
                className={`${
                  pathname.match("/client-info/requirements")
                    ? "sub-active"
                    : ""
                }`}
              >
                Joining
              </Link>
            </li>
            <li>
              <Link
                to="/client-info/languages"
                className={`${
                  pathname.match("/client-info/languages") ? "sub-active" : ""
                }`}
              >
                Why Apply
              </Link>
            </li>
            <li>
              <Link
                to="/client-info/faq"
                className={`${
                  pathname.match("/client-info/faq") ? "sub-active" : ""
                }`}
              >
                Job FAQ
              </Link>
            </li> */}
            <li>
              <Link
                to="/client-info/portals"
                className={`${
                  pathname.match("/client-info/portals") ? "sub-active" : ""
                }`}
              >
                Portals
              </Link>
            </li>
          </ul>
        </Route>
      </div>
    </div>
  );
};

export default ClientInfoSubmenu;
