import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const subCategoryAdd = createAsyncThunk(
  "clients/requirnment/subcategory",
  async ({ categoryId, keys, subCategoryName }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/requirnments-sub-category",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            categoryId,
            keys,
            subCategoryName,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const addCategory = createAsyncThunk(
  "clients/requirnment/category",
  async ({ categoryName }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/requirnments-category",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            categoryName,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getSubCategories = createAsyncThunk(
  "clients/requirnment/subcatlist",
  async ({ categoryId, keys }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL +
        "/requirnments-sub-category/" +
        categoryId +
        "/" +
        keys,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getSubCatDetails = createAsyncThunk(
  "clients/requirnment/detailsbyid",
  async ({ id }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/requirnments-sub-category/" + id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getCategories = createAsyncThunk(
  "clients/requirnment/categoryReqnmntlist",
  async (thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/requirnments-category/inadmin",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getCompanyById = createAsyncThunk(
  "clients/requirnment/companyByid",
  async ({ id }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/requirnments-category/" + id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const companyUpdate = createAsyncThunk(
  "clients/requirnment/compnayupdatestatus",
  async ({ id, key, value }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL +
        "/requirnments-category/key/" +
        id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            key,
            value,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateEditorValue = createAsyncThunk(
  "clients/requirnment/updateDescriptionkeypair",
  async ({ id, key, value }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL +
        "/requirnments-sub-category/updatekey/" +
        id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            key,
            value,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateValue = createAsyncThunk(
  "clients/requirnment/keypair",
  async ({ id, key, value }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL +
        "/requirnments-sub-category/updatekey/" +
        id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            key,
            value,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateSubCategory = createAsyncThunk(
  "clients/requirnment/updatesubcategory",
  async ({ id, categoryId, subCategoryName, subCategory, keys }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/requirnments-sub-category/" + id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            categoryId,
            subCategoryName,
            subCategory,
            keys,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateCategory = createAsyncThunk(
  "clients/requirnment/updatecategory",
  async ({ id, categoryName }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/requirnments-category/" + id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            categoryName,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const catDelete = createAsyncThunk(
  "clients/requirnment/delete",
  async ({ id }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/requirnments-category/" + id,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const subCatDelete = createAsyncThunk(
  "clients/requirnment/subCatdelete",
  async ({ id, catid, tag }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL +
        "/requirnments-sub-category/" +
        id +
        "/" +
        catid +
        "/" +
        tag,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const reorderData = createAsyncThunk(
  "clients/requirnment/reorderlist",
  async ({ ids }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/requirnments-category/reorder",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            ids,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const subCatReorderData = createAsyncThunk(
  "clients/requirnment/subreorderlist",
  async ({ ids, catId, tabName }, thunkAPI) => {
    try {
      const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
      const response = await fetch(
        process.env.REACT_APP_BASE_URL +
        "/requirnments-sub-category/reorder/" +
        catId +
        "/" +
        tabName,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_WEB_API_KEY,
            platform: "web",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            ids,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


const usersSlice = createSlice({
  name: "clientInfoRequirnment",
  initialState: {
    categoryReqnmntList: [],
    subCategoryRequirnmentList: [],
    subCategoryDetails: [],
    comapnaydetails: [],
    isCompanyUpdate: false,
    isClientCompUpdate: false,
    isSuccess: false,
    isTabActive: false,
    isCatInsert: false,
    isSubCatInsert: false,
    isCatUpdate: false,
    isSubCatUpdate: false,
    isLoading: false,
    issLoading: false,
    isSubLoading: false,
    isSubReoder: false,
    isError: false,
    isDelete: false,
    issDelete: false,
    isReoder: false,
    isInsert: false,
    isCompany: false,
  },
  reducers: {
    clearState: (state) => {
      state.isCompanyUpdate = false;
      state.isClientCompUpdate = false;
      state.isSubReoder = false;
      state.isDetails = false;
      state.isSubLoading = false;
      state.issLoading = false;
      state.isLoading = false;
      state.isSubCatInsert = false;
      state.isSuccess = false;
      state.isTabActive = false;
      state.isError = false;
      state.isCatInsert = false;
      state.isSubCatUpdate = false;
      state.isCatUpdate = false;
      state.isDelete = false;
      state.issDelete = false;
      state.isReoder = false;
      state.isInsert = false;
      state.isCompany = false;
    },
  },
  extraReducers: {
    [subCatReorderData.pending]: (state) => {
      state.isLoading = true;
      state.isSubReoder = false;
    },
    [subCatReorderData.fulfilled]: (state, { payload }) => {
      state.isSubReoder = true;
      state.isLoading = false;
      state.subCategoryRequirnmentList = payload.result;
      state.msg = "Reorder successfully.";
    },
    [subCatReorderData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = payload.message;
      state.msg = payload.message;
    },
    [reorderData.pending]: (state) => {
      state.isLoading = true;
      state.isReoder = false;
    },
    [reorderData.fulfilled]: (state, { payload }) => {
      state.isReoder = true;
      state.isLoading = false;
      state.categoryReqnmntList = payload.result;
      state.msg = "Reorder successfully.";
    },
    [reorderData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = payload.message;
      state.msg = payload.message;
    },

    [getSubCatDetails.pending]: (state) => {
      state.isError = false;
      state.isDetails = false;
    },
    [getSubCatDetails.fulfilled]: (state, { payload }) => {
      state.isDetails = true;
      state.subCategoryDetails = payload.result;
    },
    [getSubCatDetails.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isDetails = false;
    },
    [getSubCategories.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isTabActive = false;
    },
    [getSubCategories.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isTabActive = true;
      state.isSuccess = true;
      state.subCategoryRequirnmentList = payload.result;
    },
    [getSubCategories.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
      state.isTabActive = false;
    },

    [getCompanyById.pending]: (state) => {
      state.isCompany = true;
      state.isError = false;
    },
    [getCompanyById.fulfilled]: (state, { payload }) => {
      state.isCompany = false;
      state.comapnaydetails = payload.result;
    },
    [getCompanyById.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
    },
    [getCategories.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [getCategories.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.categoryReqnmntList = payload.result;
    },
    [getCategories.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },

    [subCategoryAdd.pending]: (state) => {
      state.isLoading = true;
      state.isSubCatInsert = false;
      state.isError = false;
    },
    [subCategoryAdd.fulfilled]: (state, { payload }) => {
      state.isLoading = true;
      state.isError = true;
      state.isSuccess = true;
      state.isSubCatInsert = true;
      // state.subCategoryRequirnmentList=payload.result
      state.subCategoryRequirnmentList.push(payload.result); //add end of item
      //state.subCategoryRequirnmentList.unshift(payload.result)
    },
    [subCategoryAdd.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },

    [addCategory.pending]: (state) => {
      state.isLoading = true;
      state.isCatInsert = false;
      state.isError = false;
    },
    [addCategory.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = true;
      state.isCatInsert = true;
      state.categoryReqnmntList = payload.result;
    },
    [addCategory.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },

    [updateCategory.pending]: (state) => {
      state.isLoading = true;
      state.isCatUpdate = false;
      state.isError = false;
    },
    [updateCategory.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.isCatUpdate = true;
      let mainArray = state.categoryReqnmntList;
      let finalResult = mainArray.map((r) => {
        if (r.id === payload.result.id) {
          return payload.result;
        } else {
          return r;
        }
      });
      state.categoryReqnmntList = finalResult;
    },
    [updateCategory.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },
    [updateSubCategory.pending]: (state) => {
      state.isLoading = true;
      state.isSubCatUpdate = false;
      state.isError = false;
    },
    [updateSubCategory.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      // state.isSuccess = true;
      state.isSubCatUpdate = true;
      let mainArray = state.subCategoryRequirnmentList;
      let finalResult = mainArray.map((r) => {
        if (r.id === payload.result.id) {
          return payload.result;
        } else {
          return r;
        }
      });
      state.subCategoryRequirnmentList = finalResult;
    },
    [updateSubCategory.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },

    [companyUpdate.pending]: (state) => {
      state.isLoading = true;
      state.isCompanyUpdate = false;
      state.isError = false;
    },
    [companyUpdate.fulfilled]: (state, { payload }) => {
      let mainArray = state.categoryReqnmntList;
      // let finalResult = mainArray.map((r) => {
      //   if (r.id === payload.result.id) {
      //     return payload.result;
      //   } else {
      //     return r;
      //   }
      // });
      state.isCompanyUpdate = true;
      //state.categoryReqnmntList = finalResult;
    },
    [companyUpdate.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },



    [updateValue.pending]: (state) => {
      state.isLoading = true;
      state.isSubCatUpdate = false;
      state.isError = false;
    },
    [updateValue.fulfilled]: (state, { payload }) => {
      //  state.isLoading = false;
      //  state.isError = true;
      //  state.isSubCatUpdate = true;
      let mainArray = state.subCategoryRequirnmentList;
      let finalResult = mainArray.map((r) => {
        if (r.id === payload.result.id) {
          return payload.result;
        } else {
          return r;
        }
      });
      state.isSubCatUpdate = true;
      state.subCategoryRequirnmentList = finalResult;
    },
    [updateValue.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },

    [updateEditorValue.pending]: (state) => {
      state.isInsert = true;
      // state.isLoading = true;
      // state.isSubCatUpdate = false;
      // state.isError = false;
    },
    [updateEditorValue.fulfilled]: (state, { payload }) => {
      state.isInsert = false
      // state.isLoading = false;
      //  state.isError = true;
      //  state.isSubCatUpdate = true;
    },
    [updateEditorValue.rejected]: (state, { payload }) => {
      state.isInsert = false
      //  state.isError = true;
      //state.isLoading = false;
      //  state.isSuccess = false;
    },

    [subCatDelete.pending]: (state) => {
      state.issDelete = false;
      state.isLoading = true;
    },
    [subCatDelete.fulfilled]: (state, payload) => {
      state.issDelete = true;
      state.isLoading = false;
      state.subCategoryRequirnmentList = payload.result;
      // state.subCategoryRequirnmentList = state.subCategoryRequirnmentList.filter((item) => item.id !== action.meta.arg.id);
      state.msg = "Delete Successfully";
    },
    [subCatDelete.rejected]: (state, { payload }) => {
      state.issDelete = false;
      state.isError = true;
      state.isLoading = false;
      state.msg = payload.msgBody;
    },
    [catDelete.pending]: (state) => {
      state.isSuccess = false;
      state.issDelete = false;
      state.isLoading = true;
    },
    [catDelete.fulfilled]: (state, payload) => {
      state.isDelete = true;
      state.isLoading = false;
      state.categoryReqnmntList = payload.result;
      state.msg = "Delete Successfully";
    },
    [catDelete.rejected]: (state, { payload }) => {
      state.isDelete = false;
      state.isError = true;
      state.isLoading = false;
      state.msg = payload.msgBody;
    },
  },
});
export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
