import React, { useState, useCallback, useRef, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import SubmitButton from "../../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit, FaTimes } from "react-icons/fa";
import ChangeIcon from "../../../../../assets/images/complain.png";
import LogoViewImg from "../../../../../assets/images/dkg-jobs.png";

import {
  showError,
  showSuccess,
} from "../../../../utils/messages/messageSlice";
import AWS from "aws-sdk";
import { Buffer } from "buffer";
import "react-image-crop/dist/ReactCrop.css";
//import ReactCrop from 'react-image-crop';
import {
  getTextHeading,
  updateCMSText,
  updateHeadingText,
  clearState,
} from "../../../../../slice/candidate/frontendCms/frontendCmsSlice";
import WhyChoose from "./chooseus/WhyChoose";

const OverviewTab = () => {
  const dispatch = useDispatch();
  //const { getPrincipalsList } = useSelector((state) => state.principals);
  const { isLoading, getcmsList, isUpdate, isSuccess } = useSelector(
    (state) => state.cmslist
  );
  const [data, setData] = useState([]);
  const [pageHeading, setPageHeading] = useState([]);

  // const [firstHeading, setFirstHeading] = useState("");
  // const [firstDescription, setFirstDescription] = useState("");

  // const [secondHeading, setSecondHeading] = useState("");
  // const [secondDescription, setSecondDescription] = useState("");

  // const [thirdHeading, setThirdHeading] = useState("");
  // const [thirdDescription, setThirdDescription] = useState("");

  // const [fourthHeading, setFourthHeading] = useState("");
  // const [fourthDescription, setFourthDescription] = useState("");

  // const [fifthHeading, setFifthHeading] = useState("");
  // const [fifthDescription, setFifthDescription] = useState("");

  // const [sixHeading, setSixHeading] = useState("");
  // const [sixDescription, setSixDescription] = useState("");

  // const [sevenHeading, setSevenHeading] = useState("");
  // const [sevenDescription, setSevenDescription] = useState("");

  // const [eightHeading, setEightHeading] = useState("");
  // const [eightDescription, setEightDescription] = useState("");

  // const [nineHeading, setNineHeading] = useState("");
  // const [nineDescription, setNineDescription] = useState("");

  // const [tenHeading, setTenHeading] = useState("");
  // const [tenDescription, setTenDescription] = useState("");
  // const [elevenHeading, setElevenHeading] = useState("");
  // const [elevenDescription, setElevenDescription] = useState("");
  // const [twelveHeading, setTwelveHeading] = useState("");
  // const [twelveDescription, setTwelveDescription] = useState("");

  // useEffect(() => {
  //   if (!isLoading && getPrincipalsList && getPrincipalsList.length > 0) {
  //     setData(getPrincipalsList);
  //   }
  // }, [isLoading]);

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setData(getcmsList);
      setPageHeading(getcmsList[1]);
      //setFirstHeading(getcmsList[2].heading);
      //setFirstDescription(getcmsList[2].heading_decription);
      //setSecondHeading(getcmsList[3].heading);
      //setSecondDescription(getcmsList[3].heading_decription);
      // setThirdHeading(getcmsList[4].heading);
      // setThirdDescription(getcmsList[4].heading_decription);
      // setFourthHeading(getcmsList[5].heading);
      // setFourthDescription(getcmsList[5].heading_decription);
      // setFifthHeading(getcmsList[6].heading);
      // setFifthDescription(getcmsList[6].heading_decription);
      // setSixHeading(getcmsList[7].heading);
      // setSixDescription(getcmsList[7].heading_decription);

      // setSevenHeading(getcmsList[8].heading);
      // setSevenDescription(getcmsList[8].heading_decription);
      // setEightHeading(getcmsList[9].heading);
      // setEightDescription(getcmsList[9].heading_decription);

      // setNineHeading(getcmsList[10].heading);
      // setNineDescription(getcmsList[10].heading_decription);

      // setTenHeading(getcmsList[12].heading);
      // setTenDescription(getcmsList[12].heading_decription);
      // setElevenHeading(getcmsList[13].heading);
      // setElevenDescription(getcmsList[13].heading_decription);
      // setTwelveHeading(getcmsList[14].heading);
      // setTwelveDescription(getcmsList[14].heading_decription);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getTextHeading({}));
        //await dispatch(getTextGetStart({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  const [firstImg, setFirstImg] = useState(true);
  const [firstImgbase64, setFirstImgbase64] = useState("");

  const [secondImg, setSecondImg] = useState(true);
  const [secondImgbase64, setSecondImgbase64] = useState("");

  const [thirdImg, setthirdImg] = useState(true);
  const [thirdImgbase64, setthirdImgbase64] = useState("");

  const [fourthImg, setfourthImg] = useState(true);
  const [fourthImgbase64, setfourthImgbase64] = useState("");

  const [fifthImg, setfifthImg] = useState(true);
  const [fifthImgbase64, setfifthImgbase64] = useState("");

  const [sixthImg, setSixthImg] = useState(true);
  const [sixthImgbase64, setSixthImgbase64] = useState("");

  const [sevenImg, setSevenImg] = useState(true);
  const [sevenImgbase64, setSevenImgbase64] = useState("");

  const [eightImg, seteightImg] = useState(true);
  const [eightImgbase64, seteightImgbase64] = useState("");

  const [nineImg, setnineImg] = useState(true);
  const [nineImgbase64, setnineImgbase64] = useState("");

  const [tenImg, settenImg] = useState(true);
  const [tenImgbase64, settenImgbase64] = useState("");

  const [elevenImg, setelevenImg] = useState(true);
  const [elevenImgbase64, setelevenImgbase64] = useState("");

  const [twelveImg, settwelveImg] = useState(true);
  const [twelveImgbase64, settwelveImgbase64] = useState("");

  const [ImgName, setImgName] = useState("");

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState({}));
      dispatch(showSuccess({ msg: "Information updated successfully" }));
    }
  }, [isUpdate]);

  //isUpdate
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        if (e.target.name == "sixthImg") {
          setSixthImg(false);
          setSixthImgbase64(reader.result);
        }
        if (e.target.name == "elevenImg") {
          setelevenImg(false);
          setelevenImgbase64(reader.result);
        }
        if (e.target.name == "twelveImg") {
          settwelveImg(false);
          settwelveImgbase64(reader.result);
        }
        if (e.target.name == "tenImg") {
          settenImg(false);
          settenImgbase64(reader.result);
        }
        if (e.target.name == "nineImg") {
          setnineImg(false);
          setnineImgbase64(reader.result);
        }
        if (e.target.name == "eightImg") {
          seteightImg(false);
          seteightImgbase64(reader.result);
        }
        if (e.target.name == "sevenImg") {
          setSevenImg(false);
          setSevenImgbase64(reader.result);
        }
        if (e.target.name == "firstImg") {
          setFirstImg(false);
          setFirstImgbase64(reader.result);
        }
        if (e.target.name == "secondImg") {
          setSecondImg(false);
          setSecondImgbase64(reader.result);
        }
        if (e.target.name == "thirdImg") {
          setthirdImg(false);
          setthirdImgbase64(reader.result);
        }
        if (e.target.name == "fourthImg") {
          setfourthImg(false);
          setfourthImgbase64(reader.result);
        }
        if (e.target.name == "fifthImg") {
          setfifthImg(false);
          setfifthImgbase64(reader.result);
        }

        let s3bucket = new AWS.S3({
          accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
          secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
          Bucket: process.env.REACT_APP_BUCKET_NAME,
        });

        let buf = new Buffer(
          reader.result.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );
        s3bucket.createBucket(function () {
          var params = {
            Bucket: process.env.REACT_APP_BUCKET_NAME + "/users/images",
            Key: Date.now() + ".png",
            ContentEncoding: "base64",
            ContentType: "image/png",
            Body: buf,
          };
          s3bucket.upload(params, function (err, data) {
            if (err) {
              console.log(err);
            } else {
              dispatch(
                updateCMSText({
                  id: e.target.id,
                  key: "image",
                  value: data.key,
                })
              );
            }
          });
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  // Targets all textareas with class "txta"
  // let textareas = document.querySelectorAll(".txta"),
  //   hiddenDiv = document.createElement("div"),
  //   content = null;

  // for (let j of textareas) {
  //   j.classList.add("txtstuff");
  // }
  // hiddenDiv.classList.add("txta");
  // hiddenDiv.style.display = "none";
  // hiddenDiv.style.whiteSpace = "pre-wrap";
  // hiddenDiv.style.wordWrap = "break-word";
  // for (let i of textareas) {
  //   (function (i) {
  //     i.addEventListener("input", function () {
  //       i.parentNode.appendChild(hiddenDiv);
  //       i.style.resize = "none";
  //       i.style.overflow = "hidden";
  //       content = i.value;
  //       content = content.replace(/\n/g, "<br>");
  //       hiddenDiv.innerHTML = content + '<br style="line-height: 3px;">';
  //       hiddenDiv.style.visibility = "hidden";
  //       hiddenDiv.style.display = "block";
  //       i.style.height = hiddenDiv.offsetHeight + "px";
  //       hiddenDiv.style.visibility = "visible";
  //       hiddenDiv.style.display = "none";
  //     });
  //   })(i);
  // }

  const [selectedFile, setSelectedFile] = useState(null);
  const [defaultPreview, setDefaultPreview] = useState(<h3>browse Image</h3>);
  const [isImageUpdate, setIsImageUpdate] = useState(false);
  const [newImageData, setNewImageData] = useState();
  const defaultImage = () => {
    setSelectedFile(null);
    setNewImageData("");
  };

  const [paHeading, setPHeading] = useState("");
  const [paSubHeading, setPSubHeading] = useState("");

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setPHeading(getcmsList[28].heading);
      setPSubHeading(getcmsList[28].heading_decription);
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState({}));
      dispatch(showSuccess({ msg: "Information updated successfully" }));
    }
  }, [isUpdate]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getTextHeading({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  const SubmitBtn = (id) => {
    dispatch(
      updateHeadingText({
        id,
        heading: paHeading,
        heading_decription: paSubHeading,
      })
    );
  };

  return (
    <>
      <div className="dkg-cand-services-Side">
        <Tab.Container defaultActiveKey="title">
          <div className="row m-0 dkg-payment-qt-tab-mainCon">
            <div className="col-md-2 pl-0 dkg-payment-qt-tabCon dkg-profile-task-sideMenu">
              <Nav
                variant="pills"
                className="flex-column dkg-payment-qt-tab-navpills"
              >
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="title"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Banner
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="services"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Services
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="subtitle"
                    className="dkg-payment-qt-tab-navlink"
                  >
                   Choose us 
                  </Nav.Link>
                </Nav.Item> */}
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="why-choose-us"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Choose us
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="our-team"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Consultants
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="our-testinomials"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Testinomials
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="our-application"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Pricing
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="about-us"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    About us
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="why-consultation"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Why Consultation
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
            </div>
            <div className="col-md-10  dkg-paidservices-rightCon">
              <Tab.Content className="dkg-paidservices-tabcontent">
                <Tab.Pane eventKey="title" className="dkg-paidservices-tabpane">
                  <div className="dkg-banner-body">
                    <div className="dkaddNewRightCon">
                      <div className="addNewBox">
                        <div className="dk-FileUploadCon">
                          {selectedFile === null ? (
                            <>
                              <div className="dk-fileUldimgCon">
                                <figure id="edit_image">
                                  {defaultPreview}
                                </figure>
                              </div>
                              <div className="dk-browsedFile">
                                <button onChange={onSelectFile}>
                                  <input
                                    type="file"
                                    id=""
                                    name=""
                                    accept="image/*"
                                  />
                                  <i className="fas fa-upload mr-2"></i> Upload
                                </button>
                              </div>
                            </>
                          ) : (
                            <div className="dk-uploadView-img">
                              {isImageUpdate ? (
                                <img src={newImageData} className="img-fluid" />
                              ) : (
                                <img src={LogoViewImg} className="img-fluid" />
                              )}
                              <span
                                className="close-btn"
                                onClick={defaultImage}
                              >
                                <FaTimes />
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="dkg-livejob-titleCon">
                      <div className="dkg-livejob-inputCon">
                        <label htmlFor="" className="dkg-jobtitle-label">
                          TITLE
                        </label>
                        <input
                          type="text"
                          className="dkg-livejobs-input form-control"
                          name=""
                          id=""
                          defaultValue={paHeading != "" ? paHeading : ""}
                          onChange={(e) => setPHeading(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="dkg-livejobs-subtitleCon">
                      <div className="dkg-livejobs-subtitle-header">
                        <h2 className="dkg-livejobs-subtitle">SUBTITLE</h2>
                      </div>
                      <div className="dkg-livejobs-subtitle-body">
                        <textarea
                          name=""
                          className="dkg-subtitle-textarea"
                          id=""
                          defaultValue={paSubHeading != "" ? paSubHeading : ""}
                          onChange={(e) => setPSubHeading(e.target.value)}
                        ></textarea>
                        <div className="dkg-livjobs-btnCon">
                          <button
                            className="btn dkg-livjobs-btn"
                            onClick={(e) => SubmitBtn(29)}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="subtitle"
                  className="dkg-paidservices-tabpane"
                ></Tab.Pane> 
                <Tab.Pane
                  eventKey="our-team"
                  className="dkg-paidservices-tabpane"
                ></Tab.Pane>
                <Tab.Pane eventKey="why-choose-us" className="dkg-paidservices-tabpane">
                   <WhyChoose />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default OverviewTab;
