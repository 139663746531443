import React from 'react'
import Header from '../../../../elements/header/Header'
import Navbar from "../../../../elements/navbar/dkgcrm/trainnig/CommanTraining";


const CommanTrainig = () => {
    return (
        <>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/summary" mainheaderLink="Training" moduleLink="/dashboard/training" linkcolor="#396a95" mainheaderTitle=" - Comman" textcolor="#396a95" />
            <Navbar />
        </>
    )
}

export default CommanTrainig