import React from 'react';
import { Route, Link } from "react-router-dom";
import '../submenu/submenu.scss';

const SettingSubmenu = () => {
    const pathname = window.location.pathname;
    return (
        <div>
            <div className="dk-adminSubmenuMain">
                <Route>
                    <ul>
                        {/* <li><Link to="/settings/users" className={`${pathname.match('/settings/users') ? 'sub-active' : ''}`}>Users</Link></li> */}
                        <li><Link to="/settings/bkvalues" className={`${pathname.match('/settings/bkvalues') ? 'sub-active' : ''}`}>Backend Values</Link></li>
                        <li><Link to="/settings/deleted" className={`${pathname.match('/settings/deleted') ? 'sub-active' : ''}`}>Deleted A/C</Link></li>
                        <li><Link to="/settings/legal" className={`${pathname.match('/settings/legal') ? 'sub-active' : ''}`}>Legal</Link></li>
                        <li><Link to="/settings/login-faq" className={`${pathname.match('/settings/login-faq') ? 'sub-active' : ''}`}>Login FAQ</Link></li>
                    </ul>
                </Route>
            </div>
        </div>
    )
}

export default SettingSubmenu;
