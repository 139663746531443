import React, { useState } from "react";
import "./checkbox.scss";
import { useDispatch, useSelector } from "react-redux";
import CandidateInfo from "./modal/CandidateInfo";
import { FaLongArrowAltLeft } from "react-icons/fa";
import DeleteModal from "../../../../ui/Modal/deletemodal/DeleteModal";
import ConfirmModal from "../../../../ui/Modal/confirmModel/ConfirmModal";
import { updateData } from "../../../../../slice/candidate/candidateSlice";
const DeleteCheckbox = ({ canid }) => {
  const dispatch = useDispatch();
  const [isDelete, setIsDelete] = useState(false);
  const [isMove, setIsMove] = useState(false);
  //import { Link } from "react-router-dom";
  //import { makeStyles } from "@material-ui/core/styles";
  //import $ from "jquery";
  // const [isActive, setIsActive] = useState(false);
  // const [countCheck, setCountCheck] = useState(0);
  // const [checkBtnTxt, setCheckBtnTxt] = useState("Check All");
  // const handleToggle = () => {
  //   $(".chkCandidate").prop("checked", false);
  //   setIsActive(false);
  // };
  // const deleteSelected = () => {
  //   setShowDelete(true);
  // };
  // const toggleCheckbox = (e) => {
  //   var checkedBoxCount = $('input[name="chkCandidate"]:checked').length;
  //   if (checkedBoxCount > 0) {
  //     setCountCheck(checkedBoxCount);
  //     setIsActive(true);
  //   } else {
  //     setCountCheck(0);
  //     setIsActive(false);
  //   }
  //   var checkedBoxCount = $('input[name="chkCandidate"]:checked').length;
  //   var totalCheckboxes = $('input[name="chkCandidate"]').length;
  //   if (checkedBoxCount < totalCheckboxes) {
  //     setCheckBtnTxt("Check All");
  //   }
  //   if (checkedBoxCount == totalCheckboxes) {
  //     setCheckBtnTxt("Uncheck All");
  //   }
  // };
  //const [show, setShow] = useState(false);
  //const handleClose = () => setShow(false);
  const showdeleteModal = () => {
    setIsDelete(true);
  };
  const hidedeleteModal = () => {
    setIsDelete(false);
  };

  const showConfirmModal = () => {
    setIsMove(true);
  };
  const hideConfirmModal = () => {
    setIsMove(false);
  };
  const deleteCandidate = async () => {
    await dispatch(
      updateData({ id: canid.id, key: "status", value: "Archive" })
    );
  };
  const moveConfirmCandidate = async () => {
    await dispatch(
      updateData({ id: canid.id, key: "accountStatus", value: 1 })
    );
  };
  return (
    <React.Fragment>
      {isDelete ? (
        <DeleteModal
          handleClose={hidedeleteModal}
          deleteItem={deleteCandidate}
        />
      ) : null}
      {isMove ? (
        <ConfirmModal
          ConfirmHandleClose={hideConfirmModal}
          ConfirmDeleteItem={moveConfirmCandidate}
          title="Are you sure you want to move this candidate to Live User Table ?"
        />
      ) : null}
      <span className="dk-smsTableCheckbox">
        {/* <input
          type="radio"
          className="chkCandidate"
          name="chkCandidate"
          onChange={(e) => toggleCheckbox()}
        /> */}
        <span className="dkg-active-account">
          <span className="dkg-active-deleteicon">
            <i className="far fa-trash-alt" onClick={showdeleteModal}></i>
          </span>
        </span>
        <span className="dkg-active-editicon">
          <FaLongArrowAltLeft onClick={showConfirmModal} />
        </span>
        <CandidateInfo userInfo={canid} />
      </span>
      {/* <span
        className={`dk-smsMessage-selectMsg ${isActive ? "activePopup" : ""}`}
      >
        <span className="d-flex align-items-center justify-content-between">
          <span className="dk-checkAll" onClick={deleteSelected}>
            <span>
              <i className="far fa-edit"></i>
            </span>
            Edit
          </span>
          <span className="dk-checkAll" onClick={deleteSelected}>
            <span>
              <i className="far fa-trash-alt"></i>
            </span>
            Delete
          </span>
          <span
            className="dk-checkAll d-flex align-items-center justify-content-center"
            onClick={handleToggle}
          >
            <span>
              <i className="fas fa-times"></i>
            </span>
          </span>
        </span>
      </span> */}
    </React.Fragment>
  );
};

export default DeleteCheckbox;
