import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import SubmitButton from "../../../../ui/submitButton";
//import { FaInfoCircle, FaTimes } from "react-icons/fa";
import LogoViewImg from "../../../../../assets/images/dkg-jobs.png";
//import ProgressBar from './progress-bar/ProgressBar'
import ViewProjectModal from "./modal/ViewProject";
import UpdatePop from "./modal/UpdatePop";
import AddurlPopup from "./modal/AddurlPopup";
import {
  listData,
  updateData,
  updateProjectData,
  getprojectData,
} from "../../../../../slice/cmsSlice";
import { showError } from "../../../../utils/messages/messageSlice";
import AWS from "aws-sdk";

const OthersSideMenu = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList, isUpdate, projectDataList, isSuccess } =
    useSelector((state) => state.cms);
  const [overviews, setOverview] = useState("");

  const [defaultPreview, setDefaultPreview] = useState(<h3>browse Image</h3>);
  const [selectedFirstFile, setSelectedFirstFile] = useState(null);
  const [selectedSecondFile, setSelectedSecondFile] = useState(null);
  const [selectedThirdFile, setSelectedThirdFile] = useState(null);
  const [selectedFourthFile, setSelectedfourthFile] = useState(null);
  const [selectedFivethFile, setSelectedFivethFile] = useState(null);
  const [selectedSixthFile, setSelectedSixthFile] = useState(null);
  const [projectData, setProjectData] = useState("");
  const [isNumber, setIsNumber] = useState(false);
  const [firstImgCondition, setFirstImgCondidtion] = useState(false);
  const [firstImg, setFirstImg] = useState("");

  const [secondImgCondition, setSecondImgCondidtion] = useState(false);
  const [secondImg, setSecondImg] = useState("");

  const [thirdImgCondition, setThirdImgCondidtion] = useState(false);
  const [thirdImg, setThirdImg] = useState("");

  const [fourthImgCondition, setFourthImgCondidtion] = useState(false);
  const [fourthImg, setFourthImg] = useState("");

  const [fivethImgCondition, setFivethImgCondidtion] = useState(false);
  const [fivethImg, setFivethImg] = useState("");

  const [sixthImgCondition, setSixthImgCondidtion] = useState(false);
  const [sixthImg, setSixthImg] = useState("");

  const [isAddUrl, setIsAddUrl] = useState(false);

  const [isSiteUrl, setIsSiteUrl] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(listData({}));
        await dispatch(getprojectData({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setOverview(getcmsList[1].otherProjectOverview);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isUpdate) {
      setIsNumber(false);
      setProjectData(projectDataList);
    }
  }, [isUpdate]);

  useEffect(() => {
    if (!isSuccess && projectDataList.length > 0) {
      setProjectData(projectDataList);
      setSelectedFirstFile("1st");
      setFirstImgCondidtion(true);
      setFirstImg(
        process.env.REACT_APP_IMG_CLOUD_BASE_URL + projectDataList[0].image
      );
      // setFirstImg(
      //   projectDataList[0].image != ""
      //     ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + projectDataList[0].image
      //     : setSelectedFirstFile(null)
      // );
      /************************************ */
      setSelectedSecondFile("2nd");
      setSecondImgCondidtion(true);
      setSecondImg(
        projectDataList[1].image != ""
          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + projectDataList[1].image
          : setSelectedSecondFile(null)
      );
      /************************************* */
      setSelectedThirdFile("3rd");
      setThirdImgCondidtion(true);
      setThirdImg(
        projectDataList[2].image != ""
          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + projectDataList[2].image
          : setSelectedThirdFile(null)
      );
      /********************************* */
      setSelectedfourthFile("4rth");
      setFourthImgCondidtion(true);
      setFourthImg(
        projectDataList[3].image != ""
          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + projectDataList[3].image
          : setSelectedfourthFile(null)
      );
      /********************************* */
      setSelectedFivethFile("5th");
      setFivethImgCondidtion(true);
      setFivethImg(
        projectDataList[4].image != ""
          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + projectDataList[4].image
          : setSelectedFivethFile(null)
      );
      /********************************* */
      setSelectedSixthFile("6th");
      setSixthImgCondidtion(true);
      setSixthImg(
        projectDataList[5].image != ""
          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + projectDataList[5].image
          : setSelectedSixthFile(null)
      );
      /********************************* */
    }
  }, [isSuccess]);

  const SubmitBtn = (id, value, key) => {
    dispatch(
      updateData({
        id,
        key,
        value,
      })
    );
  };

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let imgid = "";
      switch (e.target.id) {
        case "firstImg":
          imgid = 1;
          setSelectedFirstFile(e.target.files[0]);
          break;
        case "secondImg":
          imgid = 2;
          setSelectedSecondFile(e.target.files[0]);
          break;
        case "thirdImg":
          imgid = 3;
          setSelectedThirdFile(e.target.files[0]);
          break;
        case "fourthImg":
          imgid = 4;
          setSelectedfourthFile(e.target.files[0]);
          break;
        case "fiveImg":
          imgid = 5;
          setSelectedFivethFile(e.target.files[0]);
          break;
        case "sixImg":
          imgid = 6;
          setSelectedSixthFile(e.target.files[0]);
          break;
      }

      var fileToLoad = e.target.files[0];
      var fileReader = new FileReader();
      fileReader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result; // <--- data: base64
        var newImage = document.createElement("img");
        newImage.src = srcData;
        switch (e.target.id) {
          case "firstImg":
            setFirstImgCondidtion(true);
            setFirstImg(newImage.src);
            break;
          case "secondImg":
            setSecondImgCondidtion(true);
            setSecondImg(newImage.src);
            break;
          case "thirdImg":
            setThirdImgCondidtion(true);
            setThirdImg(newImage.src);
            break;
          case "fourthImg":
            setFourthImgCondidtion(true);
            setFourthImg(newImage.src);
            break;
          case "fiveImg":
            setFivethImgCondidtion(true);
            setFivethImg(newImage.src);
            break;
          case "sixImg":
            setSixthImgCondidtion(true);
            setSixthImg(newImage.src);
            break;
        }
      };
      fileReader.readAsDataURL(fileToLoad);
      uploadimg2Bucket(imgid, e.target.files[0]);
    }
  };

  const defaultImage = async (crossId) => {
    let imgid = "";
    switch (crossId) {
      case "firstImg":
        setSelectedFirstFile(null);
        setFirstImg("");
        imgid = 1;
        break;
      case "secondImg":
        setSelectedSecondFile(null);
        setSecondImg("");
        imgid = 2;
        break;
      case "thirdImg":
        setSelectedThirdFile(null);
        setThirdImg("");
        imgid = 3;
        break;
      case "fourthImg":
        setSelectedfourthFile(null);
        setFourthImg("");
        imgid = 4;
        break;
      case "fiveImg":
        setSelectedFivethFile(null);
        setFivethImg("");
        imgid = 5;
        break;
      case "sixImg":
        setSelectedSixthFile(null);
        setSixthImg("");
        imgid = 6;
        break;
    }
    await dispatch(
      updateProjectData({
        id: imgid,
        key: "image",
        value: "",
      })
    );
  };

  //const [progress, setProgress] = useState(60);
  // useEffect(() => {
  //   setInterval(() => setProgress(Math.floor(Math.random() * 100) + 1), 2000);
  // }, []);
  const [newValues, setValues] = useState("");

  const shownumberPopup = (data) => {
    setValues(data);
    setIsNumber(true);
  };
  const hidenumberPopup = () => {
    setIsNumber(false);
  };

  const uploadimg2Bucket = async (id, imgdata) => {
    // if (!loading) {
    //  setSuccess(false);
    //  setLoading(true);
    //
    //return false;
    if (imgdata != null) {
      let s3bucket = new AWS.S3({
        accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
        secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
        Bucket: process.env.REACT_APP_BUCKET_NAME + "/clients",
      });
      s3bucket.createBucket(function () {
        var params = {
          Bucket: process.env.REACT_APP_BUCKET_NAME + "/clients",
          Key: Date.now() + "-" + imgdata.name.replace(/ /g, "_"),
          Body: imgdata,
        };
        s3bucket.upload(params, function (err, data) {
          if (err) {
            console.log(err);
          } else {
            dispatch(
              updateProjectData({
                id,
                key: "image",
                value: "/" + data.key,
              })
            );
          }
        });
      });
    } else {
    } //end of imgdata
    //}
  };

  const showAddurlModal = (data) => {
    setIsSiteUrl(data);
    setIsAddUrl(true);
  };
  const hideAddurlModal = () => {
    setIsAddUrl(false);
  };

  return (
    <>
      {isNumber ? (
        <UpdatePop onHide={hidenumberPopup} data={newValues} />
      ) : null}
      {isAddUrl ? (
        <AddurlPopup onHide={hideAddurlModal} siteUrl={isSiteUrl} />
      ) : null}
      <div className="dkg-cand-services-Side">
        <Tab.Container defaultActiveKey="first">
          <div className="row m-0 dkg-payment-qt-tab-mainCon">
            <div className="col-md-2 pl-0 dkg-payment-qt-tabCon dkg-profile-task-sideMenu">
              <Nav
                variant="pills"
                className="flex-column dkg-payment-qt-tab-navpills"
              >
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="first"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Instructions
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="second"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Projects
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-md-10  dkg-paidservices-rightCon">
              <Tab.Content className="dkg-paidservices-tabcontent">
                <Tab.Pane eventKey="first" className="dkg-paidservices-tabpane">
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Instructions</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <textarea
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue={overviews != "" ? overviews : ""}
                      onChange={(e) => setOverview(e.target.value)}
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                        onClick={(e) =>
                          SubmitBtn(2, overviews, "otherProjectOverview")
                        }
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Information</h4>
                  </div>
                  <div className="dkg-paidservices-body dkg-admin-setting-prjbody">
                    <div className="dkg-visitwebaite-cardCon">
                      <div className="dk-otherproject-cols">
                        <div className="dk-otherproject-box">
                          <div className="dkg-otherprj-boxcols">
                            <div className="dk-FileUploadCon">
                              {selectedFirstFile === null ? (
                                <>
                                  <div className="dk-fileUldimgCon">
                                    <figure id="edit_image">
                                      {defaultPreview}
                                    </figure>
                                  </div>
                                </>
                              ) : (
                                <div className="dk-uploadView-img">
                                  {firstImgCondition ? (
                                    <img
                                      src={firstImg}
                                      alt="Prject Logo"
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src={LogoViewImg}
                                      alt="Prject Logo"
                                      className="img-fluid"
                                    />
                                  )}
                                  {/* <span
                                    className="close-btn"
                                    onClick={(e) => defaultImage("firstImg")}
                                  >
                                    <FaTimes />
                                  </span> */}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="dkg-porject-info-progressbarCon">
                            <span className="dkg-info-icon-leftCon">
                              {" "}
                              <ViewProjectModal
                                projectdetails={
                                  projectData != "" ? projectData[0] : ""
                                }
                              />
                            </span>
                            <div
                              className="dkg-project-rightCon"
                              onClick={() =>
                                shownumberPopup(
                                  projectData != "" ? projectData[0] : ""
                                )
                              }
                            >
                              Completed -{" "}
                              {projectData != ""
                                ? projectData[0].completed + "%"
                                : ""}
                            </div>
                          </div>
                          <div className="dkg-project-btnCon">
                            <div className="dkg-upload-prjbtn-Con">
                              <button
                                onChange={onSelectFile}
                                className="dkg-upload-prjbtn"
                              >
                                <input
                                  type="file"
                                  id="firstImg"
                                  name="test"
                                  accept="image/*"
                                />
                                <i className="fas fa-upload mr-2"></i> Upload
                              </button>
                            </div>
                            <div className="dkg-url-prjbtn-Con">
                              <button
                                className="dkg-url-prjbtn"
                                onClick={() =>
                                  showAddurlModal(
                                    projectData != "" ? projectData[0] : ""
                                  )
                                }
                              >
                                Url
                              </button>
                            </div>
                            <div className="dkg-cancel-prjbtn-Con">
                              <button
                                className="dkg-cancel-prjbtn"
                                onClick={(e) => defaultImage("firstImg")}
                              >
                                <i className="fas fa-times mr-2"></i> Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="dk-otherproject-box">
                          <div className="dkg-otherprj-boxcols">
                            <div className="dk-FileUploadCon">
                              {selectedSecondFile === null ? (
                                <>
                                  <div className="dk-fileUldimgCon">
                                    <figure id="edit_image">
                                      {defaultPreview}
                                    </figure>
                                  </div>
                                </>
                              ) : (
                                <div className="dk-uploadView-img">
                                  {secondImgCondition ? (
                                    <img
                                      src={secondImg}
                                      alt="Prject Logo"
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src={LogoViewImg}
                                      alt="Prject Logo"
                                      className="img-fluid"
                                    />
                                  )}
                                  {/* <span
                                    className="close-btn"
                                    onClick={(e) => defaultImage("secondImg")}
                                  >
                                    <FaTimes />
                                  </span> */}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="dkg-porject-info-progressbarCon">
                            <span className="dkg-info-icon-leftCon">
                              {" "}
                              <ViewProjectModal
                                projectdetails={
                                  projectData != "" ? projectData[1] : ""
                                }
                              />
                            </span>
                            <div
                              className="dkg-project-rightCon"
                              onClick={() =>
                                shownumberPopup(
                                  projectData != "" ? projectData[1] : ""
                                )
                              }
                            >
                              Completed -{" "}
                              {projectData != ""
                                ? projectData[1].completed + "%"
                                : ""}
                            </div>
                          </div>
                          <div className="dkg-project-btnCon">
                            <div className="dkg-upload-prjbtn-Con">
                              <button
                                onChange={onSelectFile}
                                className="dkg-upload-prjbtn"
                              >
                                <input
                                  type="file"
                                  id="secondImg"
                                  name=""
                                  accept="image/*"
                                />
                                <i className="fas fa-upload mr-2"></i> Upload
                              </button>
                            </div>
                            <div className="dkg-url-prjbtn-Con">
                              <button
                                className="dkg-url-prjbtn"
                                onClick={() =>
                                  showAddurlModal(
                                    projectData != "" ? projectData[1] : ""
                                  )
                                }
                              >
                                Url
                              </button>
                            </div>
                            <div className="dkg-cancel-prjbtn-Con">
                              <button
                                className="dkg-cancel-prjbtn"
                                onClick={(e) => defaultImage("secondImg")}
                              >
                                <i className="fas fa-times mr-2"></i> Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="dk-otherproject-box">
                          <div className="dkg-otherprj-boxcols">
                            <div className="dk-FileUploadCon">
                              {selectedThirdFile === null ? (
                                <>
                                  <div className="dk-fileUldimgCon">
                                    <figure id="edit_image">
                                      {defaultPreview}
                                    </figure>
                                  </div>
                                </>
                              ) : (
                                <div className="dk-uploadView-img">
                                  {thirdImgCondition ? (
                                    <img
                                      src={thirdImg}
                                      alt="Prject Logo"
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src={LogoViewImg}
                                      alt="Prject Logo"
                                      className="img-fluid"
                                    />
                                  )}
                                  {/* <span
                                    className="close-btn"
                                    onClick={(e) => defaultImage("thirdImg")}
                                  >
                                    <FaTimes />
                                  </span> */}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="dkg-porject-info-progressbarCon">
                            <span className="dkg-info-icon-leftCon">
                              {" "}
                              <ViewProjectModal
                                projectdetails={
                                  projectData != "" ? projectData[2] : ""
                                }
                              />
                            </span>
                            <div
                              className="dkg-project-rightCon"
                              onClick={() =>
                                shownumberPopup(
                                  projectData != "" ? projectData[2] : ""
                                )
                              }
                            >
                              Completed -{" "}
                              {projectData != ""
                                ? projectData[2].completed + "%"
                                : ""}
                            </div>
                          </div>
                          <div className="dkg-project-btnCon">
                            <div className="dkg-upload-prjbtn-Con">
                              <button
                                onChange={onSelectFile}
                                className="dkg-upload-prjbtn"
                              >
                                <input
                                  type="file"
                                  id="thirdImg"
                                  name=""
                                  accept="image/*"
                                />
                                <i className="fas fa-upload mr-2"></i> Upload
                              </button>
                            </div>
                            <div className="dkg-url-prjbtn-Con">
                              <button
                                className="dkg-url-prjbtn"
                                onClick={() =>
                                  showAddurlModal(
                                    projectData != "" ? projectData[2] : ""
                                  )
                                }
                              >
                                Url
                              </button>
                            </div>
                            <div className="dkg-cancel-prjbtn-Con">
                              <button
                                className="dkg-cancel-prjbtn"
                                onClick={(e) => defaultImage("thirdImg")}
                              >
                                <i className="fas fa-times mr-2"></i> Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dk-otherproject-cols">
                        <div className="dk-otherproject-box">
                          <div className="dkg-otherprj-boxcols">
                            <div className="dk-FileUploadCon">
                              {selectedFourthFile === null ? (
                                <>
                                  <div className="dk-fileUldimgCon">
                                    <figure id="edit_image">
                                      {defaultPreview}
                                    </figure>
                                  </div>
                                </>
                              ) : (
                                <div className="dk-uploadView-img">
                                  {fourthImgCondition ? (
                                    <img
                                      src={fourthImg}
                                      alt="Prject Logo"
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src={LogoViewImg}
                                      alt="Prject Logo"
                                      className="img-fluid"
                                    />
                                  )}
                                  {/* <span
                                    className="close-btn"
                                    onClick={(e) => defaultImage("fourthImg")}
                                  >
                                    <FaTimes />
                                  </span> */}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="dkg-porject-info-progressbarCon">
                            <span className="dkg-info-icon-leftCon">
                              {" "}
                              <ViewProjectModal
                                projectdetails={
                                  projectData != "" ? projectData[3] : ""
                                }
                              />
                            </span>
                            <div
                              className="dkg-project-rightCon"
                              onClick={() =>
                                shownumberPopup(
                                  projectData != "" ? projectData[3] : ""
                                )
                              }
                            >
                              Completed -{" "}
                              {projectData != ""
                                ? projectData[3].completed + "%"
                                : ""}
                            </div>
                          </div>
                          <div className="dkg-project-btnCon">
                            <div className="dkg-upload-prjbtn-Con">
                              <button
                                onChange={onSelectFile}
                                className="dkg-upload-prjbtn"
                              >
                                <input
                                  type="file"
                                  id="fourthImg"
                                  name=""
                                  accept="image/*"
                                />
                                <i className="fas fa-upload mr-2"></i> Upload
                              </button>
                            </div>
                            <div className="dkg-url-prjbtn-Con">
                              <button
                                className="dkg-url-prjbtn"
                                onClick={() =>
                                  showAddurlModal(
                                    projectData != "" ? projectData[3] : ""
                                  )
                                }
                              >
                                Url
                              </button>
                            </div>
                            <div className="dkg-cancel-prjbtn-Con">
                              <button
                                className="dkg-cancel-prjbtn"
                                onClick={(e) => defaultImage("fourthImg")}
                              >
                                <i className="fas fa-times mr-2"></i> Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="dk-otherproject-box">
                          <div className="dkg-otherprj-boxcols">
                            <div className="dk-FileUploadCon">
                              {selectedFivethFile === null ? (
                                <>
                                  <div className="dk-fileUldimgCon">
                                    <figure id="edit_image">
                                      {defaultPreview}
                                    </figure>
                                  </div>
                                </>
                              ) : (
                                <div className="dk-uploadView-img">
                                  {fivethImgCondition ? (
                                    <img
                                      src={fivethImg}
                                      alt="Prject Logo"
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src={LogoViewImg}
                                      alt="Prject Logo"
                                      className="img-fluid"
                                    />
                                  )}
                                  {/* <span
                                    className="close-btn"
                                    onClick={(e) => defaultImage("fiveImg")}
                                  >
                                    <FaTimes />
                                  </span> */}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="dkg-porject-info-progressbarCon">
                            <span className="dkg-info-icon-leftCon">
                              {" "}
                              <ViewProjectModal
                                projectdetails={
                                  projectData != "" ? projectData[4] : ""
                                }
                              />
                            </span>
                            <div
                              className="dkg-project-rightCon"
                              onClick={() =>
                                shownumberPopup(
                                  projectData != "" ? projectData[4] : ""
                                )
                              }
                            >
                              Completed -{" "}
                              {projectData != ""
                                ? projectData[4].completed + "%"
                                : ""}
                            </div>
                          </div>
                          <div className="dkg-project-btnCon">
                            <div className="dkg-upload-prjbtn-Con">
                              <button
                                onChange={onSelectFile}
                                className="dkg-upload-prjbtn"
                              >
                                <input
                                  type="file"
                                  id="fiveImg"
                                  name=""
                                  accept="image/*"
                                />
                                <i className="fas fa-upload mr-2"></i> Upload
                              </button>
                            </div>
                            <div className="dkg-url-prjbtn-Con">
                              <button
                                className="dkg-url-prjbtn"
                                onClick={() =>
                                  showAddurlModal(
                                    projectData != "" ? projectData[4] : ""
                                  )
                                }
                              >
                                Url
                              </button>
                            </div>
                            <div className="dkg-cancel-prjbtn-Con">
                              <button
                                className="dkg-cancel-prjbtn"
                                onClick={(e) => defaultImage("fiveImg")}
                              >
                                <i className="fas fa-times mr-2"></i> Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="dk-otherproject-box">
                          <div className="dkg-otherprj-boxcols">
                            <div className="dk-FileUploadCon">
                              {selectedSixthFile === null ? (
                                <>
                                  <div className="dk-fileUldimgCon">
                                    <figure id="edit_image">
                                      {defaultPreview}
                                    </figure>
                                  </div>
                                </>
                              ) : (
                                <div className="dk-uploadView-img">
                                  {sixthImgCondition ? (
                                    <img
                                      src={sixthImg}
                                      alt="Prject Logo"
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src={LogoViewImg}
                                      alt="Prject Logo"
                                      className="img-fluid"
                                    />
                                  )}
                                  {/* <span
                                    className="close-btn"
                                    onClick={(e) => defaultImage("sixImg")}
                                  >
                                    <FaTimes />
                                  </span> */}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="dkg-porject-info-progressbarCon">
                            <span className="dkg-info-icon-leftCon">
                              {" "}
                              <ViewProjectModal
                                projectdetails={
                                  projectData != "" ? projectData[5] : ""
                                }
                              />
                            </span>
                            <div
                              className="dkg-project-rightCon"
                              onClick={() =>
                                shownumberPopup(
                                  projectData != "" ? projectData[5] : ""
                                )
                              }
                            >
                              Completed -{" "}
                              {projectData != ""
                                ? projectData[5].completed + "%"
                                : ""}
                            </div>
                          </div>
                          <div className="dkg-project-btnCon">
                            <div className="dkg-upload-prjbtn-Con">
                              <button
                                onChange={onSelectFile}
                                className="dkg-upload-prjbtn"
                              >
                                <input
                                  type="file"
                                  id="sixImg"
                                  name=""
                                  accept="image/*"
                                />
                                <i className="fas fa-upload mr-2"></i> Upload
                              </button>
                            </div>
                            <div className="dkg-url-prjbtn-Con">
                              <button
                                className="dkg-url-prjbtn"
                                onClick={() =>
                                  showAddurlModal(
                                    projectData != "" ? projectData[5] : ""
                                  )
                                }
                              >
                                Url
                              </button>
                            </div>
                            <div className="dkg-cancel-prjbtn-Con">
                              <button
                                className="dkg-cancel-prjbtn"
                                onClick={(e) => defaultImage("sixImg")}
                              >
                                <i className="fas fa-times mr-2"></i> Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default OthersSideMenu;
