import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./company-album.scss";
import Pagetypealbums from "../../../../../../../assets/images/album.jpg";
import ViewDataModal from "./modal/UpdateModal";
import {
  getAlbumImgJobSpec,
  getAlbumCover,
  clearState,
} from "../../../../../../../slice/cmsSlice";
import { getCategories } from "../../../../../../../slice/clieninfo/requirnment/requirnmentSlice";
import {
  showError,
  showSuccess,
} from "../../../../../../../components/utils/messages/messageSlice";
import EditModal from "./modal/EditModal";

const CompanyAlbum = ({ compAlbum, albumImgs }) => {
  const dispatch = useDispatch();

  const { issSuccess, isLoading, isAlbum, imgAlbumList, coverAlbumList } =
    useSelector((state) => state.cms);

  const [isUpload, setIsUpload] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [data, setData] = useState([]);
  const [rowwId, setRowwId] = useState(0);
  const [albumname, setalbumname] = useState("");

  const [albumcover, setAlbumcover] = useState("");
  const [datacover, setDatacover] = useState("");

  useEffect(() => {
    if (!isLoading && imgAlbumList.length > 0) {
      setData(imgAlbumList);
      //setDatacover(coverAlbumList);
    } else {
      setData([]);
    }
  }, [isLoading]);

  useEffect(() => {
    if (compAlbum) {
      setAlbumcover(compAlbum);
      dispatch(getAlbumCover({ id: compAlbum[0].compId }));
    }
  }, [compAlbum]);

  useEffect(() => {
    if (isAlbum) {
      //dispatch(getCategories({}));
      setAlbumcover(imgAlbumList);
      ////dispatch(getAlbumImgJobSpec({ id: 13 }));
      // const albumWIthImg = compAlbum
      //   .map((album) => {
      //     const image = imgAlbumList.find(
      //       (image) => image.albumId === album.id
      //     );
      //     if (image) {
      //       return {
      //         ...album,
      //         imageName:
      //           image.imageName != null
      //             ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
      //               "/" +
      //               image.imageName
      //             : Pagetypealbums,
      //       };
      //     }
      //     return null; // Filter out albums without images
      //   })
      //   .filter(Boolean);
      console.log(imgAlbumList);
    }
  }, [isAlbum]);

  useEffect(() => {
    if (!issSuccess && imgAlbumList.length > 0) {
      setRowwId(rowwId);
      dispatch(clearState({}));
      dispatch(showSuccess({ msg: "Image updated successfully" }));
    }
  }, [!issSuccess]);

  const showuploadModal = (id, name) => {
    setRowwId(id);
    setalbumname(name);
    dispatch(getAlbumImgJobSpec({ id }));
    setIsUpload(true);
  };
  const hideuploadModal = () => {
    setIsUpload(false);
  };
  const showeditModal = (id, name) => {
    setRowwId(id);
    setalbumname(name);
    dispatch(getAlbumImgJobSpec({ id })); //call images
    setIsEdit(true);
  };
  const hideeditModal = () => {
    setIsEdit(false);
  };

  console.log(imgAlbumList);
  console.log(albumcover); //a1
  console.log(coverAlbumList); //a2

  const mergedData =
    albumcover.length > 0
      ? albumcover.map((item) => {
          const matchingItem = coverAlbumList.find(
            (el) => el.albumId === item.id
          );
          if (matchingItem) {
            return {
              ...item,
              image: matchingItem.imageName,
              // image:
              //   matchingItem.imageName != null && matchingItem.imageName != ""
              //     ? matchingItem.imageName
              //     : item.companyAlbumsJobSp[0].imageName,
            };
          } else {
            return item;
          }
        })
      : [];

  console.log(mergedData);

  return (
    <>
      {isUpload ? (
        <ViewDataModal
          onHide={hideuploadModal}
          albumimg={data}
          albumnme={albumname}
        />
      ) : null}
      {isEdit ? (
        <EditModal
          onHide={hideeditModal}
          albumimg={data}
          albumnme={albumname}
          rowid={rowwId}
        />
      ) : null}
      <div className="dkg-company-album-mainCon">
      <div className="dkg-jopspec-pageheader">
          <div className="dkg-jopspec-page-title">Images</div>
        </div>
        <div className="dkg-company-albumCon">
          <div className="dkg-company-mainRow row">
            {mergedData.length > 0 &&
              mergedData.map((data, index) => (
                <div
                  className="col-lg-4 col-md-4 col-sm-12 dkg-company-album-cols"
                  key={index + 1}
                >
                  <div className="dkg-company-album-box">
                    <div className="dkg-company-album-box-header">
                      <h2 className="dkg-album-box-header-title">
                        {data.albumName}
                      </h2>
                    </div>
                    <figure className="dkg-company-album-figure">
                      <div className="dkg-company-album-imgCon">
                        <img
                          src={
                            process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            "/" +
                            data.image
                          }
                          className="dkg-company-album-img"
                          alt="album"
                        />
                      </div>
                    </figure>
                    <div className="dkg-company-bottomCon">
                      <button
                        className="btn dkg-upload-mediabtn"
                        onClick={() => showuploadModal(data.id, data.albumName)}
                      >
                        VIEW
                      </button>
                      <button
                        className="btn dkg-edit-mediabtn"
                        onClick={() => showeditModal(data.id, data.albumName)}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyAlbum;
