import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav } from "react-bootstrap";

import {
  getTextHeading,
  updateCMSText,
  clearState,
} from "../../../../../../slice/candidate/frontendCms/frontendCmsSlice";
import SubmitButton from "../../../../../ui/submitButton";
import {
  showError,
  showSuccess,
} from "../../../../../../components/utils/messages/messageSlice";


const WhyChoose = () => {
    const dispatch = useDispatch();
    const { isLoading, getcmsList, isSuccess, isUpdate } = useSelector(
      (state) => state.cmslist
    );
  
    const [paHeading, setPHeading] = useState([]);
    const [paSubHeading, setPSubHeading] = useState([]);
    const [paDescription1, setPaDescription1] = useState([]);
    const [paDescription2, setPaDescription2] = useState([]);
  
    useEffect(() => {
      if (!isLoading && getcmsList && getcmsList.length > 0) {
        setPHeading(getcmsList[17]);
        setPSubHeading(getcmsList[18]);
        setPaDescription1(getcmsList[35]?.page_description);
        setPaDescription2(getcmsList[36]?.page_description);
      }
    }, [isLoading, isSuccess]);
  
    useEffect(() => {
      if (isUpdate) {
        dispatch(clearState({}));
        dispatch(showSuccess({ msg: "Information updated successfully" }));
      }
    }, [isUpdate]);
  
    useEffect(() => {
      async function fetchData() {
        try {
          await dispatch(getTextHeading({}));
        } catch (err) {
          dispatch(showError({ msg: "Somthings went wrong" }));
        }
      }
      fetchData();
    }, [dispatch]);
  
    const SubmitBtn = (id) => {
      if (id === 18) {
        dispatch(
          updateCMSText({
            id,
            key: "page_description",
            value: paHeading,
          })
        );
      }
  
      if (id === 19) {
        dispatch(
          updateCMSText({
            id,
            key: "page_description",
            value: paSubHeading,
          })
        );
      }  
      
  
      if (id === 36) {
        dispatch(
          updateCMSText({
            id,
            key: "page_description",
            value: paDescription1,
          })
        );
        id = 37;
        dispatch(
          updateCMSText({
            id,
            key: "page_description",
            value: paDescription2,
          })
        );
      }  
  
    };
  return (
    <>
        <Tab.Container defaultActiveKey="why-choose-us-title">
            <div className="row m-0 dkg-choose-us-tab-mainCon">
                <div className="col-md-12 dkg-choose-us-qt-tabCon dkg-choose-us-sideMenu">
                    <Nav variant="pills" className="flex-row dkg-choose-us-tab-navpills">
                        <Nav.Item className="dkg-choose-us-tab-nav-item">
                        <Nav.Link eventKey="why-choose-us-title" className="dkg-choose-us-tab-navlink">Title</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dkg-choose-us-tab-nav-item">
                        <Nav.Link eventKey="why-choose-us-subtitle" className="dkg-choose-us-tab-navlink">Sub-Title</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dkg-choose-us-tab-nav-item">
                        <Nav.Link eventKey="why-choose-us-description" className="dkg-choose-us-tab-navlink">Description</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dkg-choose-us-tab-nav-item">
                        <Nav.Link eventKey="why-choose-us-banner" className="dkg-choose-us-tab-navlink">Banner</Nav.Link>
                        </Nav.Item>
                        
                    </Nav>
                    <Tab.Content className="dkg-paidservices-tabcontent">
                        <Tab.Pane
                        eventKey="why-choose-us-title"
                        className="dkg-why-choose-us-tabpane"
                        >
                            <div className="dkg-paidservices-header">
                                <h4 className="dkg-paidservices-title">Title</h4>
                            </div>
                            <div className="dkg-paidservices-body">
                                <input
                                type="text"
                                className="dkg-cand-dash-input"
                                name="cand-dash-input"
                                id="cand-dash-input"
                                defaultValue={
                                    paHeading != "" ? paHeading.page_description : ""
                                }
                                onChange={(e) => setPHeading(e.target.value)}
                                />
                                <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                    txt="SAVE"
                                    className="dkg-cand-submit-btn"
                                    onClick={(e) => SubmitBtn(18)}
                                />
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane
                        eventKey="why-choose-us-subtitle"
                        className="dkg-why-choose-us-tabpane"
                        >
                            <div className="dkg-paidservices-header">
                                <h4 className="dkg-paidservices-title">Subtitle</h4>
                            </div>
                            <div className="dkg-paidservices-body">
                                <textarea
                                type="text"
                                className="dkg-cand-dash-input"
                                name="cand-dash-input"
                                id="cand-dash-input"
                                defaultValue={
                                    paSubHeading != "" ? paSubHeading.page_description : ""
                                }
                                onChange={(e) => setPSubHeading(e.target.value)}
                                />
                                <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                    txt="SAVE"
                                    className="dkg-cand-submit-btn"
                                    onClick={(e) => SubmitBtn(19)}
                                />
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane
                        eventKey="why-choose-us-description"
                        className="dkg-why-choose-us-tabpane"
                        >
                            <div className="dkg-paidservices-header">
                                <h4 className="dkg-paidservices-title">Description</h4>
                            </div>
                            <div className="dkg-paidservices-body">
                                <textarea
                                type="text"
                                className="dkg-cand-dash-input mb-2"
                                name="cand-dash-title"
                                id="cand-dash-title"
                                placeholder="Description 1"
                                defaultValue={
                                    paDescription1 != "" ? paDescription1 : ""
                                }
                                onChange={(e) => setPaDescription1(e.target.value)}
                                ></textarea>
                                <textarea
                                type="text"
                                className="dkg-cand-dash-input mt-2"
                                name="cand-dash-desc"
                                id="cand-dash-desc"
                                defaultValue={
                                    paDescription2 != "" ? paDescription2 : ""
                                }
                                onChange={(e) => setPaDescription2(e.target.value)}
                                placeholder="Description 2"
                                ></textarea>
                                <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                    txt="SAVE"
                                    className="dkg-cand-submit-btn"
                                    onClick={(e) => SubmitBtn(36)}
                                />
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane
                        eventKey="why-choose-us-banner"
                        className="dkg-why-choose-us-tabpane"
                        >
                        
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </div>
            </Tab.Container>
    </>
  )
}

export default WhyChoose