import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav } from "react-bootstrap";
import { FaPlus, FaArrowsAlt, FaTrashAlt, FaEdit } from "react-icons/fa";
import AddNewCategoryModal from "./modal/AddNewCategory";
import EditSubCategory from "./modal/EditSubCategory";
import ReorderSubCategory from "./modal/ReorderSubCategory";
import DeleteModal from "./modal/DeleteDummy";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import {
  getSubCategories,
  getSubCatDetails,
  updateEditorValue,
} from "../../../../../../slice/solutions/solutionsSlice";
// import {
//   showSuccess,
//   showError,
//   clearMessage,
// } from "../../../../../../components/utils/messages/messageSlice";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { Link } from "react-router-dom";
import $ from "jquery";

const Step1 = (props) => {
  const dispatch = useDispatch();
  const {
    // isSuccess,
    // isTabActive,
    // isError,
    isSubCatInsert,
    isSubCatUpdate,
    isDetails,
    isLoading,
    //issLoading,
    subCategoryDetails,
    isSubReoder,
    issDelete,
    subCategorySolutionList,
  } = useSelector((state) => state.solutions);

  const [categoryId, setCategoryId] = useState(props.catIdds);
  const [tabName, setTabName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");

  const [data, setData] = useState([]);
  const [catRowId, setCatRowId] = useState("");
  const [activeSubCat, setActiveSubCat] = useState("");
  const [defaultId, setDefaultId] = useState("");
  const [detailsSubtab, setDetailsSubtab] = useState("");
  const [showDetailsTab, setShowDetailsTab] = useState(false);
  const [editorDescription, setEditorDescription] = useState("");
  const [overviewData, setOverviewData] = useState("");
  const [activeEditTab, setActiveEditTab] = useState(false);

  useEffect(() => {
    if (!isLoading && subCategorySolutionList && subCategorySolutionList.length > 0) {
      setData(subCategorySolutionList);
      setEditorDescription(subCategorySolutionList[0].description);
      setOverviewData(subCategorySolutionList[0].description);
      setDefaultId(subCategorySolutionList[0].id);
      setCatRowId(subCategorySolutionList[0].id);
    } else {
      setData([]);
    }
  }, [isLoading]);
  //dispatch(getSubCategories({ categoryId: props.catIdds, keys: tab }));

  useEffect(() => {
    dispatch(getSubCategories({ categoryId: props.catIdds, keys: 'details' }));
  }, [props.pagetitle]);


  useEffect(() => {
    if (isDetails) {
      setOverviewData(subCategoryDetails.description);
      setEditorDescription(subCategoryDetails.description);
    }
    if (isSubReoder) {
      setShowDetailsTab(false);
      $(".activDeleteEdit").css('display', 'none');
    }
    if (isSubCatUpdate) {
      setShowDetailsTab(false);
      $(".activDeleteEdit").css('display', 'none');
    }
    if (issDelete) {
      setShowDetailsTab(false);
      $(".activDeleteEdit").css('display', 'none');
    }

  }, [
    isSubCatUpdate,
    //props.pagetitle,
    isSubCatInsert,
    isDetails,
    issDelete,
    isSubReoder,
  ]);

  const [activeEditDelete, setActiveEditDelete] = useState(false);
  const [addReorderTab, setAddReorderTab] = useState(false);

  const changeTabName = async (tab) => {
    setTabName(tab);
    setActiveEditDelete(false);
    setAddReorderTab(true);
    $(".addReorderTab").css('display', 'block');
    //setShowDetailsTab(true)
    switch (tab) {
      case "details":
        $(".tab_1").addClass("activeSubTab");
        $(".tab_2").removeClass("activeSubTab");
        $(".tab_3").removeClass("activeSubTab");
        break;
      case "audio":
        $(".tab_2").addClass("activeSubTab");
        $(".tab_1").removeClass("activeSubTab");
        $(".tab_3").removeClass("activeSubTab");
        break;
      case "video":
        $(".tab_3").addClass("activeSubTab");
        $(".tab_2").removeClass("activeSubTab");
        $(".tab_1").removeClass("activeSubTab");
        break;
      // default:
      //     setActiveSubCat('activeSubTab')
      //     break;
    }

    $(".addnewClass").addClass("activesSubTab");
    setShowDetailsTab(false);
    setActiveEditTab(false);
    dispatch(getSubCategories({ categoryId: props.catIdds, keys: tab }));
  };

  const subCategoryFunction = (id, catId, data) => {
    $(".activDeleteEdit").css('display', 'block');
    if (props.pagetitle != "Overview") {
      $(".dk-tabs-right-navlink-13").removeClass("addActiveClass");
      $(".xyz" + id).addClass("addActiveClass");
    }
    setActiveEditDelete(true);
    setShowDetailsTab(true);
    setOverviewData("");
    setEditorDescription("");
    setSubCategoryName(data);
    setCategoryId(catId);
    setCatRowId(id);
    dispatch(getSubCatDetails({ id }));
    setActiveEditTab(true);
  };

  let overviewDetails =
    overviewData !== undefined && overviewData !== null ? overviewData : "";
  const html_1 = overviewDetails;
  const contentBlock_1 = htmlToDraft(html_1);
  const contentState_1 = ContentState.createFromBlockArray(
    contentBlock_1.contentBlocks
  );
  const [editorOverviewState, setEditorState_1] = useState(
    EditorState.createEmpty()
  );
  useEffect(() => {
    setEditorState_1(EditorState.createEmpty());
    if (overviewDetails !== "") {
      setEditorState_1(EditorState.createWithContent(contentState_1));
    }
  }, [overviewDetails]);

  const onOverviewStateChange = async (state) => {
    setEditorState_1(state);
    let currentContentAsHTML_1 = draftToHtml(
      convertToRaw(editorOverviewState.getCurrentContent())
    );
    dispatch(
      updateEditorValue({
        id: defaultId,
        key: "description",
        value: currentContentAsHTML_1,
      })
    );
  };

  let description =
    editorDescription !== undefined && editorDescription !== null
      ? editorDescription
      : "";
  //const [value, setValue] = useState("");
  const html = description;
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    setEditorState(EditorState.createEmpty());
    if (description !== "") {
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [description]);

  const onEditorStateChange = async (state) => {
    setEditorState(state);
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    if (props.pagetitle === "Overview") {
      dispatch(
        updateEditorValue({
          id: defaultId,
          key: "description",
          value: currentContentAsHTML,
        })
      );
    } else {
      dispatch(
        updateEditorValue({
          id: catRowId,
          key: "description",
          value: currentContentAsHTML,
        })
      );
    }
  };

  const [isReorderModal, setIReorderModal] = useState(false);

  const showreorderModal = () => {
    setIReorderModal(true);
  };
  const hidereorderModal = () => {
    setIReorderModal(false);
  };

  return (
    <>
      <div className="dkg-payment-qt-rightpanel">
        <div className="dkg-tabs-rightPanel-12">
          <div className="dkg-subcategory-header">
            {props.pagetitle === "Overview" ? (
              <>
                <ul className="headerlist"></ul>
                <div className="dkg-script-overview-title">teeee</div>
              </>
            ) : (
              <>
                <ul className="headerlist">
                  {addReorderTab ? <>
                    <li className="headeritem mr-2 addReorderTab">
                      <AddNewCategoryModal
                        categoryId={categoryId}
                        tabName={tabName}
                      />
                    </li>
                    <li className="headeritem mr-2 addReorderTab">
                      <span
                        className="dk-resCrcleIconCon"
                        onClick={showreorderModal}
                      >
                        <FaArrowsAlt />
                      </span>
                      {isReorderModal ? (
                        <ReorderSubCategory
                          onHide={hidereorderModal}
                          dataList={data}
                          catIdds={props.catIdds}
                          activeEditTab={tabName}
                        />
                      ) : null}
                    </li>
                  </>
                    :
                    null
                  }
                  {
                    activeEditDelete ? <>
                      <li className="headeritem mr-2 activDeleteEdit">
                        <EditSubCategory
                          categoryId={categoryId}
                          tabName={tabName}
                          subCategory={subCategoryName}
                          catRowId={catRowId}
                          // activeEditTab={activeEditTab}
                          list={data}
                        />
                      </li>

                      <li className="headeritem activDeleteEdit">
                        <DeleteModal
                          catIdds={catRowId}
                          activeEditTab={activeEditTab}
                          atag={tabName}
                          catids={props.catIdds}
                        />
                      </li>
                    </> : null
                  }

                </ul>
              </>
            )}

            <div className="title dkg-page-title dkg-overview-title">
              {props.pagetitle}
            </div>
          </div>
          <div className="dk-tabs-rightCols-12">
            <div className="dk-tabs-right-detail-12">
              <Tab.Container id="left-tabs-example">
                {props.pagetitle === "Overview" ? (
                  <>{/*add condition for overview  */}</>
                ) : (
                  <>
                    <Nav variant="" className="dk-tabs-right-nav-12">
                      <Nav.Item className="dk-tabs-right-navitem-12">
                        <Link
                          to="#"
                          className={`dk-tabs-right-navlink-12 tab_1 `}
                          onClick={() => changeTabName("details")}
                        >
                          Details
                        </Link>
                      </Nav.Item>
                      <Nav.Item className="dk-tabs-right-navitem-12">
                        <Link
                          to="#"
                          className={`dk-tabs-right-navlink-12 tab_2 `}
                          onClick={() => changeTabName("audio")}
                        >
                          Audio
                        </Link>
                      </Nav.Item>
                      <Nav.Item className="dk-tabs-right-navitem-12">
                        <Link
                          to="#"
                          className={`dk-tabs-right-navlink-12 tab_3 `}
                          onClick={() => changeTabName("video")}
                        >
                          Video
                        </Link>
                      </Nav.Item>
                    </Nav>
                  </>
                )}

                <div className={`dk-tabs-right-tabpane-12 addnewClass dkg-hidewithoutselct`} >
                  <div className="dk-rightDetail-panel-13">
                    <div className="dk-getStartedRight-detail-13">
                      <Nav
                        variant=""
                        className="dk-tabs-right-nav-13 flex-column"
                      >
                        {data.map((item, key) => (
                          <Nav.Item className="dk-tabs-right-navitem-13">

                            <Link
                              to="#"
                              key={key + 1}
                              className={
                                `dk-tabs-right-navlink-13 xyz` + item.id
                              }
                              onClick={() =>
                                subCategoryFunction(
                                  item.id,
                                  item.categoryId,
                                  item.subCategoryName
                                )
                              }

                            >
                              {item.subCategoryName}{" "}
                            </Link>

                          </Nav.Item>
                        ))}
                      </Nav>

                      {showDetailsTab ? (
                        <Tab.Content className="dk-tabs-right-tabcontent-13">
                          <Editor
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            toolbar={{
                              options: [
                                "inline",
                                "fontSize",
                                "fontFamily",
                                "list",
                                "textAlign",
                                "colorPicker",
                                "link",
                                "image",
                              ],
                              inline: { inDropdown: false },
                              list: { inDropdown: false },
                              textAlign: { inDropdown: false },
                              link: { inDropdown: false },
                              history: { inDropdown: false },
                            }}
                          />
                        </Tab.Content>
                      ) : (
                        <>
                          {/* this is for default active tab data */}
                          {/* {data.length > 0 ? (
                            <Tab.Content className="dk-tabs-right-tabcontent-13">
                              <Editor
                                editorState={editorState}
                                onEditorStateChange={onEditorStateChange}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                toolbar={{
                                  options: [
                                    "inline",
                                    "fontSize",
                                    "fontFamily",
                                    "list",
                                    "textAlign",
                                    "colorPicker",
                                    "link",
                                    "image",
                                  ],
                                  inline: { inDropdown: false },
                                  list: { inDropdown: false },
                                  textAlign: { inDropdown: false },
                                  link: { inDropdown: false },
                                  history: { inDropdown: false },
                                }}
                              />
                            </Tab.Content>
                          ) : null} */}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {props.pagetitle === "Overview" ? (
                  <>
                    <Tab.Content className="dk-tabs-right-tabcontent-13">
                      <Editor
                        editorState={editorOverviewState}
                        onEditorStateChange={onOverviewStateChange}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                          options: [
                            "inline",
                            "fontSize",
                            "fontFamily",
                            "list",
                            "textAlign",
                            "colorPicker",
                            "link",
                            "image",
                          ],
                          inline: { inDropdown: false },
                          list: { inDropdown: false },
                          textAlign: { inDropdown: false },
                          link: { inDropdown: false },
                          history: { inDropdown: false },
                        }}
                      />
                    </Tab.Content>
                  </>
                ) : null}
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1;
