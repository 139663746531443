import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { FaPlus } from "react-icons/fa";
import {
  addCategory,
  clearState,
} from "../../../../../../../slice/clieninfo/requirnment/requirnmentSlice";
import SubmitButton from "../../../../../../ui/submitButton";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../../../components/utils/messages/messageSlice";

const AddNewCategory = () => {
  const dispatch = useDispatch();
  const { isSuccess, isError, isCatInsert, categoryList } = useSelector(
    (state) => state.requirnments
  );

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setShow(false);
      setLoading(false);
      setSuccess(false);
    }
    if (isCatInsert) {
      dispatch(clearState());
      setCategoryName("");
      setShow(false);
      setLoading(false);
      setSuccess(false);
    }
  }, [isError, isCatInsert]);

  const addNewCategory = (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);
    if (!categoryName) {
      dispatch(showError({ msg: "Please enter category naem  required" }));
      setLoading(false);
    } else {
      setLoading(true);
      dispatch(addCategory({ categoryName }));
    }
  };
  return (
    <>
      <span className="dk-resCrcleIconCon" onClick={handleShow}>
        <FaPlus />
      </span>
      <Modal show={show} onHide={handleClose} className="dkg-script-modal-2345">
        <Modal.Header closeButton>
          <Modal.Title>ADD NEW COMPANY</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="">Name of Company</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setCategoryName(e.target.value)}
                value={categoryName}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            txt="Submit"
            loading={loading}
            success={success}
            onClick={addNewCategory}
            position="justify-content-center"
            className="dkg-submitbtn"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNewCategory;
