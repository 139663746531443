import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminHeader from "../../../../../elements/header/Header";
import Navbar from "../../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../../elements/submenu/mainmenu/CandAccSubmenu";
import { Nav, Tab } from "react-bootstrap";
import SecondSideResume from "./SecondSideResume";
import Documnets from "./Documents";
import SummaryComponent from "./Summary";
import MyContactTab from "./MyContactTab";
import ReferralsCompont from "./Referrals";
// import CoverComponent from "./Cover";
//import CountryComponent from "./Country";
import IndustryComponent from "./Industry";
import PositionsComponent from "./Positions";
import JobTitlesComponent from "./JobTitles";
import SalaryComponent from "./Salary";
import BenifitesComponent from "./Benifits";
import MyServicesTab from './MyServicesTab'
import MyresumeComponent from "./myResume";
import { listData, clearState } from "../../../../../../slice/cmsSlice";

import {
  showSuccess,
  showError,
} from "../../../../../utils/messages/messageSlice";
import MyDesiredJobTab from "./MyDesiredJobTab";
import MyDkReumeTab from "./MyDkReumeTab";
import MySummaryTab from "./MySummaryTab";

const MyProfile = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList, isUpdate } = useSelector((state) => state.cms);
  const [summary, setSummaryDescription] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(listData({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setSummaryDescription(getcmsList);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState({}));
      dispatch(showSuccess({ msg: "Information updated successfully." }));
    }
  }, [isUpdate]);

  return (
    <>
      <AdminHeader />
      <Navbar />
      <Submenu />
      <div className="dkg-contact-mainCon">
        <div className="dkg-contact-main">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="dkg-cv-summary"
          >
            <div className="row justify-content-center dkg-contact-mainRow">
              <div className="col-md-2 dkg-contact-main-sideCol">
                <Nav
                  variant="pills dkg-aboutus-navpills dkg-paid-services-navpills"
                  className="flex-column"
                >
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-summary"
                      className="dkg-aboutus-navlink"
                    >
                      Summary
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-preview"
                      className="dkg-aboutus-navlink"
                    >
                      My Account
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-my-contact"
                      className="dkg-aboutus-navlink"
                    >
                      My Contact
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-profile-summary"
                      className="dkg-aboutus-navlink"
                    >
                      My Profile
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-profile-resume-new"
                      className="dkg-aboutus-navlink"
                    >
                      My Resume
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-referrals"
                      className="dkg-aboutus-navlink"
                    >
                      My Referrals
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-contact-details"
                      className="dkg-aboutus-navlink"
                    >
                      My Documents
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-my-dk-resume"
                      className="dkg-aboutus-navlink"
                    >
                      My DK Resume
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-desired-job"
                      className="dkg-aboutus-navlink"
                    >
                      My Desired Job
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-my-services"
                      className="dkg-aboutus-navlink"
                    >
                      My DK Services
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-work-exp"
                      className="dkg-aboutus-navlink"
                    >
                      Create CV
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-qualifications"
                      className="dkg-aboutus-navlink"
                    >
                     Desired Job
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </div>
              <div className="col-md-10 col-10 dkg-contact-mainCol">
                <Tab.Content className="dkg-aboutus-tabcontent">
                  <Tab.Pane
                    eventKey="dkg-cv-preview"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">My Account</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SummaryComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-my-contact"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">My Contact</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <MyContactTab data={summary} />
                    </div>
                  </Tab.Pane> 
                  <Tab.Pane
                    eventKey="dkg-referrals"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">My Referrals</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <ReferralsCompont data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-my-services"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">My Services</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <MyServicesTab data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-desired-job"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">My Desired Job</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <MyDesiredJobTab data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-my-dk-resume"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">My DK Resume</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <MyDkReumeTab data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-summary"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Summary</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <MySummaryTab data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-profile-summary"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">My Profile</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <MyresumeComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-work-exp"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Create CV</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <IndustryComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-profile-resume-new"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Instructions</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <IndustryComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-qualifications"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Desired Job</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <PositionsComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-job-skills"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Job Skills</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <JobTitlesComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-technical-skills"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Technical Skills</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SalaryComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-langauge-skils"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Language Skills</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <BenifitesComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-achievements"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Achievements</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-hobbies"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Hobbies & Interests</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-work-references"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Work References</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-contact-details"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">My Documents</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <Documnets data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-gdpr-consent"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">GDPR Consent</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume data={summary} />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
