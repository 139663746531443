import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Facebook from "../../../../../../assets/images/facebook.png";
import TwitterLogo from "../../../../../../assets/images/twitter-new.png";
import Linkedin from "../../../../../../assets/images/linkedin.png";
import Instagram from "../../../../../../assets/images/instagram.png";
import Youtube from "../../../../../../assets/images/youtube.png";
import Dkgloballogo from "../../../../../../assets/images/dk-global-rec-logo.png";
import { FaEdit } from "react-icons/fa";
import CompanynameModal from "./CompanynameModal";
import {
  updateCompanyJobSpec,
  getcompanydetails,
  clearState,
} from "../../../../../../slice/cmsSlice";
import AWS from "aws-sdk";
import { Buffer } from "buffer";

import $ from "jquery";

const CompanyyInfo = ({ selectedItem }) => {
  console.log(selectedItem);
  //const [companyData, setCompanyData] = useState(null);
  /******* */
  const [inputs, setInputs] = useState({
    comapnyName: "",
    founder: "",
    industry: "",
    companySize: "",
    headquarters: "",
    companyType: "",
    areaServed: "",
    webAddress: "",
    logoImg: "",
    bannerImg: "",
    fblink: "",
    linkdinlink: "",
    instalink: "",
    youtubelink: "",
    twitterlink: "",
  });

  useEffect(() => {
    if (selectedItem) {
      setInputs({
        logoImg: selectedItem[0].logoImg != null ? selectedItem[0].logoImg : "",
        bannerImg:
          selectedItem[0].bannerImg != null ? selectedItem[0].bannerImg : "",
        comapnyName:
          selectedItem[0].comapnyName != null
            ? selectedItem[0].comapnyName
            : "",
        founder: selectedItem[0].founder != null ? selectedItem[0].founder : "",
        industry:
          selectedItem[0].industry != null ? selectedItem[0].industry : "",
        companySize:
          selectedItem[0].companySize != null
            ? selectedItem[0].companySize
            : "",
        headquarters:
          selectedItem[0].headquarters != null
            ? selectedItem[0].headquarters
            : "",
        companyType:
          selectedItem[0].companyType != null
            ? selectedItem[0].companyType
            : "",
        areaServed:
          selectedItem[0].areaServed != null ? selectedItem[0].areaServed : "",
        webAddress:
          selectedItem[0].webAddress != null ? selectedItem[0].webAddress : "",

        fblink: selectedItem[0].fbUrl != null ? selectedItem[0].fbUrl : "",
        linkdinlink:
          selectedItem[0].linkdinUrl != null ? selectedItem[0].linkdinUrl : "",
        instalink:
          selectedItem[0].instagramUrl != null
            ? selectedItem[0].instagramUrl
            : "",
        youtubelink:
          selectedItem[0].youtubeUrl != null ? selectedItem[0].youtubeUrl : "",
        twitterlink:
          selectedItem[0].twitterUrl != null ? selectedItem[0].twitterUrl : "",
      });
    }
  }, [selectedItem]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    dispatch(
      updateCompanyJobSpec({
        id: selectedItem[0].id,
        key: name,
        value: value,
      })
    );
  };
  /******* */

  // const { isLoading, isSuccess, companyjobspList, isError, issUpdate } =
  //   useSelector((state) => state.cms);

  const dispatch = useDispatch();
  const [isCompanyName, setIsCompanyName] = useState(false);

  const [firstImg, setFirstImg] = useState(true);
  const [firstImgbase64, setFirstImgbase64] = useState("");

  // const [rowidd, setrowidd] = useState(0);
  // const [companyyLogo, setCompanyyLogo] = useState("");
  // const [companyyBanner, setCompanyyBanner] = useState("");
  // const [companyyName, setCompanyyName] = useState("");
  // const [companyyfounder, setCompanyyFouder] = useState("");
  // const [companyIndustry, setCompanyIndustry] = useState("");
  // const [companyySize, setCompanyySize] = useState("");
  // const [companyyHeadquater, setCompanyyHeadquater] = useState("");
  // const [companyytype, setCompanyytype] = useState("");
  // const [companyyAreaServe, setCompanyyAreaServe] = useState("");
  // const [companyyWebaddress, setCompanyyWebaddress] = useState("");

  // useEffect(() => {
  //   setCompanyyName("");
  //   setCompanyyFouder("");
  //   setCompanyIndustry("");
  //   setCompanyySize("");
  //   setCompanyyHeadquater("");
  //   setCompanyytype("");
  //   setCompanyyAreaServe("");
  //   setCompanyyWebaddress("");
  //   dispatch(clearState());
  //   dispatch(getcompanydetails({ id: companyInfo[0].id }));
  // }, [rowidd]);

  // useEffect(() => {
  //   if (!isLoading && companyjobspList) {
  //     dispatch(clearState());
  //     setrowidd(companyjobspList.id);
  //     setCompanyyLogo(companyjobspList.logoImg);
  //     setCompanyyBanner(companyjobspList.bannerImg);
  //     setCompanyyName(companyjobspList.comapnyName);
  //     setCompanyyFouder(companyjobspList.founder);
  //     setCompanyIndustry(companyjobspList.industry);
  //     setCompanyySize(companyjobspList.companySize);
  //     setCompanyyHeadquater(companyjobspList.headquarters);
  //     setCompanyytype(companyjobspList.companyType);
  //     setCompanyyAreaServe(companyjobspList.areaServed);
  //     setCompanyyWebaddress(companyjobspList.webAddress);
  //   }
  // }, [isLoading]);

  const [secondImg, setSecondImg] = useState(true);
  const [secondImgbase64, setSecondImgbase64] = useState("");

  // const showcompanyNameModal = () => {
  //   setIsCompanyName(true);
  // };
  const hidecompanyNameModal = () => {
    setIsCompanyName(false);
  };

  //const handleChange = () => {
  // dispatch(
  //   updateCompanyJobSpec({
  //     id: rowidd,
  //     key: columnName,
  //     value: data,
  //   })
  // );
  //};

  const openUrl = (url) => {
    window.open(url, "_blank").focus();
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        if (e.target.name === "secondImg") {
          $(".changeSecondImageDynamic" + e.target.id).attr(
            "src",
            reader.result
          );
        } else if (e.target.name === "thirdImg") {
          $(".changeThirdImageDynamic" + e.target.id).attr(
            "src",
            reader.result
          );
        } else {
          $(".changeImageDynamic" + e.target.id).attr("src", reader.result);
        }
        let s3bucket = new AWS.S3({
          accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
          secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
          Bucket: process.env.REACT_APP_BUCKET_NAME,
        });
        let buf = new Buffer(
          reader.result.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );
        s3bucket.createBucket(function () {
          var params = {
            Bucket: process.env.REACT_APP_BUCKET_NAME + "/users/images",
            Key: Date.now() + ".png",
            ContentEncoding: "base64",
            ContentType: "image/png",
            Body: buf,
          };
          s3bucket.upload(params, function (err, data) {
            if (err) {
              console.log(err);
            } else {
              dispatch(
                updateCompanyJobSpec({
                  id: selectedItem[0].id,
                  key: e.target.name === "secondImg" ? "bannerImg" : "logoImg",
                  value: data.key,
                })
              );
            }
          });
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <>
      {isCompanyName ? (
        <CompanynameModal onHide={hidecompanyNameModal} />
      ) : null}
      <div className="dkg-companynew-info-mainCon">
        <div className="dkg-jopspec-pageheader">
          <div className="dkg-jopspec-page-title">Overview</div>
        </div>
        <div className="dkg-companynew-info-con">
          <div className="dkg-comany-banner-mainCon">
            <div className="dkg-comany-banner-cols">
              <div className="dkg-comany-banner-box">
                {firstImg ? (
                  <img
                    alt=""
                    className={`dkg-changIcon dkg-comany-banner changeImageDynamic`}
                    src={
                      process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                      "/" +
                      inputs.logoImg
                    }
                  />
                ) : (
                  <img
                    alt=""
                    className={`dkg-changIcon changeImageDynamic`}
                    src={firstImgbase64}
                  />
                )}
                <div className="dkg-companynew-banner-edit">
                  <input
                    type="file"
                    accept="image/*"
                    name="firstImg"
                    onChange={onSelectFile}
                    className="dkg-banner-input"
                  />
                  <FaEdit />
                </div>
              </div>
              <div className="dkg-cp-info-bottomcon">
                <div className="dkg-cp-info-leftCon">
                  <div className="dkg-cp-info-box">
                    {secondImg ? (
                      <img
                        alt=""
                        className={`dkg-changIcon changeSecondImageDynamic dkg-cp-info-logo`}
                        src={
                          process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          inputs.bannerImg
                        }
                      />
                    ) : (
                      <img
                        alt=""
                        className={`dkg-changIcon changeSecondImageDynamic dkg-cp-info-logo`}
                        src={secondImgbase64}
                      />
                    )}

                    <div className="dkg-cp-info-edit-icon">
                      <input
                        type="file"
                        accept="image/*"
                        name="secondImg"
                        onChange={onSelectFile}
                        className="dkg-cp-info-logo-input"
                      />
                      <FaEdit />
                    </div>
                  </div>
                  <div className="dkg-cp-info-nameCon">
                    <input
                      type="text"
                      className="form-control dkg-cp-info-name"
                      name="comapnyName"
                      id="comapnyName"
                      // value={companyyName}
                      // onChange={(e) => {
                      //   setCompanyyName(e.target.value);
                      //   updatedata(e.target.value, "comapnyName");
                      // }}
                      value={inputs.comapnyName}
                      onChange={handleChange}
                    />
                    <div className="dkg-cp-info-title-edit">
                      <FaEdit />
                      {/* <FaEdit onClick={showcompanyNameModal} /> */}
                    </div>
                  </div>
                </div>
                <div className="dkg-cp-info-rightCon">
                  <div className="dkg-socailmedia-box">
                    <Link
                      to="#"
                      className="dkg-socailmedia-tab mr-2"
                      onClick={() => openUrl(inputs.fblink)}
                    >
                      <img
                        src={Facebook}
                        className="dkg-socailmedia-img"
                        alt="Facebook"
                      />
                    </Link>
                    <Link
                      to="#"
                      className="dkg-socailmedia-tab mr-2"
                      onClick={() => openUrl(inputs.linkdinlink)}
                    >
                      <img
                        src={Linkedin}
                        className="dkg-socailmedia-img"
                        alt="Linkedin"
                      />
                    </Link>
                    <Link
                      to="#"
                      className="dkg-socailmedia-tab mr-2"
                      onClick={() => openUrl(inputs.instalink)}
                    >
                      <img
                        src={Instagram}
                        className="dkg-socailmedia-img"
                        alt="Instagram"
                      />
                    </Link>
                    <Link
                      to="#"
                      className="dkg-socailmedia-tab mr-2"
                      onClick={() => openUrl(inputs.youtubelink)}
                    >
                      <img
                        src={Youtube}
                        className="dkg-socailmedia-img"
                        alt="Youtube"
                      />
                    </Link>
                    <Link
                      to="#"
                      className="dkg-socailmedia-tab"
                      onClick={() => openUrl(inputs.twitterlink)}
                    >
                      <img
                        src={TwitterLogo}
                        className="dkg-socailmedia-img"
                        alt="Twitter"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dkg-companynew-banner-tableCon">
            <div className="dkg-companynew-info-tablecon">
              <table className="table table-bordered dkg-companynew-info-table">
                <tbody>
                  <tr>
                    <td>Founder</td>
                    <td>
                      <input
                        type="text"
                        className="form-control b-none dkg-sm-input"
                        name="founder"
                        id="founder"
                        // value={companyyfounder}
                        // onChange={(e) => {
                        //   setCompanyyFouder(e.target.value);
                        //   updatedata(e.target.value, "founder");
                        // }}
                        value={inputs.founder}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Industry</td>
                    <td>
                      <input
                        type="text"
                        className="form-control b-none dkg-sm-input"
                        id="industry"
                        // defaultValue={companyIndustry}
                        // onChange={(e) => {
                        //   setCompanyIndustry(e.target.value);
                        //   updatedata(e.target.value, "industry");
                        // }}
                        name="industry"
                        value={inputs.industry}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Company Size</td>
                    <td>
                      <input
                        type="text"
                        className="form-control b-none dkg-sm-input"
                        id="companySize"
                        //name="companySize"
                        // defaultValue={companyySize}
                        // onChange={(e) => {
                        //   setCompanyySize(e.target.value);
                        //   updatedata(e.target.value, "companySize");
                        // }}
                        name="companySize"
                        value={inputs.companySize}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Headquarters</td>
                    <td>
                      <input
                        type="text"
                        className="form-control b-none dkg-sm-input"
                        name="headquarters"
                        id="headquarter"
                        // defaultValue={companyyHeadquater}
                        // onChange={(e) => {
                        //   setCompanyyHeadquater(e.target.value);
                        //   updatedata(e.target.value, "headquarters");
                        // }}
                        value={inputs.headquarters}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Company Type</td>
                    <td>
                      <input
                        type="text"
                        className="form-control b-none dkg-sm-input"
                        name="companyType"
                        id="companyType"
                        // defaultValue={companyytype}
                        // onChange={(e) => {
                        //   setCompanyytype(e.target.value);
                        //   updatedata(e.target.value, "companyType");
                        // }}
                        value={inputs.companyType}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Area Served</td>
                    <td>
                      <input
                        type="text"
                        className="form-control b-none dkg-sm-input"
                        name="areaServed"
                        id="Area"
                        // defaultValue={companyyAreaServe}
                        // onChange={(e) => {
                        //   setCompanyyAreaServe(e.target.value);
                        //   updatedata(e.target.value, "areaServed");
                        // }}
                        value={inputs.areaServed}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Web Address</td>
                    <td>
                      <Link to="#" style={{ color: "#629bd3" }}>
                        <input
                          type="text"
                          className="form-control b-none dkg-sm-input"
                          name="webAddress"
                          id="webAddress"
                          // defaultValue={companyyWebaddress}
                          // onChange={(e) => {
                          //   setCompanyyWebaddress(e.target.value);
                          //   updatedata(e.target.value, "webAddress");
                          // }}
                          value={inputs.webAddress}
                          onChange={handleChange}
                        />
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyyInfo;
