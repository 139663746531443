import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminHeader from "../../../../../elements/header/Header";
import Navbar from "../../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../../elements/submenu/mainmenu/CandAccSubmenu";
import { Nav, Tab } from "react-bootstrap";
import SummaryComponent from './SummaryComponent'
import MyContactTab from './MyContactTab'

const MailboxTab = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList, isUpdate } = useSelector((state) => state.cms);
  const [summary, setSummaryDescription] = useState("");
  return (
    <>
      <AdminHeader />
      <Navbar />
      <Submenu />
      <div className="dkg-contact-mainCon">
        <div className="dkg-contact-main">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="dkg-cv-preview"
          >
            <div className="row justify-content-center dkg-contact-mainRow">
              <div className="col-md-2 dkg-contact-main-sideCol">
                <Nav
                  variant="pills dkg-aboutus-navpills dkg-paid-services-navpills"
                  className="flex-column"
                >
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-preview"
                      className="dkg-aboutus-navlink"
                    >
                      Title
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-my-contact"
                      className="dkg-aboutus-navlink"
                    >
                      My Contact
                    </Nav.Link>
                  </Nav.Item> */}
                  
                </Nav>
              </div>
              <div className="col-md-10 col-10 dkg-contact-mainCol">
                <Tab.Content className="dkg-aboutus-tabcontent">
                  <Tab.Pane
                    eventKey="dkg-cv-preview"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">My Account</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SummaryComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-my-contact"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">My Contact</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <MyContactTab data={summary} />
                    </div>
                  </Tab.Pane>
                  
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default MailboxTab;
