import React from 'react'
import AdminHeader from "../../../elements/header/Header";
import Navbar from "../../../elements/navbar/mainmenu/MainNavbar";

const index = () => {
  return (
    <>
        <AdminHeader />
        <Navbar />
         <div className="dkg-legal-mainCon" style={{backgroundColor: "#eaeaea" , height: "100vh"}}></div>
    </>
  )
}

export default index