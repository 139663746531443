import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./checkbox.scss";
import CandidateInfo from "./modal/CandidateInfo";
import DeleteModal from "../../../../ui/Modal/deletemodal/DeleteModal";
import { updateData } from "../../../../../slice/candidate/candidateSlice";

const CheckboxDummy = ({ canid }) => {
  //console.log(canid);
  const dispatch = useDispatch();
  const [isDelete, setIsDelete] = useState(false);
  // const [isActive, setIsActive] = useState(false);
  // const [countCheck, setCountCheck] = useState(0);
  // const [checkBtnTxt, setCheckBtnTxt] = useState("Check All");

  // const handleToggle = () => {
  //   $(".chkCandidate").prop("checked", false);
  //   setIsActive(false);
  // };

  // const deleteSelected = () => {
  //   setShowDelete(true);
  // };
  // const toggleCheckbox = (e) => {
  //   var checkedBoxCount = $('input[name="chkCandidate"]:checked').length;
  //   if (checkedBoxCount > 0) {
  //     setCountCheck(checkedBoxCount);
  //     setIsActive(true);
  //   } else {
  //     setCountCheck(0);
  //     setIsActive(false);
  //   }
  //   var checkedBoxCount = $('input[name="chkCandidate"]:checked').length;
  //   var totalCheckboxes = $('input[name="chkCandidate"]').length;
  //   if (checkedBoxCount < totalCheckboxes) {
  //     setCheckBtnTxt("Check All");
  //   }
  //   if (checkedBoxCount == totalCheckboxes) {
  //     setCheckBtnTxt("Uncheck All");
  //   }
  // };
  //const [show, setShow] = useState(false);
  //const handleClose = () => setShow(false);
  const showdeleteModal = () => {
    setIsDelete(true);
  };
  const hidedeleteModal = () => {
    setIsDelete(false);
  };
  const deleteCandidate = async () => {
    await dispatch(
      updateData({ id: canid.id, key: "status", value: "Archive" })
    );
  };

  const [checkedJobseekerAcc, setcheckedJobseekerAcc] = useState(false)
  const [countCheck, setCountCheck] = useState(1)

  const toggleCheckbox = () =>{
      console.log("Toggle Checkbox ")
      setcheckedJobseekerAcc(!checkedJobseekerAcc)
  }
  return (
    <React.Fragment>
      {isDelete ? (
        <DeleteModal
          handleClose={hidedeleteModal}
          deleteItem={deleteCandidate}
        />
      ) : null}
     {/* {
      checkedJobseekerAcc ? (
        <DeleteModal
          handleClose={hidedeleteModal}
          deleteItem={deleteCandidate}
        />
        ) : null
      } */}
      <span
        className={`dk-smsMessage-selectMsg ${checkedJobseekerAcc ? "activePopup" : ""}`}
        style={{width: "390px"}}
      >
        <span className="d-flex align-items-center justify-content-between">
          <span className="dk-selectNo" style={{minWidth: "130px"}}>{countCheck}</span>
          {/* <span className="dk-rowSelected">
            <span>Selected</span>
          </span> */}
          <span className="dk-checkAll delete-check-tab" style={{minWidth: "130px" , borderLeft: "1px solid transparent"}} >
            <span className="dkg-trash-span">
              <i className="far fa-trash-alt"></i>
            </span>
            Delete Permanently
          </span>
          <span className="dk-checkAll"  onClick={toggleCheckbox} style={{minWidth: "130px"}}>
            <span className="dkg-trash-span" onClick={toggleCheckbox}>
              <i className="fas fa-times"onClick={toggleCheckbox}></i>
            </span>
            Cancel
          </span>
        </span>
      </span>
      <span className="dk-smsTableCheckbox">
        <span className="dkg-jobseeker-acc-chkCon">
          <input
            type="checkbox"
            className="chkCandidate"
            name="chkCandidate"
            checked={checkedJobseekerAcc}
            // onChange={(e) => toggleCheckbox()}
            onChange={toggleCheckbox}
          />
        </span>
        <span className="dkg-active-account">
          {/* <span className="dkg-active-editicon">
            <i className="far fa-edit"></i>
          </span> */}
          <span className="dkg-active-deleteicon">
            {canid.accountStatus === 0 ? (
              <>
                <i className="far fa-trash-alt" onClick={showdeleteModal}></i>
              </>
            ) : (
              <></>
            )}
          </span>
        </span>
        <CandidateInfo userInfo={canid} />
      </span>
      {/* <span
        className={`dk-smsMessage-selectMsg ${isActive ? "activePopup" : ""}`}
      >
        <span className="d-flex align-items-center justify-content-between">
          <span className="dk-checkAll" onClick={deleteSelected}>
            <span>
              <i className="far fa-edit"></i>
            </span>
            Edit
          </span>
          <span className="dk-checkAll" onClick={deleteSelected}>
            <span>
              <i className="far fa-trash-alt"></i>
            </span>
            Delete
          </span>
          <span
            className="dk-checkAll d-flex align-items-center justify-content-center"
            onClick={handleToggle}
          >
            <span>
              <i className="fas fa-times"></i>
            </span>
          </span>
        </span>
      </span> */}
    </React.Fragment>
  );
};

export default CheckboxDummy;
