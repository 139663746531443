import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav } from "react-bootstrap";
import SubmitButton from "../../../../ui/submitButton";
import {
  listData,
  updateData,
  updateNotification,
  clearState,
} from "../../../../../slice/cmsSlice";
import {
  showError,
  showSuccess,
} from "../../../../utils/messages/messageSlice";

const TextChangeNotifiactions = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList, isUpdate } = useSelector((state) => state.cms);
  const [overviewData, setOverviewData] = useState("");
  const [data, setData] = useState([]);

  const [f1, setF1] = useState("");
  const [f2, setF2] = useState("");
  const [f3, setF3] = useState("");
  const [f4, setF4] = useState("");
  const [f5, setF5] = useState("");
  const [f6, setF6] = useState("");
  const [f7, setF7] = useState("");
  const [f8, setF8] = useState("");
  const [f9, setF9] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(listData({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState({}));
      dispatch(showSuccess({ msg: "Information updated successfully" }));
    }
  }, [isUpdate]);

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setOverviewData(getcmsList[7].description);
      setData(
        getcmsList.filter((item) => item.type === "setting-notification-desc")
      );
      setF1(getcmsList[18].description);
      setF2(getcmsList[19].description);
      setF3(getcmsList[20].description);
      setF4(getcmsList[21].description);
      setF5(getcmsList[22].description);
      setF6(getcmsList[23].description);
      setF7(getcmsList[24].description);
      setF8(getcmsList[25].description);
      setF9(getcmsList[26].description);
    }
  }, [isLoading]);

  const SubmitBtn = () => {
    dispatch(
      updateData({
        id: 8,
        key: "description",
        value: overviewData,
      })
    );
  };

  const updateHandle = (id, value, key) => {
    dispatch(
      updateNotification({
        id,
        key,
        value,
      })
    );
  };
  return (
    <>
      <div className="dkg-cand-services-Side">
        <Tab.Container defaultActiveKey="first">
          <div className="row m-0 dkg-payment-qt-tab-mainCon">
            <div className="col-md-2 pl-0 dkg-payment-qt-tabCon dkg-profile-task-sideMenu">
              <Nav
                variant="pills"
                className="flex-column dkg-payment-qt-tab-navpills"
              >
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="first"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Instrctions
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="dkg-cond-notification"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Conditions
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-md-10  dkg-paidservices-rightCon">
              <Tab.Content className="dkg-paidservices-tabcontent">
                <Tab.Pane eventKey="first" className="dkg-paidservices-tabpane">
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Summary</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <textarea
                      className="dkg-cand-dash-input"
                      defaultValue={overviewData != "" ? overviewData : ""}
                      onChange={(e) => setOverviewData(e.target.value)}
                    ></textarea>
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                        onClick={SubmitBtn}
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="dkg-cond-notification"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Conditions</h4>
                  </div>
                  <div className="dkg-paidservices-body dkg-cn-notification-body">
                    <div className="dkg-cn-noti-tableCon">
                      <table className="table dkg-cn-noti-table table-bordered">
                        <thead>
                          <tr>
                            <th>Conditions</th>
                            <th>Title</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.length > 0 ? (
                            <>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    className="dkg-noti-title-frmcontrol form-control"
                                    defaultValue="System Notifications"
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <div className="dkg-noti-subtitle-cols">
                                    <input
                                      type="text"
                                      className="dkg-noti-subtitle-frmcontrol form-control"
                                      defaultValue={f1}
                                      onChange={(e) =>
                                        updateHandle(
                                          19,
                                          e.target.value,
                                          "description"
                                        )
                                      }
                                    />
                                    <input
                                      type="text"
                                      className="dkg-noti-subtitle-frmcontrol form-control"
                                      defaultValue={f2}
                                      onChange={(e) =>
                                        updateHandle(
                                          20,
                                          e.target.value,
                                          "description"
                                        )
                                      }
                                    />
                                    <input
                                      type="text"
                                      className="dkg-noti-subtitle-frmcontrol form-control"
                                      defaultValue={f3}
                                      onChange={(e) =>
                                        updateHandle(
                                          21,
                                          e.target.value,
                                          "description"
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    className="dkg-noti-title-frmcontrol form-control"
                                    defaultValue="Job Match Notification"
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <div className="dkg-noti-subtitle-cols">
                                    <input
                                      type="text"
                                      className="dkg-noti-subtitle-frmcontrol form-control"
                                      defaultValue={f4}
                                      onChange={(e) =>
                                        updateHandle(
                                          22,
                                          e.target.value,
                                          "description"
                                        )
                                      }
                                    />
                                    <input
                                      type="text"
                                      className="dkg-noti-subtitle-frmcontrol form-control"
                                      defaultValue={f5}
                                      onChange={(e) =>
                                        updateHandle(
                                          23,
                                          e.target.value,
                                          "description"
                                        )
                                      }
                                    />
                                    <input
                                      type="text"
                                      className="dkg-noti-subtitle-frmcontrol form-control"
                                      defaultValue={f6}
                                      onChange={(e) =>
                                        updateHandle(
                                          24,
                                          e.target.value,
                                          "description"
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    className="dkg-noti-title-frmcontrol form-control"
                                    defaultValue="Job Promotions Notification"
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <div className="dkg-noti-subtitle-cols">
                                    <input
                                      type="text"
                                      className="dkg-noti-subtitle-frmcontrol form-control"
                                      defaultValue={f7}
                                      onChange={(e) =>
                                        updateHandle(
                                          25,
                                          e.target.value,
                                          "description"
                                        )
                                      }
                                    />
                                    <input
                                      type="text"
                                      className="dkg-noti-subtitle-frmcontrol form-control"
                                      defaultValue={f8}
                                      onChange={(e) =>
                                        updateHandle(
                                          26,
                                          e.target.value,
                                          "description"
                                        )
                                      }
                                    />
                                    <input
                                      type="text"
                                      className="dkg-noti-subtitle-frmcontrol form-control"
                                      defaultValue={f9}
                                      onChange={(e) =>
                                        updateHandle(
                                          27,
                                          e.target.value,
                                          "description"
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            </>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                        onClick={SubmitBtn}
                      />
                    </div> */}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default TextChangeNotifiactions;
