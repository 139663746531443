import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getUserslist = createAsyncThunk(
    'users/listdata',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/managment-list',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer '+ token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const userDelete = createAsyncThunk(
    'user/delete',
    async ({ rowId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/' + rowId,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer '+token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const registerUser = createAsyncThunk(
    'user/add',
    async ({ firstName, lastName, email, role, status, password }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer '+ token
                    },
                    body: JSON.stringify({
                        firstName, lastName, email, role, status, password
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)
export const updateStatus = createAsyncThunk(
    'users/changeStatus',
    async ({ rowId, status }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/updateStatus/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer '+ token
                    },
                    body: JSON.stringify({
                        status
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateCategory = createAsyncThunk(
    'users/changeCategory',
    async ({ rowId, category }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/updateCategory/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer '+ token
                    },
                    body: JSON.stringify({
                        category
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateRole = createAsyncThunk(
    'users/changeRole',
    async ({ rowId, role }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/updateRole/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer '+ token
                    },
                    body: JSON.stringify({
                        role
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const usersById = createAsyncThunk(
    'users/listById',
    async ({ userId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/' + userId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer '+ token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateDetails = createAsyncThunk(
    'users/updateUserDetails',
    async ({ file, userId, firstName, lName, country, city, nationality, birthDate, backupEmail, mobileNo, landlineNo, skypeId, whatsApp, password, confirmPassword, imgFile }, thunkAPI) => {
        try {
            let lastName;
            var lsName = lName.split(" ");
            if (lsName[1] === "undefined") {
                lastName = lsName[0];
            } else {
                lastName = lName
            }
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/' + userId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer '+ token
                    },
                    body: JSON.stringify({
                        file, firstName, lastName, country, city, nationality, birthDate, backupEmail, mobileNo, landlineNo, skypeId, whatsApp, password, confirmPassword, imgFile
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)
export const reorderData = createAsyncThunk(
    'users/usermanagment/reorderlist',
    async ({ ids }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/reorder',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer '+ token
                    },
                    body: JSON.stringify({
                        ids
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const usersSlice = createSlice({
    name: 'adminusers',
    initialState: {
        allusers: [],
        usersRole: [],
        isSuccess: false,
        isLoading: false,
        isError: false,
        isUpdate: false,
        userDetails: {},
        isReoder: false,
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.isDelete = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isReoder = false;
        }

    },
    extraReducers: {

        [reorderData.pending]: (state) => {
            state.isloading = true;
            state.isReoder = false;
        },
        [reorderData.fulfilled]: (state, { payload }) => {
            state.isReoder = true;
            state.isloading = false;
            state.msg = "Reorder successfully.";
        },
        [reorderData.rejected]: (state, { payload }) => {
            state.isloading = false;
            state.isError = payload.message;
            state.msg = payload.message;
        },

        [getUserslist.pending]: (state) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [getUserslist.fulfilled]: (state, { payload }) => {
            state.allusers = payload.result;
            state.isLoading = false;
            state.isSuccess = true;
        },
        [getUserslist.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
        },

        [userDelete.pending]: (state) => {
            state.isSuccess = false;
        },
        [userDelete.fulfilled]: (state, action) => {
            state.isDelete = true;
            state.allusers = state.allusers.filter((user) => user.id !== action.meta.arg.rowId)
            state.msg = 'Delete Successfully';
        },
        [userDelete.rejected]: (state, { payload }) => {
            state.isDelete = false;
            state.isError = true;
            state.msg = payload.msgBody
        },
        [registerUser.pending]: (state) => {
            state.isLoading = true;
        },
        [registerUser.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = true;
            state.allusers.push(payload.result)
            state.msg = payload.message
        },
        [registerUser.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message
        },
        [updateStatus.pending]: (state) => {
        },
        [updateStatus.fulfilled]: (state, { payload }) => {
            let mainArray = state.allusers;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })

            state.isUpdate = true;
            state.allusers = finalResult
            state.msg = payload.message
        },
        [updateStatus.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.msgBody
        },
        [updateCategory.pending]: (state) => {
            state.isUpdate = true;
        },
        [updateCategory.fulfilled]: (state, { payload }) => {
            let mainArray = state.allusers;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.isUpdate = true;
            state.allusers = finalResult
            state.msg = payload.message
        },
        [updateCategory.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.msgBody
        },
        [updateRole.pending]: (state) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [updateRole.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.allusers;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.allusers = finalResult
            state.msg = payload.message
        },
        [updateRole.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.msgBody
        },
        [usersById.pending]: (state) => {
            state.isLoading = true;
        },
        [usersById.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.userDetails = payload.result;
        },
        [usersById.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateDetails.pending]: (state) => {
            state.isLoading = true;
        },
        [updateDetails.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.allusers;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.allusers = finalResult;
            state.userDetails = payload.result;
            state.msg = payload.message;
        },
        [updateDetails.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message
        },
    }
})
export const { clearState } = usersSlice.actions
export default usersSlice.reducer;