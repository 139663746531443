import React from "react";
import { Route, Link } from "react-router-dom";

const JobsSubmenu = () => {
  const pathname = window.location.pathname;
  return (
    <div>
      <div className="dk-adminSubmenuMain">
        <Route>
          <ul>
            <li>
              <Link
                to="/jobs/summary"
                className={`${
                  pathname.match("/jobs/summary") ? "sub-active" : ""
                }`}
              >
                Summary
              </Link>
            </li>
            <li>
              <Link
                to="#/mailbox/mailbox"
                className={`${
                  pathname.match("/mailbox/report") ? "sub-active" : ""
                }`}
              >
                Active Jobs
              </Link>
            </li>
            <li>
              <Link
                to="#/mailbox/tasks"
                className={`${
                  pathname.match("/mailbox/validation") ? "sub-active" : ""
                }`}
              >
                Inactive Jobs
              </Link>
            </li>
            <li>
              <Link
                to="#/mailbox/actions"
                className={`${
                  pathname.match("/mailbox/mailshot") ? "sub-active" : ""
                }`}
              >
                Closed Jobs
              </Link>
            </li>
            <li>
              <Link
                to="/jobs/apply-job"
                className={`${
                  pathname.match("/jobs/apply-job") ? "sub-active" : ""
                }`}
              >
                Apply Job
              </Link>
            </li>
            <li>
              <Link
                to="/job/faq"
                className={`${pathname.match("/job/faq") ? "sub-active" : ""}`}
              >
                Job FAQ
              </Link>
            </li>
            <li>
              <Link
                to="/jobs/faqs"
                className={`${
                  pathname.match("/jobs/faqs") ? "sub-active" : ""
                }`}
              >
                Jobs FAQ
              </Link>
            </li>
            <li>
              <Link
                to="#/mailbox/summary"
                className={`${
                  pathname.match("/mailbox/inbox") ? "sub-active" : ""
                }`}
              >
                Settings
              </Link>
            </li>
          </ul>
        </Route>
      </div>
    </div>
  );
};

export default JobsSubmenu;
