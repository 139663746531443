import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../../../utils/messages/messageSlice";
import { getprojectlist,deleteProjectValue,clearState } from "../../.../../../../../../../slice/companies/clients/clientSlice";
import { Modal } from 'react-bootstrap';
import Submit from '../../../../../../ui/submitButton'

const DeleteDummy = ({ onHide,id,companyId }) => {
  
    const dispatch = useDispatch();
    const { isLoading,isDelete,message,isError,isProjectInsert  } = useSelector(state => state.companies)
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (!id) {
            dispatch(showError({ msg: 'All fields required' }))
        } else {
            setSuccess(false);
            setLoading(true);
            dispatch(deleteProjectValue({id})) 
        }
    }

useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: message }))
            onHide();
            dispatch(clearState())
        }
      
    }, [isError,companyId])

    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-adstarckDeletePop"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Body className="dk-adstrackDelBody">
                    <p>Are you sure wants to Delete these records ?</p>
                </Modal.Body>
                <Modal.Footer className="dk-adstrackDelFooter">
                    <button className="btn dk-nodelBtn" onClick={onHide}>No</button>
                    <Submit txt="Yes" position="justify-content-center" className="btn dk-yesdelBtn" loading={loading} success={success} onClick={handleButtonClick}/>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default DeleteDummy;
