import React, { useState } from "react";
import { Modal, Dropdown, Nav, Tab } from "react-bootstrap";
import "./myprofile.scss";
import { useDispatch, useSelector } from "react-redux";
import { FaCalendar, FaEdit, FaUser } from "react-icons/fa";
import SubmitButton from "../.././../../../ui/submitButton";
import { updateData } from "../../../../../../slice/candidate/candidateSlice";
//import UserImge from "../../../../../../assets/images/ajay.jpeg";
//import { fetchCandidate } from "../../../../../../slice/candidate/candidateSlice";
// import AWS from "aws-sdk";
// import { Buffer } from "buffer";
// import ReactCrop from "react-image-crop";

const CandidateInfo = ({ userInfo }) => {
  const dispatch = useDispatch();
  // const { isGetcan, candidateDetails } = useSelector(
  //   (state) => state.candidate
  // );
  // const [candidateInfo, setCandidateInfo] = useState([]);
  // const [count, setCount] = useState(0);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  const [userId, setUserId] = useState(userInfo.canId);
  // const [jobid, setJobId] = useState(
  //   userInfo.role != "candidate" ? "" : userInfo.jobid
  // );

  const [firstName, setFirstName] = useState(userInfo.first_name);
  const [lastName, setLastName] = useState(userInfo.last_name);
  const [phone, setPhone] = useState(userInfo.phone1);
  const [accontStatus, setAccontStatus] = useState(
    userInfo.accountStatus === 1 ? "Active" : "Inactive"
  );
  const [accontBgColorStatus, setAccontBgColorStatus] = useState(
    userInfo.accountStatus === 1 ? "#3a9469" : "#d91010"
  );
  // const [department, setDepartment] = useState("");
  // const [currentLocation, setCurrentLocation] = useState(
  //   userInfo.currentLocation
  // );
  // const [dateOfJoining, setJoiningDate] = useState(userInfo.dateOfJoining);
  // const [emergencyContact, setEmryContact] = useState(
  //   userInfo.emergencyContact
  // );
  // const [emergencyPhone, setEmryPhone] = useState(userInfo.emergencyPhone);
  // const [homeAddress, setHomeAddress] = useState(userInfo.homeAddress);

  // const [postalName, setPostalName] = useState(userInfo.postal);
  const [cityName, setCityName] = useState(userInfo.c_city);
  const [countryName, setCountryName] = useState(userInfo.c_country);
  const [nationality, setNationality] = useState(userInfo.nationality);

  // const [uemail, setuEmail] = useState(
  //   userInfo.role == "candidate" ? userInfo.email2 : userInfo.email
  // );
  // const [email, setEmail] = useState(userInfo.email);
  // const [mobileNo, setMobileNo] = useState(userInfo.mobileNo);
  //const handleOnChange = async (e) => {
  // setIsChecked((current) => !current);
  // if (e.target.checked === true) {
  //   dispatch(updateData({ id: canid, key: "accountStatus", value: 1 }));
  // } else {
  //   dispatch(updateData({ id: canid, key: "accountStatus", value: 0 }));
  // }
  //};

  const selectValue = (value, bgcolor) => {
    setAccontStatus(value);
    setAccontBgColorStatus("#" + bgcolor);
    let v;
    if (value == "Active") {
      v = 1;
    } else {
      v = 0;
    }
    dispatch(updateData({ id: userInfo.id, key: "accountStatus", value: v }));
  };

  return (
    <>
      <span>{userId}</span>
      <span className="dkg-cand-info-icon" onClick={handleShow}>
        <FaUser />
      </span>
      <Modal
        show={show}
        onHide={handleClose}
        className="dkg-myacc-profileModal"
        // backdrop="static"
        // keyboard={false}
      >
        <Modal.Header closeButton className="dkg-myacc-profileModal-Header">
          <span className="dkg-candemp-id">CASE ID - {userId}</span>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-myacc-profileModal-Body">
          <div className="dkg-myprofile-mainpageCon dkg-myacc-profile-maincon container-fluid">
            <Tab.Container id="left-tabs-example" defaultActiveKey="my-profile">
              <div className="row dkg-myprofile-maincon-123 m-0">
                <div className="col-md-12 dkg-myprofile-leftSidemenu pl-0 pr-0">
                  <Nav
                    variant="pills"
                    className="flex-row dkg-acc-myprofile-navpills"
                  >
                    <Nav.Item className="dkg-acc-myprofile-navitem">
                      <Nav.Link
                        eventKey="my-profile"
                        className="dkg-acc-myprofile-navlink"
                      >
                        My Profile
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-acc-myprofile-navitem">
                      <Nav.Link
                        eventKey="emergency"
                        className="dkg-acc-myprofile-navlink"
                      >
                        Emergency
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-acc-myprofile-navitem">
                      <Nav.Link
                        eventKey="full-address"
                        className="dkg-acc-myprofile-navlink"
                      >
                        Full Address
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item className='dkg-acc-myprofile-navitem'>
                                    <Nav.Link eventKey="bank-details" className='dkg-acc-myprofile-navlink'>Bank Details</Nav.Link>
                                </Nav.Item> */}
                  </Nav>
                  <Tab.Content className="dkg-acc-myprofile-tabCon">
                    <Tab.Pane
                      eventKey="my-profile"
                      className="dkg-acc-myprofile-tabpane"
                    >
                      <div className="row m-0 dk-billingHistroy-fluid">
                        <div className="col-md-12 dk-contactPerson-form pr-0">
                          <form action="">
                            <div className="row m-0">
                              <div className="col-md-4">
                                <div className="form-group uplaod-image">
                                  <div className="d-flex align-items-center">
                                    <div className="dk-edit-userImgCon">
                                      <input
                                        type="file"
                                        accept="image/*"
                                        name=""
                                      />
                                      <span className="dk-myprofile-userImgCon">
                                        <figure id="edit_image">
                                          {/* <canvas

                                              className="dkg-update-profile-234"
                                            /> */}
                                          <img
                                            alt="Crop"
                                            id="cropImgId"
                                            className="dk-myprofile-userImg"
                                            src={
                                              process.env
                                                .REACT_APP_IMG_CLOUD_BASE_URL +
                                              userInfo.profileImage
                                            }
                                          />
                                        </figure>
                                      </span>
                                      <span className="dk-myprofile-editicon">
                                        <FaEdit />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div
                                  className="form-group"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <label for="">Account Status</label>
                                  <div className="dkg-candinfo-dropdownCon">
                                    <Dropdown className="dkg-candinfo-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className="dkg-candinfo-dropdown-toggle"
                                        style={{
                                          backgroundColor: accontBgColorStatus,
                                          color: "#fff",
                                        }}
                                      >
                                        {accontStatus}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-candinfo-dropdown-menu">
                                        <Dropdown.Item
                                          href="#"
                                          className="dkg-candinfo-item"
                                          style={{
                                            backgroundColor: "#3a9469",
                                            color: "#fff",
                                          }}
                                          onClick={() =>
                                            selectValue("Active", "3a9469")
                                          }
                                        >
                                          Active
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          className="dkg-candinfo-item"
                                          style={{
                                            backgroundColor: "#d91010",
                                            color: "#fff",
                                          }}
                                          onClick={() =>
                                            selectValue("Inactive", "d91010")
                                          }
                                        >
                                          Inactive
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label for="">Account Created</label>
                                  <div
                                    className="dkg-birtday-rowCon"
                                    style={{ position: "relative" }}
                                  >
                                    <input
                                      type="text"
                                      name=""
                                      id=""
                                      className="form-control dkg-datejoing-frame"
                                      defaultValue="23-05-2023"
                                    />
                                    <span
                                      className="dkg-job-birthayicon-con"
                                      style={{ right: "2px" }}
                                    >
                                      <FaCalendar />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label for="">First Name</label>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="form-control w-75"
                                    defaultValue={firstName}
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label for="">Last Name</label>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="form-control"
                                    defaultValue={lastName}
                                    onChange={(e) =>
                                      setLastName(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label for="">Nationality</label>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="form-control"
                                    defaultValue={nationality}
                                    onChange={(e) =>
                                      setNationality(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label for="">Phone Number</label>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="form-control"
                                    defaultValue={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label for="">Account Email</label>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="form-control"
                                    defaultValue={userInfo.email1}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label for="">System Email</label>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="form-control"
                                    defaultValue={userInfo.system_email}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label for="">City/Town</label>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="form-control"
                                    defaultValue={cityName}
                                    onChange={(e) =>
                                      setCityName(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group">
                                  <label for="">Country</label>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="form-control"
                                    defaultValue={countryName}
                                    onChange={(e) =>
                                      setCountryName(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label for="">Account category </label>
                                  <div className="dkg-cand-info-div">Jobseeker</div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label for="">Subscription </label>
                                  <div className="dkg-cand-info-div">
                                    Basic{" "}
                                    <span className="dkg-acc-free-btn">Free</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 dkg-myacc-myprofile-savebtnCon">
                                <div className="dkg-resetbtn-Con-24">
                                  <SubmitButton
                                    txt="SAVE CHANGES"
                                    className="btn dkg-resetbtn"
                                    position="justify-content-center"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="emergency"
                      className="dkg-acc-myprofile-tabpane"
                    >
                      <div className="row m-0 dk-billingHistroy-fluid">
                        <div className="col-md-12 dk-contactPerson-form pr-0">
                          <form action="">
                            <div className="row m-0">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label for="" style={{ minWidth: "140px" }}>
                                    Emergency Contact
                                  </label>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="form-control"
                                    defaultValue="4324356"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label for="" style={{ minWidth: "140px" }}>
                                    Emergency Ph. No.
                                  </label>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="form-control"
                                    defaultValue="232435364"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 dkg-myacc-myprofile-savebtnCon">
                                <div className="dkg-resetbtn-Con-24">
                                  <SubmitButton
                                    txt="SAVE CHANGES"
                                    className="btn dkg-resetbtn"
                                    position="justify-content-center"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="full-address"
                      className="dkg-acc-myprofile-tabpane"
                    >
                      <div className="row m-0 dk-billingHistroy-fluid">
                        <div className="col-md-12 dk-contactPerson-form pr-0">
                          <form action="">
                            <div className="row m-0">
                              <div className="col-md-12">
                                <div
                                  className="form-group"
                                  style={{ alignItems: "inherit" }}
                                >
                                  <label for="">Address line 1</label>
                                  {/* <textarea rows="2" className="form-control w-100 dk-myprofile-textArea" placeholder="">{homeAddress}</textarea> */}
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    defaultValue=""
                                    className="form-control"
                                  />
                                </div>
                                <div
                                  className="form-group"
                                  style={{ alignItems: "inherit" }}
                                >
                                  <label for="">Address line 2</label>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="form-control"
                                    defaultValue=""
                                  />
                                </div>
                                <div className="form-group">
                                  <label for="">City/Town</label>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="form-control"
                                    defaultValue="Noida"
                                  />
                                </div>
                                <div className="form-group">
                                  <label for="">Post Code</label>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="form-control"
                                    defaultValue="201301"
                                  />
                                </div>{" "}
                                <div className="form-group">
                                  <label for="">State</label>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="form-control"
                                    defaultValue="Uttar Pardesh"
                                  />
                                </div>
                                <div className="form-group">
                                  <label for="">Country</label>
                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="form-control"
                                    defaultValue="India"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 dkg-myacc-myprofile-savebtnCon">
                                <div className="dkg-resetbtn-Con-24">
                                  <SubmitButton
                                    txt="SAVE CHANGES"
                                    className="btn dkg-resetbtn"
                                    position="justify-content-center"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CandidateInfo;
