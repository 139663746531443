import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminHeader from "../../../../elements/header/Header";
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../elements/submenu/mainmenu/CandAccSubmenu";
import { Nav, Tab } from "react-bootstrap";
import SecondSideResume from "./SecondSideResume ";
import SummaryComponent from "./Summary";
import CoverComponent from "./Cover";
import CountryComponent from "./Country";
import CityComponent from "./City";
import IndustryComponent from "./Industry";
import PositionsComponent from "./Positions";
import JobTitlesComponent from "./JobTitles";
import DesiredWorkPlace from "./Workplace";
import SalaryComponent from "./Salary";
import BenifitesComponent from "./Benifits";
import { listData, clearState } from "../../../../../slice/cmsSlice";

import {
  showSuccess,
  showError,
} from "../../../../utils/messages/messageSlice";

const MyProfile = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList, isUpdate } = useSelector((state) => state.cms);
  const [summary, setSummaryDescription] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(listData({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setSummaryDescription(getcmsList);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState({}));
      dispatch(showSuccess({ msg: "Information updated successfully." }));
    }
  }, [isUpdate]);

  return (
    <>
      <AdminHeader />
      <Navbar />
      <Submenu />
      <div className="dkg-contact-mainCon">
        <div className="dkg-contact-main">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="dkg-desired-summary"
          >
            <div className="row justify-content-center dkg-contact-mainRow">
              <div className="col-md-2 dkg-contact-main-sideCol">
                <Nav
                  variant="pills dkg-aboutus-navpills dkg-paid-services-navpills"
                  className="flex-column"
                >
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-desired-summary"
                      className="dkg-aboutus-navlink"
                    >
                      Summary
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-desired-coverletter"
                      className="dkg-aboutus-navlink"
                    >
                      Cover Letter
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-search-job"
                      className="dkg-aboutus-navlink"
                    >
                      Desired Countries
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-city-town"
                      className="dkg-aboutus-navlink"
                    >
                      Desired City/Town
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-logos"
                      className="dkg-aboutus-navlink"
                    >
                      Desired Industries
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-social"
                      className="dkg-aboutus-navlink"
                    >
                      Desired Positions
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-study-abroad"
                      className="dkg-aboutus-navlink"
                    >
                      Desired Job Titles
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-desired-workplace"
                      className="dkg-aboutus-navlink"
                    >
                      Desired Workplace
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-plan-career"
                      className="dkg-aboutus-navlink"
                    >
                      Desired Salaries
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="consultation-work-aboard"
                      className="dkg-aboutus-navlink"
                    >
                      Desired Benefits
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-10 col-10 dkg-contact-mainCol">
                <Tab.Content className="dkg-aboutus-tabcontent">
                  <Tab.Pane
                    eventKey="dkg-desired-summary"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Overview</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SummaryComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-desired-coverletter"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Resume</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CoverComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-search-job"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Job Search</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CountryComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-city-town"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Job Search</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CityComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-logos"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Interviews</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <IndustryComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-social"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Assessment</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <PositionsComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-study-abroad"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Communication</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <JobTitlesComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-desired-workplace"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Work Place</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <DesiredWorkPlace data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-plan-career"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Using Internet</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SalaryComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="consultation-work-aboard"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Work Aboard</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <BenifitesComponent data={summary} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="consultation-study-aboard"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Study Aboard</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="consultation-career-planning"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Plan Career</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideResume />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
