import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import "./kpireport-sendouts.scss";
import {
  addQueAns,
  clearState,
} from "../../../../../../../../slice/termsCondition/termsConditionSlice";
import SubmitButton from "../../../../../../../../components/ui/submitButton";
import { showError } from "../../../../../../../../components/utils/messages/messageSlice";
import MessageBox from "../../../../../../../utils/joditEditor/MessageBox";

const AddNewQuestions = ({}) => {
  const dispatch = useDispatch();
  const { isError, isCategoryInsert } = useSelector(
    (state) => state.termsCondition
  );

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [questionS, setIsQuestion] = useState("");
  const [isAnswer, setIsAnswer] = useState("");

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setShow(false);
      setLoading(false);
      setSuccess(false);
    }
    if (isCategoryInsert) {
      dispatch(clearState());
      setIsQuestion("");
      setIsAnswer("");
      setShow(false);
      setLoading(false);
      setSuccess(false);
    }
  }, [isError, isCategoryInsert]);

  const addData = (e) => {
    e.preventDefault();

    setSuccess(false);
    setLoading(true);
    if (!questionS) {
      dispatch(showError({ msg: "Please enter question name" }));
      setLoading(false);
    } else {
      setLoading(true);
      dispatch(
        addQueAns({
          question: questionS,
          answer: isAnswer,
        })
      );
    }
  };

  return (
    <>
      <Link to="#" onClick={handleShow} className="dk-faq-addcategory-btn">
        <i className="fa fa-plus"></i> Add Terms Conditions
      </Link>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="dkg-faq-addnew-questionModal dkg-terms-of-use-modal-234"
      >
        <Modal.Header closeButton className="dk-faq-addquestion-modal-header">
          <Modal.Title>Add Terms Condition</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dk-faq-addquestion-modal-body dkg-terms-of-use-modalbody">
          <div className="form-group mb-0">
            <label>Title</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setIsQuestion(e.target.value)}
              value={questionS}
            />
          </div>
          <div className="form-group mb-0">
            <label>Description</label>

            <MessageBox
              value={isAnswer}
              onChange={(value) => setIsAnswer(value)}
            />
          </div>
          {/* <div className="form-group mb-0">
                        <label>Sequence</label>
                        <input type="text" className="form-control" />
                    </div> */}
        </Modal.Body>
        <Modal.Footer className="dk-faq-addquestion-modal-footer">
          {/* <Link to="#" className="dkg-create-btn mr-2">
            Add
          </Link> */}
          <Link to="#" onClick={handleClose} className="dkg-close-btn mr-2">
            Close
          </Link>
          <SubmitButton
            txt="Add"
            loading={loading}
            success={success}
            onClick={addData}
            position="justify-content-center"
            className="dkg-create-btn mr-2"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNewQuestions;
