import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav } from "react-bootstrap";
import Step1 from "./Step1";
// import "./pt-questions.scss";
import Header from '../../../../elements/header/Header'
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../../elements/submenu/mainmenu/ClientInfoSubmenu'

import {
  getCategories,
  getSubCategories,
  clearState,
} from "../../../../../slice/prescreeningForm";
import { FaArrowsAlt } from "react-icons/fa";
import AddNewCategory from "./modal/maincategory/AddNewCategory";
import EditCategory from "./modal/maincategory/EditCategory";
import ReorderCategory from "./modal/maincategory/ReorderCategory";
import DeleteDummy from "./modal/maincategory/DeleteDummy";
import {
  showSuccess,
  showError,
  clearMessage,
} from '../../../../../components/utils/messages/messageSlice'
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import $ from "jquery";

const Overview = () => {
  const dispatch = useDispatch();
  const {
    isSuccess,
    isLoading,
    isCatUpdate,
    isError,
    isDelete,
    isReoder,
    isSubCatUpdate,
    isSubReoder,
    isCatInsert,
    categoryList,
  } = useSelector((state) => state.screeningFormScript);

  const [data, setData] = useState([]);
  const [catRowId, setCatRowId] = useState("");
  const [catRowName, setCatRowName] = useState("");
  const [activeEditTab, setActiveEditTab] = useState(false);
  const [activeSubCat, setActiveSubCat] = useState("");
  const [activeSTab, setActiveSTab] = useState(false);

  useEffect(() => {
    if (!isLoading && categoryList && categoryList.length > 0) {
      setData(categoryList);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isDelete) {
      setActiveEditTab(false);
    }
  }, [isSubCatUpdate, isCatUpdate, isDelete]);

  useEffect(() => {
    if (isSubReoder) {
      dispatch(clearState());
      dispatch(getSubCategories({ categoryId: catRowId, keys: "details" }));
    }
  }, [isSubReoder]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCategories({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch, isCatInsert, isDelete]);

  useEffect(() => {
    if (isError) {
      dispatch(showError({ msg: "Error while creating categories" }));
    }
    if (isCatInsert) {
      dispatch(showSuccess({ msg: "Category added successful created" }));
    }
  }, [isError, isCatInsert]);

  const selectCat = async (catid, catName) => {
    $(".activeSubTab").removeClass("activeSubTab");
    $(".activesSubTab").removeClass("activesSubTab");
    setActiveSTab(true);
    setCatRowId(catid);
    setCatRowName(catName);
    setActiveEditTab(true);
    await dispatch(getSubCategories({ categoryId: catid, keys: "Overview" }));
  };

  const [isReorderModal, setIReorderModal] = useState(false);
  const showreorderModal = () => {
    setIReorderModal(true);
  };
  const hidereorderModal = () => {
    setIReorderModal(false);
  };

  return (
    <>
      <Header dashboardheaderLink="DKG CRM " dashboardLink="/dashboard/summary" />
      <Navbar />
      <Submenu />
      <div className="dkg-payment-qt-pageCon " style={{ marginTop: "140px" }}>
        <div className="dkg-maincategory-header">
          <ul className="headerlist">
            <li className="headeritem mr-2">
              <AddNewCategory />
            </li>
            <li className="headeritem mr-2">
              <EditCategory
                catIdds={catRowId}
                catName={catRowName}
                activeEditTab={activeEditTab}
              />
            </li>
            <li className="headeritem mr-2">
              <span className="dk-resCrcleIconCon" onClick={showreorderModal}>
                <FaArrowsAlt />
              </span>
              {isReorderModal ? (
                <ReorderCategory onHide={hidereorderModal} dataList={data} />
              ) : null}
            </li>
            <li className="headeritem">
              <DeleteDummy catIdds={catRowId} activeEditTab={activeEditTab} />
            </li>
          </ul>
        </div>
        <Tab.Container defaultActiveKey="question-pt-new-overview">
          <div className="row m-0 dkg-payment-qt-tab-mainCon">
            <div className="col-md-2 pl-0 dkg-payment-qt-tabCon">
              <Nav
                variant="pills"
                className="flex-column dkg-payment-qt-tab-navpills"
              >
                {data.map((item, i) => (
                  <Nav.Item
                    className="dkg-payment-qt-tab-nav-item"
                    onClick={() => selectCat(item.id, item.categoryName)}
                  >
                    {
                      <Nav.Link
                        eventKey={item.categoryName + i}
                        className="dkg-payment-qt-tab-navlink"
                      >
                        {item.categoryName}
                      </Nav.Link>
                    }
                  </Nav.Item>
                ))}
              </Nav>
            </div>
            <div className="col-md-10 dkg-payment-rt-tabCon-10">
              <Tab.Content className="dkg-payment-qt-tabcontent">
                {activeSTab == true
                  ? data.map((item, i) => (
                    <Tab.Pane
                      eventKey={item.categoryName + i}
                      className="dkg-payment-qt-tabpane"
                    >
                      <Step1
                        pagetitle={item.categoryName}
                        catIdds={item.id}
                        tabName="tabChange"
                        catOverviewid={catRowId}
                      />
                    </Tab.Pane>
                  ))
                  : null}
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default Overview;
