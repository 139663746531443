import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

const CasestageDropdwn = () => {
  return (
    <>
      <span className="dkg-casestage-dropdownCon">
        <Dropdown className="dkg-casestage-dropdown">
          <Dropdown.Toggle variant="" className="dkg-casestage-dropdown-toggle">
            <div
              class="caseDiv"
              style={{ backgroundColor: "#30887e", color: "#fff" }}
            >
              Active
            </div>
          </Dropdown.Toggle>
        </Dropdown>
      </span>
    </>
  );
};

export default CasestageDropdwn;
