import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../../../../ui/submitButton";
import Facebook from "../../../../../../assets/images/facebook.png";
import Linkedin from "../../../../../../assets/images/linkedin.png";
import Instagram from "../../../../../../assets/images/instagram.png";
import Youtube from "../../../../../../assets/images/youtube.png";
import DKGlobal from "../../../../../../assets/images/business_icon.png";
import { listData, updateData } from "../../../../../../slice/cmsSlice";
import { showError } from "../../../../../utils/messages/messageSlice";

const SocialmediaTab = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList } = useSelector((state) => state.cms);

  const [fbtitle, setFbtitle] = useState("");
  const [fbUrl, setFbUrl] = useState("");
  const [linkdinTitle, setLinkdinTitle] = useState("");
  const [linkdiUrl, setLinkdinUrl] = useState("");
  const [instaTilte, setInstatitle] = useState("");
  const [instaUrl, setInstaUrl] = useState("");
  const [youtubeTitle, setYoutubeTitle] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [otherTitle, setOtherTitle] = useState("");
  const [otherUrl, setOtherUrl] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(listData({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setFbtitle(getcmsList[1].fbTitle);
      setFbUrl(getcmsList[1].fbUrl);
      setLinkdinTitle(getcmsList[1].linkdinTitle);
      setLinkdinUrl(getcmsList[1].linkdinUrl);
      setInstatitle(getcmsList[1].insta);
      setInstaUrl(getcmsList[1].instaUrl);
      setYoutubeTitle(getcmsList[1].youtubeTitle);
      setYoutubeUrl(getcmsList[1].youtubeUrl);
      setOtherTitle(getcmsList[1].otherTitle);
      setOtherUrl(getcmsList[1].otherTitleUrl);
    }
  }, [isLoading]);

  const SubmitBtn = (id, value, key) => {
    dispatch(
      updateData({
        id,
        key,
        value,
      })
    );
  };

  return (
    <>
      <div className="dkg-socialmedia-tab">
        <div className="dkg-social-box-cols">
          <div className="dkg-social-media-box">
            <div className="dkg-socail-media-icon">
              <div className="dkg-sm-imgcon">
                <img src={Facebook} alt="Facebook" className="dkg-sm-img" />
              </div>
            </div>
            <div className="dkg-sm-nameurl-con">
              <div className="dkg-timeflex">
                <small className="dkg-social-media-name">
                  <input
                    type="text"
                    className="form-control dkg-social-name-iput"
                    defaultValue={fbtitle != "" ? fbtitle : ""}
                    onChange={(e) => setFbtitle(e.target.value)}
                  />
                </small>
                <small className="dkg-social-media-url">
                  <input
                    type="text"
                    className="form-control dkg-social-name-url"
                    defaultValue={fbUrl != "" ? fbUrl : ""}
                    onChange={(e) => setFbUrl(e.target.value)}
                  />
                </small>
              </div>
            </div>
            <div className="dkg-send-urlbtn-con">
              <div className="dkg-send-urlbtn-flex">
                <small className="dkg-social-media-name-btn">
                  <SubmitButton
                    txt="Save Title"
                    className="dkg-send-urlbtn btn"
                    onClick={(e) => SubmitBtn(2, fbtitle, "fbTitle")}
                  />
                </small>
                <small className="dkg-social-media-url-btn">
                  <SubmitButton
                    txt="Save URL"
                    className="dkg-send-urlbtn btn"
                    onClick={(e) => SubmitBtn(2, fbUrl, "fbUrl")}
                  />
                </small>
              </div>
            </div>
          </div>
          <div className="dkg-social-media-box">
            <div className="dkg-socail-media-icon">
              <div className="dkg-sm-imgcon">
                <img src={Linkedin} alt="Linkedin" className="dkg-sm-img" />
              </div>
            </div>
            <div className="dkg-sm-nameurl-con">
              <div className="dkg-timeflex">
                <small className="dkg-social-media-name">
                  <input
                    type="text"
                    className="form-control dkg-social-name-iput"
                    defaultValue={linkdinTitle != "" ? linkdinTitle : ""}
                    onChange={(e) => setLinkdinTitle(e.target.value)}
                  />{" "}
                </small>
                <small className="dkg-social-media-url">
                  <input
                    type="text"
                    className="form-control dkg-social-name-url"
                    defaultValue={linkdiUrl != "" ? linkdiUrl : ""}
                    onChange={(e) => setLinkdinUrl(e.target.value)}
                  />
                </small>
              </div>
            </div>
            <div className="dkg-send-urlbtn-con">
              <div className="dkg-send-urlbtn-flex">
                <small className="dkg-social-media-name-btn">
                  <SubmitButton
                    txt="Save Title"
                    className="dkg-send-urlbtn btn"
                    onClick={(e) => SubmitBtn(2, linkdinTitle, "linkdinTitle")}
                  />
                </small>
                <small className="dkg-social-media-url-btn">
                  <SubmitButton
                    txt="Save URL"
                    className="dkg-send-urlbtn btn"
                    onClick={(e) => SubmitBtn(2, linkdiUrl, "linkdinUrl")}
                  />
                </small>
              </div>
            </div>
          </div>
          <div className="dkg-social-media-box">
            <div className="dkg-socail-media-icon">
              <div className="dkg-sm-imgcon">
                <img src={Instagram} alt="Youtube" className="dkg-sm-img" />
              </div>
            </div>
            <div className="dkg-sm-nameurl-con">
              <div className="dkg-timeflex">
                <small className="dkg-social-media-name">
                  <input
                    type="text"
                    className="form-control dkg-social-name-iput"
                    defaultValue={instaTilte != "" ? instaTilte : ""}
                    onChange={(e) => setInstatitle(e.target.value)}
                  />{" "}
                </small>
                <small className="dkg-social-media-url">
                  <input
                    type="text"
                    className="form-control dkg-social-name-url"
                    defaultValue={instaUrl != "" ? instaUrl : ""}
                    onChange={(e) => setInstaUrl(e.target.value)}
                  />
                </small>
              </div>
            </div>
            <div className="dkg-send-urlbtn-con">
              <div className="dkg-send-urlbtn-flex">
                <small className="dkg-social-media-name-btn">
                  <SubmitButton
                    txt="Save Title"
                    className="dkg-send-urlbtn btn"
                    onClick={(e) => SubmitBtn(2, instaTilte, "insta")}
                  />
                </small>
                <small className="dkg-social-media-url-btn">
                  <SubmitButton
                    txt="Save URL"
                    className="dkg-send-urlbtn btn"
                    onClick={(e) => SubmitBtn(2, instaUrl, "instaUrl")}
                  />
                </small>
              </div>
            </div>
          </div>
          <div className="dkg-social-media-box">
            <div className="dkg-socail-media-icon">
              <div className="dkg-sm-imgcon">
                <img src={Youtube} alt="Youtube" className="dkg-sm-img" />
              </div>
            </div>
            <div className="dkg-sm-nameurl-con">
              <div className="dkg-timeflex">
                <small className="dkg-social-media-name">
                  <input
                    type="text"
                    className="form-control dkg-social-name-iput"
                    defaultValue={youtubeTitle != "" ? youtubeTitle : ""}
                    onChange={(e) => setYoutubeTitle(e.target.value)}
                  />{" "}
                </small>
                <small className="dkg-social-media-url">
                  <input
                    type="text"
                    className="form-control dkg-social-name-url"
                    defaultValue={youtubeUrl != "" ? youtubeUrl : ""}
                    onChange={(e) => setYoutubeUrl(e.target.value)}
                  />
                </small>
              </div>
            </div>
            <div className="dkg-send-urlbtn-con">
              <div className="dkg-send-urlbtn-flex">
                <small className="dkg-social-media-name-btn">
                  <SubmitButton
                    txt="Save Title"
                    className="dkg-send-urlbtn btn"
                    onClick={(e) => SubmitBtn(2, youtubeTitle, "youtubeTitle")}
                  />
                </small>
                <small className="dkg-social-media-url-btn">
                  <SubmitButton
                    txt="Save URL"
                    className="dkg-send-urlbtn btn"
                    onClick={(e) => SubmitBtn(2, youtubeUrl, "youtubeUrl")}
                  />
                </small>
              </div>
            </div>
          </div>
          <div className="dkg-social-media-box">
            <div className="dkg-socail-media-icon">
              <div className="dkg-sm-imgcon">
                <img
                  src={DKGlobal}
                  alt="DK Global Website"
                  className="dkg-sm-img"
                />
              </div>
            </div>
            <div className="dkg-sm-nameurl-con">
              <div className="dkg-timeflex">
                <small className="dkg-social-media-name">
                  <input
                    type="text"
                    className="form-control dkg-social-name-iput"
                    defaultValue={otherTitle != "" ? otherTitle : ""}
                    onChange={(e) => setOtherTitle(e.target.value)}
                  />{" "}
                </small>
                <small className="dkg-social-media-url">
                  <input
                    type="text"
                    className="form-control dkg-social-name-url"
                    defaultValue={otherUrl != "" ? otherUrl : ""}
                    onChange={(e) => setOtherUrl(e.target.value)}
                  />
                </small>
              </div>
            </div>
            <div className="dkg-send-urlbtn-con">
              <div className="dkg-send-urlbtn-flex">
                <small className="dkg-social-media-name-btn">
                  <SubmitButton
                    txt="Save Title"
                    className="dkg-send-urlbtn btn"
                    onClick={(e) => SubmitBtn(2, otherTitle, "otherTitle")}
                  />
                </small>
                <small className="dkg-social-media-url-btn">
                  <SubmitButton
                    txt="Save URL"
                    className="dkg-send-urlbtn btn"
                    onClick={(e) => SubmitBtn(2, otherUrl, "otherTitleUrl")}
                  />
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialmediaTab;
