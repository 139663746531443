import React from 'react'
import { Link } from 'react-router-dom'
import  './company.scss'
import AdminHeader from '../../../../elements/header/Header'
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../../elements/submenu/mainmenu/PagetypeSubmenu'; 
import Facebook from '../../../../../assets/images/facebook.png'
import TwitterLogo from '../../../../../assets/images/twitter-new.png'
import Linkedin from '../../../../../assets/images/linkedin.png'
import Instagram from '../../../../../assets/images/instagram.png' 
import Youtube from '../../../../../assets/images/youtube.png'
import Dkgloballogo from '../../../../../assets/images/dk-global-rec-logo.png'
import { FaEdit } from "react-icons/fa";


const CompanyInfo = () => {
  return (
    <>
        <AdminHeader />
        <Navbar />
        <Submenu />
        <div className="dkg-company-info-mainCon">
            <div className="dkg-company-info-con">
                <div className='dkg-comany-banner-mainCon'>
                    <div className="dkg-comany-banner-cols">
                        <div className="dkg-comany-banner-box">
                            <img src="https://dkglobalrecruiters.com/admin/public/images/candidate/16152159811542900248751.jpg" className='dkg-comany-banner' alt="Company Banner" />
                            <div className="dkg-company-banner-edit">
                                <FaEdit />
                            </div>
                        </div>
                        <div className="dkg-cp-info-bottomcon">
                            <div className="dkg-cp-info-leftCon">
                                <div className="dkg-cp-info-box">
                                    <img src={Dkgloballogo} className='dkg-cp-info-logo' alt="Company Logo" />
                                    <div className="dkg-cp-info-edit-icon">
                                        <FaEdit />
                                    </div>
                                </div>
                                <div className='dkg-cp-info-nameCon'>
                                    <input type="text"  className='form-control dkg-cp-info-name' name='company-name' id='company-name' defaultValue="DK Global Recruiters Ltds" />
                                    <div className="dkg-cp-info-title-edit">
                                        <FaEdit />
                                    </div>
                                </div>
                            </div>
                            <div className="dkg-cp-info-rightCon">
                                <div className="dkg-socailmedia-box">
                                    <Link to="#" className="dkg-socailmedia-tab mr-2">
                                        <img src={Facebook} className='dkg-socailmedia-img' alt="Facebook" />
                                    </Link>
                                    <Link to="#" className="dkg-socailmedia-tab mr-2">
                                        <img src={TwitterLogo} className='dkg-socailmedia-img' alt="Twitter" />
                                    </Link>
                                    <Link to="#" className="dkg-socailmedia-tab mr-2">
                                        <img src={Linkedin} className='dkg-socailmedia-img' alt="Linkedin" />
                                    </Link>
                                    <Link to="#" className="dkg-socailmedia-tab mr-2">
                                        <img src={Instagram} className='dkg-socailmedia-img' alt="Instagram" />
                                    </Link>
                                    <Link to="#" className="dkg-socailmedia-tab">
                                        <img src={Youtube} className='dkg-socailmedia-img' alt="Youtube" />
                                    </Link>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dkg-company-banner-tableCon">
                    <div className="dkg-company-info-tablecon">
                        <table className="table table-bordered dkg-company-info-table">
                            <tbody>
                                <tr>
                                    <td>Founder</td>
                                    <td>
                                        <input type="text" className='form-control b-none dkg-sm-input' name='founder' id='founder' />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Industry</td>
                                    <td>
                                        <input type="text" className='form-control b-none dkg-sm-input' name='founder' id='founder' />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Company Size</td>
                                    <td>
                                    <input type="text" className='form-control b-none dkg-sm-input' name='founder' id='founder' />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Headquarters</td>
                                    <td>
                                    <input type="text" className='form-control b-none dkg-sm-input' name='founder' id='founder' />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Company Type</td>
                                    <td>
                                    <input type="text" className='form-control b-none dkg-sm-input' name='founder' id='founder' />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Area Served</td>
                                    <td>
                                        <input type="text" className='form-control b-none dkg-sm-input' name='founder' id='founder' />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Web Address</td>
                                    <td>
                                        <Link to="#" style={{color: "#629bd3"}}>
                                            <input type="text" className='form-control b-none dkg-sm-input' name='founder' id='founder' />
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default CompanyInfo