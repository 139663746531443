import React from 'react';
import { Route, Link } from "react-router-dom";

const JobspecSubmenu = () => {
    const pathname = window.location.pathname;
    return (
        <div>
            <div className="dk-adminSubmenuMain">
                <Route>
                    <ul className='dkg-jobspec-submenu-ul'>
                        <li>
                            <Link to="/job-specs/instructions" className={`${pathname.match('/job-specs/instructions') ? 'sub-active' : ''}`}>Instructions</Link>
                        </li>
                        <li>
                            <Link
                                to="/job-specs/company"
                                className={`${
                                pathname.match("/job-specs/company") ? "sub-active" : ""
                                }`}
                            >
                                Companies
                            </Link>
                        </li>
                        <li>
                        <Link
                            to="/job-specs/projects"
                            className={`${
                            pathname.match("/job-specs/projects") ? "sub-active" : ""
                            }`}
                        >
                            Projects
                        </Link>
                        </li>
                        <li>
                        <Link
                            to="/job-specs/role-types"
                            className={`${
                            pathname.match("/job-specs/role-types") ? "sub-active" : ""
                            }`}
                        >
                            Role Types
                        </Link>
                        </li>

                        <li>
                        <Link
                            to="/job-specs/salaries"
                            className={`${
                            pathname.match("/job-specs/salaries") ? "sub-active" : ""
                            }`}
                        >
                            Interviews
                        </Link>
                        </li>
                        <li>
                        <Link
                            to="/job-specs/benefits"
                            className={`${
                            pathname.match("/job-specs/benefits") ? "sub-active" : ""
                            }`}
                        >
                            Job Offers
                        </Link>
                        </li>
                        <li>
                        <Link
                            to="/job-specs/relocation"
                            className={`${
                            pathname.match("/job-specs/relocation") ? "sub-active" : ""
                            }`}
                        >
                            Relocations
                        </Link>
                        </li>
                        <li>
                        <Link
                            to="/job-specs/requirements"
                            className={`${
                            pathname.match("/job-specs/requirements")
                                ? "sub-active"
                                : ""
                            }`}
                        >
                            Joining
                        </Link>
                        </li>
                        <li>
                        <Link
                            to="/job-specs/languages"
                            className={`${
                            pathname.match("/job-specs/languages") ? "sub-active" : ""
                            }`}
                        >
                            Why Apply
                        </Link>
                        </li>
                        <li>
                        <Link
                            to="/job-specs/faq"
                            className={`${
                            pathname.match("/job-specs/faq") ? "sub-active" : ""
                            }`}
                        >
                            Job FAQ
                        </Link>
                        </li>
                    </ul>
                </Route>
            </div>
        </div>
    )
}

export default JobspecSubmenu;
