import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './rightmodalfilter.scss';
import { Modal, Nav, Tab } from 'react-bootstrap';
import { MdInsertDriveFile } from "react-icons/md";
//import {  FaCheck } from "react-icons/fa";

const RightModalFilter = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);
    }
    return (
        <React.Fragment>
            <div className="dkg-plusicon-Con mr-2">
                <Link to="#" className="dkg-plusicon" onClick={handleShow}>
                 <i className="fa fa-filter"></i>
                </Link>
            </div>
            <div className="dk-jobsMainModalCon">
                <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName="dk-pipenwqrightFliterModal"
                >
                    <Modal.Header closeButton className="dkmb-Fliterheader">
                        <Modal.Title className="dkmb-FliterTitle">
                            SEARCH & REPORT
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="dkmb-Fliterbody dkg-pipelinesRightFilterBody-13">
                        <Tab.Container id="dk-jobsRightFilterModal" defaultActiveKey="company">
                            <div className="row ml-0 mr-0">
                                <div className="col-md-4 pl-0 pr-0">
                                    <Nav variant="pills" className="flex-column dk-leftSideMenu dkg-right-sideFilter-13">
                                        <Nav.Item>
                                            <Nav.Link eventKey="company">
                                                <i className="fa fa-calendar mr-2" aria-hidden="true"></i>YEAR
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="languages">
                                            <i className="fa fa-calendar mr-2" aria-hidden="true"></i>MONTH
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="project">
                                            <i className="fa fa-flag mr-2" aria-hidden="true"></i>COUNTRY
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="roletype">
                                                <i className="fa fa-flag mr-2" aria-hidden="true"></i>NATIONALITY
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                <div className="col-md-8 pl-0 pr-0">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="rec-admin">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-Recuriter">
                                                    <ul>

                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="rc">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-Recuriter">
                                                    <ul>

                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    )
}
export default RightModalFilter;