import React from 'react'
import AdminHeader from '../../../../elements/header/Header'
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../../pages/submenu/SettingSubmenu'

import FAQComponents from './category/CompanyTable'
import './login-faq.scss'

const LoginFaq = () => {
    return (
        <>
            <AdminHeader />
            <Navbar />
            <Submenu />
            <div className='dkg-loginfaq-mainCon' style={{marginTop: "140px"}}>
                <FAQComponents />
            </div>
        </>
    )
}

export default LoginFaq;