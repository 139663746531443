import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav, InputGroup, FormControl } from "react-bootstrap";
import SubmitButton from "../../../../ui/submitButton";
import { FaBuilding, FaMapMarker, FaEnvelope, FaPhone } from "react-icons/fa";
import {
  listData,
  updateData,
  bulkUpdateData,
  clearState,
} from "../../../../../slice/cmsSlice";
import {
  showSuccess,
  showError,
} from "../../../../utils/messages/messageSlice";

const SendusSideMenu = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList, isUpdate } = useSelector((state) => state.cms);
  const [overview, setOverview] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [emailId, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(listData({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setOverview(getcmsList[1].sendusOverview);
      setCompanyName(getcmsList[1].sendusCompany);
      setAddress(getcmsList[1].sendusAddress);
      setEmail(getcmsList[1].sendusEmail);
      setPhone(getcmsList[1].sendusPhone);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState({}));
      dispatch(showSuccess({ msg: "Information updated successfully." }));
    }
  }, [isUpdate]);

  const SubmitBtn = (id, value, key) => {
    dispatch(
      updateData({
        id,
        key,
        value,
      })
    );
  };

  const updateHandleBtn = () => {
    dispatch(
      bulkUpdateData({
        id: 2,
        sendusCompany: companyName,
        sendusAddress: address,
        sendusEmail: emailId,
        sendusPhone: phone,
      })
    );
  };
  return (
    <>
      <div className="dkg-cand-services-Side">
        <Tab.Container defaultActiveKey="first">
          <div className="row m-0 dkg-payment-qt-tab-mainCon">
            <div className="col-md-2 pl-0 dkg-payment-qt-tabCon dkg-profile-task-sideMenu">
              <Nav
                variant="pills"
                className="flex-column dkg-payment-qt-tab-navpills"
              >
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="first"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Instructions
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="second"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Information
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-md-10  dkg-paidservices-rightCon">
              <Tab.Content className="dkg-paidservices-tabcontent">
                <Tab.Pane eventKey="first" className="dkg-paidservices-tabpane">
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Instructions</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <textarea
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue={overview != "" ? overview : ""}
                      onChange={(e) => setOverview(e.target.value)}
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                        onClick={(e) =>
                          SubmitBtn(2, overview, "sendusOverview")
                        }
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Information</h4>
                  </div>
                  <div className="dkg-paidservices-body dkg-setting-sendus-body">
                    <div className="dkg-candidate-Row row">
                      <div className="col-md-12 col-sm-12 dkg-candidate-leftCon">
                        <div className="dkg-cand-form-group form-group">
                          <InputGroup className="dkg-candidate-inputgrp">
                            <InputGroup.Prepend className="dkg-inputgrp-prepared">
                              <InputGroup.Text className="dkg-inputgrp-text">
                                <FaBuilding />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              aria-label="nameofcompany"
                              className="dkg-cand-frmcontrol"
                              defaultValue={
                                companyName != "" ? companyName : ""
                              }
                              onChange={(e) => setCompanyName(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                        <div className="dkg-cand-form-group form-group">
                          <InputGroup className="dkg-candidate-inputgrp">
                            <InputGroup.Prepend className="dkg-inputgrp-prepared">
                              <InputGroup.Text className="dkg-inputgrp-text">
                                <FaMapMarker />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              aria-label="head-office"
                              className="dkg-cand-frmcontrol"
                              defaultValue={address != "" ? address : ""}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                        <div className="dkg-cand-form-group form-group">
                          <InputGroup className="dkg-candidate-inputgrp">
                            <InputGroup.Prepend className="dkg-inputgrp-prepared">
                              <InputGroup.Text className="dkg-inputgrp-text">
                                <FaEnvelope />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              aria-label="registration"
                              className="dkg-cand-frmcontrol"
                              defaultValue={emailId != "" ? emailId : ""}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                        <div className="dkg-cand-form-group form-group">
                          <InputGroup className="dkg-candidate-inputgrp">
                            <InputGroup.Prepend className="dkg-inputgrp-prepared">
                              <InputGroup.Text className="dkg-inputgrp-text">
                                <FaPhone />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder=""
                              aria-label="vat-number"
                              className="dkg-cand-frmcontrol"
                              defaultValue={phone != "" ? phone : ""}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                        <div className="dkg-cand-form-group form-group">
                          <div className="dkg-sendus-btnCon">
                            <SubmitButton
                              txt="SAVE"
                              className="dkg-sendus-btn btn"
                              onClick={updateHandleBtn}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default SendusSideMenu;
