import React, { useState } from "react";
// import "./switch.scss";
const Switch = ({ handleToggle, id, value, isChecked }) => {
  const [chcked, setIsChecked] = useState(false);
  const handleOnChange = (e) => {
    setIsChecked((current) => !current);
    handleToggle(e);
  };

  return (
    <>
      <div className="dkg-feature-column">
        <span className="dkg-off-label mr-2">Unavailable</span>
        <input
          onChange={handleOnChange}
          className="react-switch-checkbox"
          id={`react-switch-new` + id + value}
          type="checkbox"
          data-id={id}
          data-value={value}
          data-name={value}
          key={id + 1 + value}
          checked={isChecked == chcked ? false : true}
          //checked={isChecked == chcked ? true : false}
        />
        <label
          className="react-switch-label"
          htmlFor={`react-switch-new` + id + value}
        >
          <span className="react-switch-button" />
        </label>
        <span className="dkg-off-label ml-2">Available</span>
      </div>
    </>
  );
};

export default Switch;
