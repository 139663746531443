import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const addQueAns = createAsyncThunk(
    "termsconditionFaq/addQuestionAns",
    async (
        { question, answer },
        thunkAPI
    ) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/terms-condition",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        question,
                        answer
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const getTermsCondition = createAsyncThunk(
    "termsconditionFaq/categorylist",
    async ({ }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/terms-condition/list",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateQueAnsStatus = createAsyncThunk(
    "termsconditionFaq/update_Que_ans",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/terms-condition/update/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        key,
                        value,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateQuesAns = createAsyncThunk(
    "termsconditionFaq/update_quesAns",
    async ({ id, question, answer }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/terms-condition/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        question,
                        answer,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteTermsCondition = createAsyncThunk(
    "termsconditionFaq/companydelete",
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/terms-condition/" + id,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const usersSlice = createSlice({
    name: "termsslice",
    initialState: {
        bkCompanylist: [],
        companyList: [],
        dataaList: [],
        categoryList: [],
        faqCategoryList: [],
        quesansList: [],
        firstLevelList: [],
        isSuccess: false,
        isTabActive: false,
        isCompanyInsert: false,
        isCategoryInsert: false,
        isSubCatInsert: false,
        isCatUpdate: false,
        isUpdate: false,
        isLoading: false,
        issLoading: false,
        isSubLoading: false,
        isSubReoder: false,
        isError: false,
        isDelete: false,
        issDelete: false,
        isReoder: false,
        isDefult: false,
        isFaqs: false,
    },
    reducers: {
        clearState: (state) => {
            state.isSubReoder = false;
            state.isDetails = false;
            state.isFaqs = false;
            state.isSubLoading = false;
            state.issLoading = false;
            state.isLoading = false;
            state.isSubCatInsert = false;
            state.isSuccess = false;
            state.isDefult = false;
            state.isTabActive = false;
            state.isError = false;
            state.isCompanyInsert = false;
            state.isCategoryInsert = false;
            state.isUpdate = false;
            state.isCatUpdate = false;
            state.isDelete = false;
            state.issDelete = false;
            state.isReoder = false;
        },
    },
    extraReducers: {
        [addQueAns.pending]: (state) => {
            state.isLoading = true;
            state.isCategoryInsert = false;
            state.isError = false;
        },
        [addQueAns.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.isCategoryInsert = true;
            state.dataaList = payload.result;
        },
        [addQueAns.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.isCategoryInsert = false;
        },

        [getTermsCondition.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getTermsCondition.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.dataaList = payload.result;
        },
        [getTermsCondition.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
        },

        [updateQueAnsStatus.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
            state.isError = false;
        },
        [updateQueAnsStatus.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.isUpdate = true;
            let mainArray = state.dataaList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return payload.result;
                } else {
                    return r;
                }
            });
            state.dataaList = finalResult;
        },
        [updateQueAnsStatus.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },


        [updateQuesAns.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            // state.isSuccess = true;
            state.isUpdate = true;
            let mainArray = state.dataaList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return payload.result;
                } else {
                    return r;
                }
            });
            state.dataaList = finalResult;
        },
        [updateQuesAns.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateQuesAns.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
            state.isError = false;
        },
        [deleteTermsCondition.pending]: (state) => {
            state.issDelete = false;
            state.isLoading = true;
        },
        [deleteTermsCondition.fulfilled]: (state, action) => {
            state.issDelete = true;
            state.isLoading = false;
            state.dataaList = state.dataaList.filter(
                (item) => item.id !== action.meta.arg.id
            );
            state.msg = "Delete Successfully";
        },
        [deleteTermsCondition.rejected]: (state, { payload }) => {
            state.issDelete = false;
            state.isError = true;
            state.isLoading = false;
            state.msg = payload.msgBody;
        },

    },
});
export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
