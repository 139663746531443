import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import { useSelector } from "react-redux";

const MainNavbar = () => {
    const pathname = window.location.pathname;
    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | " + "DK MasterBox";
    }, []);

    const { allBadge } = useSelector(state => state.tasks);
    return (
        <React.Fragment>
            <div className="dk-navBarwrapper">
                <div className="dk-navBarMain dk-adminNavbar">
                    <Route>
                        <ul>
                            <li>
                                <Link to="/dashboard/summary" className={`${pathname.match('/dashboard') ? 'white-active' : ''}`}>Dashboard </Link>
                            </li>
                            {/* <li className="dropdown-toggle">
                                <Link to="#/" className={`${pathname.match('/') ? 'sub-active' : ''}`}><span class="dk-maninmenu-badgeCon dk-today-badgeCon"><span>8</span></span>Today</Link>
                            </li> */}
                            {/* <li className="dropdown-toggle">
                                <Link to="/mailbox/inbox" className={`${pathname.match('/mailbox') ? 'active' : ''}`}><span class="dk-maninmenu-badgeCon dk-mailbox-badgeCon"><span>8</span></span>Mailbox</Link>
                            </li> */}
                            <li className="dropdown-toggle">
                                <Link to="/client-info/client-list" className={`${pathname.match('/client-info') ? 'active' : ''}`}><span class="dk-maninmenu-badgeCon dk-jobs-badgeCon"><span>8</span></span>Clients</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="/jobs/summary" className={`${pathname.match('/jobs') ? 'active' : ''}`}><span class="dk-maninmenu-badgeCon dk-jobs-badgeCon"><span>8</span></span>Jobs</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="/job-specs/instructions" className={`${pathname.match('/job-specs') ? 'active' : ''}`}>Job Specs</Link>
                            </li> 
                            <li className="dropdown-toggle">
                                <Link to="/candidate/dash-board" className={`${pathname.match('/candidate') ? 'active' : ''}`}>Jobseeker A/c</Link>
                            </li>
                        
                            {/*<li className="dropdown-toggle">
                                <Link to="#" className={`${pathname.match('#/tasks') ? 'sub-active' : ''}`}></Link>
                            </li>
                            */}
                            <li className="dropdown-toggle">
                                <Link to="#" className={`${pathname.match('/legggss') ? 'active' : ''}`}></Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="#/leggg" className={`${pathname.match('/tecchnical') ? 'active' : ''}`}></Link>
                            </li>

                            <li className="dropdown-toggle">
                                <Link to="#/payments" className={`${pathname.match('/payments') ? 'active' : ''}`}></Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="#/nav-ttt" className={`${pathname.match('/nav-ttt') ? 'active' : ''}`}></Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="/site-users/summary" className={`${pathname.match('/site-users') ? 'active' : ''}`}>Users</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="/settings/bkvalues" className={`${pathname.match('/settings') ? 'active' : ''}`}>Settings</Link>
                            </li>
                        </ul>
                    </Route>
                </div>
            </div>
        </React.Fragment>
    );
}

export default MainNavbar;
