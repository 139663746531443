import React from 'react';
import './archived-table.scss';

const ArchivedTable = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="dk-newprescrenformTableCon table-responsive">
                            <table className="dk-newprescrenformTable table table-bordered">
                                <thead>
                                    <tr>
                                        <th>SNo.</th>
                                        <th>Data &amp; Time</th>
                                        <th>RC</th>
                                        <th>AM</th>
                                        <th>Name of Candidate</th>
                                        <th>Current Location</th>
                                        <th>Company</th>
                                        <th>Language</th>
                                        <th>Project</th>
                                        <th>Role Type</th>
                                        <th>CV Source</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="dk-checkinputIconCon">1
                                                <div className="dk-checkinputIcon">
                                                    <input type="radio" className="chkScreanCandidate " name="chkScreanCandidate[]" data-id="86" value="86" />
                                                </div>
                                            </span>
                                        </td>
                                        <td>16 Mar, 15:03</td>
                                        <td>
                                            <div className="dk-prescreen-recuiter-con">
                                                <img src='https://d3eppau9to224z.cloudfront.net/users/images/1664436575398.png' alt='Recuiter' className='dk-prescreen-recuiter-img' />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-prescreen-recuiter-con">
                                                <img src='https://d3eppau9to224z.cloudfront.net/users/images/1580424792.jpeg' alt='Recuiter' className='dk-prescreen-recuiter-img' />
                                            </div>
                                        </td>
                                        <td>MAYRA CRIVAC</td>
                                        <td>Craiova</td>
                                        <td>Tech M Bucharest</td>
                                        <td>Turkish</td>
                                        <td>Select</td>
                                        <td>Content Moderator</td>
                                        <td>Ejobs</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ArchivedTable