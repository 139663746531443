import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton } from "react-bootstrap";
// import "./faq-catprescreen.scss";
import {
  updateQueAnsStatus,
  getTermsCondition,
} from "../../../../../../../slice/termsCondition/termsConditionSlice";
import ReadQuestionModal from "./modal/ReadQuestion";
import DeleteDummy from "./modal/DeleteDummy";
import { showError } from "../../../../../../../components/utils/messages/messageSlice";
import AddNewQuestions from "../questions/modal/AddNewQuestions";
import EditQuestion from "../questions/modal/EditQuestionAnswer";

const CompanyTable = () => {
  const dispatch = useDispatch();
  const { isLoading, dataaList, isCompanyInsert } = useSelector(
    (state) => state.termsCondition
  );

  const [quesAns, setQueAns] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getTermsCondition({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch, isCompanyInsert]);

  useEffect(() => {
    if (!isLoading && dataaList.length > 0) {
      setQueAns(dataaList);
    }
  }, [isLoading]);

  const changeQueAnsStatus = async (id, stts) => {
    await dispatch(
      updateQueAnsStatus({
        id: id,
        key: "status",
        value: stts,
      })
    );
  };

  const [isActiveQs, setIsActiveQs] = useState("");
  const [isActiveAns, setIsActiveAns] = useState("");
  const [isreadModal, setReadModal] = useState(false);

  const showreadModal = (ques, ans) => {
    setIsActiveQs(ques);
    setIsActiveAns(ans);
    setReadModal(true);
  };
  const hidereadModal = () => {
    setReadModal(false);
  };

  return (
    <>
      {isreadModal ? (
        <ReadQuestionModal
          onHide={hidereadModal}
          questiontitle={isActiveQs}
          answerTitle={isActiveAns}
        />
      ) : null}

      <div className="row dkg-cand-faq-question-tabCon m-0">
        <div className="col-md-12 dkg-cand-faq-question-rightCon">
          <div className="dkg-cand-faq-question-tabContent">
            <div className="dkg-cand-faq-question-tabpane">
              <div
                className="dkg-task-paymentpage12 container-fluid"
                style={{ height: "calc(100vh - 206px)" }}
              >
                <div className="row">
                  <div className="col-md-12 p-0">
                    <div className="dk-faq-candprescreen-TopHead">
                      <div className="dk-faq-candprescreenLeftTopHead">
                        <div className="dk-SrchInputBox">
                          <AddNewQuestions />
                        </div>
                      </div>
                      <div className="dk-faq-candprescreen-titleCon">
                        <h2 className="dk-faq-candprescreen-title">
                          TERMS OF CONDITIONS
                        </h2>
                      </div>
                      <div className="dk-faq-right-candprescreenCon">
                        {/* <Link
                          to="#"
                          className="dk-faq-addcategory-btn mr-2"
                          onClick={showQueAnsReorderModal}
                        >
                          <FaArrowsAlt /> Reorder
                        </Link> */}

                        {/* {reorderQueAnsModal ? (
                          <QuestionReorder
                            onHide={hideQueAnsModal}
                            dataList={quesAns}
                            categoryId={defaultCatId}
                          />
                        ) : null} */}
                      </div>
                    </div>
                    <div className="dkg-faq-candprescreen-tableCon-12  dkg-termsofuse-tableCon">
                      <table className="table table-borderd dkg-faq-category-table dkg-faq-category-table-234 dkg-termsofuse-table">
                        <thead>
                          <tr>
                            <th>S No.</th>
                            <th>Status</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {quesAns.map((rec, key) => (
                            <tr>
                              <td>{key + 1}</td>
                              <td>
                                <DropdownButton
                                  className={
                                    rec.status == "Active"
                                      ? "dkg-status-dropdown activeClass"
                                      : "dkg-status-dropdown deActiveClass"
                                  }
                                  title={rec.status}
                                >
                                  <Dropdown.Item
                                    href="#"
                                    className="dkg-status-dropdown-item"
                                    style={{
                                      backgroundColor: "#3a9469",
                                      color: "#fff",
                                    }}
                                    onSelect={() =>
                                      changeQueAnsStatus(rec.id, "Active")
                                    }
                                  >
                                    Active
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href="#"
                                    className="dkg-status-dropdown-item"
                                    style={{
                                      backgroundColor: "#625c60",
                                      color: "#fff",
                                    }}
                                    onSelect={() =>
                                      changeQueAnsStatus(rec.id, "Inactive")
                                    }
                                  >
                                    Inactive
                                  </Dropdown.Item>
                                </DropdownButton>
                              </td>
                              <td>{rec.question}</td>
                              <td>
                                <div
                                  className="dkg-faq-questtitle"
                                  onClick={(e) =>
                                    showreadModal(rec.question, rec.answer)
                                  }
                                >
                                  {rec.answer != "" && rec.answer != null ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: rec.answer,
                                      }}
                                    ></div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>

                              <td>
                                <div className="dkg-faq-actions-col">
                                  <EditQuestion
                                    rowid={rec.id}
                                    qes={rec.question}
                                    anss={rec.answer}
                                  />
                                  <DeleteDummy rowid={rec.id} />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyTable;
