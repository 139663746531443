import React from 'react';
import { Route, Link } from "react-router-dom";

const TasksSubmenu = () => {
    const pathname = window.location.pathname;
    return (
        <div>
            <div className="dk-adminSubmenuMain">
                <Route>
                    <ul>
                        <li><Link to="/tasks/summary" className={`${pathname.match('/tasks/summary') ? 'sub-active' : ''}`}>Summary</Link></li>
                        <li><Link to="#/mailbox/mailbox" className={`${pathname.match('/mailbox/report') ? 'sub-active' : ''}`}>To Do</Link></li>
                        <li><Link to="#/mailbox/tasks" className={`${pathname.match('/mailbox/validation') ? 'sub-active' : ''}`}>In Progress</Link></li>
                        <li><Link to="#/mailbox/actions" className={`${pathname.match('/mailbox/mailshot') ? 'sub-active' : ''}`}>Completed</Link></li>
                        <li><Link to="#/mailbox/notification" className={`${pathname.match('/dashboard/templates') ? 'sub-active' : ''}`}>Archived</Link></li>
                    </ul>
                </Route>
            </div>
        </div>
    )
}

export default TasksSubmenu;
