import React from 'react'
import { Link } from 'react-router-dom';
import './recruitmentmodule.scss';
import AdminHeader from '../../../elements/header/Header'
import Navbar from "../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../elements/submenu/mainmenu/DashboardSubmenu';
import Recruitment from "../../../../assets/images/building_icon.png";
import FaqIcon from "../../../../assets/images/information_white.png";
import JobSpecIcon from "../../../../assets/images/information.png";
import Projections from "../../../../assets/images/excel-file.png";
import ProjectsImg from "../../../../assets/images/projects.png";
import DataTableIcon from "../../../../assets/images/database_icon.png";
import InterviewIcon from "../../../../assets/images/interview-icon-white.png";
import JobspecImg from "../../../../assets/images/job-specs.png";


const RecruitmentModule = () => {
    return (
        <>
            <AdminHeader />
            <Navbar />
            <Submenu />
            <div className="dk-master-moduleCon">
                <div className="dk-module-blocksContainer dk-crmContainer">
                    <h4 className="title">RECRUITMENT DEPARTMENT</h4>
                    <div className="dk-portalCols">
                        <div className="dk-portalBox dk_portalBox_1">
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons">
                                        <img src={Recruitment} alt="Recruitment" />
                                    </div>
                                    <div className="dk-PortalTitle">Clients Info</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_portalBox_10">
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons">
                                        <img src={ProjectsImg} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dk-PortalTitle">Projects</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_visainfo_bg">
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons">
                                        <img src={JobspecImg} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dk-PortalTitle">Job Demand</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="dk-portalCols">
                        <div className="dk-portalBox dk_portalBox_marketing">
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons">
                                        <img src={JobSpecIcon} alt="Job Spec" />
                                    </div>
                                    <div className="dk-PortalTitle">Job Spec</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_recruitmentrules_bg">
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons">
                                        <img src={InterviewIcon} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dk-PortalTitle">Interview Spec</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_portalBox_6">
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons">
                                        <img src={Projections} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dk-PortalTitle">Projections</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="dk-portalCols">
                        <div className="dk-portalBox dk_portalBox_2">
                            <Link
                                to="#"

                            >
                                <div>
                                    <div className="dk-Portalicons">
                                        <img src={DataTableIcon} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dk-PortalTitle">Search Database</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_portalBox_onboarding">
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={DataTableIcon}
                                            className="img-fluid"
                                            alt="Full Database"
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Full Database</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_frlancerresource">
                            <Link to="#" >
                                <div>
                                    <div className="dk-Portalicons">
                                        <img src={FaqIcon} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dk-PortalTitle">FAQ</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecruitmentModule