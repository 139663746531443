import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { showError } from "../../../../../utils/messages/messageSlice";
import SubmitButton from "../../../../../ui/submitButton";
import { updateCompanyJobSpec } from "../../../../../../slice/cmsSlice";
import MessageBox from "../../../../../utils/joditEditor/MessageBox";
import { getCategories } from "../../../../../../slice/clieninfo/requirnment/requirnmentSlice";
import {
  //showError,
  showSuccess,
} from "../../../../../../components/utils/messages/messageSlice";

const AboutUs = ({ selectedItem }) => {
  const dispatch = useDispatch();
  const { issUpdate } = useSelector((state) => state.cms);

  const [inputs, setInputs] = useState({ aboutUs: "" });

  useEffect(() => {
    if (issUpdate) {
      dispatch(showSuccess({ msg: "Data updated successfully" }));
      dispatch(getCategories({}));
    }
  }, [issUpdate]);

  useEffect(() => {
    if (selectedItem) {
      setInputs({
        aboutUs:
          selectedItem[0].aboutUs != null && selectedItem[0].aboutUs != ""
            ? selectedItem[0].aboutUs
            : "",
      });
    }
  }, [selectedItem]);

  const handleChange = (value) => {
    setInputs((prevState) => ({
      ...prevState,
      value,
    }));
  };

  const [overviewData, setOverviewData] = useState("");
  // const [rowId, setRowId] = useState(
  //   companyAboutus.length > 0 ? companyAboutus[0].id : 0
  // );
  const SubmitBtn = () => {
    dispatch(
      updateCompanyJobSpec({
        id: selectedItem[0].id,
        key: "aboutUs",
        value: inputs.value,
      })
    );
  };

  return (
    <>
      <div className="dkg-companynew-aboutus-Con">
        <div className="dkg-jopspec-pageheader">
          <div className="dkg-jopspec-page-title">Company</div>
        </div>
        <div className="dkg-instructions-editorCon">
          <MessageBox value={inputs.aboutUs} onChange={handleChange} />

          <div className="dkg-submit-btnCon">
            <SubmitButton
              txt="SAVE"
              className="dkg-cand-submit-btn"
              onClick={SubmitBtn}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
