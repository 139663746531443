import React from 'react'
import { Tab, Nav } from "react-bootstrap";


const LocationTab = () => {
  return (
    <>
        <div className="dkg-jobspec-location-tabCon">
            <Tab.Container id="left-tabs-example" defaultActiveKey="dkg-company-info">
                <div className="dk-location-panel-13">
                    <div className="dk-location-detail-13">
                    <Nav variant="pills" className="flex-row dkg-location-navpills" >
                        <Nav.Item className="dkg-location-navitem">
                        <Nav.Link eventKey="dkg-company-info" className="dkg-location-navlink">Welcome</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dkg-location-navitem">
                        <Nav.Link eventKey="dkg-company-location" className="dkg-location-navlink">Cost of Living</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dkg-location-navitem">
                        <Nav.Link eventKey="dkg-company-aboutus" className="dkg-location-navlink">Working in Greece</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dkg-location-navitem">
                        <Nav.Link eventKey="dkg-company-services" className="dkg-location-navlink">Lifestyle</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dkg-location-navitem">
                        <Nav.Link eventKey="dkg-company-video" className="dkg-location-navlink">FAQ</Nav.Link>
                        </Nav.Item>                        
                    </Nav>
                    <Tab.Content className="dk-location-tabcontent-13">
                        <Tab.Pane eventKey="dkg-company-info" className="dkg-location-tabpane">
                          <div className="dkg-location-tab-block-con"></div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="dkg-company-location" className="dkg-location-tabpane">
                          <div className="dkg-location-tab-block-con"></div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="dkg-company-aboutus" className="dkg-location-tabpane dkg-company-abouts-tabpane">
                          <div className="dkg-location-tab-block-con"></div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="dkg-company-services" className="dkg-location-tabpane dkg-company-abouts-tabpane">
                          <div className="dkg-location-tab-block-con"></div>
                        </Tab.Pane>
                          <div className="dkg-location-tab-block-con"></div>
                        <Tab.Pane eventKey="dkg-company-video" className="dkg-location-tabpane">
                          <div className="dkg-location-tab-block-con"></div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="dkg-company-album" className="dkg-location-tabpane">
                          <div className="dkg-location-tab-block-con"></div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="dkg-company-socail-media" className="dkg-location-tabpane">
                          <div className="dkg-location-tab-block-con"></div>
                        </Tab.Pane>
                    </Tab.Content>
                    </div>
                </div>
            </Tab.Container>
      </div>
    </>
  )
}

export default LocationTab