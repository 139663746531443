import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
//import Submit from "../../../../../../../ui/submitButton";
import "./upload-video.scss";
import { updateVideoComJobSpec } from "../../../../../../../../slice/cmsSlice";
import { FaTimes } from "react-icons/fa";

const UpdateModal = ({ onHide, companyId, vid, yurl, type, title }) => {
  const dispatch = useDispatch();
  const [videoUrl, setVideoUrl] = useState(type !== "edit" ? yurl : "");
  const [titlesUrl, setTitles] = useState(title);

  const clearTxtBoxUrl = () => {
    setVideoUrl("");
  };
  const clearBoxTitle = () => {
    setTitles("");
  };
  // const btnClose = (e) => {
  //   e.preventDefault();
  //   onHide();
  // };
  const submitBtn = (e) => {
    e.preventDefault();
    dispatch(
      updateVideoComJobSpec({
        id: vid,
        youtubeurl: videoUrl,
        videoTitle: titlesUrl,
      })
    );
  };

  return (
    <>
      <Modal
        show={true}
        onHide={onHide}
        className="dkg-pagetype-uploadModal"
        centered
      >
        <Modal.Header closeButton className="dkg-video-upload-Header">
          <Modal.Title>EDIT VIDEO</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-video-upload-Body">
          <form>
            <div className="dkg-upload-frmgrp">
              <label>Youtube URL</label>
              <div className="dkg-youtube-urlCon">
                <input
                  type="text"
                  className="form-control dkg-youtube-url-input"
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                />
                <span className="dkg-youtube-editCon">
                  <FaTimes onClick={(e) => clearTxtBoxUrl("")} />
                </span>
              </div>
            </div>

            <div className="dkg-upload-frmgrp">
              {" "}
              <label>Video Title</label>
              <div className="dkg-youtube-urlCon">
                <input
                  type="text"
                  className="form-control dkg-youtube-url-input"
                  value={titlesUrl}
                  onChange={(e) => setTitles(e.target.value)}
                />
                <span className="dkg-youtube-editCon">
                  <FaTimes onClick={(e) => clearBoxTitle("")} />
                </span>
              </div>
            </div>
            <div className="dkg-upload-frmgrp">
              <div className="dkg-submit-BtnCon">
                <button className="btn  dkg-submit-Btn" onClick={submitBtn}>
                  SAVE
                </button>
                {/* <button className="btn dkg-close-btn" onClick={btnClose}>
                  Close
                </button> */}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateModal;
