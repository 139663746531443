import React, { useEffect, useState } from "react";
import "./switch.scss";
// import { updateData } from "../../../../../slice/candidate/candidateSlice";
// import { useDispatch, useSelector } from "react-redux";

const Switch = ({ value, canid }) => {
  //const dispatch = useDispatch();
  //const [chcked, setIsChecked] = useState(true);

  let status;
  let bgStatus;
  if (value == 1) {
    status = "Active";
    bgStatus = "#3a9469";
  } else {
    status = "Inactive";
    bgStatus = "#d91010";
  }

  // useEffect(() => {
  //   if (value == 1) {
  //     setIsChecked(true);
  //   } else {
  //     setIsChecked(false);
  //   }
  // }, [value]);

  // const handleOnChange = async (e) => {
  //   setIsChecked((current) => !current);

  //   if (e.target.checked === true) {
  //     dispatch(updateData({ id: canid, key: "accountStatus", value: 1 }));
  //   } else {
  //     dispatch(updateData({ id: canid, key: "accountStatus", value: 0 }));
  //   }
  // };

  return (
    <>
      {/* <span className="dkg-account-acmainCon">
        <span className="dkg-feature-column">
          <span className="dkg-off-label mr-2">OFF</span>
          <input
            onChange={handleOnChange}
            className="react-switch-checkbox"
            id={`react-switch-new` + canid + value}
            type="checkbox"
            data-id={canid}
            data-value={value}
            // data-name={name}
            key={canid + value}
            checked={chcked}
          />
          <label
            className="react-switch-label"
            htmlFor={`react-switch-new` + canid + value}
          >
            <span className="react-switch-button" />
          </label>
          <span className="dkg-off-label ml-2">ON</span>
        </span>
      </span> */}
      <span
        className="dkg-cand-account-status"
        style={{ backgroundColor: bgStatus, color: "#fff" }}
      >
        {status}
      </span>
    </>
  );
};

export default Switch;
