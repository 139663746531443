import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortableContainer, SortableElement, sortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from 'array-move';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import { reorderFilesRecord, getprojectlist, clearState } from "../../.../../../../../../../slice/companies/clients/clientSlice";
import { showSuccess, showError, clearMessage } from "../../../../../../utils/messages/messageSlice";

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import Modal from 'react-bootstrap/Modal';
import { FaArrowsAlt } from "react-icons/fa";
import Submit from '../../../../../../ui/submitButton'

const DragHandle = sortableHandle(() => <span className="dk-handle" style={{ backgroundColor: "#363f4c", height: "35px !important", width: "50px", textAlign: "center", lineHeight: "35px", color: "white", cursor: "all-scroll" }}><i className="fas fa-bars"></i></span>);

const SortableItem = SortableElement(({ value }) => {
    return (
        <React.Fragment>
            <div className="Mukul">
                <div className="dk-valueFields d-flex" key={value.id} id={value.id}>
                    <DragHandle />
                    <input type="text" style={{ background: value.bgColor, color: value.textColor, height: "35px", borderRadius: "0", border: "1px solid #ddd", borderBottom: "none", boxShadow: "none !important" }} value={value.name} name="" id="" className="form-control" />
                </div>
            </div>
        </React.Fragment>
    )
})

const SortableList = SortableContainer(({ items }) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </ul>
    );
});

const ReorderModal = ({ dataList,clientid }) => {
    const dispatch = useDispatch();
    let { isProjectReoder, isError, msg } = useSelector(state => state.companies)
    const [listt, setList] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setList(dataList)
    }, [dataList])

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setList(arrayMoveImmutable(listt, oldIndex, newIndex))
    };

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const reorderData = async () => {
        setLoading(true)
        setSuccess(false)
        let reorderListArray = []
        listt.map((data) => {
            reorderListArray.push(data.id)
        })
      await dispatch(reorderFilesRecord({ ids: reorderListArray }))
    }

    useEffect(() => {
        dispatch(clearMessage())
        dispatch(clearState())
    }, [])

    useEffect(() => {
        if (isProjectReoder) {
            setLoading(false)
            setSuccess(false)
            setShow(false)
            fetchData()
            // dispatch(showSuccess({ msg }))
            // dispatch(clearState())
        }
        async function fetchData() {
            await dispatch(getprojectlist({companyId:clientid}))
        }

        if (isError) {
            setLoading(false)
            setSuccess(false)
            dispatch(showError({ msg }))
            dispatch(clearState())
        }

    }, [isProjectReoder, isError, dispatch])

    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    return (
        <React.Fragment>
            {/* <Link to="#" onClick={handleShow} className="dk-campgiansIcon mr-2" ><i className="fas fa-arrows-alt"></i></Link> */}
            <Link to="#" onClick={handleShow} className="dkg-addnew-btn mr-2"><FaArrowsAlt /></Link>
            {/* <Modal className="dkg-campagins-reorderModal" show={show} onHide={handleClose} centered>
                <Modal.Header className="dkg-campagins-reorderModalheader" closeButton >
                    <Modal.Title>Reorder</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-campagins-reorderModalBody">
                    <div className="Mukul">
                        <div className="dk-valueFields d-flex">
                            <span className="dk-handle" style={{ backgroundColor: "#363f4c", height: "35px !important", width: "50px", textAlign: "center", lineHeight: "35px", color: "white", cursor: "all-scroll" }}><i className="fas fa-bars"></i></span>
                            <input type="text" style={{ height: "35px", borderRadius: "0", border: "1px solid #ddd", boxShadow: "none !important" }} value="Readme.Txt" name="" id="" className="form-control" readOnly />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center dk-res-reorder-save-btnCon mt-4">
                        <Submit txt="Save Order" position="justify-content-center" className="dk-toolsPanelSaveBtn" />
                    </div>
                </Modal.Body>
            </Modal> */}
            <Modal className="dk-viewResModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton className="dk-viewResModalHder dk-res-reorder-Modalheader">
                    <Modal.Title>Reorder</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-viewResModalBdy res-rordermodal-body">
                    <SortableList lockAxis="y" items={listt} onSortEnd={onSortEnd} useDragHandle />
                    <div className="d-flex justify-content-center dk-res-reorder-save-btnCon">
                        <Submit txt="Save Order" loading={loading} success={success} onClick={reorderData} position="justify-content-center" className="dk-toolsPanelSaveBtn" />
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default ReorderModal;