import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// function start
export const getBenifits = createAsyncThunk(
    "frontendcms/benifits",
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/mangecontent/benifits",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getAboutService = createAsyncThunk(
    "frontendcms/aboutService",
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/mangecontent/aboutusservices",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getService = createAsyncThunk(
    "frontendcms/text1change",
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/candidates/paid-services",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getTextHeading = createAsyncThunk(
    "frontendcms/textchange",
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/candidates/frontend-cms",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateHeadingText = createAsyncThunk(
    "frontendcms/headinText",
    async ({ id, heading, heading_decription }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/candidates/frontend-cms/uptxtdate/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                    body: JSON.stringify({
                        heading, heading_decription
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateKeyBenifits = createAsyncThunk(
    "frontendcms/updatekeyTxt",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/mangecontent/benifits-key/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                    body: JSON.stringify({
                        key,
                        value,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateCMSText = createAsyncThunk(
    "frontendcms/updateTxt",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/candidates/frontend-cms/update/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                    body: JSON.stringify({
                        key,
                        value,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateServices = createAsyncThunk(
    "frontendcms/updateServiceTxt",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/candidates/paid-services/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                    body: JSON.stringify({
                        key,
                        value,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateBenifits = createAsyncThunk(
    "frontendcms/updateBenifitsTxt",
    async ({ id, heading, descriptions }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/mangecontent/benifits/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                    body: JSON.stringify({
                        heading, descriptions
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateAboutUsService = createAsyncThunk(
    "frontendcms/udateAboutTxt",
    async ({ id, srNo, descriptions }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/mangecontent/aboutus-services/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer" + " " + token,
                    },
                    body: JSON.stringify({
                        srNo, descriptions
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const usersSlice = createSlice({
    name: "frontendcms",
    initialState: {
        getcmsList: [],
        serviceList: [],
        aboutServiceLists: [],
        isSuccess: false,
        isUpdate: false,
        isLoading: false,
        isError: false,
        isDelete: false,
        isBenifits: false,
        isAboutService: false,
        isAboutUpdate: false,
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.isUpdate = false;
            state.isAboutUpdate = false;
            state.isDelete = false;
            state.isBenifits = false;
            state.isAboutService = false;
        },
    },
    extraReducers: {

        [updateAboutUsService.pending]: (state) => {
            state.isAboutUpdate = false;
            state.isError = false;
        },
        [updateAboutUsService.fulfilled]: (state, { payload }) => {
            state.isError = true;
            state.isAboutUpdate = true;
        },
        [updateAboutUsService.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isAboutUpdate = false;
        },
        [updateBenifits.pending]: (state) => {
            state.isUpdate = false;
            state.isError = false;
        },
        [updateBenifits.fulfilled]: (state, { payload }) => {
            state.isError = true;
            state.isUpdate = true;
        },
        [updateBenifits.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isUpdate = false;
        },
        [updateServices.pending]: (state) => {
            state.isUpdate = false;
            state.isError = false;
        },
        [updateServices.fulfilled]: (state, { payload }) => {
            state.isError = true;
            state.isUpdate = true;
        },
        [updateServices.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isUpdate = false;
        },
        [updateKeyBenifits.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
            state.isError = false;
        },
        [updateKeyBenifits.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.isUpdate = true;
            state.benifitsLists = payload.result;
        },
        [updateKeyBenifits.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateCMSText.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
            state.isError = false;
        },
        [updateCMSText.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.isUpdate = true;
            state.getcmsList = payload.result;
        },
        [updateCMSText.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateHeadingText.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
            state.isError = false;
        },
        [updateHeadingText.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.isUpdate = true;
            state.getcmsList = payload.result;
        },
        [updateHeadingText.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [getTextHeading.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getTextHeading.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.getcmsList = payload.result;
        },
        [getTextHeading.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [getService.pending]: (state) => {
            state.isError = true;
            state.isSuccess = true;
        },
        [getService.fulfilled]: (state, { payload }) => {
            state.isSuccess = false;
            state.serviceList = payload.result;
        },
        [getService.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isSuccess = false;
        },
        [getBenifits.pending]: (state) => {
            state.isError = true;
            state.isBenifits = true;
        },
        [getBenifits.fulfilled]: (state, { payload }) => {
            state.isBenifits = false;
            state.benifitsLists = payload.result;
        },
        [getBenifits.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isBenifits = false;
        },
        [getAboutService.pending]: (state) => {
            state.isError = true;
            state.isAboutService = true;
        },
        [getAboutService.fulfilled]: (state, { payload }) => {
            state.isAboutService = false;
            state.aboutServiceLists = payload.result;
        },
        [getAboutService.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isAboutService = false;
        },
    },
});
export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
