import React from 'react';
import AdminHeader from "../../../../components/elements/header/Header";
import AdminNavbar from "../../../../components/elements/navbar/Navbar";
import TrainingSubmenu from '../../submenu/TrainingSubmenu';
import '../admintraining.scss';

const AdminTools = () => {
    return (
        <>
            <AdminHeader />
            <AdminNavbar />
            <TrainingSubmenu />
        </>
    )
}

export default AdminTools;

