import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import AdminHeader from '../../../../../elements/header/Header'
import Navbar from "../../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../../../elements/submenu/mainmenu/DashboardSubmenu';
import './dkg-teams.scss';
import UpdateTeamModal from './UpdateTeam'
import {
getteamMembses,
  clearState,
} from "../../../../../../slice/teams/teamMembers";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../utils/messages/messageSlice";

const Teams = () => {
const dispatch = useDispatch();
  const {
    isSuccess,
    isLoading,
    isError,
    teammemberlist,
  } = useSelector((state) => state.teammembers);

  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isLoading && teammemberlist && teammemberlist.length > 0) {
      setData(teammemberlist);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getteamMembses({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch,  ]);

    const [rowId, setRowId] = useState('0')
    const [columnKey, setColumnKey] = useState('')
    const [columnValue, setColumnValue] = useState('0')

    const [isUpdateTeam, setUpdateTeam] = useState(false);
    const showUpdateTeam = () => {
        setUpdateTeam(true)
    }
    const hideUpdateTeam = () => {
        setUpdateTeam(false)
    }

     const openUpdateValuePop = (id, key, value) => {        
        setRowId(id)
        setColumnKey(key)
        setColumnValue(value)
        setUpdateTeam(true)
    }
    

    return (
        <>
            {
                (isUpdateTeam) ? <UpdateTeamModal onHide={hideUpdateTeam} rowId={rowId} columnKey={columnKey} value={columnValue} /> : null
            }
            <AdminHeader />
            <Navbar />
            <Submenu />
            <div className=" dkg-teams-mainpageCon">
                <div className="dkg-teams-mainbox-flex">
                    <div className="dkg-teams-cols">
                        <div className="d-flex w-100">
                            <div className="dkg-teams-box ">
                                <div className="title">
                                    RECRUITMENT TEAM - 1
                                </div>
                                <ul>
                                    <div className="dkg-content-con">
                                        <div className='dkg-content-title'>Clients List</div>
                                        <div className="dkg-teams-tableCon">
                                            <table className='table table-borderded dkg-teams-table'>
                                                <tbody>
                                                    {
                                                        data && data.filter(rec => rec.title === "RECRUITMENT TEAM - 1").map((item, index) => {
                                                            return (
                                                                 <tr key={index + 1} rowid={item.id}>
                                                                    <td onClick={() => openUpdateValuePop(item.id, 'name', item.name)}>{item.name}</td>
                                                                    <td onClick={() => openUpdateValuePop(item.id, 'values', item.values)}>
                                                                        {
                                                                            item.values
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                   
                                                   
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="dkg-subcontent-con">
                                        <div className='dkg-content-subtitle'>Team Members</div>
                                        <div className="dkg-teams-recuiterCon">
                                            <table className='table table-borderded dkg-recuiter-table'>
                                                <tbody>
                                                   {
                                                        data && data.filter(rec => rec.title === "TEAM MEMBERS-1").map((item, index) => {
                                                            return (
                                                                 <tr key={index + 1} rowid={item.id}>
                                                                    <td onClick={() => openUpdateValuePop(item.id, 'name', item.name)}>{item.name}</td>
                                                                    <td onClick={() => openUpdateValuePop(item.id, 'values', item.values)}>
                                                                        {
                                                                            item.values
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <div className="dkg-teams-box">
                                <div className="title">
                                    RECRUITMENT TEAM - 2
                                </div>
                                <ul>
                                    <div className="dkg-content-con">
                                        <div className='dkg-content-title'>Clients List</div>
                                        <div className="dkg-teams-tableCon">
                                            <table className='table table-borderded dkg-teams-table'>
                                                <tbody>
                                                    {
                                                        data && data.filter(rec => rec.title === "RECRUITMENT TEAM - 2").map((item, index) => {
                                                            return (
                                                                 <tr key={index + 1} rowid={item.id}>
                                                                    <td onClick={() => openUpdateValuePop(item.id, 'name', item.name)}>{item.name}</td>
                                                                    <td onClick={() => openUpdateValuePop(item.id, 'values', item.values)}>
                                                                        {
                                                                            item.values
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="dkg-subcontent-con">
                                        <div className='dkg-content-subtitle'>Team Members</div>
                                        <div className="dkg-teams-recuiterCon">
                                            <table className='table table-borderded dkg-recuiter-table'>
                                                <tbody>
                                                   {
                                                        data && data.filter(rec => rec.title === "TEAM MEMBERS-2").map((item, index) => {
                                                            return (
                                                                 <tr key={index + 1} rowid={item.id}>
                                                                   <td onClick={() => openUpdateValuePop(item.id, 'name', item.name)}>{item.name}</td>
                                                                    <td onClick={() => openUpdateValuePop(item.id, 'values', item.values)}>
                                                                        {
                                                                            item.values
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <div className="dkg-teams-box">
                                <div className="title">
                                    RECRUITMENT TEAM - 3
                                </div>
                                <ul>
                                    <div className="dkg-content-con">
                                        <div className='dkg-content-title'>Clients List</div>
                                        <div className="dkg-teams-tableCon">
                                            <table className='table table-borderded dkg-teams-table'>
                                                <tbody>
                                                     {
                                                        data && data.filter(rec => rec.title === "RECRUITMENT TEAM - 3").map((item, index) => {
                                                            return (
                                                                 <tr key={index + 1} rowid={item.id}>
                                                                    <td onClick={() => openUpdateValuePop(item.id, 'name', item.name)}>{item.name}</td>
                                                                    <td onClick={() => openUpdateValuePop(item.id, 'values', item.values)}>
                                                                        {
                                                                            item.values
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="dkg-subcontent-con">
                                        <div className='dkg-content-subtitle'>Team Members</div>
                                        <div className="dkg-teams-recuiterCon">
                                            <table className='table table-borderded dkg-recuiter-table'>
                                                <tbody>
                                                   {
                                                        data && data.filter(rec => rec.title === "TEAM MEMBERS-3").map((item, index) => {
                                                            return (
                                                                 <tr key={index + 1} rowid={item.id}>
                                                                    <td onClick={() => openUpdateValuePop(item.id, 'name', item.name)}>{item.name}</td>
                                                                    <td onClick={() => openUpdateValuePop(item.id, 'values', item.values)}>
                                                                        {
                                                                            item.values
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Teams