import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav } from "react-bootstrap";
import { FaPlus, FaArrowsAlt, FaTrashAlt, FaEdit } from "react-icons/fa";
import AddNewCategoryModal from "./modal/AddNewCategory";
import EditSubCategory from "./modal/EditSubCategory";
import ReorderSubCategory from "./modal/ReorderSubCategory";
import DeleteModal from "./modal/DeleteDummy";
import SubmitButton from "../../../../ui/submitButton";
import MessageBox from "../../../../utils/joditEditor/MessageBox";

import {
  getSubCategories,
  getSubCatDetails,
  updateEditorValue,
} from "../../../../../slice/clientProject";
import { Link } from "react-router-dom";
import $ from "jquery";

import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../components/utils/messages/messageSlice";

const ReportsStep1 = (props) => {

  const dispatch = useDispatch();
  const {
    // isSuccess,
    // isTabActive,
    // isError,
    isSubCatInsert,
    isSubCatUpdate,
    isDetails,
    isLoading,
    //issLoading,
    subCategoryDetails,
    isSubReoder,
    issDelete,
    subCategoryWhatisList,
  } = useSelector((state) => state.clientproject);


  const [categoryId, setCategoryId] = useState(props.catIdds);
  const [tabName, setTabName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");

  const [data, setData] = useState([]);
  const [catRowId, setCatRowId] = useState("");
  //const [activeSubCat, setActiveSubCat] = useState("");
  const [defaultId, setDefaultId] = useState("");
  //const [detailsSubtab, setDetailsSubtab] = useState("");
  const [showDetailsTab, setShowDetailsTab] = useState(false);
  const [editorDescription, setEditorDescription] = useState("");
  //const [overviewData, setOverviewData] = useState("");
  const [activeEditTab, setActiveEditTab] = useState(false);
  const [editorData, setEditorData] = useState("");
  useEffect(() => {
    if (!isLoading && subCategoryWhatisList && subCategoryWhatisList.length > 0) {
      setData(subCategoryWhatisList);
      setEditorDescription(subCategoryWhatisList[0].description);
      // setOverviewData(subCategoryWhatisList[0].description);
      setDefaultId(subCategoryWhatisList[0].id);
      setCatRowId(subCategoryWhatisList[0].id);
      setSubCategoryName(subCategoryWhatisList[0].subCategoryName);
    } else {
      setData([]);
      setEditorDescription("")
    }
  }, [isLoading]);
  //dispatch(getSubCategories({ categoryId: props.catIdds, keys: tab }));
  // useEffect(() => {
  //   dispatch(getSubCategories({ categoryId: props.catIdds, keys: 'details' }));
  // }, [props.pagetitle]);

  useEffect(() => {
    setActiveEditDelete(false);
    setAddReorderTab(true);
    $(".addReorderTab").css('display', 'block');
    $(".addnewClass").addClass("activesSubTab");
    setShowDetailsTab(true);
    setActiveEditTab(false);
    // setDefaultId(props.catIdds)
  }, [props]);

  useEffect(() => {
    if (isDetails) {
      //setOverviewData(subCategoryDetails.description);
      setEditorDescription(subCategoryDetails.description);
    }
    if (isSubReoder) {
      setShowDetailsTab(false);
      $(".activDeleteEdit").css('display', 'none');
    }
    if (isSubCatUpdate) {
      //setShowDetailsTab(false);
      dispatch(showSuccess({ msg: "Record updated successfully." }));
      $(".activDeleteEdit").css('display', 'none');
    }
    if (issDelete) {
      setShowDetailsTab(false);
      $(".activDeleteEdit").css('display', 'none');
    }

  }, [
    isSubCatUpdate,
    //props.pagetitle,
    isSubCatInsert,
    isDetails,
    issDelete,
    isSubReoder,
  ]);

  const [activeEditDelete, setActiveEditDelete] = useState(false);
  const [addReorderTab, setAddReorderTab] = useState(false);

  // const changeTabName = async (tab) => {
  //   setTabName(tab);
  //   setActiveEditDelete(false);
  //   setAddReorderTab(true);
  //   $(".addReorderTab").css('display', 'block');
  //   setShowDetailsTab(true)
  //   switch (tab) {
  //     case "details":
  //       $(".tab_1").addClass("activeSubTab");
  //       $(".tab_2").removeClass("activeSubTab");
  //       $(".tab_3").removeClass("activeSubTab");
  //       break;
  //     case "audio":
  //       $(".tab_2").addClass("activeSubTab");
  //       $(".tab_1").removeClass("activeSubTab");
  //       $(".tab_3").removeClass("activeSubTab");
  //       break;
  //     case "video":
  //       $(".tab_3").addClass("activeSubTab");
  //       $(".tab_2").removeClass("activeSubTab");
  //       $(".tab_1").removeClass("activeSubTab");
  //       break;
  //     // default:
  //     //     setActiveSubCat('activeSubTab')
  //     //     break;
  //   }
  //   $(".addnewClass").addClass("activesSubTab");
  //   setShowDetailsTab(false);
  //   setActiveEditTab(false);
  //   setDefaultId(props.catIdds)
  //   dispatch(getSubCategories({ categoryId: props.catIdds, keys: tab }));
  // };

  const subCategoryFunction = (id, catId, data) => {
    setDefaultId(id)
    $(".activDeleteEdit").css('display', 'block');
    if (props.pagetitle != "Overview") {
      $(".dk-tabs-right-navlink-13").removeClass("addActiveClass");
      $(".xyz" + id).addClass("addActiveClass");
    }
    setActiveEditDelete(true);
    setShowDetailsTab(true);
    //setOverviewData("");
    setEditorDescription("");
    setSubCategoryName(data);
    setCategoryId(catId);
    setCatRowId(id);
    dispatch(getSubCatDetails({ id }));
    setActiveEditTab(true);
  };


  const [isReorderModal, setIReorderModal] = useState(false);
  // const [isEditModal, setIsEditModal] = useState(false);

  const showreorderModal = () => {
    setIReorderModal(true);
  };
  const hidereorderModal = () => {
    setIReorderModal(false);
  };
  // const showeditModal = () => {
  //   setIsEditModal(true);
  // };
  // const hideeditModal = () => {
  //   setIsEditModal(false);
  // };
  const SubmitBtn = () => {
    dispatch(
      updateEditorValue({
        id: defaultId,
        key: "description",
        value: editorData
      })
    );
  };
  console.log(data)
  return (
    <>
      <div className="dkg-payment-qt-rightpanel">
        <div className="dkg-tabs-rightPanel-12">
          <div className="dkg-subcategory-header dkg-jobspec-subcat-cols">
            <>
              <ul className="headerlist">
                {addReorderTab ? <>
                  <li className="headeritem mr-2 addReorderTab">
                    <AddNewCategoryModal
                      categoryId={categoryId}
                      tabName={tabName}
                    />
                  </li>
                  <li className="headeritem addReorderTab mr-2">
                    <span
                      className="dk-resCrcleIconCon"
                    >
                      <EditSubCategory
                        categoryId={categoryId}
                        tabName={tabName}
                        subCategory={subCategoryName}
                        catRowId={catRowId}
                        list={data}
                      />

                    </span>
                  </li>
                  <li className="headeritem addReorderTab mr-2">
                    <span
                      className="dk-resCrcleIconCon"
                      onClick={showreorderModal}
                    >
                      <FaArrowsAlt />
                    </span>
                    {isReorderModal ? (
                      <ReorderSubCategory
                        onHide={hidereorderModal}
                        dataList={data}
                        catIdds={props.catIdds}
                        activeEditTab={tabName}
                      />
                    ) : null}
                  </li>
                  <li className="headeritem addReorderTab">
                    <span className="dk-resCrcleIconCon">
                      {/* <FaTrashAlt /> */}
                      <DeleteModal
                        catIdds={catRowId}
                        activeEditTab={activeEditTab}
                        atag={tabName}
                        catids={props.catIdds}
                      />
                    </span>
                  </li>
                </>
                  :
                  null
                }
                {
                  activeEditDelete ? <>
                    <li className="headeritem mr-2 activDeleteEdit">
                      <EditSubCategory
                        categoryId={categoryId}
                        tabName={tabName}
                        subCategory={subCategoryName}
                        catRowId={catRowId}
                        activeEditTab={activeEditTab}
                        list={data}
                      />
                    </li>
                    <li className="headeritem activDeleteEdit">
                      <DeleteModal
                        catIdds={catRowId}
                        activeEditTab={activeEditTab}
                        atag={tabName}
                        catids={props.catIdds}
                      />
                    </li>
                  </> : null
                }

              </ul>
            </>


            {/* <div className="title dkg-page-title dkg-overview-title">
              {props.pagetitle}
            </div> */}
          </div>
          <div className="dk-tabs-rightCols-12">
            <div className="dk-tabs-right-detail-12">
              <Tab.Container id="left-tabs-example">

                <div className={`dk-tabs-right-tabpane-12 addnewClass dkg-hidewithoutselct`} >
                  <div className="dk-rightDetail-panel-13">
                    <div className="dk-getStartedRight-detail-13">
                      <Nav
                        variant=""
                        className="dk-tabs-right-nav-13 flex-column"
                      >
                        {data.map((item, key) => (
                          <Nav.Item className="dk-tabs-right-navitem-13">

                            <Link
                              to="#"
                              key={key + 1}
                              className={
                                key === 0 ?
                                  `dk-tabs-right-navlink-13 addActiveClass xyz` + item.id : `dk-tabs-right-navlink-13 xyz` + item.id
                              }
                              onClick={() =>
                                subCategoryFunction(
                                  item.id,
                                  item.categoryId,
                                  item.subCategoryName
                                )
                              }
                            >
                              {item.subCategoryName}{" "}
                            </Link>

                          </Nav.Item>
                        ))}
                      </Nav>

                      {showDetailsTab ? (
                        data.length > 0 ? <><Tab.Content className="dk-tabs-right-tabcontent-13">
                          <div className="dkg-jopspec-pageheader">
                            <div className="dkg-jopspec-page-title">{subCategoryName}</div>
                          </div>
                          <MessageBox
                            value={editorDescription}
                            onChange={(value) => setEditorData(value)}
                          />
                          <div className="dkg-submit-btnCon">
                            <SubmitButton
                              txt="SAVE"
                              className="dkg-cand-submit-btn"
                              onClick={SubmitBtn}
                            />
                          </div>
                        </Tab.Content></> : null


                      ) : (
                        <>

                        </>
                      )}
                    </div>
                  </div>
                </div>
                {/* {props.pagetitle === "Overview" ? (
                  <>
                    <Tab.Content className="dk-tabs-right-tabcontent-13">
                      <MessageBox
                        value={overviewData}
                        onChange={(value) => setEditorData(value)}
                      />
                      <div className="dkg-submit-btnCon">
                        <SubmitButton
                          txt="SAVE"
                          className="dkg-cand-submit-btn"
                          onClick={SubmitBtn}
                        />
                      </div>
                    </Tab.Content>
                  </>
                ) : null} */}
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportsStep1;
