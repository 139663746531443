import React from 'react';
import { Link } from 'react-router-dom';
import './adminmodule.scss';
import AdminHeader from '../../../elements/header/Header'
import Navbar from "../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../elements/submenu/mainmenu/DashboardSubmenu';
import Holidays from '../../../../assets/images/calendar-white.png';
import MyFile from '../../../../assets/images/file-folder.png';
import Reports from '../../../../assets/images/report_white.png';
import Euro from '../../../../assets/images/euro.png';
import FbWhite from '../../../../assets/images/socials-media.png';
import SaveWhite from '../../../../assets/images/save-white.png';
import ThinkingWhite from '../../../../assets/images/thinking.png';
import InformationIcon from '../../../../assets/images/information_white.png';


const AdvertisementsModule = () => {
    return (
        <>
            <AdminHeader />
            <Navbar />
            <Submenu />
            <div className="dk-master-moduleCon">
                <div className="dk-module-blocksContainer dk-adminContainer">
                    <h4 className="title">ADVERTISEMENT DEPARTMENT</h4>
                    <div className="dk-portalCols">
                        <div className="dkg-adver-top-box">
                            <div className="title">MONTHLY BUDGET</div>
                            <div className="dk-textPanel">
                                <table className="table dkg-accounting-boxtable dkg-monthly-budget-table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='dkg-red-counter' style={{ color: "#333" }}>1223<span className="ml-1">€</span></div>
                                            </td>
                                            <td>
                                                <div className='dkg-green-counter'>1870<span className="ml-1">€</span></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="dkg-adver-top-box">
                            <div className="title">LIVE ADS</div>
                            <div className="dk-textPanel">
                                <table className="table dkg-accounting-boxtable">
                                    <tbody>
                                        <tr>

                                            <td>
                                                <div className='dkg-green-counter'>87</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="dkg-adver-top-box">
                            <div className="title">LIVE CAMPAIGN</div>
                            <div className="dk-textPanel">
                                <table className="table dkg-accounting-boxtable">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='dkg-red-counter'>23</div>
                                            </td>

                                        </tr>
                                    </tbody></table>
                            </div>
                        </div>
                        <div className="dkg-adver-top-box">
                            <div className="title">MONTHLY APPS</div>
                            <div className="dk-textPanel">
                                <table className="table dkg-accounting-boxtable">
                                    <tbody>
                                        <tr>

                                            <td>
                                                <div className='dkg-green-counter'>87</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="dk-portalCols">
                        <div className="dk-portalBox dk_portalBox_5">
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={Euro}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Cost/Benefits</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_portalBox_7">
                            <Link to="#" >
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={Holidays}
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Live Ads</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_portalBox_8">
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={FbWhite}
                                            alt="PR"
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Live Campaigns</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_supplierbgcolor">
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={Reports}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Job Apps</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="dk-portalCols">
                        <div className="dk-portalBox dk_portalBox_support">
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={MyFile}
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Resource List</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_portalBox_4" style={{ background: '#4c5f59' }}>
                            <Link to="#" >
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={SaveWhite}
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Saved Ads</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_frlancerresource">
                            <Link to="#" >
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={ThinkingWhite}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Daily Ads Plan</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_portalBox_support">
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={InformationIcon}
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Knowledge Base</div>
                                </div>
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AdvertisementsModule