import React, { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FAQComponents from "./category/CompanyTable";
import "./paidservice-faq.scss";
import { Tab, Nav } from "react-bootstrap";
import SubmitButton from "../../../../../ui/submitButton";
import {
  getTextHeading,
  updateCMSText,
  updateHeadingText,
  clearState,
} from "../../../../../../slice/candidate/frontendCms/frontendCmsSlice";
import {
  showError,
  showSuccess,
} from "../../../../../utils/messages/messageSlice";

const PaidServiceFaq = () => {
  const dispatch = useDispatch();
  //const { getPrincipalsList } = useSelector((state) => state.principals);
  const { isLoading, getcmsList, isUpdate } = useSelector(
    (state) => state.cmslist
  );

  const [pageHeading, setPageHeading] = useState([]);
  const [pageDescription, setDescription] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getTextHeading({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState({}));
      dispatch(showSuccess({ msg: "Information updated successfully" }));
    }
  }, [isUpdate]);

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setPageHeading(getcmsList[30]);
      setDescription(getcmsList[31]);
    }
  }, [isLoading]);

  const SubmitBtn = (id, key, value) => {
    dispatch(
      updateCMSText({
        id,
        key,
        value,
      })
    );
  };

  return (
    <>
      <div className="dkg-cand-services-Side">
        <Tab.Container defaultActiveKey="dkg-faq-title">
          <div className="row m-0 dkg-payment-qt-tab-mainCon">
            <div className="col-md-2 pl-0 dkg-payment-qt-tabCon dkg-profile-task-sideMenu">
              <Nav
                variant="pills"
                className="flex-column dkg-payment-qt-tab-navpills"
              >
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="dkg-faq-title"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Title
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="dkg-faq-instructions"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Instructions
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="dkg-faq-backend"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    FAQ Backend
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-md-10  dkg-paidservices-rightCon">
              <Tab.Content className="dkg-paidservices-tabcontent">
                <Tab.Pane
                  eventKey="dkg-faq-title"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Title</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <input
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue={
                        pageHeading != "" ? pageHeading.heading_decription : ""
                      }
                      onChange={(e) => setPageHeading(e.target.value)}
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                        onClick={() =>
                          SubmitBtn(31, "heading_decription", pageHeading)
                        }
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="dkg-faq-instructions"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Instructions</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <input
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue={
                        pageDescription != ""
                          ? pageDescription.heading_decription
                          : ""
                      }
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                        onClick={() =>
                          SubmitBtn(32, "heading_decription", pageDescription)
                        }
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="dkg-faq-backend"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">FAQ Backend</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <div className="dkg-paidservice-faq-mainCon">
                      <FAQComponents />
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default PaidServiceFaq;
