import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaTrashAlt, FaRegEdit } from "react-icons/fa";
import {
  getTextLevel,
  updateScreeningdata,
  clearState,
} from "../../../../slice/prescreeningForm/formTextSlice";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../components/utils/messages/messageSlice";

const SecondLevelTable = () => {
  const dispatch = useDispatch();
  const { isLoading, firstLevelList } = useSelector(
    (state) => state.screaningFormText
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!isLoading && firstLevelList && firstLevelList.length > 0) {
      setData(firstLevelList);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getTextLevel({ type: "2stLeve" }));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  const updateData = (val, id) => {
    dispatch(
      updateScreeningdata({
        id: id,
        key: "title",
        value: val,
      })
    );
  };

  return (
    <>
      <div className="container-fluid dkg-levels-tabCon">
        <div className="row dkg-level-row">
          <div className="col-12 col-md-12 dkg-level-col">
            <div className="dkg-level-TableCon table-responsive">
              <table className="dkg-level-Table table table-bordered">
                <thead>
                  <tr>
                    <th>SNo.</th>
                    <th>CATEGORIES</th>
                    <th>QUESTIONS HINT</th>
                    <th>QUESTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[28].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[28].title,
                              29
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[29].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[29].title,
                                30
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[30].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[30].title,
                                31
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[31].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[31].title,
                                32
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[32].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[32].title,
                                33
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[33].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[33].title,
                                34
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[34].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[34].title,
                                35
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[35].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[35].title,
                              36
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[36].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[36].title,
                                37
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[37].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[37].title,
                                38
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[38].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[38].title,
                                39
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[39].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[39].title,
                                40
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[40].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[40].title,
                                41
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[41].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[41].title,
                                42
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[42].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[42].title,
                              43
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[43].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[43].title,
                                44
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[44].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[44].title,
                                45
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[45].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[45].title,
                                46
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[46].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[46].title,
                                47
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[47].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[47].title,
                                48
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[48].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[48].title,
                                49
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[49].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[49].title,
                              50
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[50].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[50].title,
                                51
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[51].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[51].title,
                                52
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[52].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[52].title,
                                53
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[53].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[53].title,
                                54
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[54].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[54].title,
                                55
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[55].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[55].title,
                                56
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[56].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[56].title,
                              57
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[57].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[57].title,
                                58
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[58].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[58].title,
                                59
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[59].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[59].title,
                                60
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[60].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[60].title,
                                61
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[61].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[61].title,
                                62
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[62].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[62].title,
                                63
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[63].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[63].title,
                              64
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[64].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[64].title,
                                65
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[65].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[65].title,
                                66
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[66].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[66].title,
                                67
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[67].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[67].title,
                                68
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[68].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[68].title,
                                69
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[69].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[69].title,
                                70
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[70].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[70].title,
                              71
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[71].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[71].title,
                                72
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[72].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[72].title,
                                73
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[73].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[73].title,
                                74
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[74].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[74].title,
                                75
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[75].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[75].title,
                                76
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[76].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[76].title,
                                77
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[77].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[77].title,
                              78
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[78].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[78].title,
                                79
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[79].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[79].title,
                                80
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[80].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[80].title,
                                81
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[81].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[81].title,
                                82
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[82].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[82].title,
                                83
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[83].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[83].title,
                                84
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[84].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[84].title,
                              85
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[85].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[85].title,
                                86
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[86].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[86].title,
                                87
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[87].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[87].title,
                                88
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[88].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[88].title,
                                89
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[89].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[89].title,
                                90
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[90].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[90].title,
                                91
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[91].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[91].title,
                              92
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[92].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[92].title,
                                93
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[93].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[93].title,
                                94
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[94].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[94].title,
                                95
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[95].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[95].title,
                                96
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[96].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[96].title,
                                97
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[97].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[97].title,
                                98
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[175].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[175].title,
                              176
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[176].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[176].title,
                                177
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[177].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[177].title,
                                178
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[178].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[178].title,
                                179
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[179].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[179].title,
                                180
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[180].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[180].title,
                                181
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[181].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[181].title,
                                182
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[182].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[182].title,
                              183
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[183].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[183].title,
                                184
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[184].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[184].title,
                                185
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[185].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[185].title,
                                186
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[186].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[186].title,
                                187
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[187].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[187].title,
                                188
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[188].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[188].title,
                                189
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[189].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[189].title,
                              190
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[190].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[190].title,
                                191
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[191].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[191].title,
                                192
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[192].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[192].title,
                                193
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[193].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[193].title,
                                194
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[194].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[194].title,
                                195
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[195].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[195].title,
                                196
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[196].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[196].title,
                              197
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[197].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[197].title,
                                198
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[198].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[198].title,
                                199
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[199].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[199].title,
                                200
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[200].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[200].title,
                                201
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[201].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[201].title,
                                202
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[202].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[202].title,
                                203
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[203].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[203].title,
                              204
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[204].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[204].title,
                                205
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[205].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[205].title,
                                206
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[206].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[206].title,
                                207
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[207].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[207].title,
                                208
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[208].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[208].title,
                                209
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[209].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[209].title,
                                210
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondLevelTable;
