import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import AdminLogo from "../../../assets/images/admin_logo.png";
import './header.scss';
import Dropdown from 'react-bootstrap/Dropdown'
import { logoutUser } from "../../../slice/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import CandidatePrescreen from '../modal/CandidatePrescreen';
import { getUserslist, usersById } from "../../../slice/admin/user/userSlice";

const AdminHeader = (props) => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth);
    const { userDetails } = useSelector(state => state.alluserList);

    const logout = async () => {
        dispatch(logoutUser({}))
    }
    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(getUserslist({}))
                await dispatch(usersById({ userId: userInfo.id }))
            } catch (err) {
                // dispatch(showError({ msg: err }))
            }
        }
        fetchData()
    }, [dispatch])

    // const pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-adminMainHeader">
                <div className="dk-adminNavbar">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-adminLogo">
                            <Link to="/dashboard">
                                <img src={AdminLogo} className="img-fluid" alt="" />
                            </Link>
                        </div>
                        {/* <div className="dk-nav-title" style={{ color: props.textcolor }}> */}
                        <div className="dk-nav-title" style={{ color: props.textcolor }}>

                            <Link to={props.dashLink} style={{ color: props.linkcolor }} className="mr-2">

                                {props.dashTitle}
                            </Link>
                            {/* <Link to={props.dashboardLink} style={{ color: props.linkcolor }} className="mr-2"> */}
                            <Link to={props.dashboardLink} style={{ color: "#333" }} className="mr-2">
                                {props.dashboardheaderLink}
                            </Link>
                            <Link to={props.moduleLink} style={{ color: props.linkcolor }}>
                                {props.mainheaderLink}
                            </Link>
                            {props.mainheaderTitle}
                        </div>
                        <div className='dk-mdHeader-rightPanel'>
                            <div className='dk-btnsClient d-flex'>
                                <CandidatePrescreen />
                            </div>
                            <div className="dk-nav-profile">
                                <Dropdown>
                                    <div className="dropdown-img">
                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + userDetails.profileImage} title={userDetails.firstName} className="img-fluid" alt="" />
                                    </div>
                                    <span className="dk-userIcon">{userDetails.firstName}</span>
                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/my-account/dashboard"><i class="fas fa-user-circle mr-2"></i>My Account</Dropdown.Item>
                                        {/* <Dropdown.Item href="/settings/users"><i class="fas fa-user mr-2"></i>DKG Users</Dropdown.Item> */}
                                        <Dropdown.Item href="#" onClick={logout}><i class="fas fa-sign-out-alt mr-2"></i> Sign Out</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AdminHeader;
