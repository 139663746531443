import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const subCategoryAdd = createAsyncThunk(
    "relocations/sol/subcategory",
    async ({ categoryId, keys, subCategoryName }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/relocation-sub-category",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        categoryId,
                        keys,
                        subCategoryName,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);
export const addCategory = createAsyncThunk(
    "relocations/sol/category",
    async ({ categoryName }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/relocation-category",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        categoryName,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const getSubCategories = createAsyncThunk(
    "relocations/sol/subcatlist",
    async ({ categoryId, keys }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL +
                "/relocation-sub-category/" +
                categoryId +
                "/" +
                keys,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getSubCatDetails = createAsyncThunk(
    "relocations/sol/detailsbyid",
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/relocation-sub-category/" + id,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getCategories = createAsyncThunk(
    "relocations/sol/categoryWhatislist",
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/relocation-category",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getRelocationCategories = createAsyncThunk(
    "relocations/sol/categoryRelocation",
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/relocation-category",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateEditorValue = createAsyncThunk(
    "relocations/sol/updateDescriptionkeypair",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL +
                "/relocation-sub-category/updatekey/" +
                id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        key,
                        value,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateValue = createAsyncThunk(
    "relocations/sol/keypair",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL +
                "/relocation-sub-category/updatekey/" +
                id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        key,
                        value,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateSubCategory = createAsyncThunk(
    "relocations/sol/updatesubcategory",
    async ({ id, categoryId, subCategoryName, subCategory, keys }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/relocation-sub-category/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        categoryId,
                        subCategoryName,
                        subCategory,
                        keys,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateCategory = createAsyncThunk(
    "relocations/sol/updatecategory",
    async ({ id, categoryName }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/relocation-category/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        categoryName,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const catDelete = createAsyncThunk(
    "relocations/sol/delete",
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/relocation-category/" + id,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const subCatDelete = createAsyncThunk(
    "relocations/sol/subCatdelete",
    async ({ id, catid }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL +
                "/relocation-sub-category/" +
                id +
                "/" +
                catid,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const reorderData = createAsyncThunk(
    "relocations/sol/reorderlist",
    async ({ ids }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/relocation-category/reorder",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        ids,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const subCatReorderData = createAsyncThunk(
    "relocations/sol/subreorderlist",
    async ({ ids, catId, tabName }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL +
                "/relocation-sub-category/reorder/" +
                catId +
                "/" +
                tabName,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        ids,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const clientportals = createSlice({
    name: "relocations",
    initialState: {
        categoryWhatisList: [],
        subCategoryWhatisList: [],
        subCategoryDetails: [],
        relocationsList: [],
        isRel: false,
        isSuccess: false,
        isTabActive: false,
        isCatInsert: false,
        isSubCatInsert: false,
        isCatUpdate: false,
        isSubCatUpdate: false,
        isLoading: false,
        issLoading: false,
        isSubLoading: false,
        isSubReoder: false,
        isError: false,
        isDelete: false,
        issDelete: false,
        isReoder: false,
        isSubdesc: false,
    },
    reducers: {
        clearState: (state) => {
            state.isSubdesc = false;
            state.isRel = false;
            state.isSubReoder = false;
            state.isRel = false;
            state.isDetails = false;
            state.isSubLoading = false;
            state.issLoading = false;
            state.isLoading = false;
            state.isSubCatInsert = false;
            state.isSuccess = false;
            state.isTabActive = false;
            state.isError = false;
            state.isCatInsert = false;
            state.isSubCatUpdate = false;
            state.isCatUpdate = false;
            state.isDelete = false;
            state.issDelete = false;
            state.isReoder = false;
        },
    },
    extraReducers: {
        [subCatReorderData.pending]: (state) => {
            state.isLoading = true;
            state.isSubReoder = false;
        },
        [subCatReorderData.fulfilled]: (state, { payload }) => {
            state.isSubReoder = true;
            state.isLoading = false;
            state.subCategoryWhatisList = payload.result;
            state.msg = "Reorder successfully.";
        },
        [subCatReorderData.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = payload.message;
            state.msg = payload.message;
        },
        [reorderData.pending]: (state) => {
            state.isLoading = true;
            state.isReoder = false;
        },
        [reorderData.fulfilled]: (state, { payload }) => {
            state.isReoder = true;
            state.isLoading = false;
            state.categoryWhatisList = payload.result;
            state.msg = "Reorder successfully.";
        },
        [reorderData.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = payload.message;
            state.msg = payload.message;
        },

        [getSubCatDetails.pending]: (state) => {
            state.isError = false;
            state.isDetails = false;
        },
        [getSubCatDetails.fulfilled]: (state, { payload }) => {
            state.isDetails = true;
            state.subCategoryDetails = payload.result;
        },
        [getSubCatDetails.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isDetails = false;
        },
        [getSubCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isTabActive = false;
        },
        [getSubCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isTabActive = true;
            state.isSuccess = true;
            state.relocationsList = payload.result;
        },
        [getSubCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.isTabActive = false;
        },

        [getRelocationCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isRel = false;
        },
        [getRelocationCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isRel = true;
            state.relocationsList = payload.result;
        },
        [getRelocationCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isRel = false;
            state.isSuccess = false;
        },

        [getCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.categoryWhatisList = payload.result;
        },
        [getCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [subCategoryAdd.pending]: (state) => {
            state.isLoading = true;
            state.isSubCatInsert = false;
            state.isError = false;
        },
        [subCategoryAdd.fulfilled]: (state, { payload }) => {
            state.isLoading = true;
            state.isError = true;
            state.isSuccess = true;
            state.isSubCatInsert = true;
            state.relocationsList.push(payload.result); //add end of item
        },
        [subCategoryAdd.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [addCategory.pending]: (state) => {
            state.isLoading = true;
            state.isCatInsert = false;
            state.isError = false;
        },
        [addCategory.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = true;
            state.isCatInsert = true;
            state.categoryWhatisList = payload.result;
        },
        [addCategory.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateCategory.pending]: (state) => {
            state.isLoading = true;
            // state.isCatUpdate = false;
            state.isError = false;
        },
        [updateCategory.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            // state.isCatUpdate = true;
            let mainArray = state.categoryWhatisList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return payload.result;
                } else {
                    return r;
                }
            });
            state.categoryWhatisList = finalResult;
        },
        [updateCategory.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateSubCategory.pending]: (state) => {
            state.isLoading = true;
            state.isSubCatUpdate = false;
            state.isError = false;
        },
        [updateSubCategory.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            // state.isSuccess = true;
            state.isSubCatUpdate = true;
            let mainArray = state.subCategoryWhatisList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return payload.result;
                } else {
                    return r;
                }
            });
            state.subCategoryWhatisList = finalResult;
        },
        [updateSubCategory.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateValue.pending]: (state) => {
            state.isLoading = true;
            state.isSubCatUpdate = false;
            state.isError = false;
        },
        [updateValue.fulfilled]: (state, { payload }) => {
            //  state.isLoading = false;
            //  state.isError = true;
            //  state.isSubCatUpdate = true;
            let mainArray = state.subCategoryWhatisList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return payload.result;
                } else {
                    return r;
                }
            });
            state.isSubCatUpdate = true;
            state.subCategoryWhatisList = finalResult;
        },
        [updateValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateEditorValue.pending]: (state) => {
            state.isSubdesc = false;
            state.isError = false;
        },
        [updateEditorValue.fulfilled]: (state, { payload }) => {
            state.isError = true;
            state.isSubdesc = true;
        },
        [updateEditorValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isSubdesc = false;
        },

        [subCatDelete.pending]: (state) => {
            state.issDelete = false;
            state.isLoading = true;
        },
        [subCatDelete.fulfilled]: (state, payload) => {
            state.issDelete = true;
            state.isLoading = false;
            state.subCategoryWhatisList = payload.result;
            // state.subCategoryWhatisList = state.subCategoryWhatisList.filter((item) => item.id !== action.meta.arg.id);
            state.msg = "Delete Successfully";
        },
        [subCatDelete.rejected]: (state, { payload }) => {
            state.issDelete = false;
            state.isError = true;
            state.isLoading = false;
            state.msg = payload.msgBody;
        },
        [catDelete.pending]: (state) => {
            state.isSuccess = false;
            state.issDelete = false;
            state.isLoading = true;
        },
        [catDelete.fulfilled]: (state, payload) => {
            state.isDelete = true;
            state.isLoading = false;
            state.categoryWhatisList = payload.result;
            state.msg = "Delete Successfully";
        },
        [catDelete.rejected]: (state, { payload }) => {
            state.isDelete = false;
            state.isError = true;
            state.isLoading = false;
            state.msg = payload.msgBody;
        },
    },
});
export const { clearState } = clientportals.actions;
export default clientportals.reducer;
