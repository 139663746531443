import React from "react";
import AdminHeader from "../../../../elements/header/Header";
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../elements/submenu/mainmenu/CandAccSubmenu";
import { Nav, Tab } from "react-bootstrap";
import "./compliance.scss";
import SecondSideMenu from "./SecondSideMenu ";
import ResetLogins from "./ResetLogins";
import CookiesPolicy from "./CookiesPolicy";
import NotificationsText from "./NotificationsText";
import DeleteAcText from "./DeleteAccontText";
import PrivacyEditor from "./privacy/PrivacyEditor";
import GdprEditor from "./gdpr/GdprEditor";
import TermsCondidtion from "./candifaq/category/CompanyTable";
import ContactusSideMenu from "./ContactusSideMenu";

const Index = () => {
  return (
    <>
      <AdminHeader />
      <Navbar />
      <Submenu />
      <div className="dkg-contact-mainCon">
        <div className="dkg-contact-main">
          <Tab.Container id="left-tabs-example" defaultActiveKey="dkg-setting-faq">
            <div className="row justify-content-center dkg-contact-mainRow">
              <div className="col-md-2 dkg-contact-main-sideCol">
                <Nav
                  variant="pills dkg-aboutus-navpills dkg-paid-services-navpills"
                  className="flex-column"
                >
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-setting-faq"
                      className="dkg-aboutus-navlink"
                    >
                      FAQ’s
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-company"
                      className="dkg-aboutus-navlink"
                    >
                      Privacy
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item className="dkg-aboutus-navitem">
                                    <Nav.Link eventKey="dkg-services" className="dkg-aboutus-navlink">Admin Users</Nav.Link>
                                </Nav.Item> */}
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-logos"
                      className="dkg-aboutus-navlink"
                    >
                      GDPR
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-social"
                      className="dkg-aboutus-navlink"
                    >
                      Terms
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cookies"
                      className="dkg-aboutus-navlink"
                    >
                      Cookies
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-notification"
                      className="dkg-aboutus-navlink"
                    >
                      Notifications
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-feedback"
                      className="dkg-aboutus-navlink"
                    >
                      Feedbacks
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-resetlogin"
                      className="dkg-aboutus-navlink"
                    >
                      Reset Logins
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-deleteacc"
                      className="dkg-aboutus-navlink"
                    >
                      Delete A/C
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-setting-about-us"
                      className="dkg-aboutus-navlink"
                    >
                      About us
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-setting-contact-us"
                      className="dkg-aboutus-navlink"
                    >
                      Contact us
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-10 col-10 dkg-contact-mainCol">
                <Tab.Content className="dkg-aboutus-tabcontent">
                  <Tab.Pane
                    eventKey="dkg-company"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Privacy</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon dkg-setting-mainCon-234">
                      <PrivacyEditor />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-services"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Admin Users</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon"></div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-logos"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">GDPR</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon dkg-setting-mainCon-234">
                      <GdprEditor />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-social"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Terms</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon dkg-terms-of-use-faqCon">
                      <TermsCondidtion />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cookies"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Cookies</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <CookiesPolicy />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-notification"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Notifications</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <NotificationsText />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-feedback"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Feedbacks</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <SecondSideMenu />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-resetlogin"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Reset Logins</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <ResetLogins />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-setting-faq"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">FAQ’s</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      {/* <ResetLogins /> */}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-setting-about-us"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">About us</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      {/* <ResetLogins /> */}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-setting-contact-us"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Contact Us</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      {/* <ResetLogins /> */}
                      <ContactusSideMenu />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-deleteacc"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Delete A/C</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon">
                      <DeleteAcText />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default Index;
