import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal'
//import Submit from "../../../../../ui/submitButton";
import { FaTrashAlt } from "react-icons/fa";
import { catDelete, clearState } from "../../../../../../../slice/whatis/whatisSlice";
import SubmitButton from "../../../../../../ui/submitButton";
import { showSuccess, showError, clearMessage } from "../../../../../../../components/utils/messages/messageSlice";


const DeleteDummy = ({ catIdds, activeEditTab }) => {
    const dispatch = useDispatch();
    const { isError, isDelete } = useSelector(state => state.whatis);

    const [show, setShow] = useState(false);
    const [categoryId, setCategoryId] = useState('');

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setCategoryId(catIdds)
    }, [catIdds])

    useEffect(() => {
        if (isError) {
            dispatch(clearState())
            setShow(false)
            setLoading(false);
            setSuccess(false);
        }
        if (isDelete) {
            dispatch(clearState())
            setShow(false)
            setLoading(false);
            setSuccess(false);
        }
    }, [isError, isDelete])


    const deleteCategory = (e) => {
        e.preventDefault()
        setSuccess(false);
        setLoading(true);
        if (!categoryId) {
            dispatch(showError({ msg: 'Please enter category naem  required' }))
            setLoading(false);
        } else {
            setLoading(true);
            dispatch(catDelete({ id: categoryId }))
        }
    }


    return (
        <React.Fragment>
            {
                activeEditTab ? <span className="dk-resCrcleIconCon" onClick={handleShow}><FaTrashAlt /></span> : <span className="dk-resCrcleIconCon" style={{ cursor: "not-allowed" }} ><FaTrashAlt /></span>
            }

            <Modal className="dk-mlAddNewModal dk-deleteModal dkg-script-deleteModal234" show={show} onHide={handleClose}>
                <Modal.Body className="dk-mlAddModalBody">
                    <p>Are you sure that you want to delete this tab ?</p>
                    <div className="d-flex justify-content-end">
                        <button onClick={handleClose} className="mr-2">No</button>
                        {/* <Submit txt="Yes" position="justify-content-center" className="dk-yesBtn" /> */}
                        <SubmitButton txt="Yes" loading={loading} success={success} onClick={deleteCategory} position="justify-content-center" className="dkg-submitbtn" />
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default DeleteDummy;
