import React from 'react'
import { Modal } from "react-bootstrap";
import Submit from '../../../../../ui/submitButton'
// import  './upload-video.scss'


const CompanynameModal = ({ onHide }) => { 
  return (
    <>
        <Modal show={true} onHide={onHide} className="dkg-pagetype-uploadModal" centered>
        <Modal.Header closeButton className='dkg-video-upload-Header'>
          <Modal.Title className='w-100 text-center'> Add New Company</Modal.Title>
        </Modal.Header>
        <Modal.Body className='dkg-video-upload-Body'>
          <form action="">
            <div className="dkg-upload-frmgrp">
                {/* <label>Youtube URL</label> */}
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                />
            </div>
            <div className="dkg-upload-frmgrp">
                <div className="dkg-submit-BtnCon">
                    {/* <Submit
                    txt="Submit"
                    className="btn  dkg-submit-Btn"
                    position="justify-content-center"
                    /> */}
                     <button className='btn  dkg-submit-Btn'>Save</button>
                </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CompanynameModal