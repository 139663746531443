import React from 'react'
import { Link } from 'react-router-dom';
import './itteams-module.scss'
import AdminHeader from '../../../elements/header/Header'
import Navbar from "../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../elements/submenu/mainmenu/DashboardSubmenu';
import ProjectionsIcon from '../../../../assets/images/projections.png';
import InformationIcon from '../../../../assets/images/information_white.png';
import CalendarIcon from '../../../../assets/images/calendar-white.png';
import UidesignerIcon from '../../../../assets/images/uidesigner.png';
import DevelopmentIcon from '../../../../assets/images/app-development.png';


const ItTeamModule = () => {
    return (
        <>
            <AdminHeader />
            <Navbar />
            <Submenu />
            <div className="dk-itteamModulemain">
                <div className="dk-it-teamemModule-container">
                    <div className="title mb-2">IT Department</div>
                    <div className="d-flex">
                        <div className="dk-it-team-Module-box">
                            <Link to="#" style={{ background: '#b55355' }}>
                                <img src={ProjectionsIcon} alt="" />
                                <p>Projects</p>
                            </Link>
                        </div>
                        <div className="dk-it-team-Module-box">
                            <Link to="#" style={{ background: '#b55355' }}>
                                <img src={CalendarIcon} alt="" />
                                <p>Backlogs</p>
                            </Link>
                        </div>
                        <div className="dk-it-team-Module-box">
                            <Link to="#" style={{ background: '#b55355' }}>
                                <img src={InformationIcon} alt="" />
                                <p>Guidelines</p>
                            </Link>
                        </div>

                    </div>
                    <div className="d-flex">
                        <div className="dk-it-team-Module-box">
                            <Link to="#" style={{ background: '#b55355' }}>
                                <img src={UidesignerIcon} alt="" />
                                <p>Designers Tasks</p>
                            </Link>
                        </div>
                        <div className="dk-it-team-Module-box">
                            <Link to="#" style={{ background: '#b55355' }}>
                                <img src={DevelopmentIcon} alt="" />
                                <p>Developer Tasks</p>
                            </Link>
                        </div>
                        <div className="dk-it-team-Module-box">
                            <Link to="#" style={{ background: '#b55355' }}>
                                {/* <img src={TesterIcon} alt="" />
                                <p>Tester Tasks</p> */}
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="dk-it-team-Module-box">
                            <Link to="#" style={{ background: '#b55355' }}>
                                {/* <img src={DkGlobalRecruitersImg} alt="" /> */}

                            </Link>
                        </div>
                        <div className="dk-it-team-Module-box">
                            <Link to="#" style={{ background: '#b55355' }}>
                                {/* <img src={DkGlobalRecruitersImg} alt="" /> */}

                            </Link>
                        </div>
                        <div className="dk-it-team-Module-box">
                            <Link to="#" style={{ background: '#b55355' }}>
                                {/* <img src={DkGlobalRecruitersImg} alt="" /> */}

                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ItTeamModule