import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import ProgressBarReducer from "../slice/utils/loader/linearSlice";
import alertMessagesReducer from "../slice/utils/message/messageSlice";
import authReducer from "../slice/auth/authSlice";
import tokenReducer from "../slice/auth/tokenSlice";
import userListReducer from "../slice/userList/userSlice";
import backendValuesReducer from "../slice/backendValues/backendValuesSlice";
import adminUserListReducer from "../slice/admin/user/userSlice";
import tasksReducer from "../slice/tasks/taskSlice";
import prescreeningForm from "../slice/prescreeningForm";
import faqSlice from "../slice/faq/faqSlice";
import jobsfaqSlice from "../slice/faq/jobsFaqSlice";
import jobs2faqSlice from "../slice/faq/job2FaqSlice";
import faqloginSlice from "../slice/faq/loginFaqSlice";
import candidatefaqSlice from "../slice/faq/candidateFaqSlice";
import candidateServiceFaqSlice from "../slice/faq/candidateServiceFaqSlice";
import textChangeScreaningSlice from "../slice/prescreeningForm/formTextSlice";
import getStartSlice from "../slice/getStart/getstart";
import demandSlice from "../slice/demand/demandSlice";
import requirnmentSlice from "../slice/clieninfo/requirnment/requirnmentSlice";
import processSlice from "../slice/commonTraning/processSlice";
import principalsSlice from "../slice/principals/principalsSlice";
import solutionsSlice from "../slice/solutions/solutionsSlice";
import teamMembersSlice from "../slice/teams/teamMembers";
import whatisSlice from "../slice/whatis/whatisSlice";
import clientroletypeSlice from "../slice/clientRoleType";
import clientPortalsSlice from "../slice/clientPortals";
import relocationsSlice from "../slice/relocations";
import companiesReducer from "../slice/companies/clients/clientSlice";
import clientEuropeReducer from "../slice/europe";
import clientProjectReducer from "../slice/clientProject";
import clientRoleReducer from "../slice/clientRole";
import candidateReducer from "../slice/candidate/candidateSlice";
import cmslistReducer from "../slice/candidate/frontendCms/frontendCmsSlice";
import cmsReducer from "../slice/cmsSlice";
import termsCondidtionReducer from "../slice/termsCondition/termsConditionSlice";

const reducers = combineReducers({
  progressBar: ProgressBarReducer,
  alertMessage: alertMessagesReducer,
  auth: authReducer,
  tasks: tasksReducer,
  token: tokenReducer,
  users: userListReducer,
  backendValues: backendValuesReducer,
  alluserList: adminUserListReducer,
  screeningFormScript: prescreeningForm,
  faqs: faqSlice,
  jobsfaqs: jobsfaqSlice,
  jobs2faqs: jobs2faqSlice,
  faqlogin: faqloginSlice,
  candidateFaqs: candidatefaqSlice,
  candidateServiceFaqs: candidateServiceFaqSlice,
  screaningFormText: textChangeScreaningSlice,
  getStart: getStartSlice,
  demands: demandSlice,
  requirnments: requirnmentSlice,
  process: processSlice,
  principals: principalsSlice,
  solutions: solutionsSlice,
  teammembers: teamMembersSlice,
  whatis: whatisSlice,
  clientroletype: clientroletypeSlice,
  clientportals: clientPortalsSlice,
  relocation: relocationsSlice,
  companies: companiesReducer,
  clientEurope: clientEuropeReducer,
  clientproject: clientProjectReducer,
  clientRolesType: clientRoleReducer,
  candidate: candidateReducer,
  cmslist: cmslistReducer,
  cms: cmsReducer,
  termsCondition: termsCondidtionReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "module", "users"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
