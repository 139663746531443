import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import Submit from "../../../../../ui/submitButton";
import "./add-url.scss";
import { updateProjectData } from "../../../../../../slice/cmsSlice";

const AddurlPopup = ({ onHide, siteUrl }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState(siteUrl.site_url);

  const SubmitBtn = (e) => {
    e.preventDefault();
    dispatch(
      updateProjectData({
        id: siteUrl.id,
        key: "site_url",
        value: details,
      })
    );
  };
  return (
    <React.Fragment>
      <Modal
        show={true}
        onHide={onHide}
        dialogClassName="dkg-pr-addurl-modal-dialog"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton className="dkg-addurl-header">
          <Modal.Title>Add Url</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-addurl-body">
          <input
            type="text"
            className="form-control dkg-pr-addurl-input"
            name="addurl"
            id="addurl"
            defaultValue={siteUrl.site_url}
            onChange={(e) => setDetails(e.target.value)}
          />
          <div className="dkg-addurl-btnCon">
            <Submit
              txt="SAVE"
              className="btn dkg-addurl-btn"
              position="justify-content-center"
              onClick={SubmitBtn}
            />
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddurlPopup;
