import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import AdminHeader from "../../../elements/header/MainHeader";
import Navbar from "../../../elements/navbar/mainmenu/MainNavbar";
// import DashboardSubmenu from '../../submenu/DashboardSubmenu';
import DkGlobalCRMLogoRecImg from '../../../../assets/images/dkgcrmlogo.png'
import DkGlobalRecruitment from '../../../../assets/images/dk-global-rec-logo.png'
import DkGlobalJobs from '../../../../assets/images/dkg-jobs.png'
import DkGConsults from '../../../../assets/images/dkg-consults.png'
import DkGlobalAcademy from '../../../../assets/images/dkg-acadmey.png'
import DkGRecruiters from '../../../../assets/images/dkg-recruiters.png'
import './visitesite.scss'
const Dashboard1 = () => {
    return (
        <>
            <AdminHeader />
            {/* <Navbar /> */}
            {/* <DashboardSubmenu /> */}

            <div className="dk-visitWebsiteMain">
                <div className='dkg-visitwebaite-cardCon'>
                    {/* <div className="dk-visitWebsite-cols dkg-top-level-boxCon">
                        <div className="dk-visitWebsite-box">
                            <Link to="/dashboard/summary">
                                <div className="dk-visitLogo">
                                    <img src={DkGlobalCRMLogoRecImg} alt="" style={{ maxWidth: "162px" }} />
                                </div>
                            </Link>
                        </div>
                    </div> */}
                    <div className="dk-visitWebsite-cols">
                        <div className="dk-visitWebsite-box">
                            <Link to="/dashboard/summary">
                                <div className="dk-visitLogo">
                                    <img src={DkGlobalCRMLogoRecImg} alt="" style={{ maxWidth: "162px" }} />
                                </div>
                            </Link>
                        </div>
                        <div className="dk-visitWebsite-box">
                            <Link to="#/dashboard/recruitment">
                                <div className="dk-visitLogo">
                                    <img src={DkGlobalRecruitment} alt="" style={{ maxWidth: "162px" }} />
                                </div>
                            </Link>
                        </div>
                        <div className="dk-visitWebsite-box">
                            <Link to="#/dashboard/recruitment">
                                <div className="dk-visitLogo">
                                    <img src={DkGRecruiters} alt="" style={{ maxWidth: "162px" }} />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="dk-visitWebsite-cols">

                        <div className="dk-visitWebsite-box">
                            <Link to="#/dkglobalfinance/dashboard/module">
                                <div className="dk-visitLogo">
                                    <img src={DkGlobalJobs} alt="" style={{ maxWidth: "162px" }} />
                                </div>
                            </Link>
                        </div>
                        <div className="dk-visitWebsite-box">
                            <Link to="#/dkglobaladmin/dashboard/module">
                                <div className="dk-visitLogo">
                                    <img src={DkGlobalAcademy} alt="" style={{ maxWidth: "162px" }} />
                                </div>
                            </Link>
                        </div>
                        <div className="dk-visitWebsite-box">
                            <Link to="#/dkglobalfinance/dashboard/module">
                                <div className="dk-visitLogo">
                                    <img src={DkGConsults} alt="" style={{ maxWidth: "162px" }} />
                                </div>
                            </Link>
                        </div>
                    </div>
                    {/* <div className="dk-visitWebsite-cols">
                        <div className="dk-visitWebsite-box">
                            <Link to="#/dkglobalfinance/dashboard/module">
                                <div className="dk-visitLogo">
                                    <h2 className='dkg-adminboard-title'>HR</h2>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-visitWebsite-box">
                            <Link to="#/dkglobaladmin/dashboard/module">
                                <div className="dk-visitLogo">
                                    <h2 className='dkg-adminboard-title'>TRAINING</h2>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-visitWebsite-box">
                            <Link to="#/dashboard/recruitment">
                                <div className="dk-visitLogo">
                                    <h2 className='dkg-adminboard-title'>IT TEAM</h2>
                                </div>
                            </Link>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Dashboard1;

