import React from 'react'
import AdminHeader from '../../../../elements/header/Header'
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../../elements/submenu/mainmenu/CandidateSubmenu';
import  './cand-summary.scss'


const index = () => {
    return (
        <>
            <AdminHeader />
            <Navbar />
            <Submenu />
            <div className='dkg-cand-summ-mainCon'></div>
        </>
    )
}

export default index