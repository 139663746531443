import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import Submit from "../../../../../ui/submitButton";
import { FaTimes } from "react-icons/fa";
import { updateProjectData } from "../../../../../../slice/cmsSlice";

const UpdatePop = ({ onHide, data }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState("");
  //   useEffect(() => {
  //     setDetails(data.completed);
  //   }, [data]);

  const SubmitBtn = (e) => {
    e.preventDefault();
    dispatch(
      updateProjectData({
        id: data.id,
        key: "completed",
        value: details,
      })
    );
  };

  return (
    <React.Fragment>
      <Modal
        show={true}
        onHide={onHide}
        dialogClassName="dk-adsCreAddNewPop"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Body>
          <span className="dkg-crosssicon-con" onClick={onHide}>
            <FaTimes />
          </span>
          <form action="">
            <div className="dk-adsCreditInCon">
              <div className="firstInput">
                <label>Current</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={data.completed}
                  readOnly
                />
              </div>
              <div className="SecondInput">
                <label>New</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </div>
              <div className="dk-adsCreditBtnCon">
                <Submit
                  txt="SAVE"
                  className="btn  dk-calcsaveBtn"
                  position="justify-content-center"
                  onClick={SubmitBtn}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default UpdatePop;
