import React from "react";
import Modal from "react-bootstrap/Modal";
import "./view-terms.scss";

const ApplytermsModal = ({ onHide }) => {
  return (
    <>
      <Modal
       show={true}
       onHide={onHide}
        className="dkg-view-referal-terms-modal-21"
      >
        <Modal.Header closeButton className="dkg-referal-terms-modalheader-21">
          <Modal.Title>APPLY FOR THIS REFERRAL</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-referal-terms-modalbody-21">
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApplytermsModal;
