import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { Link } from 'react-router-dom'
import { Tab, Nav } from "react-bootstrap";
import Step1 from "./Step1";
import Header from '../../../../elements/header/Header'
import Navbar from "../../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../../elements/submenu/mainmenu/ClientInfoSubmenu'
import {
  getCategories,
  getSubCategories,
  clearState,
} from "../../../../../slice/clientPortals";
import { FaArrowsAlt } from "react-icons/fa";
import AddNewCategory from "./reportsModel/maincategory/AddNewCategory";
import EditCategory from "./reportsModel/maincategory/EditCategory";
import ReorderCategory from "./reportsModel/maincategory/ReorderCategory";
import DeleteDummy from "./reportsModel/maincategory/DeleteDummy";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../utils/messages/messageSlice";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import $ from "jquery";
const Overview = () => {
  const dispatch = useDispatch();
  const {
    isSuccess,
    isLoading,
    isCatUpdate,
    isError,
    isDelete,
    isReoder,
    isSubCatUpdate,
    isSubReoder,
    isCatInsert,
    categoryWhatisList,
  } = useSelector((state) => state.clientportals);

  const [data, setData] = useState([]);
  const [catRowId, setCatRowId] = useState("");
  const [catRowName, setCatRowName] = useState("");
  const [activeEditTab, setActiveEditTab] = useState(false);
  //const [activeSubCat, setActiveSubCat] = useState("");
  const [activeSTab, setActiveSTab] = useState(false);

  useEffect(() => {
    if (!isLoading && categoryWhatisList && categoryWhatisList.length > 0) {
      setData(categoryWhatisList);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isDelete) {
      setActiveEditTab(false);
    }
  }, [isSubCatUpdate, isCatUpdate, isDelete]);

  useEffect(() => {
    if (isSubReoder) {
      dispatch(clearState());
      dispatch(getSubCategories({ categoryId: catRowId, keys: "details" }));
    }
  }, [isSubReoder]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCategories({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch, isCatInsert, isDelete]);

  useEffect(() => {
    // if (isError) {
    //   dispatch(showError({ msg: "Error while creating categories" }));
    // }
    if (isCatInsert) {
      dispatch(showSuccess({ msg: "Category added successful created" }));
    }
  }, [isError, isCatInsert]);

  const selectCat = async (catid, catName) => {

    setActiveSTab(true);
    setCatRowId(catid);
    setCatRowName(catName);
    setActiveEditTab(true);

    if (catName == "Overview") {
      $(".activesSubTab").removeClass("activesSubTab");
      await dispatch(getSubCategories({ categoryId: catid, keys: 'Overview' }))
    } else {
      $(".activeSubTab").removeClass("activeSubTab");
      $(".activesSubTab").removeClass("activesSubTab");
      $(".activDeleteEdit").css('display', 'none');
      $(".addReorderTab").css('display', 'none');
    }
  };

  const [isReorderModal, setIReorderModal] = useState(false);
  const showreorderModal = () => {
    setIReorderModal(true);
  };
  const hidereorderModal = () => {
    setIReorderModal(false);
  };

  return (
    <>
      <Header dashboardheaderLink="DKG CRM " dashboardLink="/dashboard/summary" />
      <Navbar />
      <Submenu />
      <div className="dkg-clientlist-pageCon dkg-payment-qt-pageCon" style={{ marginTop: "140px" }} >
        <div className="dkg-maincategory-header">
          <ul className="headerlist">
            <li className="headeritem mr-2">
              <AddNewCategory />
            </li>
            <li className="headeritem mr-2">
              <EditCategory
                catIdds={catRowId}
                catName={catRowName}
                activeEditTab={activeEditTab}
              />
            </li>
            <li className="headeritem mr-2">
              <span className="dk-resCrcleIconCon" onClick={showreorderModal}>
                <FaArrowsAlt />
              </span>
              {isReorderModal ? (
                <ReorderCategory onHide={hidereorderModal} dataList={data} />
              ) : null}
            </li>
            <li className="headeritem">
              <DeleteDummy catIdds={catRowId} activeEditTab={activeEditTab} />
            </li>
          </ul>
        </div>
        <div className="dkg-clientlist-mainsec-Con dkg-adminclients-mainsec-Con">
          <div className="dkg-clientlist-main-ulCon dkg-adminclients-main-ulCon">
            <div className="dkg-clientlist-main-ul-tabsCon dkg-adminclients-main-ul-tabsCon">
              <Tab.Container defaultActiveKey="question-pt-new-overview">
                <div className="row m-0 dkg-payment-qt-tab-mainCon">
                  <div className="col-md-2 pl-0 dkg-payment-qt-tabCon dkg-profile-task-sideMenu">
                    <Nav
                      variant="pills"
                      className="flex-column dkg-payment-qt-tab-navpills"
                    >
                      {data.map((item, i) => (
                        <Nav.Item
                          className="dkg-payment-qt-tab-nav-item"
                          onClick={() => selectCat(item.id, item.categoryName)}
                        >
                          {
                            <Nav.Link
                              eventKey={item.categoryName + i}
                              className="dkg-payment-qt-tab-navlink"
                            >
                              {item.categoryName}
                            </Nav.Link>
                          }
                        </Nav.Item>
                      ))}
                    </Nav>
                  </div>
                  <div className="col-md-10 dkg-payment-rt-tabCon-10 dkg-profile-task-rightCon">
                    <Tab.Content className="dkg-payment-qt-tabcontent">
                      {activeSTab == true
                        ? data.map((item, i) => (
                          <Tab.Pane
                            eventKey={item.categoryName + i}
                            className="dkg-payment-qt-tabpane"
                          >
                            <Step1
                              pagetitle={item.categoryName}
                              catIdds={item.id}
                              tabName="tabChange"
                              catOverviewid={catRowId}
                            />
                          </Tab.Pane>
                        ))
                        : null}
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
