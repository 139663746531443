import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
// import Submit from "../../submitButton"; 
import SubmitButton from "../../../../../../../ui/submitButton"; 

import {
  companyDelete,
  clearState,
  categoryDelete,
  QuesAnsDelete,
} from "../../../../../../../../slice/faq/faqSlice";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../../../utils/messages/messageSlice";

const DeleteDummy = ({ rowid, type }) => {
  const dispatch = useDispatch();
  const { isError, issDelete } = useSelector((state) => state.faqs);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [deletedId, setDeletId] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setDeletId(rowid);
  }, [rowid]);

  const deleteCompany = (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);
    if (!deletedId) {
      dispatch(showError({ msg: "Please enter category name" }));
      setLoading(false);
    } else {
      setLoading(true);
      if (type === "company") {
        dispatch(companyDelete({ id: deletedId }));
      } else if (type === "QuesAns") {
        dispatch(QuesAnsDelete({ id: deletedId }));
      } else {
        dispatch(categoryDelete({ id: deletedId }));
      }
    }
  };

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setShow(false);
      setLoading(false);
      setSuccess(false);
    }
    if (issDelete) {
      dispatch(clearState());
      setShow(false);
      setLoading(false);
      setSuccess(false);
    }
  }, [isError, issDelete]);

  return (
    <React.Fragment>
      {/* <span ><i class="far fa-trash-alt"></i></span> */}
      <Link onClick={handleShow} to="#" className="dkg-faq-delete-btn">
        <i className="fa fa-trash " aria-hidden="true"></i>
      </Link>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dk-adstarckDeletePop"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Body className="dk-adstrackDelBody">
          <p>Are you sure wants to Delete these records ?</p>
        </Modal.Body>
        <Modal.Footer className="dk-adstrackDelFooter">
          <button onClick={handleClose} className="btn dk-nodelBtn">
            No
          </button>
          <SubmitButton
            txt="Yes"
            position="justify-content-center"
            className="btn dk-yesdelBtn"
            loading={loading}
            success={success}
            onClick={deleteCompany}
          />
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteDummy;
