import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom'
//import { Modal } from "react-bootstrap";
import AddfileModal from './modal/AddfileModal';
import EditfileModal from './modal/EditfileModal'
import ReorderModal from './modal/ReorderModal'
import DeleteDummy from './modal/DeleteDummy'
import NewDocumentModal from "./modal/DocumentNwModal";
// import { FaEdit, FaArrowsAlt, FaEye, FaDownload, FaTrashAlt } from "react-icons/fa";
import { FaSearch, FaPlus, FaEye, FaDownload, FaFilter, FaArrowsAlt, FaTrashAlt, FaEdit } from 'react-icons/fa';
import  "./jobfile-modal.scss";


import { showSuccess, showError, clearMessage }  from '../../../../../utils/messages/messageSlice'
import { getprojectlist, deleteProjectValue, clearState,detailsbyid } from "../../../../../../slice/companies/clients/clientSlice";

const JobDetails = ({ companyid }) => {

    const dispatch = useDispatch();
    const { isLoading, isDelete,isUpdate, isError, message, newProjectList, isProjectInsert,projectdetails } = useSelector(state => state.companies)

    const [isPopupDelete, setIsDelete] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [rowid, setRowid] = useState(0);
    const [searchInput , setSearchInput] = useState('')
    const submitSearch = ()=>{
        console.log(dataList)
        console.log(searchInput)
    }

    useEffect(() => {
       if (!isLoading && newProjectList && newProjectList.length > 0) {
        setDataList(newProjectList)
       }else{
        setDataList([])
       }

    },[isLoading])
       
    const [showEdit , setShowEdit] = useState(false)    

    useEffect(() => {
        const fetchdata = async () => {
             await dispatch(getprojectlist({ companyId: companyid }))
             await dispatch(detailsbyid({ companyId: companyid }))
        }
        fetchdata();
        
    }, [companyid,isDelete])


    const showDeleteModal = async (id) => {
        setRowid(id)
        setIsDelete(true);
    };
    const hideDeleteModal = () => {
        setRowid(0)
        setIsDelete(false);
    };

    useEffect(() => {
        if (isUpdate) {
            setDataList(newProjectList)
        }
        if (isProjectInsert) {            
            dispatch(showSuccess({ msg: message }))
            dispatch(clearState())
        }
        if (isDelete) {
            dispatch(clearState())
        }
    }, [isError, isProjectInsert,isUpdate])
   

    const editFunction = (id)=>{ 
        dispatch(detailsbyid({ companyId: id }))
        setShowEdit(true)
    }
    const hideEdit =()=>{
        setShowEdit(false)
    }

    return (
        <>
            {
                (isPopupDelete) ? <DeleteDummy onHide={hideDeleteModal} id={rowid} companyId={companyid}/> : null
            }
            {
                (showEdit) ? <EditfileModal onHide={hideEdit}  /> :null 
            } 
            <div className="dkg-edit-jobfiles-mainCon">
                <div className='dkg-jobfiles-header'>
                    <div className='dkg-leftStaffnHead'>
                        <div className="dk-searchCols">
                            <input type="text" name="" id="" placeholder="Search..." className="form-control" value={searchInput} onChange={(e)=>setSearchInput(e.target.value)}/>
                            <button onClick={submitSearch}><FaSearch /></button>
                        </div>
                    </div>
                    <div class="title">CLIENTS FILES</div>
                    <div className="d-flex justify-content-end dkg-add-new-btnCon">
                        <ReorderModal dataList={newProjectList} clientid={companyid} />
                        <AddfileModal companyid={companyid} />
                    </div>
                </div>
                <div className="dkg-edit-jobfiles-tableCon">
                    <table className="dkg-edit-jobfiles-table table table-bordered">
                        <thead>
                            <tr>
                                <th>SNo.</th>
                                <th>File Name</th>
                                <th>File Type</th>
                                <th>View</th>
                                <th>Download</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataList.map((item, index) =>
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.fileType}</td>
                                        <td>
                                            <NewDocumentModal name={item.file} canId={item.id} />
                                        </td>
                                        <td>
                                            <div className="dkg-file-actions">
                                                <a href={process.env.REACT_APP_INTERNAL_BASE_URL + '/' + item.file} download target='_blank' className="dkg-download-icon mr-2">
                                                    <FaDownload />
                                                </a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dkg-file-actions">

                                              <Link to="#" onClick={(e)=>{editFunction(item.id)}} className="dkg-editfile-icon mr-2"><FaEdit /></Link> 

                                                <Link to="#" className="dkg-deletefile-icon" onClick={(e) => showDeleteModal(item.id)}>
                                                    <FaTrashAlt />
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }

                        </tbody>
                      
                    </table>
                </div>
            </div>
        </>
    )
}

export default JobDetails