import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import "./deletemodal.css";
import Submit from "../../submitButton";

const ConfirmModal = ({ id, ConfirmHandleClose, ConfirmDeleteItem, title }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true)
    }, [id])

    return (
        <React.Fragment>
            <Modal
                show={show}
                onHide={ConfirmHandleClose}
                dialogClassName="dk-adstarckDeletePop"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Body className="dk-adstrackDelBody">
                    <p>{title}</p>
                </Modal.Body>
                <Modal.Footer className="dk-adstrackDelFooter">
                    <button className="btn dk-nodelBtn" onClick={ConfirmHandleClose}>No</button>
                    <Submit txt="Yes" onClick={ConfirmDeleteItem} position="justify-content-center" className="btn dk-yesdelBtn" />
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default ConfirmModal;
