import React from "react";
import { Route, Link } from "react-router-dom";

const DashboardSubmenu = () => {
  const pathname = window.location.pathname;
  return (
    <div>
      <div className="dk-adminSubmenuMain dkg-admin-home-submenuMain">
        <Route>
          <ul >
            <li>
              <Link
                to="/dashboard/summary"
                className={`${pathname.match("/dashboard/summary") ? "white-active" : ""
                  }`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/principles"
                className={`${pathname.match("/dashboard/principles") ? "white-active" : ""
                  }`}
              >
                CSF
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/teams"
                className={`${pathname.match("/dashboard/teams") ? "white-active" : ""
                  }`}
              >
                Rec Teams
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/get-start"
                className={`${pathname.match("/dashboard/get-start") ? "white-active" : ""
                  }`}
              >
                Get Start
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/check-in"
                className={`${pathname.match("/dashboard/check-in") ? "white-active" : ""
                  }`}
              >
                Check In
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/my-moods"
                className={`${pathname.match("/dashboard/my-moods") ? "white-active" : ""
                  }`}
              >
                Blackbook
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/job-profiles"
                className={`${pathname.match("/dashboard/job-profiles") ? "white-active" : ""
                  }`}
              >
                Jobs Profile
              </Link>
            </li>
            {/* <li>
              <Link
                to="/dashboard/checklist"
                className={`${pathname.match("/dashboard/checklist") ? "white-active" : ""
                  }`}
              >
                Checklist
              </Link>
            </li> */}

            <li>
              <Link
                to="/dashboard/my-profiles"
                className={`${pathname.match("/dashboard/my-profiles") ? "white-active" : ""
                  }`}
              >
                My Profile
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/today-work"
                className={`${pathname.match("/dashboard/today-work") ? "white-active" : ""
                  }`}
              >
                My Workload
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/reports"
                className={`${pathname.match("/dashboard/reports") ? "white-active" : ""
                  }`}
              >
               Training
              </Link>
            </li>
          </ul>
        </Route>
      </div>
    </div>
  );
};

export default DashboardSubmenu;
