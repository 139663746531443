import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { Link } from "react-router-dom";
import {
  getTextLevel,
  updateScreeningdata,
} from "../../../../slice/prescreeningForm/formTextSlice";
import {
  //showSuccess,
  showError,
  //clearMessage,
} from "../../../../components/utils/messages/messageSlice";

const FirstLevelTable = () => {
  const dispatch = useDispatch();
  const { isLoading, firstLevelList } = useSelector(
    (state) => state.screaningFormText
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!isLoading && firstLevelList && firstLevelList.length > 0) {
      setData(firstLevelList);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getTextLevel({ type: "1stLevel" }));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  const updateData = (val, id) => {
    dispatch(
      updateScreeningdata({
        id: id,
        key: "title",
        value: val,
      })
    );
  };

  return (
    <>
      <div className="container-fluid dkg-levels-tabCon">
        <div className="row dkg-level-row">
          <div className="col-12 col-md-12 dkg-level-col">
            <div className="dkg-level-TableCon table-responsive">
              <table className="dkg-level-Table table table-bordered">
                <thead>
                  <tr>
                    <th>SNo.</th>
                    <th>CATEGORIES</th>
                    <th>QUESTIONS HINT</th>
                    <th>QUESTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[0].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[0].title,
                              1
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[1].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[1].title,
                                2
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[2].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[2].title,
                                3
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[3].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[3].title,
                                4
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[210].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[210].title,
                                211
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[211].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[211].title,
                                212
                              )
                            }
                          />
                        </div>

                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[4].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[4].title,
                                5
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[5].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[5].title,
                                6
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[6].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[6].title,
                                7
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[212].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[212].title,
                                213
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[213].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[213].title,
                                214
                              )
                            }
                          />
                        </div>

                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[7].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[7].title,
                              8
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[8].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[8].title,
                                9
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[9].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[9].title,
                                10
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[10].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[10].title,
                                11
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[11].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[11].title,
                                12
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[12].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[12].title,
                                13
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[13].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[13].title,
                                14
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[14].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[14].title,
                              15
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[15].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[15].title,
                                16
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[16].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[16].title,
                                17
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[17].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[17].title,
                                18
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[18].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[18].title,
                                19
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[19].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[19].title,
                                20
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[20].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[20].title,
                                21
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[21].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[21].title,
                              22
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[22].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[22].title,
                                23
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[23].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[23].title,
                                24
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[24].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[24].title,
                                25
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[25].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[25].title,
                                26
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[27].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[27].title,
                                27
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[27].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[27].title,
                                28
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>5</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[98].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[98].title,
                              99
                            )
                          }
                        />
                      </div>

                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[99].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[99].title,
                                100
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[100].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[100].title,
                                101
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[101].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[101].title,
                                102
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[102].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[102].title,
                                103
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[103].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[103].title,
                                104
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[104].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[104].title,
                                105
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>6</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[105].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[105].title,
                              106
                            )
                          }
                        />
                      </div>

                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[106].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[106].title,
                                107
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[107].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[107].title,
                                108
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[108].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[108].title,
                                109
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[109].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[109].title,
                                110
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[110].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[110].title,
                                111
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[111].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[111].title,
                                112
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[112].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[112].title,
                              113
                            )
                          }
                        />
                      </div>

                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[113].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[113].title,
                                114
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[114].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[114].title,
                                115
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[115].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[115].title,
                                116
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[214].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[214].title,
                                215
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[116].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[116].title,
                                117
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[117].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[117].title,
                                118
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[118].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[118].title,
                                119
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[215].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[215].title,
                                216
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>8</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[119].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[119].title,
                              120
                            )
                          }
                        />
                      </div>

                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[120].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[120].title,
                                121
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[121].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[121].title,
                                122
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[122].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[122].title,
                                123
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[123].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[123].title,
                                124
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[124].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[124].title,
                                125
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[125].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[125].title,
                                126
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[126].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[126].title,
                              127
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[127].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[127].title,
                                128
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[128].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[128].title,
                                129
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[129].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[129].title,
                                130
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[130].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[130].title,
                                131
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[131].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[131].title,
                                132
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[132].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[132].title,
                                133
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>10</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[133].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[133].title,
                              134
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[134].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[134].title,
                                135
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[135].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[135].title,
                                136
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[136].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[136].title,
                                137
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[137].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[137].title,
                                138
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[138].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[138].title,
                                139
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[139].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[139].title,
                                140
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[140].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[140].title,
                              141
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[141].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[141].title,
                                142
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[142].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[142].title,
                                143
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[143].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[143].title,
                                144
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[144].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[144].title,
                                145
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[145].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[145].title,
                                146
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[146].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[146].title,
                                147
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[147].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[147].title,
                              148
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[148].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[148].title,
                                149
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[149].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[149].title,
                                150
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[150].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[150].title,
                                151
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[216].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[216].title,
                                217
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[151].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[151].title,
                                152
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[152].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[152].title,
                                153
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[153].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[153].title,
                                154
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[217].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[217].title,
                                218
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[154].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[154].title,
                              155
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[155].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[155].title,
                                156
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[156].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[156].title,
                                157
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[157].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[157].title,
                                158
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[158].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[158].title,
                                159
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[159].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[159].title,
                                160
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[160].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[160].title,
                                161
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[161].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[161].title,
                              162
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[162].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[162].title,
                                163
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[163].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[163].title,
                                164
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[164].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[164].title,
                                165
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[165].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[165].title,
                                166
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[166].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[166].title,
                                167
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[167].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[167].title,
                                168
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>
                      <div className="dkg-maintitle">
                        <input
                          type="text"
                          className="form-control"
                          name="main-title"
                          defaultValue={data != "" ? data[168].title : ""}
                          onChange={(e) =>
                            updateData(
                              data != "" ? e.target.value : data[168].title,
                              169
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-subtitleBlock">
                        <div className="dkg-level-1stsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[169].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[169].title,
                                170
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[170].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[170].title,
                                171
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[171].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[171].title,
                                172
                              )
                            }
                          />
                        </div>
                        {/* new block */}
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[220].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[220].title,
                                221
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[222].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[222].title,
                                223
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdsubtitleBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[224].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[224].title,
                                225
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-level-messageBlock">
                        <div className="dkg-level-1stmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[172].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[172].title,
                                173
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-2ndmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[173].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[173].title,
                                174
                              )
                            }
                          />
                        </div>
                        <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[174].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[174].title,
                                175
                              )
                            }
                          />
                        </div>
                        {/* new block */}
                         <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[221].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[221].title,
                                222
                              )
                            }
                          />
                        </div>
                         <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[223].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[223].title,
                                224
                              )
                            }
                          />
                        </div>
                         <div className="dkg-level-3rdmessageBlock">
                          <input
                            type="text"
                            className="form-control"
                            name="main-title"
                            defaultValue={data != "" ? data[225].title : ""}
                            onChange={(e) =>
                              updateData(
                                data != "" ? e.target.value : data[225].title,
                                226
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstLevelTable;
