import React from 'react';
import { Route, Link } from "react-router-dom";

const CandidateSubmenu = () => {
    const pathname = window.location.pathname;
    return (
        <div>
            <div className="dk-adminSubmenuMain">
                <Route>
                    <ul>
                        <li><Link to="/accounts/summary" className={`${pathname.match('/accounts/summary') ? 'sub-active' : ''}`}>Summary</Link></li>
                        {/* <li><Link to="/accounts/active-account" className={`${pathname.match('/accounts/active-account') ? 'sub-active' : ''}`}>Live Users</Link></li> */}
                        {/* <li><Link to="#/candidate-acc/actions" className={`${pathname.match('/candidate-acc/mailshot') ? 'sub-active' : ''}`}>Inactive</Link></li>
                        <li><Link to="#/candidate-acc/notification" className={`${pathname.match('/dashboard/templates') ? 'sub-active' : ''}`}>Blocked</Link></li> */}
                        {/* <li><Link to="/accounts/delete-account" className={`${pathname.match('/accounts/delete-account') ? 'sub-active' : ''}`}>Deleted</Link></li> */}
                    </ul>
                </Route>
            </div>
        </div>
    )
}

export default CandidateSubmenu;
