import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData } from "../../services";

export const getService = createAsyncThunk(
    "frontend/getServiceList",
    async (thunkAPI) => {
        const res = await GetData(true, "/candidates/paid-services");
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getCMSHeadingText = createAsyncThunk(
    "frontend/cms/text-change",
    async (thunkAPI) => {
        const res = await GetData(true, "/candidates/frontend-cms");
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getBenifits = createAsyncThunk(
    "cmsdetails/benifits",
    async (thunkAPI) => {
        const res = await GetData(true, "/mangecontent/benifits");
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const usersSlice = createSlice({
    name: "frontendcms",
    initialState: {
        getcmsList: [],
        serviceList: [],
        benifitslist: [],
        isSuccess: false,
        isUpdate: false,
        isLoading: false,
        isError: false,
        isDelete: false,
        isBenifits: false,
        issLoading: false,
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.isUpdate = false;
            state.isDelete = false;
            state.isBenifits = false;
            state.issLoading = false;
        },
    },
    extraReducers: {

        [getService.pending]: (state) => {
            state.isError = true;
            state.isSuccess = true;
        },
        [getService.fulfilled]: (state, { payload }) => {
            state.isSuccess = false;
            state.serviceList = payload.result;
        },
        [getService.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isSuccess = false;
        },

        [getCMSHeadingText.pending]: (state) => {
            state.isLoading = true;
            state.issLoading = true;
            state.isError = false;
        },
        [getCMSHeadingText.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.issLoading = false;
            state.isSuccess = true;
            state.getcmsList = payload.result;
        },
        [getCMSHeadingText.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.issLoading = false;
            state.isSuccess = false;
        },

        [getBenifits.pending]: (state) => {
            state.isBenifits = true;
            state.isError = false;
        },
        [getBenifits.fulfilled]: (state, { payload }) => {
            state.isBenifits = false;
            state.benifitslist = payload.result;
        },
        [getBenifits.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isBenifits = false;
        },
    },
});

export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
