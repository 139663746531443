import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import {SYSTEM_CONSTANTS} from "../../constants"
// function start
export const addCategories = createAsyncThunk(
    "loginfaqs/addCat",
    async ({ companyId, companyName, name }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/onlyJobs-faqs-category",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        companyId,
                        companyName,
                        name,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);
export const reorderCompanyData = createAsyncThunk(
    "loginfaqs/reorderlist",
    async ({ ids }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/onlyJobs-faqs-company/reorder",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        ids,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const reorderCategoryData = createAsyncThunk(
    "loginfaqs/reorderCategorylist",
    async ({ ids }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/onlyJobs-faqs-category/reorder",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        ids,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const reorderQueAnsData = createAsyncThunk(
    "loginfaqs/reorderQuestlist",
    async ({ ids }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL +
                "/faqs-login/reorder",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        ids,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const addQueAns = createAsyncThunk(
    "loginfaqs/addQuestionAns",
    async (
        { question, answer },
        thunkAPI
    ) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/faqs-login",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        question,
                        answer
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);
export const addCompany = createAsyncThunk(
    "loginfaqs/addcompany",
    async ({ name }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/onlyJobs-faqs-company",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        name,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);
export const getQueAns = createAsyncThunk(
    "loginfaqs/list-ques-ans",
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/onlyJobs-faqs-question-answer/list/" + id,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getIndivisualCategory = createAsyncThunk(
    "loginfaqs/induvidualcategorylist",
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/onlyJobs-faqs-category/allCatlist/" + id,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getFaqCategory = createAsyncThunk(
    "loginfaqs/categorylist",
    async ({ }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/faqs-login",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getCategory = createAsyncThunk(
    "loginfaqs/getcategorylist",
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/onlyJobs-faqs-category/list/" + id,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getCompany = createAsyncThunk(
    "loginfaqs/company-list",
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/onlyJobs-faqs-company",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateQueAnsStatus = createAsyncThunk(
    "loginfaqs/update_Que_ans",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/faqs-login/update/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        key,
                        value,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateCatSequence = createAsyncThunk(
    "loginfaqs/update_cat_sequence",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/onlyJobs-faqs-category/update/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        key,
                        value,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateSequence = createAsyncThunk(
    "loginfaqs/update_sequence",
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/onlyJobs-faqs-company/update/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        key,
                        value,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateCategory = createAsyncThunk(
    "loginfaqs/update_category",
    async ({ id, name, companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/onlyJobs-faqs-category/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        name,
                        companyId,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateQuesAns = createAsyncThunk(
    "loginfaqs/updatequesAns",
    async ({ id, question, answer }, thunkAPI) => {
        try {

            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/faqs-login/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        question,
                        answer,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateCompany = createAsyncThunk(
    "loginfaqs/update_sequence",
    async ({ id, name }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/onlyJobs-faqs-company/" + id,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        name,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const companyDelete = createAsyncThunk(
    "loginfaqs/subCatdelete",
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/onlyJobs-faqs-company/" + id,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const QuesAnsDelete = createAsyncThunk(
    "loginfaqs/quesAns_Delete",
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/faqs-login/" + id,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const categoryDelete = createAsyncThunk(
    "loginfaqs/companydelete",
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/onlyJobs-faqs-category/" + id,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const allcompanylist = createAsyncThunk(
    "loginfaqs/companylist/backendvalue",
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxAdmin"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + "/jobs-company/",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
                        platform: "web",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const usersSlice = createSlice({
    name: "loginfaqsslice",
    initialState: {
        bkCompanylist: [],
        companyList: [],
        categoryList: [],
        faqCategoryList: [],
        quesansList: [],
        firstLevelList: [],
        isSuccess: false,
        isTabActive: false,
        isCompanyInsert: false,
        isCategoryInsert: false,
        isSubCatInsert: false,
        isCatUpdate: false,
        isUpdate: false,
        isLoading: false,
        issLoading: false,
        isSubLoading: false,
        isSubReoder: false,
        isError: false,
        isDelete: false,
        issDelete: false,
        isReoder: false,
        isDefult: false,
        isFaqs: false,
    },
    reducers: {
        clearState: (state) => {
            state.isSubReoder = false;
            state.isDetails = false;
            state.isFaqs = false;
            state.isSubLoading = false;
            state.issLoading = false;
            state.isLoading = false;
            state.isSubCatInsert = false;
            state.isSuccess = false;
            state.isDefult = false;
            state.isTabActive = false;
            state.isError = false;
            state.isCompanyInsert = false;
            state.isCategoryInsert = false;
            state.isUpdate = false;
            state.isCatUpdate = false;
            state.isDelete = false;
            state.issDelete = false;
            state.isReoder = false;
        },
    },
    extraReducers: {
        [addQueAns.pending]: (state) => {
            state.isLoading = true;
            state.isCategoryInsert = false;
            state.isError = false;
        },
        [addQueAns.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.isCategoryInsert = true;
            state.categoryList = payload.result;
        },
        [addQueAns.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.isCategoryInsert = false;
        },
        [addCategories.pending]: (state) => {
            state.isLoading = true;
            state.isCategoryInsert = false;
            state.isError = false;
        },
        [addCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.isCategoryInsert = true;
            state.categoryList = payload.result;
        },
        [addCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.isCategoryInsert = false;
        },
        [addCompany.pending]: (state) => {
            state.isLoading = true;
            state.isCompanyInsert = false;
            state.isError = false;
        },
        [addCompany.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.isCompanyInsert = true;
            state.companyList = payload.result;
        },
        [addCompany.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [getQueAns.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getQueAns.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.quesansList = payload.result;
        },
        [getQueAns.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [getIndivisualCategory.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getIndivisualCategory.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.categoryList = payload.result;
        },
        [getIndivisualCategory.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [getFaqCategory.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isDefult = false;
            state.isFaqs = false;
        },
        [getFaqCategory.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isDefult = true;
            state.isFaqs = true;
            state.categoryList = payload.result;
            state.faqCategoryList = payload.result;
        },
        [getFaqCategory.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isFaqs = false;
            state.isDefult = false;
            state.isSuccess = false;
        },
        [getCategory.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isDefult = false;
            state.isFaqs = false;
        },
        [getCategory.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isDefult = true;
            state.isFaqs = true;
            state.categoryList = payload.result;
            state.faqCategoryList = payload.result;
        },
        [getCategory.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isFaqs = false;
            state.isDefult = false;
            state.isSuccess = false;
        },

        [getCompany.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getCompany.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.companyList = payload.result;
        },
        [getCompany.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
        },
        [allcompanylist.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [allcompanylist.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.bkCompanylist = payload.result;
        },
        [allcompanylist.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
        },
        [updateQueAnsStatus.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
            state.isError = false;
        },
        [updateQueAnsStatus.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.isUpdate = true;
            let mainArray = state.categoryList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return payload.result;
                } else {
                    return r;
                }
            });
            state.categoryList = finalResult;
        },
        [updateQueAnsStatus.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateCatSequence.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
            state.isError = false;
        },
        [updateCatSequence.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.isUpdate = true;
            let mainArray = state.categoryList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return payload.result;
                } else {
                    return r;
                }
            });
            state.categoryList = finalResult;
        },
        [updateCatSequence.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateSequence.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
            state.isError = false;
        },
        [updateSequence.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            // state.isSuccess = true;
            state.isUpdate = true;
            let mainArray = state.companyList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return payload.result;
                } else {
                    return r;
                }
            });
            state.companyList = finalResult;
        },
        [updateSequence.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateCategory.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
            state.isError = false;
        },
        [updateCategory.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.isUpdate = true;
            let mainArray = state.categoryList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return payload.result;
                } else {
                    return r;
                }
            });
            state.categoryList = finalResult;
        },
        [updateCategory.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateCompany.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
            state.isError = false;
        },
        [updateQuesAns.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            // state.isSuccess = true;
            //state.isUpdate = true;

            state.categoryList = payload.result;
        },
        [updateQuesAns.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateQuesAns.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
            state.isError = false;
        },
        [updateCompany.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            // state.isSuccess = true;
            state.isUpdate = true;
            let mainArray = state.companyList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return payload.result;
                } else {
                    return r;
                }
            });
            state.companyList = finalResult;
        },
        [updateCompany.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [QuesAnsDelete.pending]: (state) => {
            state.issDelete = false;
            state.isLoading = true;
        },
        [QuesAnsDelete.fulfilled]: (state, action) => {
            state.issDelete = true;
            state.isLoading = false;
            state.categoryList = state.categoryList.filter(
                (item) => item.id !== action.meta.arg.id
            );
            state.msg = "Delete Successfully";
        },
        [QuesAnsDelete.rejected]: (state, { payload }) => {
            state.issDelete = false;
            state.isError = true;
            state.isLoading = false;
            state.msg = payload.msgBody;
        },
        [categoryDelete.pending]: (state) => {
            state.issDelete = false;
            state.isLoading = true;
        },
        [categoryDelete.fulfilled]: (state, action) => {
            state.issDelete = true;
            state.isLoading = false;
            state.categoryList = state.categoryList.filter(
                (item) => item.id !== action.meta.arg.id
            );
            state.msg = "Delete Successfully";
        },
        [categoryDelete.rejected]: (state, { payload }) => {
            state.issDelete = false;
            state.isError = true;
            state.isLoading = false;
            state.msg = payload.msgBody;
        },
        [companyDelete.pending]: (state) => {
            state.issDelete = false;
            state.isLoading = true;
        },
        [companyDelete.fulfilled]: (state, action) => {
            state.issDelete = true;
            state.isLoading = false;
            // state.companyList = payload.result;
            state.companyList = state.companyList.filter(
                (item) => item.id !== action.meta.arg.id
            );
            state.msg = "Delete Successfully";
        },
        [companyDelete.rejected]: (state, { payload }) => {
            state.issDelete = false;
            state.isError = true;
            state.isLoading = false;
            state.msg = payload.msgBody;
        },

        [reorderCompanyData.pending]: (state) => {
            state.isLoading = true;
            state.isReoder = false;
        },
        [reorderCompanyData.fulfilled]: (state, { payload }) => {
            state.isReoder = true;
            state.isLoading = false;
            state.companyList = payload.result;
            state.msg = "Reorder successfully.";
        },
        [reorderCompanyData.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = payload.message;
            state.msg = payload.message;
        },

        [reorderCategoryData.pending]: (state) => {
            state.isLoading = true;
            state.isReoder = false;
        },
        [reorderCategoryData.fulfilled]: (state, { payload }) => {
            state.isReoder = true;
            state.isLoading = false;
            state.categoryList = payload.result;
            state.msg = "Reorder successfully.";
        },
        [reorderCategoryData.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isReoder = false;
            state.isError = payload.message;
            state.msg = payload.message;
        },

        [reorderQueAnsData.pending]: (state) => {
            state.isLoading = true;
            state.isReoder = false;
        },
        [reorderQueAnsData.fulfilled]: (state, { payload }) => {
            state.isReoder = true;
            state.isLoading = false;
            state.categoryList = payload.result;
            state.msg = "Reorder successfully.";
        },
        [reorderQueAnsData.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isReoder = false;
            state.isError = payload.message;
            state.msg = payload.message;
        },
    },
});
export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
