import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from "react-redux";
const LoginRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticate, userInfo } = useSelector(state => state.auth)
    const checkRole = userInfo.role

    return (
        <Route {...rest} render={props => {
            if (isAuthenticate) {
                return <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
                // if (checkRole == "User") {
                //     return <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
                // } else {
                //     return <Redirect to={{ pathname: '/admin/tools/bkvalues', state: { from: props.location } }} />
                // }

            } else {
                return <Component {...props} />
            }
        }

        } />
    )
};
export default LoginRoute;