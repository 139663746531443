import React from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../../../elements/header/Header'
import Navbar from "../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../elements/submenu/mainmenu/DashboardSubmenu';
import './supplierdashboard.scss';
import CompanyIcon from "../../../../assets/images/building_icon.png";
import FaqIcon from "../../../../assets/images/information_white.png";
import ResearchIcon from "../../../../assets/images/resourcemanagement_icon.png";
import BusgetIcon from "../../../../assets/images/euro_icon.png";
import ReportIcon from "../../../../assets/images/report_white.png";
import ItsupportIcon from "../../../../assets/images/it_white.png";



const SuppliersModule = () => {
    return (
        <>
            <AdminHeader />
            <Navbar />
            <Submenu />
            <div className="dk-vendorModulemain">
                <div className="dk-venderModule-container">
                    <div className="title mb-2">SUPPLIERS DEPARTMENT</div>
                    <div className="d-flex">
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#01796f' }}>
                                <img src={ReportIcon} alt="" />
                                <p>Job Boards</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#01796f' }}>
                                <img src={CompanyIcon} alt="" />
                                <p>IT Services</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#01796f' }}>
                                <img src={ItsupportIcon} alt="" />
                                <p>IT Support</p>
                            </Link>
                        </div>

                    </div>
                    <div className="d-flex">
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#01796f', pointerEvents: 'none' }}>

                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#01796f', pointerEvents: 'none' }}>

                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#01796f', pointerEvents: 'none' }}>

                            </Link>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#01796f' }}>
                                <img src={ResearchIcon} alt="" />
                                <p>Research</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#01796f' }}>
                                <img src={BusgetIcon} alt="" />
                                <p>Budget</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#01796f' }}>
                                <img src={FaqIcon} alt="" />
                                <p>FAQ</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SuppliersModule