import React, { useState, useMemo, useEffect } from "react";
import { Link } from 'react-router-dom'
import Header from "../../../../components/elements/header/Header";
import Navbar from "../../../../components/elements/navbar/mainmenu/MainNavbar";
import Submenu from "../../../../components/elements/submenu/mainmenu/SiteuserSubmenu";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Dropdown from 'react-bootstrap/Dropdown';
import './users.scss';
import { useDispatch, useSelector } from "react-redux";
import { getUserslist, clearState, userDelete, updateStatus, updateRole, updateCategory, usersById } from "../../../../slice/admin/user/userSlice";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import NoData from "../../../../components/utils/tableNoData";
import TableLoader from "../../../../components/utils/tableLoader/taskTable";
import { showSuccess, showError, clearMessage } from '../../../../components/utils/messages/messageSlice';
import Tooltip from "../../../../components/ui/tooltip";
import AddModal from "./AddNewModal";
import ReorderModal from './Reorder';
import UpdateDetails from "./UpdateUserDetails";
import DeleteUser from "../../../../components/ui/delete";

function Table({ loading, columns, data }) {

    const tableInstance = useTable({
        columns: columns,
        data: data
    }, useGlobalFilter, useSortBy)

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = tableInstance
    const { globalFilter } = state

    return (
        <React.Fragment>
            <table className="table table-bordered" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                {(!loading && tableInstance.flatRows.length <= 0) ? <NoData colspan="10" txt="No user list found . . ." /> : null}
                {
                    (loading) ? <TableLoader colspan="10" /> :
                        <tbody {...getTableBodyProps()}>
                            {rows.map(
                                (row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                )
                                            })}
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>
                }
            </table>
        </React.Fragment>
    )
}

const UsersManagement = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [superAdminData, setSuperAdminData] = useState([]);
    const [adminData, setAdminData] = useState([]);
    const [userData, setUserData] = useState([]);

    const { allusers, usersRole, isLoading, isDelete, msg, isError, isInsert, isReoder, isUpdate, userDetails, isSuccess } = useSelector(state => state.alluserList);
    const [rowId, setRowId] = useState('0')
    const [archivedDataUser, setArchivedUser] = useState([]);
    const [archivedUserCount, setArchivedUserCount] = useState('0');

    const [allUserCount, setAllUserCount] = useState('0');
    const [superAdminCount, setSuperAdminCount] = useState('0');
    const [adminCount, setAdminCount] = useState('0');
    const [usersCount, setUsersCount] = useState('0');
    const [showDelete, setShowDelete] = useState(false)
    const [show, setShow] = useState(false);
    const [statusVal, setStatusVal] = useState('Active');
    const [showProfile, setShowProfile] = useState(false);
    const [isReorderModal, setIReorderModal] = useState(false);

    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: msg }))
            // setAllUserCount(allusers.length)
            setAllUserCount(allusers.filter(items => items.status === 1).length)
            setSuperAdminCount(allusers.filter(items => (items.role == 'Super Admin' && items.status != 0)).length)
            setAdminCount(allusers.filter(items => (items.role == 'Admin' && items.status != 0)).length)
            setUsersCount(allusers.filter(items => (items.role == 'User' && items.status != 0)).length)
            setArchivedUser(allusers.filter(items => items.status == 0))
            setData(allusers.filter(items => (items.status === 1)))
            //setData(allusers)
            setArchivedUserCount(allusers.filter(items => items.status == 0).length)
            setSuperAdminData(allusers.filter(items => (items.role == 'Super Admin' && items.status != 0)))
            setAdminData(allusers.filter(items => (items.role == 'Admin' && items.status != 0)))
            setUserData(allusers.filter(items => (items.role == 'User' && items.status != 0)))
            setShowDelete(false)
        }
    }, [isDelete])
    useEffect(() => {
        if (isUpdate) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: msg }))
            setData(allusers.filter(items => (items.status === 1)))
            setSuperAdminData(allusers.filter(items => (items.role == 'Super Admin' && items.status != 0)))
            setAdminData(allusers.filter(items => (items.role == 'Admin' && items.status != 0)))
            setUserData(allusers.filter(items => (items.role == 'User' && items.status != 0)))
            setArchivedUser(allusers.filter(items => items.status == 0))

        }
    }, [isUpdate])

    useEffect(() => {
        if (!isLoading && allusers && allusers.length > 0) {
            // setData(allusers)
            setData(allusers.filter(items => (items.status === 1)))
            setSuperAdminData(allusers.filter(items => (items.role == 'Super Admin' && items.status != 0)))
            setAdminData(allusers.filter(items => (items.role == 'Admin' && items.status != 0)))
            setUserData(allusers.filter(items => (items.role == 'User' && items.status != 0)))
            setArchivedUser(allusers.filter(items => items.status == 0))
            //  setAllUserCount(allusers.length)
            setAllUserCount(allusers.filter(items => items.status === 1).length)
            setArchivedUserCount(allusers.filter(items => items.status === 0).length)
            setSuperAdminCount(allusers.filter(items => (items.role == 'Super Admin' && items.status != 0)).length)
            setAdminCount(allusers.filter(items => (items.role == 'Admin' && items.status != 0)).length)
            setUsersCount(allusers.filter(items => (items.role == 'User' && items.status != 0)).length)

        }
    }, [isLoading])


    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(getUserslist({}))
                //setAllUserCount(allusers.length)
                setAllUserCount(allusers.filter(items => items.status === 1).length)
            } catch (err) {
                dispatch(showError({ msg: err }))
            }
        }
        fetchData()

        if (isReoder) {
            dispatch(clearState())
            dispatch(getUserslist({}))
        }

    }, [dispatch, isReoder])
    const showreorderModal = () => {
        //  console.log("Click Useer list Reorder popup")
        setIReorderModal(true);

    }
    const hidereorderModal = () => {
        setIReorderModal(false);
        //setIsReorder(false)
    }
    const columns = useMemo(() => [
        {
            Header: 'SNo',
            accessor: 'id',
            Cell: (row) => Number(row.row.id) + 1
        },
        {
            Header: 'Status',
            Cell: ({ cell }) => {
                return (

                    <div className={(cell.row.original.status === 0) ? 'dk-userStatusBtn inActiveBtn' : 'dk-userStatusBtn'}>
                        <Dropdown>
                            <Dropdown.Toggle className="" variant="" id="dropdown-basic">
                                {(cell.row.original.status) === 0 ? 'Inactive' : 'Active'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item id={cell.row.original.id} onClick={changeStatus} data-key='1' >Active</Dropdown.Item>
                                <Dropdown.Item id={cell.row.original.id} onClick={changeStatus} data-key='0' >Inactive</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )
            }
        },
        {
            Header: 'Users',
            title: 'Users',
            Cell: ({ cell }) => (
                <div className="dk-userImg">
                    <Tooltip type="img" title={cell.row.original.firstName + " " + cell.row.original.lastName} value={process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.profileImage} className="img-circle" />
                </div>
            )
        },
        {
            Header: 'Name of User',
            title: 'Name of User',
            Cell: ({ cell }) => (<>
                {cell.row.original.firstName + " " + cell.row.original.lastName}
            </>
            )
        },
        {
            Header: 'User Email',
            title: 'User Email',
            Cell: ({ cell }) => (<>
                {cell.row.original.email}
            </>
            )
        },
        {
            Header: 'System ID',
            title: 'System ID',
            Cell: ({ cell }) => (<>
                {cell.row.original.uid}
            </>
            )
        },
        {
            Header: 'System Email',
            title: 'System Email',
            Cell: ({ cell }) => (<>
                {cell.row.original.systemEmail}
            </>
            )
        },
        {
            Header: 'Category',
            title: 'category',
            accessor: 'category',
            Cell: ({ cell }) => (<>

                <div className="dk-roleDropdown dropdown">
                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic">
                            <span>{cell.row.original.category}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item id={cell.row.original.id} onClick={changeCategory} data-key='ML' >ML</Dropdown.Item>
                            <Dropdown.Item id={cell.row.original.id} onClick={changeCategory} data-key='IT' >IT</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
            )
        },
        {
            Header: 'Type',
            title: 'Type',
            accessor: 'type',
            Cell: ({ cell }) => (<>

                <div className="dkg-user-type-btnCon">
                    <Link to="#" className="dkg-user-type-btn">Recruiter</Link>
                </div>
            </>
            )
        },
        {
            Header: 'Role',
            title: 'Role',
            accessor: 'role',
            Cell: ({ cell }) => (<>

                <div className="dk-roleDropdown dropdown">
                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic">
                            <span>{cell.row.original.role}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item id={cell.row.original.id} onClick={changeRole} data-key='Super Admin' >Super Admin</Dropdown.Item>
                            <Dropdown.Item id={cell.row.original.id} onClick={changeRole} data-key='Admin' >Admin</Dropdown.Item>
                            <Dropdown.Item id={cell.row.original.id} onClick={changeRole} data-key='User' >User</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
            )
        },

        {
            Header: 'Action',
            Cell: ({ cell }) => {
                return (<>
                    <div className="actionBtn">
                        <div className="dk-userEditBtn">
                            <button onClick={() => showEdit(cell.row.original.id)}>Edit</button>
                        </div>
                        <div className="dk-userDeleteBtn">
                            <button onClick={() => deleteUser(cell.row.original.id)} >Delete</button>
                        </div>
                    </div>
                </>
                )
            }
        },

    ], [])

    const showEdit = async (userId) => {
        await dispatch(usersById({ userId }))
        setShowProfile(true);
    }

    const changeRole = (e) => {
        e.preventDefault()
        dispatch(updateRole({ rowId: e.target.id, role: e.target.dataset.key }))
    }
    const changeCategory = (e) => {
        e.preventDefault()
        dispatch(updateCategory({ rowId: e.target.id, category: e.target.dataset.key }))
    }
    const changeStatus = (e) => {
        dispatch(updateStatus({ rowId: e.target.id, status: e.target.dataset.key }))
    }
    const deleteUser = (id) => {
        setRowId(id)
        setShowDelete(true)
    }
    const handleClose = () => {
        setRowId('0')
        setShow(false)
        setShowDelete(false)
    }
    const deleteItem = async () => {
        dispatch(userDelete({ rowId }))
    }

    const getUser = async (key) => {
        switch (key) {
            case 'All':
                // setAllUserCount(allusers.length)
                setAllUserCount(allusers.filter(items => items.status === 1).length)
                setShowProfile(false)
                break;
            case 'Archived':
                setArchivedUserCount(allusers.filter(items => items.status === 0).length)
                setShowProfile(false)
                break;

            case 'Super Admin':
                //await dispatch(usersByRole({ role: 'Super Admin' }))
                setSuperAdminCount(allusers.filter(items => (items.role == 'Super Admin' && items.status != 0)).length)
                setShowProfile(false)
                break;
            case 'User':
                // await dispatch(usersByRole({ role: 'User' }))
                setUsersCount(allusers.filter(items => (items.role == 'User' && items.status != 0)).length)
                setShowProfile(false)
                break;
            case 'Admin':
                // await dispatch(usersByRole({ role: 'Admin' }))
                setAdminCount(allusers.filter(items => (items.role == 'Admin' && items.status != 0)).length)
                setShowProfile(false)
                break;
            case 'editTab':
                setShowProfile(false)
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Header dashboardheaderLink="" dashboardLink="/dashboard/summary" />
            <Navbar />
            <Submenu />
            {
                (showDelete) ? <DeleteUser id={rowId} handleClose={handleClose} deleteItem={deleteItem} /> : null
            }
            {
                (isReorderModal) ? <ReorderModal onHide={hidereorderModal} dataList={data} /> : null
            }
            <div className="dk-settingsUserMain" style={{marginTop: "140px" , backgroundColor: "#eaeaea" , padding: "20px 0" , height: "calc(100vh - 140px)"}}>
                <div className="dk-settingUserTabs">
                    <Tabs defaultActiveKey="All" onSelect={getUser} id="uncontrolled-tab-example">
                        <Tab eventKey="All" title={"All - " + allUserCount}>

                            {(showProfile) ?
                                <UpdateDetails /> :
                                <div className="dk-userTableMain">
                                    <Table
                                        data={data}
                                        columns={columns}
                                        loading={isLoading}
                                        role='All'
                                    />
                                </div>
                            }
                        </Tab>
                        <Tab eventKey="Super Admin" title={"Super Admin - " + superAdminCount}>

                            {(showProfile) ? <UpdateDetails /> :
                                <div className="dk-userTableMain">
                                    <Table
                                        data={superAdminData}
                                        columns={columns}
                                        loading={isLoading}
                                        role='Super Admin'
                                    />
                                </div>
                            }
                        </Tab>
                        <Tab eventKey="Admin" title={"Admin - " + adminCount}>
                            {(showProfile) ? <UpdateDetails /> :
                                <div className="dk-userTableMain">
                                    <Table
                                        data={adminData}
                                        columns={columns}
                                        loading={isLoading}
                                        role='Admin'
                                    />
                                </div>
                            }
                        </Tab>
                        <Tab eventKey="User" title={"User - " + usersCount}>
                            {(showProfile) ? <UpdateDetails /> :
                                <div className="dk-userTableMain">
                                    <Table
                                        data={userData}
                                        columns={columns}
                                        loading={isLoading}
                                        role='User'
                                    />
                                </div>
                            }

                        </Tab>
                        <Tab eventKey="Archived" title={"Archived - " + archivedUserCount}>
                            {(showProfile) ?
                                <UpdateDetails /> :
                                <div className="dk-userTableMain">
                                    <Table
                                        data={archivedDataUser}
                                        columns={columns}
                                        loading={isLoading}
                                        status='0'
                                    />
                                </div>
                            }
                        </Tab>

                        <Tab ventKey="reoder-tabs" className="dk-userSettingAddnew-container" title={<span onClick={showreorderModal}><i class="fas fa-arrows-alt mr-2"></i> Reorder</span>}>
                        </Tab>
                        <Tab eventKey="tabs5" className="dk-userSettingAddnew-container" title={<span><i class="fas fa-plus mr-2"></i> Add New </span>}>
                            <div className="dk-userSetting-addNew">
                                <AddModal />
                            </div>
                        </Tab>

                    </Tabs>
                </div>
            </div>
        </>
    )
}

export default UsersManagement;
