import React, { useState, useCallback, useRef, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import SubmitButton from "../../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit } from "react-icons/fa";
import ChangeIcon from "../../../../../assets/images/complain.png";
import {
  showError,
  showSuccess,
} from "../../../../utils/messages/messageSlice";
import AWS from "aws-sdk";
import { Buffer } from "buffer";
import "react-image-crop/dist/ReactCrop.css";
//import ReactCrop from 'react-image-crop';
import {
  getTextHeading,
  updateCMSText,
  updateHeadingText,
  clearState,
} from "../../../../../slice/candidate/frontendCms/frontendCmsSlice";

const SecondSidemenu = () => {
  const dispatch = useDispatch();
  //const { getPrincipalsList } = useSelector((state) => state.principals);
  const { isLoading, getcmsList, isUpdate } = useSelector(
    (state) => state.cmslist
  );
  const [data, setData] = useState([]);
  const [pageHeading, setPageHeading] = useState([]);
  const [serviceSubtitile, setServiceSubtitile] = useState("");

  const [firstHeading, setFirstHeading] = useState("");
  const [firstDescription, setFirstDescription] = useState("");

  const [secondHeading, setSecondHeading] = useState("");
  const [secondDescription, setSecondDescription] = useState("");

  const [thirdHeading, setThirdHeading] = useState("");
  const [thirdDescription, setThirdDescription] = useState("");

  const [fourthHeading, setFourthHeading] = useState("");
  const [fourthDescription, setFourthDescription] = useState("");

  const [fifthHeading, setFifthHeading] = useState("");
  const [fifthDescription, setFifthDescription] = useState("");

  const [sixHeading, setSixHeading] = useState("");
  const [sixDescription, setSixDescription] = useState("");

  const [sevenHeading, setSevenHeading] = useState("");
  const [sevenDescription, setSevenDescription] = useState("");

  const [eightHeading, setEightHeading] = useState("");
  const [eightDescription, setEightDescription] = useState("");

  const [nineHeading, setNineHeading] = useState("");
  const [nineDescription, setNineDescription] = useState("");

  const [tenHeading, setTenHeading] = useState("");
  const [tenDescription, setTenDescription] = useState("");
  const [elevenHeading, setElevenHeading] = useState("");
  const [elevenDescription, setElevenDescription] = useState("");
  const [twelveHeading, setTwelveHeading] = useState("");
  const [twelveDescription, setTwelveDescription] = useState("");

  const [thirtenHeading, setThirtenHeading] = useState("");
  const [thirtenDescription, setThirtenDescription] = useState("");

  const [fourteenHeading, setfourteenHeading] = useState("");
  const [fourteenDescription, setfourteenDescription] = useState("");

  const [fifteenHeading, setfifteenHeading] = useState("");
  const [fifteenDescription, setfifteenDescription] = useState("");

  /************************************************************** */
  const [firstImg, setFirstImg] = useState(true);
  const [firstImgbase64, setFirstImgbase64] = useState("");

  const [secondImg, setSecondImg] = useState(true);
  const [secondImgbase64, setSecondImgbase64] = useState("");

  const [thirdImg, setthirdImg] = useState(true);
  const [thirdImgbase64, setthirdImgbase64] = useState("");

  const [fourthImg, setfourthImg] = useState(true);
  const [fourthImgbase64, setfourthImgbase64] = useState("");

  const [fifthImg, setfifthImg] = useState(true);
  const [fifthImgbase64, setfifthImgbase64] = useState("");

  const [sixthImg, setSixthImg] = useState(true);
  const [sixthImgbase64, setSixthImgbase64] = useState("");

  const [sevenImg, setSevenImg] = useState(true);
  const [sevenImgbase64, setSevenImgbase64] = useState("");

  const [eightImg, seteightImg] = useState(true);
  const [eightImgbase64, seteightImgbase64] = useState("");

  const [nineImg, setnineImg] = useState(true);
  const [nineImgbase64, setnineImgbase64] = useState("");

  const [tenImg, settenImg] = useState(true);
  const [tenImgbase64, settenImgbase64] = useState("");

  const [elevenImg, setelevenImg] = useState(true);
  const [elevenImgbase64, setelevenImgbase64] = useState("");

  const [twelveImg, settwelveImg] = useState(true);
  const [twelveImgbase64, settwelveImgbase64] = useState("");

  const [thirteenImg, setThirteenImg] = useState(true);
  const [thirteenImgbase64, setThirteenImgbase64] = useState("");

  const [fourteenImg, setfourteenImg] = useState(true);
  const [fourteenImgbase64, setfourteenImgbase64] = useState("");

  const [fifteenImg, setfifteenImg] = useState(true);
  const [fifteenImgbase64, setfifteenImgbase64] = useState("");

  // useEffect(() => {
  //   if (!isLoading && getPrincipalsList && getPrincipalsList.length > 0) {
  //     setData(getPrincipalsList);
  //   }
  // }, [isLoading]);

  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setData(getcmsList);
      setPageHeading(getcmsList[1]);
      setServiceSubtitile(getcmsList[15]);
      setFirstHeading(getcmsList[2].heading);
      setFirstDescription(getcmsList[2].heading_decription);
      setSecondHeading(getcmsList[3].heading);
      setSecondDescription(getcmsList[3].heading_decription);
      setThirdHeading(getcmsList[4].heading);
      setThirdDescription(getcmsList[4].heading_decription);
      setFourthHeading(getcmsList[5].heading);
      setFourthDescription(getcmsList[5].heading_decription);
      setFifthHeading(getcmsList[6].heading);
      setFifthDescription(getcmsList[6].heading_decription);
      setSixHeading(getcmsList[7].heading);
      setSixDescription(getcmsList[7].heading_decription);

      setSevenHeading(getcmsList[8].heading);
      setSevenDescription(getcmsList[8].heading_decription);
      setEightHeading(getcmsList[9].heading);
      setEightDescription(getcmsList[9].heading_decription);

      setNineHeading(getcmsList[10].heading);
      setNineDescription(getcmsList[10].heading_decription);

      setTenHeading(getcmsList[12].heading);
      setTenDescription(getcmsList[12].heading_decription);
      setElevenHeading(getcmsList[13].heading);
      setElevenDescription(getcmsList[13].heading_decription);

      setTwelveHeading(getcmsList[14].heading);
      setTwelveDescription(getcmsList[14].heading_decription);

      setThirtenHeading(getcmsList[23].heading);
      setThirtenDescription(getcmsList[23].heading_decription);

      setfourteenHeading(getcmsList[24].heading);
      setfourteenDescription(getcmsList[24].heading_decription);

      setfifteenHeading(getcmsList[25].heading);
      setfifteenDescription(getcmsList[25].heading_decription);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getTextHeading({}));
        //await dispatch(getTextGetStart({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  const [ImgName, setImgName] = useState("");

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState({}));
      dispatch(showSuccess({ msg: "Information updated successfully" }));
    }
  }, [isUpdate]);

  //isUpdate
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        if (e.target.name == "sixthImg") {
          setSixthImg(false);
          setSixthImgbase64(reader.result);
        }
        if (e.target.name == "elevenImg") {
          setelevenImg(false);
          setelevenImgbase64(reader.result);
        }
        if (e.target.name == "twelveImg") {
          settwelveImg(false);
          settwelveImgbase64(reader.result);
        }
        if (e.target.name == "thirtenImg") {
          setThirteenImg(false);
          setThirteenImgbase64(reader.result);
        }

        if (e.target.name == "fourtenImg") {
          setfourteenImg(false);
          setfourteenImgbase64(reader.result);
        }
        if (e.target.name == "fiftenImg") {
          setfifteenImg(false);
          setfifteenImgbase64(reader.result);
        }
        if (e.target.name == "tenImg") {
          settenImg(false);
          settenImgbase64(reader.result);
        }
        if (e.target.name == "nineImg") {
          setnineImg(false);
          setnineImgbase64(reader.result);
        }
        if (e.target.name == "eightImg") {
          seteightImg(false);
          seteightImgbase64(reader.result);
        }
        if (e.target.name == "sevenImg") {
          setSevenImg(false);
          setSevenImgbase64(reader.result);
        }
        if (e.target.name == "firstImg") {
          setFirstImg(false);
          setFirstImgbase64(reader.result);
        }
        if (e.target.name == "secondImg") {
          setSecondImg(false);
          setSecondImgbase64(reader.result);
        }
        if (e.target.name == "thirdImg") {
          setthirdImg(false);
          setthirdImgbase64(reader.result);
        }
        if (e.target.name == "fourthImg") {
          setfourthImg(false);
          setfourthImgbase64(reader.result);
        }
        if (e.target.name == "fifthImg") {
          setfifthImg(false);
          setfifthImgbase64(reader.result);
        }

        let s3bucket = new AWS.S3({
          accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
          secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
          Bucket: process.env.REACT_APP_BUCKET_NAME,
        });

        let buf = new Buffer(
          reader.result.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );
        s3bucket.createBucket(function () {
          var params = {
            Bucket: process.env.REACT_APP_BUCKET_NAME + "/users/images",
            Key: Date.now() + ".png",
            ContentEncoding: "base64",
            ContentType: "image/png",
            Body: buf,
          };
          s3bucket.upload(params, function (err, data) {
            if (err) {
              console.log(err);
            } else {
              dispatch(
                updateCMSText({
                  id: e.target.id,
                  key: "image",
                  value: data.key,
                })
              );
            }
          });
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  // Targets all textareas with class "txta"
  // let textareas = document.querySelectorAll(".txta"),
  //   hiddenDiv = document.createElement("div"),
  //   content = null;

  // for (let j of textareas) {
  //   j.classList.add("txtstuff");
  // }
  // hiddenDiv.classList.add("txta");
  // hiddenDiv.style.display = "none";
  // hiddenDiv.style.whiteSpace = "pre-wrap";
  // hiddenDiv.style.wordWrap = "break-word";
  // for (let i of textareas) {
  //   (function (i) {
  //     i.addEventListener("input", function () {
  //       i.parentNode.appendChild(hiddenDiv);
  //       i.style.resize = "none";
  //       i.style.overflow = "hidden";
  //       content = i.value;
  //       content = content.replace(/\n/g, "<br>");
  //       hiddenDiv.innerHTML = content + '<br style="line-height: 3px;">';
  //       hiddenDiv.style.visibility = "hidden";
  //       hiddenDiv.style.display = "block";
  //       i.style.height = hiddenDiv.offsetHeight + "px";
  //       hiddenDiv.style.visibility = "visible";
  //       hiddenDiv.style.display = "none";
  //     });
  //   })(i);
  // }

  const SubmitFirstBtn = (id, heading, desc) => {
    dispatch(
      updateHeadingText({
        id: id,
        heading: heading,
        heading_decription: desc,
      })
    );
  };

  const SubmitBtn = (id) => {
    if (id === 16) {
      dispatch(
        updateCMSText({
          id,
          key: "page_description",
          value: serviceSubtitile,
        })
      );
    } else {
      dispatch(
        updateCMSText({
          id,
          key: "page_description",
          value: pageHeading,
        })
      );
    }
  };

  return (
    <>
      <div className="dkg-cand-services-Side">
        <Tab.Container defaultActiveKey="first">
          <div className="row m-0 dkg-payment-qt-tab-mainCon">
            <div className="col-md-2 pl-0 dkg-payment-qt-tabCon dkg-profile-task-sideMenu">
              <Nav
                variant="pills"
                className="flex-column dkg-payment-qt-tab-navpills"
              >
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="first"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Title
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="subtitle"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Subtitle
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="dkg-payment-qt-tab-nav-item">
                  <Nav.Link
                    eventKey="second"
                    className="dkg-payment-qt-tab-navlink"
                  >
                    Description
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-md-10  dkg-paidservices-rightCon">
              <Tab.Content className="dkg-paidservices-tabcontent">
                <Tab.Pane eventKey="first" className="dkg-paidservices-tabpane">
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Title</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <input
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue={
                        pageHeading != "" ? pageHeading.page_description : ""
                      }
                      onChange={(e) => setPageHeading(e.target.value)}
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                        onClick={(e) => SubmitBtn(2)}
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="subtitle"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Subtitle</h4>
                  </div>
                  <div className="dkg-paidservices-body">
                    <textarea
                      type="text"
                      className="dkg-cand-dash-input"
                      name="cand-dash-input"
                      id="cand-dash-input"
                      defaultValue={
                        serviceSubtitile != ""
                          ? serviceSubtitile.page_description
                          : ""
                      }
                      onChange={(e) => setServiceSubtitile(e.target.value)}
                    />
                    <div className="dkg-cand-submit-btnCon">
                      <SubmitButton
                        txt="SAVE"
                        className="dkg-cand-submit-btn"
                        onClick={(e) => SubmitBtn(16)}
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  className="dkg-paidservices-tabpane"
                >
                  <div className="dkg-paidservices-header">
                    <h4 className="dkg-paidservices-title">Description</h4>
                  </div>
                  <div className="dkg-paidservices-tablebody">
                    <div className="dkg-principale-tableCon">
                      <table className="table table-bordered dkg-principale-table">
                        <tbody>
                          <tr>
                            <td>
                              <div className="dkg-uplaod-imageCon">
                                <div className="d-flex align-items-center">
                                  <div className="dk-edit-userImgCon">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="sixthImg"
                                      id="3"
                                      onChange={onSelectFile}
                                    />
                                    <span className="dk-myprofile-userImgCon">
                                      <figure id="edit_image">
                                        {sixthImg ? (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={
                                              data != ""
                                                ? process.env
                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                  "/" +
                                                  data[2].image
                                                : ChangeIcon
                                            }
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={sixthImgbase64}
                                          />
                                        )}
                                      </figure>
                                    </span>
                                    <span className="dk-myprofile-editicon">
                                      <FaEdit />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                defaultValue={firstHeading}
                                onChange={(e) =>
                                  setFirstHeading(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control txta"
                                name="title"
                                defaultValue={firstDescription}
                                onChange={(e) =>
                                  setFirstDescription(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                  txt="SAVE"
                                  className="dkg-cand-submit-btn"
                                  onClick={(e) =>
                                    SubmitFirstBtn(
                                      3,
                                      firstHeading,
                                      firstDescription
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="dkg-uplaod-imageCon">
                                <div className="d-flex align-items-center">
                                  <div className="dk-edit-userImgCon">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="firstImg"
                                      id="4"
                                      onChange={onSelectFile}
                                    />
                                    <span className="dk-myprofile-userImgCon">
                                      <figure id="edit_image">
                                        {firstImg ? (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={
                                              data != ""
                                                ? process.env
                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                  "/" +
                                                  data[3].image
                                                : ChangeIcon
                                            }
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={firstImgbase64}
                                          />
                                        )}
                                      </figure>
                                    </span>
                                    <span className="dk-myprofile-editicon">
                                      <FaEdit />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                defaultValue={secondHeading}
                                onChange={(e) =>
                                  setSecondHeading(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control txta"
                                name="title"
                                defaultValue={secondDescription}
                                onChange={(e) =>
                                  setSecondDescription(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                  txt="SAVE"
                                  className="dkg-cand-submit-btn"
                                  onClick={(e) =>
                                    SubmitFirstBtn(
                                      4,
                                      secondHeading,
                                      secondDescription
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="dkg-uplaod-imageCon">
                                <div className="d-flex align-items-center">
                                  <div className="dk-edit-userImgCon">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="secondImg"
                                      id="5"
                                      onChange={onSelectFile}
                                    />
                                    <span className="dk-myprofile-userImgCon">
                                      <figure id="edit_image">
                                        {secondImg ? (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={
                                              data != ""
                                                ? process.env
                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                  "/" +
                                                  data[4].image
                                                : ChangeIcon
                                            }
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={secondImgbase64}
                                          />
                                        )}
                                      </figure>
                                    </span>
                                    <span className="dk-myprofile-editicon">
                                      <FaEdit />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                defaultValue={thirdHeading}
                                onChange={(e) =>
                                  setThirdHeading(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control txta"
                                name="title"
                                defaultValue={thirdDescription}
                                onChange={(e) =>
                                  setThirdDescription(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                  txt="SAVE"
                                  className="dkg-cand-submit-btn"
                                  onClick={(e) =>
                                    SubmitFirstBtn(
                                      5,
                                      thirdHeading,
                                      thirdDescription
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="dkg-uplaod-imageCon">
                                <div className="d-flex align-items-center">
                                  <div className="dk-edit-userImgCon">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="thirdImg"
                                      id="6"
                                      onChange={onSelectFile}
                                    />
                                    <span className="dk-myprofile-userImgCon">
                                      <figure id="edit_image">
                                        {thirdImg ? (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={
                                              data != ""
                                                ? process.env
                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                  "/" +
                                                  data[5].image
                                                : ChangeIcon
                                            }
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={thirdImgbase64}
                                          />
                                        )}
                                      </figure>
                                    </span>
                                    <span className="dk-myprofile-editicon">
                                      <FaEdit />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                defaultValue={fourthHeading}
                                onChange={(e) =>
                                  setFourthHeading(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control txta"
                                name="title"
                                defaultValue={fourthDescription}
                                onChange={(e) =>
                                  setFourthDescription(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                  txt="SAVE"
                                  className="dkg-cand-submit-btn"
                                  onClick={(e) =>
                                    SubmitFirstBtn(
                                      6,
                                      fourthHeading,
                                      fourthDescription
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="dkg-uplaod-imageCon">
                                <div className="d-flex align-items-center">
                                  <div className="dk-edit-userImgCon">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="sevenImg"
                                      id="7"
                                      onChange={onSelectFile}
                                    />
                                    <span className="dk-myprofile-userImgCon">
                                      <figure id="edit_image">
                                        {sevenImg ? (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={
                                              data != ""
                                                ? process.env
                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                  "/" +
                                                  data[6].image
                                                : ChangeIcon
                                            }
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={sevenImgbase64}
                                          />
                                        )}
                                      </figure>
                                    </span>
                                    <span className="dk-myprofile-editicon">
                                      <FaEdit />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                defaultValue={fifthHeading}
                                onChange={(e) =>
                                  setFifthHeading(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control txta"
                                name="title"
                                defaultValue={fifthDescription}
                                onChange={(e) =>
                                  setFifthDescription(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                  txt="SAVE"
                                  className="dkg-cand-submit-btn"
                                  onClick={(e) =>
                                    SubmitFirstBtn(
                                      7,
                                      fifthHeading,
                                      fifthDescription
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="dkg-uplaod-imageCon">
                                <div className="d-flex align-items-center">
                                  <div className="dk-edit-userImgCon">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="fourthImg"
                                      id="8"
                                      onChange={onSelectFile}
                                    />
                                    <span className="dk-myprofile-userImgCon">
                                      <figure id="edit_image">
                                        {fourthImg ? (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={
                                              data != ""
                                                ? process.env
                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                  "/" +
                                                  data[7].image
                                                : ChangeIcon
                                            }
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={fourthImgbase64}
                                          />
                                        )}
                                      </figure>
                                    </span>
                                    <span className="dk-myprofile-editicon">
                                      <FaEdit />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                defaultValue={sixHeading}
                                onChange={(e) => setSixHeading(e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control txta"
                                name="title"
                                defaultValue={sixDescription}
                                onChange={(e) =>
                                  setSixDescription(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                  txt="SAVE"
                                  className="dkg-cand-submit-btn"
                                  onClick={(e) =>
                                    SubmitFirstBtn(
                                      8,
                                      sixHeading,
                                      sixDescription
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="dkg-uplaod-imageCon">
                                <div className="d-flex align-items-center">
                                  <div className="dk-edit-userImgCon">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="fifthImg"
                                      id="9"
                                      onChange={onSelectFile}
                                    />
                                    <span className="dk-myprofile-userImgCon">
                                      <figure id="edit_image">
                                        {fifthImg ? (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={
                                              data != ""
                                                ? process.env
                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                  "/" +
                                                  data[8].image
                                                : ChangeIcon
                                            }
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={fifthImgbase64}
                                          />
                                        )}
                                      </figure>
                                    </span>
                                    <span className="dk-myprofile-editicon">
                                      <FaEdit />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                defaultValue={sevenHeading}
                                onChange={(e) =>
                                  setSevenHeading(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control txta"
                                name="title"
                                defaultValue={sevenDescription}
                                onChange={(e) =>
                                  setSevenDescription(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                  txt="SAVE"
                                  className="dkg-cand-submit-btn"
                                  onClick={(e) =>
                                    SubmitFirstBtn(
                                      9,
                                      sevenHeading,
                                      sevenDescription
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="dkg-uplaod-imageCon">
                                <div className="d-flex align-items-center">
                                  <div className="dk-edit-userImgCon">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="eightImg"
                                      id="10"
                                      onChange={onSelectFile}
                                    />
                                    <span className="dk-myprofile-userImgCon">
                                      <figure id="edit_image">
                                        {eightImg ? (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={
                                              data != ""
                                                ? process.env
                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                  "/" +
                                                  data[9].image
                                                : ChangeIcon
                                            }
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={eightImgbase64}
                                          />
                                        )}
                                      </figure>
                                    </span>
                                    <span className="dk-myprofile-editicon">
                                      <FaEdit />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                defaultValue={eightHeading}
                                onChange={(e) =>
                                  setEightHeading(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control txta"
                                name="title"
                                defaultValue={eightDescription}
                                onChange={(e) =>
                                  setEightDescription(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                  txt="SAVE"
                                  className="dkg-cand-submit-btn"
                                  onClick={(e) =>
                                    SubmitFirstBtn(
                                      10,
                                      eightHeading,
                                      eightDescription
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="dkg-uplaod-imageCon">
                                <div className="d-flex align-items-center">
                                  <div className="dk-edit-userImgCon">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="nineImg"
                                      id="11"
                                      onChange={onSelectFile}
                                    />
                                    <span className="dk-myprofile-userImgCon">
                                      <figure id="edit_image">
                                        {nineImg ? (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={
                                              data != ""
                                                ? process.env
                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                  "/" +
                                                  data[10].image
                                                : ChangeIcon
                                            }
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={nineImgbase64}
                                          />
                                        )}
                                      </figure>
                                    </span>
                                    <span className="dk-myprofile-editicon">
                                      <FaEdit />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                defaultValue={nineHeading}
                                onChange={(e) => setNineHeading(e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control txta"
                                name="title"
                                defaultValue={nineDescription}
                                onChange={(e) =>
                                  setNineDescription(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                  txt="SAVE"
                                  className="dkg-cand-submit-btn"
                                  onClick={(e) =>
                                    SubmitFirstBtn(
                                      11,
                                      nineHeading,
                                      nineDescription
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="dkg-uplaod-imageCon">
                                <div className="d-flex align-items-center">
                                  <div className="dk-edit-userImgCon">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="tenImg"
                                      id="13"
                                      onChange={onSelectFile}
                                    />
                                    <span className="dk-myprofile-userImgCon">
                                      <figure id="edit_image">
                                        {tenImg ? (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={
                                              data != ""
                                                ? process.env
                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                  "/" +
                                                  data[12].image
                                                : ChangeIcon
                                            }
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={tenImgbase64}
                                          />
                                        )}
                                      </figure>
                                    </span>
                                    <span className="dk-myprofile-editicon">
                                      <FaEdit />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                defaultValue={tenHeading}
                                onChange={(e) => setTenHeading(e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control txta"
                                name="title"
                                defaultValue={tenDescription}
                                onChange={(e) =>
                                  setTenDescription(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                  txt="SAVE"
                                  className="dkg-cand-submit-btn"
                                  onClick={(e) =>
                                    SubmitFirstBtn(
                                      13,
                                      tenHeading,
                                      tenDescription
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="dkg-uplaod-imageCon">
                                <div className="d-flex align-items-center">
                                  <div className="dk-edit-userImgCon">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="elevenImg"
                                      id="14"
                                      onChange={onSelectFile}
                                    />
                                    <span className="dk-myprofile-userImgCon">
                                      <figure id="edit_image">
                                        {elevenImg ? (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={
                                              data != ""
                                                ? process.env
                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                  "/" +
                                                  data[13].image
                                                : ChangeIcon
                                            }
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={elevenImgbase64}
                                          />
                                        )}
                                      </figure>
                                    </span>
                                    <span className="dk-myprofile-editicon">
                                      <FaEdit />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                defaultValue={elevenHeading}
                                onChange={(e) =>
                                  setElevenHeading(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control txta"
                                name="title"
                                defaultValue={elevenDescription}
                                onChange={(e) =>
                                  setElevenDescription(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                  txt="SAVE"
                                  className="dkg-cand-submit-btn"
                                  onClick={(e) =>
                                    SubmitFirstBtn(
                                      14,
                                      elevenHeading,
                                      elevenDescription
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="dkg-uplaod-imageCon">
                                <div className="d-flex align-items-center">
                                  <div className="dk-edit-userImgCon">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="twelveImg"
                                      id="15"
                                      onChange={onSelectFile}
                                    />
                                    <span className="dk-myprofile-userImgCon">
                                      <figure id="edit_image">
                                        {twelveImg ? (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={
                                              data != ""
                                                ? process.env
                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                  "/" +
                                                  data[14].image
                                                : ChangeIcon
                                            }
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={twelveImgbase64}
                                          />
                                        )}
                                      </figure>
                                    </span>
                                    <span className="dk-myprofile-editicon">
                                      <FaEdit />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                defaultValue={twelveHeading}
                                onChange={(e) =>
                                  setTwelveHeading(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control txta"
                                name="title"
                                defaultValue={twelveDescription}
                                onChange={(e) =>
                                  setTwelveDescription(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                  txt="SAVE"
                                  className="dkg-cand-submit-btn"
                                  onClick={(e) =>
                                    SubmitFirstBtn(
                                      15,
                                      twelveHeading,
                                      twelveDescription
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="dkg-uplaod-imageCon">
                                <div className="d-flex align-items-center">
                                  <div className="dk-edit-userImgCon">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="thirtenImg"
                                      id="24"
                                      onChange={onSelectFile}
                                    />
                                    <span className="dk-myprofile-userImgCon">
                                      <figure id="edit_image">
                                        {thirteenImg ? (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={
                                              data != ""
                                                ? process.env
                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                  "/" +
                                                  data[23].image
                                                : ChangeIcon
                                            }
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={thirteenImgbase64}
                                          />
                                        )}
                                      </figure>
                                    </span>
                                    <span className="dk-myprofile-editicon">
                                      <FaEdit />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                defaultValue={thirtenHeading}
                                onChange={(e) =>
                                  setThirtenHeading(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control txta"
                                name="title"
                                defaultValue={thirtenDescription}
                                onChange={(e) =>
                                  setThirtenDescription(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                  txt="SAVE"
                                  className="dkg-cand-submit-btn"
                                  onClick={(e) =>
                                    SubmitFirstBtn(
                                      24,
                                      thirtenHeading,
                                      thirtenDescription
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="dkg-uplaod-imageCon">
                                <div className="d-flex align-items-center">
                                  <div className="dk-edit-userImgCon">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="fourtenImg"
                                      id="25"
                                      onChange={onSelectFile}
                                    />
                                    <span className="dk-myprofile-userImgCon">
                                      <figure id="edit_image">
                                        {fourteenImg ? (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={
                                              data != ""
                                                ? process.env
                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                  "/" +
                                                  data[24].image
                                                : ChangeIcon
                                            }
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={fourteenImgbase64}
                                          />
                                        )}
                                      </figure>
                                    </span>
                                    <span className="dk-myprofile-editicon">
                                      <FaEdit />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                defaultValue={fourteenHeading}
                                onChange={(e) =>
                                  setfourteenHeading(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control txta"
                                name="title"
                                defaultValue={fourteenDescription}
                                onChange={(e) =>
                                  setfourteenDescription(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                  txt="SAVE"
                                  className="dkg-cand-submit-btn"
                                  onClick={(e) =>
                                    SubmitFirstBtn(
                                      25,
                                      fourteenHeading,
                                      fourteenDescription
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="dkg-uplaod-imageCon">
                                <div className="d-flex align-items-center">
                                  <div className="dk-edit-userImgCon">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="fiftenImg"
                                      id="26"
                                      onChange={onSelectFile}
                                    />
                                    <span className="dk-myprofile-userImgCon">
                                      <figure id="edit_image">
                                        {fifteenImg ? (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={
                                              data != ""
                                                ? process.env
                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                  "/" +
                                                  data[25].image
                                                : ChangeIcon
                                            }
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            className="dkg-changIcon"
                                            src={fifteenImgbase64}
                                          />
                                        )}
                                      </figure>
                                    </span>
                                    <span className="dk-myprofile-editicon">
                                      <FaEdit />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                defaultValue={fifteenHeading}
                                onChange={(e) =>
                                  setfifteenHeading(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control txta"
                                name="title"
                                defaultValue={fifteenDescription}
                                onChange={(e) =>
                                  setfifteenDescription(e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <div className="dkg-cand-submit-btnCon">
                                <SubmitButton
                                  txt="SAVE"
                                  className="dkg-cand-submit-btn"
                                  onClick={(e) =>
                                    SubmitFirstBtn(
                                      26,
                                      fifteenHeading,
                                      fifteenDescription
                                    )
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default SecondSidemenu;
