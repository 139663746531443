import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Modal } from 'react-bootstrap';
import { companyDoc } from '../../../../../../../slice/companies/clients/clientSlice';
import "./documents.scss";
import { Link } from 'react-router-dom'
import { FaEye } from "react-icons/fa";

const DocumentNwModal = ({ name, canId }) => {
    
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = async () => {
        setShow(true);

    }    

    const [frameType, setFrameType] = useState('0')
    const [fileUrl, setFileUrl] = useState('0')

    function isEmpty(obj) {
        for (var i in obj) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (show) {
            async function fetchData() {
                const result = await dispatch(companyDoc({ clientId:canId }))               
                if (!isEmpty(result.payload.result)) {
                    const fileName = result.payload.result.file
                     
                    if (fileName != null || '') {
                        setFrameType(fileName.split('.').pop());
                        setFileUrl(fileName);                        
                    } else {
                        setFileUrl('0')
                    }
                } else {
                    setFileUrl('0')
                }
            }
            fetchData();
        }
    }, [show])   

    return (
        <>
            
            <div className="dkg-file-actions cursor-pointer" onClick={handleShow} >
            <Link to="#" className="dkg-viewfile-icon mr-2">
                <FaEye />
            </Link>
        </div>
            <Modal show={show} onHide={handleClose} dialogClassName="dk-pipedocs-previewModal dkg-pipeline-candModal-234"
                aria-labelledby="example-custom-modal-styling-title"
                centered animation={false}>
                <Modal.Header closeButton className='dkg-pipeline-candModal-header'>

                </Modal.Header>
                {                   

                    (frameType != '0') ?
                        (fileUrl != '0') ?
                            <Modal.Body className='dk-pipedocs-preview-modalbody'>
                              
                                {
                                    (frameType == 'pdf' || frameType == 'PDF' || frameType == 'txt' || frameType == 'jpg' || frameType =='png' || frameType =='jpg' || frameType =='JPG')  ?
                                        <iframe src={process.env.REACT_APP_INTERNAL_BASE_URL +'/'+ fileUrl} frameBorder={0}></iframe>
                                        :
                                        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${process.env.REACT_APP_INTERNAL_BASE_URL +'/'+ fileUrl}`} frameBorder={0}></iframe>
                                }
                            </Modal.Body>
                            : <Modal.Body className='dk-pipedocs-preview-modalbody'>
                                <h1>Document Not Found</h1>
                            </Modal.Body>
                        : null
                }
            </Modal>
        </>
    )
}
export default DocumentNwModal;