import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { FaEdit } from "react-icons/fa";
import {
  updateCategory,
  clearState,
} from "../../../../../../../slice/prescreeningForm";
import SubmitButton from "../../../../../../ui/submitButton";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../../utils/messages/messageSlice";

const EditCategory = ({ catIdds, catName, activeEditTab }) => {
  const dispatch = useDispatch();

  const { isSuccess, isError, isCatUpdate, langcategoryList } = useSelector(
    (state) => state.screeningFormScript
  );
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setCategoryId(catIdds);
    setCategoryName(catName);
  }, [catIdds, catName]);

  const updateNewCategory = (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);
    if (!categoryName) {
      dispatch(showError({ msg: "Please enter category naem  required" }));
      setLoading(false);
    } else {
      setLoading(true);
      dispatch(updateCategory({ id: categoryId, categoryName }));
    }
  };

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setShow(false);
      setLoading(false);
      setSuccess(false);
    }
    if (isCatUpdate) {
      dispatch(clearState());
      setShow(false);
      setLoading(false);
      setSuccess(false);
    }
  }, [isError, isCatUpdate]);

  return (
    <>
      {activeEditTab ? (
        <span className="dk-resCrcleIconCon" onClick={handleShow}>
          <FaEdit />
        </span>
      ) : (
        <span className="dk-resCrcleIconCon" style={{ cursor: "not-allowed" }}>
          <FaEdit />
        </span>
      )}

      <Modal show={show} onHide={handleClose} className="dkg-script-modal-2345">
        <Modal.Header closeButton>
          <Modal.Title>Edit Main Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="">Name of Category</label>
              <input
                type="text"
                className="form-control"
                defaultValue={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="dkg-closebtn" onClick={handleClose}>
            Close
          </button>
          {/* <button className='dkg-submitbtn'>
                        Update
                    </button> */}
          <SubmitButton
            txt="Update"
            loading={loading}
            success={success}
            onClick={updateNewCategory}
            position="justify-content-center"
            className="dkg-submitbtn"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditCategory;
