import React from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../../../elements/header/Header'
import Navbar from "../../../elements/navbar/mainmenu/MainNavbar";
import Submenu from '../../../elements/submenu/mainmenu/DashboardSubmenu';
import './financemodule.scss';
import Casflow from '../../../../assets/images/cash-flow.png';
import Contracts from '../../../../assets/images/contracts.png';
import Bankwhite from '../../../../assets/images/bank-white.png';
import Clients from '../../../../assets/images/euro.png';
import Invoices from '../../../../assets/images/invoice-white.png';
import Information from '../../../../assets/images/information_white.png';
import Report from '../../../../assets/images/report_white.png';

const AccountsModule = () => {
    return (
        <>
            <AdminHeader />
            <Navbar />
            <Submenu />
            <div className="dk-master-moduleCon dk-financeAccounting-container">
                <div className="dk-module-blocksContainer">
                    <div className="w-100">
                        <h4 className="title" style={{ marginTop: '-8px', color: "#504d4d" }}>ACCOUNTING Department</h4>
                        <div className="dk-portalCols">
                            <div className="dk-portalBox dkg-account-grey-boxs" style={{ backgroundColor: "#fff", border: "1px solid #ddd" }}>
                                <div className="title">INVOICE BOOK</div>
                                <div className="dk-textPanel">
                                    <table className="table dkg-accounting-boxtable">
                                        <tbody>
                                            <tr>
                                                <td className="dkg-orange-color">Open Invoice</td>
                                                <td className="dkg-orange-color">
                                                    8
                                                </td>
                                                <td className="dkg-green-color">Closed Invoice</td>
                                                <td className="dkg-green-color">
                                                    4
                                                </td>
                                                <td className="dkg-red-color">Credit Notes</td>
                                                <td className="dkg-red-color">
                                                    8
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="dk-portalBox dkg-account-grey-boxs" style={{ backgroundColor: "#fff", border: "1px solid #ddd" }}>
                                <div className="title">BANK STATEMENT</div>
                                <div className="dk-textPanel">
                                    <table className="table dkg-accounting-boxtable">
                                        <tbody>
                                            <tr>
                                                <td className="dkg-green-color">Total Paid In</td>
                                                <td className="dkg-green-color">
                                                    23455
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="dkg-red-color">Total Paid Out</td>
                                                <td className="dkg-red-color">
                                                    21789
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="dk-portalBox dkg-account-grey-boxs" style={{ backgroundColor: "#fff", border: "1px solid #ddd" }}>
                                <div className="title">Cashflow</div>
                                <div className="dk-textPanel">
                                    <table className="table dkg-accounting-boxtable">

                                        <tbody>
                                            <tr>
                                                <td className="dkg-green-color">Total Income</td>
                                                <td className="dkg-green-color">
                                                    34567€
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="dkg-red-color">Total Expense</td>
                                                <td className="dkg-red-color">
                                                    33245€
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="dk-portalBox dkg-account-grey-boxs" style={{ backgroundColor: "#fff", border: "1px solid #ddd" }}>
                                <div className="title">PAYMENTS</div>
                                <div className="dk-textPanel">
                                    <table className="table dkg-accounting-boxtable">
                                        <tbody>
                                            <tr>
                                                <td className="dkg-orange-color">New Task</td>
                                                <td className="dkg-orange-color">8</td>
                                            </tr>
                                            <tr>
                                                <td className="dkg-green-color">Partial Paid</td>
                                                <td className="dkg-green-color">29.00</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="dk-portalCols">
                            <div className="dk-portalBox dk-payroll-boxbg">
                                <Link to="#">
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Invoices}
                                                className="img-fluid"
                                                alt="Clients Invoices"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Invoice Book</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="dk-portalBox dk-banking-boxbg">
                                <Link to="#">
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Bankwhite}
                                                className="img-fluid"
                                                alt="Bankwhite"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Bank Statement</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="dk-portalBox dk-cashflow-boxbg">
                                <Link to="#">
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Casflow}
                                                alt="PR"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Cashflow </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="dk-portalBox dk-income-boxbg">
                                <Link to="#">
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Clients}
                                                className="img-fluid"
                                                alt="PAYMENTS"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Payments</div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="dk-portalCols">
                            {/* <div className="dk-portalBox accounting-boxbg">
                                <Link to="#" onClick={() => setModule('accounting', '#9D848E')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Accounting}
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Company Doc’s</div>
                                    </div>
                                </Link>
                            </div> */}
                            <div className="dk-portalBox dk-expense-boxbg">
                                <Link to="#">
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Invoices}
                                                alt="PR"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Invoices</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="dk-portalBox dk-knowleadge-boxbg">
                                <Link to="#">
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Report}
                                                alt="VAT"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Tax Returns</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="dk-portalBox dk-business-overviewbox">
                                <Link to="#">
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Contracts}
                                                className="img-fluid"
                                                alt="Payroll"
                                                style={{ width: "35px", height: "35px", opacity: "0.9" }}
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Contracts</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="dk-portalBox dk-knowleadge-boxbg">
                                <Link to="#">
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Information}
                                                alt="LegalDocs"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">FAQ</div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountsModule